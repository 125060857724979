import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminTable } from '../../../style/projectComponents';
import { IUserOrder } from '../../../service/order-api/models';
import OrderRow from './OrderRow';

interface AdminOrdersTableProps {
  orders: IUserOrder[];
}

const AdminOrdersTable: FC<AdminOrdersTableProps> = ({ orders }) => {
  const { t } = useTranslation();
  return (
    <AdminTable>
      <thead>
        <tr>
          <th style={{ textTransform: 'uppercase' }}>{t('common.id')}</th>
          <th>{t('common.createDate')}</th>
          <th>{t('common.address')}</th>
          <th>{t('common.price')}</th>
          <th>{t('common.status')}</th>
          <th>{t('common.order')}</th>
          <th>{t('common.actions')}</th>
        </tr>
      </thead>
      <tbody>
        {orders.map((order) => (
          <OrderRow order={order} />
        ))}
      </tbody>
    </AdminTable>
  );
};

export default AdminOrdersTable;

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FlexColumn, FlexRow } from '../../style/projectComponents';
import { colors } from '../../style/colors';
import { ICategory } from '../../service/product-api/models';

const SelectButton = styled(FlexColumn)<{ selected: boolean }>`
  width: 160px;
  height: 152px;
  background: ${({ selected }) => (selected ? '#fff' : colors.mediumGray)};
  font-size: 15px;
  cursor: pointer;
  border-radius: 32px;
  justify-content: start;
  overflow: hidden;
  @media (max-width: 768px) {
    font-size: 12px;
    height: 85px;
    width: 90px;
    border-radius: 16px;
  }

  p {
    padding-top: 14px;
    font-weight: 500;
    font-size: 15px;
    @media (max-width: 768px) {
      padding-top: 7px;
      font-size: 9px;
    }
  }

  img {
    height: 126px;
    @media (max-width: 768px) {
      height: 71px;
    }
  }
`;
const Wrapper = styled(FlexRow)`
  gap: 12px;
  justify-content: start;
  @media (max-width: 768px) {
    gap: 9px;
  }
`;

interface CategorySelectorProps {
  categories: ICategory[];
  categoryId: number;
  setCategoryId: any;
}

export const CategorySelector: React.FC<CategorySelectorProps> = ({ categories, categoryId, setCategoryId }) => {
  const { t } = useTranslation();
  // TODO: скролл боковой
  const categoriesImagesList = ['all', 'men', 'women', 'kids', 'accessories'];
  const categoryImageExist = (name: string) => categoriesImagesList.some((c) => c === name.toLowerCase());
  const imageByCategoryName = (name: string) => `/images/categories/${name.toLowerCase()}.png`;

  return (
    <Wrapper>
      {categories?.map((c, id) => (
        <SelectButton selected={categoryId === id} onClick={() => setCategoryId(id)}>
          <p>{t(c?.name.toUpperCase())}</p>
          {categoryImageExist(c?.name) && <img src={imageByCategoryName(c.name)} alt="" />}
        </SelectButton>
      ))}
    </Wrapper>
  );
};

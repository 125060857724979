import { apiClient } from '../../config/serverConstants';
import {
  IGetOrderReq, IGetOrdersRes,
  IGetUserOrdersReq, IGetUserOrdersRes,
  IOrderInterface, IUpdateOrderStatusReq,
} from './models';

class OrderService implements IOrderInterface {
  async getUserOrders(data: IGetUserOrdersReq) {
    const response = await apiClient.get('/Order/getUserOrders', { params: data });
    return response.data as IGetUserOrdersRes;
  }

  async getOrder(data: IGetOrderReq) {
    const response = await apiClient.get('/Order/getOrder', { params: data });
    return response.data as IGetOrdersRes;
  }

  async getAllOrders(data: IGetUserOrdersReq) {
    const response = await apiClient.get('/Order/getAllOrders', { params: data });
    return response.data as IGetUserOrdersRes;
  }

  async donateToCharity(data: { orderId: string }) {
    const response = await apiClient.post('/Order/donateToCharity', data);
    return response.data as any;
  }

  async updateOrderStatus(data: IUpdateOrderStatusReq) {
    const response = await apiClient.post('/Order/updateOrderStatus', data);
    return response.data as any;
  }
}

export default new OrderService();

import React, {
  useCallback, useContext, useEffect, useState,
} from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import { FlexColumn } from '../../style/projectComponents';
import { PaymentMethods } from './PaymentMethods';
import { AmountToPayBlock } from './AmountToPayBlock';
import { DeliveryOptions } from './DeliveryOptions';
import { Countries, EgyptCitiesMock } from '../../service/auth-api/models';
import { Promocode } from './Promocode';
import { AvailableBalance } from './AvailableBalance';
import GlobalModalStore from '../../storage/GlobalModalStore/GlobalModalStore';
import UserApi from '../../service/user-api/UserApi';
import { queryClient } from '../../config/query-client';
import PromoApi from '../../service/promo-api/PromoApi';

const Column = styled(FlexColumn)`
  width: calc(35vw - 16px);
  gap: 22px;
  @media (max-width: 768px) {
    display: none;
    width: 100%;
  }
`;

interface PaymentColumnProps {
  price: number;
  totalPriceInUsd: number;
  totalTickets: number;
  setCartStage: any;
  isCheckout: boolean;
  address: string;
  setCharityModal: any;
}

export const PaymentColumn: React.FC<PaymentColumnProps> = ({
  price,
  totalPriceInUsd, setCartStage,
  totalTickets,
  isCheckout,
  address,
  setCharityModal,
}) => {
  const GlobalModalStorage = useContext(GlobalModalStore);
  const [charity, setCharity] = useState(true);

  const apartmentData = address && address?.length > 0 && address?.split('-ADR-')?.[0] || '';
  const streetData = address && address?.length > 0 && address?.split('-ADR-')?.[1] || '';
  const areaData = address && address?.length > 0 && address?.split('-ADR-')?.[2] || '';
  const cityData = address && address?.length > 0 && address?.split('-ADR-')?.[3] || '';

  const { data: cities } = useQuery(
    ['cities_list'],
    () => UserApi.getCitiesList({
      skip: 0, take: 200,
    }),
  );
  const cityItems = cities?.items;
  const cityStrings = cityItems?.map((c) => c?.name);

  // address
  const [apartment, setApartment] = useState(apartmentData);
  const [street, setStreet] = useState(streetData);
  const [area, setArea] = useState(areaData);

  const [countryId, setCountryId] = useState(0);
  const [cityId, setCityId] = useState(EgyptCitiesMock?.findIndex((g) => g.toLowerCase() === cityData?.toLowerCase()) || 0);

  const backendAddress = `${apartment}-ADR-${street}-ADR-${area}-ADR-${cityStrings?.[cityId] || ''}-ADR-${Countries[countryId]}`;
  const deliveryPrice = cityItems?.[cityId]?.deliveryPrice || 0;
  const deliveryPriceInUsd = cityItems?.[cityId]?.deliveryPriceInUsd || 0;

  const {
    mutate: newAddressMutate,
  } = useMutation((data: { address: string }) => UserApi.changeAddress(data), {
    onError: () => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['user_address'] });
    },
  });

  const selectDebounce = useCallback(() => {
    newAddressMutate({ address: backendAddress });
  }, [backendAddress]);

  useEffect(selectDebounce, [backendAddress]);

  const [isActiveWallet, setIsActiveWallet] = useState(false);

  const { data: promocodes, isLoading } = useQuery(
    'get_all_promo_codes',
    () => PromoApi.get(
      {
        skip: 0,
        take: 100,
      },
    ),
  );
  const [promo, setPromo] = useState('');
  const promoItems = promocodes?.items;
  const discount = promoItems?.find((p) => p.text === promo)?.value || 0;

  return (
    <Column style={{ display: isCheckout && 'flex' }}>
      <AmountToPayBlock
        price={price}
        totalPriceInUsd={totalPriceInUsd}
        charity={charity}
        discount={discount}
        deliveryPrice={deliveryPrice}
        deliveryPriceInUsd={deliveryPriceInUsd}
      />
      <DeliveryOptions
        charity={charity}
        apartment={apartment}
        street={street}
        area={area}
        totalTickets={totalTickets}
        setCharity={setCharity}
        setApartment={setApartment}
        setStreet={setStreet}
        cityId={cityId}
        countryId={countryId}
        setCountryId={setCountryId}
        setCityId={setCityId}
        setArea={setArea}
        setCharityModal={setCharityModal}
        cityList={cityStrings}
        deliveryPrice={deliveryPrice}
        deliveryPriceInUsd={deliveryPriceInUsd}
      />
      <AvailableBalance isActive={isActiveWallet} setIsActive={setIsActiveWallet} />
      <Promocode promo={promo} setPromo={setPromo} />
      <PaymentMethods
        setCartStage={setCartStage}
        charity={charity}
        address={backendAddress}
        useWalletBalance={isActiveWallet}
        cityId={cityId}
      />
    </Column>
  );
};

import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../../style/colors';
import { FlexColumnStart, StyledButton } from '../../../../style/projectComponents';
import LanguageStorage from '../../../../storage/LanguageStorage/LanguageStorage';

const Block = styled(FlexColumnStart)`
  cursor: pointer;
  width: 100%;
  height: 184px;
  border-radius: 30px;
  background: linear-gradient(264.25deg, #CDFF05 0.04%, #103190 97.11%);
  position: relative;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 108px;
  }
`;

const Title = styled.p`
  font-size: 50px;
  font-weight: 700;
  text-align: left;
  color: transparent;
  -webkit-text-stroke: 1px #fff;
  text-stroke: 1px #fff;
  -webkit-background-clip: text;
  background-clip: text;
  font-family: 'Maswen', sans-serif;
  line-height: 125%;
  @media (max-width: 1180px) {
    font-size: 40px;
  }
  @media (max-width: 768px) {
    font-size: 29px;
  }
`;
const Description = styled.p`
  font-size: 21px;
  margin-bottom: 12px;
  color: #fff;
  @media (max-width: 1180px) {
    font-size: 16px;
  }
  @media (max-width: 768px) {
    margin-bottom: 6px;
    font-size: 12px;
  }
`;

const ActionButton = styled(StyledButton)`
  font-size: 12px;
  font-weight: 400;
  width: 200px;
  height: 42px;
  border-radius: 22px;
  background: ${colors.primary};
  color: #fff;
  @media (max-width: 768px) {
    width: 142px;
    height: 30px;
  }
`;
const Wrapper = styled(FlexColumnStart)`
  margin-left: 23px;
  margin-right: 23px;
  @media (max-width: 768px) {
    margin-left: 15px;
  }
`;
const Holm = styled.img`
  position: absolute;
  right: 23px;
  bottom: 0;
  width: 200px;
  @media (max-width: 768px) {
    width: 115px;
  }
`;

export const PromoBlockSecond: FC = () => {
  const { t } = useTranslation();
  const LanguageStore = useContext(LanguageStorage);

  return (
    <Block>
      <Wrapper>
        <Title>{t('common.exclusiveOffers')}</Title>
        <Description>{t('common.onlyOnWhatsappChannel')}</Description>
        <ActionButton variant="green" onClick={() => console.log('click')}>
          {t('common.subscribeNow')}
        </ActionButton>
      </Wrapper>
      <Holm
        style={LanguageStore.language === 'ar' ? { left: 23, right: 'auto' } : { right: 23 }}
        src="/images/mock/banner/banner-green-holm.svg"
        alt=""
      />
    </Block>
  );
};

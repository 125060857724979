import React, { FC } from 'react';
import styled from 'styled-components';
import {
  FlexRow, FlexRowBetween,
} from '../../../style/projectComponents';
import { parseAddress } from '../../../utils/parseAddress';

const Block = styled(FlexRowBetween)`
  width: 440px;
  background: #fff;
  border-radius: 19px;
  align-items: center;
  padding: 24px;
  gap: 8px;

  p {
    font-size: 14px;
    font-weight: 400;
  }

  @media (max-width: 920px) {
    width: 100%;
  }
`;

interface DeliveryAddressProps {
  address: string;
  setEditAddress: any;
}

export const DeliveryAddress: FC<DeliveryAddressProps> = ({ address, setEditAddress }) => (
  <Block>
    <p>{address?.length > 0 && parseAddress(address)}</p>
    <FlexRow style={{ cursor: 'pointer' }} onClick={() => setEditAddress(true)}>
      <img src="/images/buttons/edit.svg" alt="" width={16} height={16} />
    </FlexRow>
  </Block>
);

import React, { FC, useMemo } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FlexColumn, FlexRowBetween, StatsTitle } from '../../../style/projectComponents';
import { DataEmptyComponent } from '../../global/DataEmptyComponent';
import { PaginationComponent } from '../../global/PaginationComponent';
import { generatePagesArray } from '../../../utils/generatePagesArray';
import { IAdminCart } from '../../../service/admin-api/models';
import AdminCartTable from './AdminCartTable';

const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1100px;
  justify-content: start;
  align-items: start;
  background: rgba(255, 255, 255, 0.3);
  padding: 26px 16px;
  border-radius: 8px;
  overflow: auto;
  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`;

interface AdminUsersProps {
  cartStats: IAdminCart[];
  isLoading: boolean;
  totalCount: number;
  itemsPerPage: number;
  page: number;
  setPage: any;
}

export const AdminCartStats: FC<AdminUsersProps> = ({
  cartStats,
  isLoading,
  totalCount,
  itemsPerPage,
  page,
  setPage,
}) => {
  const { t } = useTranslation();
  const totalPages = useMemo(
    () => (totalCount ? Math.ceil(totalCount / itemsPerPage) : 1),
    [totalCount, itemsPerPage],
  );

  return (
    <OperationsWrapper>
      <FlexRowBetween style={{ alignItems: 'center' }}>
        <StatsTitle>{t('adminAnalytics.allCarts')}</StatsTitle>
      </FlexRowBetween>
      {!isLoading && cartStats?.length > 0
        ? <AdminCartTable cartStats={cartStats} />
        : (
          <FlexColumn style={{ width: '100%' }}>
            <DataEmptyComponent />
          </FlexColumn>
        )}
      <PaginationComponent
        maxPages={totalPages}
        pageId={page}
        pagesNum={generatePagesArray(totalPages, page, 100)}
        setPageId={setPage}
      />
    </OperationsWrapper>
  );
};

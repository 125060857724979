import React, { useContext } from 'react';

import styled from 'styled-components/macro';
import { colors } from '../../style/colors';
import { FlexRow } from '../../style/projectComponents';
import { generatePagesArray } from '../../utils/generatePagesArray';
import LanguageStorage from '../../storage/LanguageStorage/LanguageStorage';

interface PageButtonProps {
  isSelected: boolean
}

const PageWrapper = styled(FlexRow)`
  align-self: center;
  width: 100%;
  position: sticky;
  bottom: 0;
  left: 0;
  @media (min-width: 768px) {
    align-self: center;
    width: 100%;
    position: sticky;
    bottom: 0;
    left: 0;
  }
`;

const PageButton = styled.button<PageButtonProps>`
  margin: 4px;
  margin-top: 20px;
  background: ${({ isSelected }) => (isSelected ? colors.primary : colors.lightGray)};
  color: ${({ isSelected }) => (isSelected ? colors.white : colors.black)};
  min-width: 32px;
  width: auto;
  height: 32px;
  border: none;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  img {
    width: 14px;
    height: 14px;
  }
`;

interface PaginationComponentProps {
  maxPages: number
  pageId: number
  pagesNum?: number[]
  setPageId: (page: number) => void
}

export const PaginationComponent: React.FC<PaginationComponentProps> = ({
  maxPages,
  pageId,
  pagesNum,
  setPageId,
}) => {
  const LanguageStore = useContext(LanguageStorage);

  if (!pagesNum) {
    pagesNum = generatePagesArray(maxPages, pageId, 100);
  }
  if (pagesNum?.length > 1) {
    return (
      <PageWrapper>
        {pageId !== 0 && (
          <PageButton
            onClick={() => {
              setPageId(pageId - 1);
            }}
            isSelected={pageId === 0}
          >
            <img
              src="/images/buttons/menu/arrow-left.svg"
              alt=""
              style={{ transform: LanguageStore.language === 'ar' ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </PageButton>
        )}

        {pagesNum
          .filter(
            (page, idx) => Math.abs(page - pageId) <= (pageId < 2 || pageId > maxPages - 2 ? 3 : 2),
          )
          .map((page, id) => (
            <PageButton
              onClick={() => {
                setPageId(page - 1);
              }}
              isSelected={page === pageId + 1}
              key={`${page}+${id}`}
            >
              {page}
            </PageButton>
          ))}

        {pageId < maxPages - 1 && (
          <PageButton
            onClick={() => {
              setPageId(pageId + 1);
            }}
            isSelected={pageId === maxPages}
          >

            <img
              src="/images/buttons/arrow-right-black.svg"
              alt=""
              style={{ transform: LanguageStore.language === 'ar' ? 'rotate(180deg)' : 'rotate(0deg)' }}
            />
          </PageButton>
        )}
      </PageWrapper>
    );
  }
};

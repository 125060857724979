import { apiClient } from '../../config/serverConstants';
import {
  IAddToFavoriteEventReq,
  ICreateLotteryEventReq, IEvent,
  IGetAllLotteryEventsReq,
  IGetAllLotteryEventsRes, IGetLotteryWinners,
  ILotteryInterface, IStartDrawingReq, IUpdateLotteryEventReq,
} from './models';

class LotteryService implements ILotteryInterface {
  async getLotteryEvent(id: string) {
    const response = await apiClient.get(`/LotteryEvents/${id}`);
    return response.data as IEvent;
  }

  async getAllLotteryEvents(data: IGetAllLotteryEventsReq) {
    const response = await apiClient.get('/LotteryEvents', { params: data });
    return response.data as IGetAllLotteryEventsRes;
  }

  async getUserFavoriteEvents(data: IGetLotteryWinners) {
    const response = await apiClient.get('/LotteryEvents/favorite', { params: data });
    return response.data as IGetAllLotteryEventsRes;
  }

  async addToFavoriteEvent(data: IAddToFavoriteEventReq) {
    const response = await apiClient.post('/LotteryEvents/addToFavorite', data);
    return response.data as any;
  }

  async updateLotteryEvent(data: IUpdateLotteryEventReq) {
    const response = await apiClient.post('/LotteryEvents/update', data);
    return response.data as any;
  }

  async startDrawing(data: IStartDrawingReq) {
    const response = await apiClient.post('/LotteryEvents/startDrawing', data);
    return response.data as any;
  }

  async removeFromFavoriteEvent(data: IAddToFavoriteEventReq) {
    const response = await apiClient.post('/LotteryEvents/removeFromFavorite', data);
    return response.data as any;
  }

  async createLotteryEvent(data: ICreateLotteryEventReq) {
    const formData = new FormData();
    formData.append('name', data.name);
    formData.append('code', data.code);
    formData.append('prizeDetails', data?.prizeDetails);
    formData.append('prizeName', data.prizeName);
    formData.append('productDetails', data.productDetails);
    formData.append('ticketsLimit', data.ticketsLimit);
    formData.append('image', data.image);
    if (data.imageId) {
      formData.append('imageId', data.imageId);
    }
    formData.append('drawDate', data.drawDate);
    const response = await apiClient.post('/LotteryEvents/create', formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data as string;
  }
}

export default new LotteryService();

import React, { useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FlexColumn, FlexRow, StyledButton } from '../../../style/projectComponents';
import { PROFILE_PAGES } from '../../../pages/ProfilePage';
import { LogoutModal } from '../../global/modal/LogoutModal';
import { colors } from '../../../style/colors';
import { MenuButton } from './MenuButton';

const Block = styled(FlexColumn)`
  width: 375px;
  @media (max-width: 720px) {
    width: 100%;
  }
`;
const ButtonsWrapper = styled(FlexRow)`
  width: 100%;
  gap: 8px;
  margin-top: 8px;
`;
const MenuColoredButton = styled(StyledButton)`
  font-size: 17px;
  font-weight: 700;
  width: 100%;
  height: 60px;
  padding: 0;
`;

interface MenuComponentProps {
}

export const MenuComponent: React.FC<MenuComponentProps> = () => {
  const { t } = useTranslation();
  const [visibleLogout, setVisibleLogout] = useState<boolean>(false);
  const navigate = useNavigate();

  const menuButtons = [
    {
      icon: '/images/buttons/menu/offer.svg',
      title: t('common.offers'),
      action: () => navigate(PROFILE_PAGES.OFFERS),
    },
    {
      icon: '/images/buttons/menu/purchases.svg',
      title: t('common.purchases'),
      action: () => navigate(PROFILE_PAGES.PURCHASES),
    },
    {
      icon: '/images/buttons/menu/my-deliveries.svg',
      title: t('common.myDeliveries'),
      action: () => navigate(PROFILE_PAGES.MY_DELIVERIES),
    },
    {
      icon: '/images/buttons/menu/ticket.svg',
      title: t('common.ticketsHistory'),
      action: () => navigate(PROFILE_PAGES.ARCHIVE_TICKETS),
    },
    {
      icon: '/images/buttons/menu/settings.svg',
      title: t('settings.title'),
      action: () => navigate(PROFILE_PAGES.SETTINGS),
    },
    {
      icon: '/images/buttons/menu/notify.svg',
      title: t('common.notificationsPreference'),
      action: () => navigate(PROFILE_PAGES.NOTIFICATIONS),
    },
    {
      icon: '/images/buttons/menu/logout.svg',
      title: t('common.logout'),
      action: () => setVisibleLogout(true),
    },
  ];

  return (
    <Block>
      {visibleLogout && <LogoutModal setIsOpen={setVisibleLogout} />}
      <MenuColoredButton
        variant="darkBlue"
        noRadius
        onClick={() => navigate(PROFILE_PAGES.USERDATA)}
      >
        {t('user.personalDetails')}
      </MenuColoredButton>
      <ButtonsWrapper>
        <MenuColoredButton
          variant="darkBlue"
          noRadius
          style={{
            border: `2px solid ${colors.primary}`,
            color: '#fff',
            background: colors.primary,
          }}
          onClick={() => navigate(PROFILE_PAGES.WISHLIST)}
        >
          {t('menu.wishlist')}
        </MenuColoredButton>
        <MenuColoredButton
          variant="darkBlue"
          noRadius
          style={{
            border: `2px solid ${colors.accent}`,
            color: colors.black,
            background: colors.accent,
          }}
          onClick={() => navigate(PROFILE_PAGES.WALLET)}
        >
          {t('common.wallet')}
        </MenuColoredButton>
      </ButtonsWrapper>
      <ButtonsWrapper style={{ marginBottom: 16 }}>
        <MenuColoredButton
          variant="primary"
          style={{
            border: `2px solid ${colors.primary}`,
            color: colors.primary,
          }}
          noRadius
          onClick={() => navigate(PROFILE_PAGES.TICKETS)}
        >
          {t('menu.footer.activeTickets')}
        </MenuColoredButton>
        <MenuColoredButton
          variant="primary"
          noRadius
          onClick={() => navigate(PROFILE_PAGES.DELIVERY_ADDRESS)}
        >
          {t('cart.deliveryAddress')}
        </MenuColoredButton>
      </ButtonsWrapper>
      {menuButtons.map((m, id) => (
        <MenuButton
          title={m.title}
          icon={m.icon}
          action={m.action}
          id={id}
          length={menuButtons.length}
        />
      ))}
    </Block>
  );
};

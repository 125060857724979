import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import {
  AuthInputWrapper, ErrorText,
  FlexColumn, InfoText, PassIcon, ProfileTabTitle, StyledAdvise, StyledButton,
} from '../../../style/projectComponents';
import { TabHeader } from './TabHeader';
import ProfileStorage from '../../../storage/ProfileStorage/ProfileStorage';
import AuthApi from '../../../service/auth-api/AuthApi';
import { REC_STAGE } from '../../../pages/ForgotPassword';
import { IChangePasswordRequest } from '../../../service/auth-api/models';
import { IFinalInput } from '../../auth/Register/FirstStep';
import { isPasswordAgainGood, isPasswordGood } from '../../auth/Register/SecUtils';
import { ValidationInputComponent } from '../../global/ValidationInputComponent';
import LanguageStorage from '../../../storage/LanguageStorage/LanguageStorage';

const Block = styled(FlexColumn)`
  align-items: start;
  width: 100%;
`;

const Wrapper = styled.form`
  display: flex;
  flex-direction: column;
  background: #fff;
  border-radius: 12px;
  width: 100%;
  max-width: 470px;
  padding: 20px;
  @media (max-width: 768px) {
    padding: 10px;
    max-width: 100%;
  }
`;

const Image = styled.img`
  width: 320px;
  margin-bottom: 10px;
  object-fit: contain;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

interface SettingsTabProps {
  action: () => void;
}

export const SettingsTab: React.FC<SettingsTabProps> = ({ action }) => {
  const {
    control,
    clearErrors,
    setValue,
    resetField,
    setError,
    register,
    watch,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      oldPassword: '',
      password: '',
      retypePassword: '',
    },
  });

  const { t } = useTranslation();
  const ProfileStore = useContext(ProfileStorage);
  const profile = ProfileStore.getProfile();
  const [stage, setStage] = useState(REC_STAGE.BASE);
  const LanguageStore = useContext(LanguageStorage);

  const updatePasswordMutation = useMutation((data: IChangePasswordRequest) => AuthApi.changePassword(data), {
    onSuccess: (data) => {
      setStage(REC_STAGE.FINAL);
    },
  });

  const onSubmit = () => {
    updatePasswordMutation.mutate({
      userId: profile?.id,
      currentPassword: getValues('oldPassword'),
      newPassword: getValues('retypePassword'),
    });
  };

  const updatePassInputs: IFinalInput[] = [
    {
      name: 'oldPassword',
      label: 'auth.oldPassword',
      type: 'text',
      rules: {
        required: {
          value: true,
        },
      },
    },
    {
      name: 'password',
      label: 'auth.newPassword',
      type: 'password',
      rules: isPasswordGood,
    },
    {
      name: 'retypePassword',
      label: 'auth.retype_password',
      type: 'password',
      rules: isPasswordAgainGood,
    },
  ];
  const advisePassword = 'settings.inputs.password.errors.pattern';
  const [showPass, setShowPass] = useState(false);
  const [showRetypePass, setShowRetypePass] = useState(false);

  const handleCheckType = (name: string, typeDefault: any) => {
    switch (name) {
      case 'password':
        return showPass ? 'text' : 'password';
      case 'retypePassword':
        return showRetypePass ? 'text' : 'password';
      default:
        return typeDefault;
    }
  };

  return (
    <Block>
      <TabHeader title="Settings" action={action} />
      <ProfileTabTitle>
        <img src="/images/buttons/menu/settings.svg" alt="" />
        <h2>{t('user.changePassword')}</h2>
      </ProfileTabTitle>
      <Wrapper autoComplete="off">
        {stage === REC_STAGE.BASE ? (
          <FlexColumn style={{ alignItems: 'start' }}>
            {updatePassInputs.map((it, idx) => (
              <FlexColumn style={{ width: '100%' }}>
                <AuthInputWrapper style={{ marginBottom: 5, marginTop: idx !== 0 && 10 }}>
                  <Controller
                    name={it.name}
                    control={control}
                    rules={it.name === 'retypePassword' ? it.rules(watch) : it.rules}
                    render={({ field: { ref, ...field } }) => (
                      <ValidationInputComponent
                        {...field}
                        autoComplete="off"
                        name={it.name}
                        type={handleCheckType(it.name, it.type)}
                        isError={!!errors[it.name]}
                        label={t(it.label)}
                      />
                    )}
                  />
                  {it.name === 'password' && (
                  <PassIcon
                    lang={LanguageStore.language}
                    onClick={() => setShowPass((prev) => !prev)}
                    src={showPass ? '/images/show.svg' : '/images/hide.svg'}
                  />
                  )}

                  {it.name === 'retypePassword' && (
                  <PassIcon
                    lang={LanguageStore.language}
                    onClick={() => setShowRetypePass((prev) => !prev)}
                    src={showRetypePass ? '/images/show.svg' : '/images/hide.svg'}
                  />
                  )}
                </AuthInputWrapper>
                {it.name === 'password' && !errors[it.name]
                                    && (
                                    <StyledAdvise>
                                      {t(advisePassword)}
                                    </StyledAdvise>
                                    )}
                <ErrorText hasError={!!errors[it.name]} style={{ maxWidth: 300 }}>
                  {t(errors[it.name]?.message?.toString())}
                </ErrorText>
              </FlexColumn>
            ))}
            <StyledButton
              variant="darkBlue"
              style={{ height: 60, marginTop: 16, width: 158 }}
              disabled={!isValid || updatePasswordMutation.isLoading}
              noRadius
              onClick={onSubmit}
              type="submit"
            >
              {t('common.update')}
            </StyledButton>
          </FlexColumn>
        )
          : (
            <>
              <Image src="/images/interface/charity-congrats.png" alt="" />
              <InfoText>
                {t('common.passwordChangeSuccess')}
              </InfoText>
            </>
          )}
      </Wrapper>
    </Block>
  );
};

import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FlexColumn } from '../../style/projectComponents';
import { colors } from '../../style/colors';
import ProfileStorage from '../../storage/ProfileStorage/ProfileStorage';

const Title = styled.h4`
  font-size: 36px;
  text-transform: uppercase;
  color: ${colors.blueDark};
  @media(max-width: 768px) {
    font-size: 30px;
  }
`;
const Circle = styled(FlexColumn)`
  background: ${colors.blueDark};
  width: 93px;
  height: 93px;
  border-radius: 100%;
  margin-top: 100px;

  img {
    width: 34px;
  }
`;

const ThankYouImage = styled.img`
  margin-top: 28px;
  margin-bottom: 16px;
  @media(max-width: 768px) {
    width: 100%;
  }
`;

export const CartFinalStage: React.FC = () => {
  const { t } = useTranslation();
  const ProfileStore = useContext(ProfileStorage);
  const userEmail = ProfileStore?.getProfile()?.email || '';

  return (
    <FlexColumn>
      <Circle>
        <img src="/images/buttons/icons/checkmark.svg" alt="" />
      </Circle>
      <ThankYouImage src="/images/interface/thank-you.svg" alt="" />
      <Title>{t('cart.yourComplete')}</Title>
    </FlexColumn>
  );
};

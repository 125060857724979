import React from 'react';
import { useQuery } from 'react-query';
import { FlexColumn } from '../../../style/projectComponents';
import OrderApi from '../../../service/order-api/OrderApi';
import { parseAddress } from '../../../utils/parseAddress';
import { OrderItem } from '../Purchases/OrderItem';
import { DeliveryStatus } from '../../../service/order-api/models';

interface DeliveryProps {
  orderId: string;
  id: number;
}

export const Delivery: React.FC<DeliveryProps> = ({ orderId, id }) => {
  const { data: orderInfo } = useQuery(
    ['order_info', orderId],
    () => OrderApi.getOrder({ id: orderId }),
  );
  const orderItems = orderInfo?.orderItems?.items;
  const orderAddress = orderInfo?.adress && parseAddress(orderInfo?.adress);
  const availableForCharity = orderInfo?.status === 'created';
  const totalProductsAmount = orderItems?.reduce((acc, o) => acc + o?.amount, 0);
  const totalTicketsAmount = orderItems?.reduce((acc, o) => acc + o?.amount * o?.productTicketsAmount, 0);

  return (
    <FlexColumn style={{ width: '100%', gap: 16 }}>
      {orderItems?.map((o) => (
        <OrderItem
          id={id}
          order={o}
          orderId={orderId}
          address={orderAddress}
          isClicked
          totalProductsAmount={totalProductsAmount}
          totalTicketsAmount={totalTicketsAmount}
          availableForCharity={availableForCharity}
          isDelivery
          status={orderInfo?.status}
          handleClick={() => console.log('mock')}
        />
      ))}
    </FlexColumn>
  );
};

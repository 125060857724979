import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn,
  FlexColumnStart, FlexRow, FlexRowBetween,
  FlexRowStart,
} from '../../style/projectComponents';
import { colors } from '../../style/colors';
import GlobalModalStore from '../../storage/GlobalModalStore/GlobalModalStore';
import { IAddProductToCartReq } from '../../service/cart-api/models';
import CartApi from '../../service/cart-api/CartApi';
import { queryClient } from '../../config/query-client';
import { useCart } from '../global/CartContext';

const ButtonBlock = styled(FlexRowStart)<{ isMini: boolean }>`
  width: 100%;
  background: ${colors.primary};
  border-radius: 32px;
  height: ${({ isMini }) => (isMini ? '50px' : '67px')};
  overflow: hidden;
  @media (max-width: 768px) {
    height: ${({ isMini }) => (isMini ? '34px' : '60px')};
  }
`;

const LeftContent = styled(FlexRow)<{ isMini: boolean }>`
  width: 60%;
  background: ${colors.accent};
  color: ${colors.primary};
  border-radius: 32px;
  height: 100%;
  gap: 8px;
  cursor: pointer;

  img {
    height: ${({ isMini }) => (isMini ? '50px' : '50px')};
    width: ${({ isMini }) => (isMini ? '50px' : '50px')};
    @media (max-width: 768px) {
      height: ${({ isMini }) => (isMini ? '32px' : '50px')};
      width: ${({ isMini }) => (isMini ? '32px' : '50px')};
    }
  }
`;
const LeftContentBetween = styled(FlexRowBetween)`
  width: 60%;
  background: ${colors.accent};
  color: ${colors.primary};
  border-radius: 32px;
  height: 100%;
  align-items: center;
  padding: 0 14px;
`;

const RightContent = styled(FlexColumn)`
  width: 40%;
  color: #fff;
  height: 100%;
  align-items: start;
  padding: 0 8px;
`;
const RightText = styled.p<{ isMini: boolean }>`
  font-size: 11px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 10px;
  }
`;
const AddTitle = styled.p<{ isMini: boolean }>`
  font-weight: 600;
  font-size: ${({ isMini }) => (isMini ? '13px' : '18px')};
  width: 100%;
  @media (max-width: 768px) {
    font-size: ${({ isMini }) => (isMini ? '11px' : '18px')};
  }
`;
const Price = styled.p<{ isMini: boolean }>`
  font-weight: 900;
  font-size: ${({ isMini }) => (isMini ? '14px' : '22px')};
  width: 100%;
  @media (max-width: 768px) {
    font-size: ${({ isMini }) => (isMini ? '11px' : '22px')};
  }
`;
const MinusButton = styled(FlexRow)<{ isMini: boolean }>`
  width: ${({ isMini }) => (isMini ? '32px' : '32px')};
  height: ${({ isMini }) => (isMini ? '32px' : '32px')};
  border-radius: 100%;
  background: #1E1E1E57;
  color: #000;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }
`;
const PlusButton = styled(MinusButton)`
  background: ${colors.black};
`;
const Amount = styled.p`
  font-size: 22px;
  font-weight: 900;
  color: ${colors.black};
`;

interface AddToCartMobileProps {
  price: number;
  priceInUsd: number;
  action: any;
  isMini?: boolean;
  amount?: number;
  productId: string;
  currency: string;
  prize: string;
}

export const AddToCartStoreButton: React.FC<AddToCartMobileProps> = ({
  price,
  action,
  isMini,
  amount,
  productId,
  priceInUsd,
  currency,
  prize,
}) => {
  const { t } = useTranslation();
  const { setShowCart } = useCart();
  const GlobalModalStorage = useContext(GlobalModalStore);

  const addProductMutation = useMutation((data: IAddProductToCartReq) => CartApi.addProductToCartReq(data), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: (data) => {
      setShowCart(true);
      queryClient.refetchQueries('get_cart_data');
    },
  });

  const removeProductMutation = useMutation((data: IAddProductToCartReq) => CartApi.removeProduct(data), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: (data) => {
      queryClient.refetchQueries('get_cart_data');
    },
  });

  const handleChangeAmountMinus = () => {
    if (amount > 0) {
      removeProductMutation.mutate({
        productId,
        amount: 1,
      });
    }
  };
  const handleChangeAmountPlus = () => {
    addProductMutation.mutate({
      productId,
      amount: 1,
    });
  };
  const priceString = useMemo(
    () => `${currency === 'EGP' && price || priceInUsd} ${currency}`,
    [currency],
  );

  return (
    <ButtonBlock isMini={isMini}>
      {(!amount || amount <= 0) ? (
        <LeftContent isMini={isMini} onClick={action}>
          <FlexColumnStart>
            <AddTitle isMini={isMini}>{t('cart.addToCart')}</AddTitle>
            <Price isMini={isMini}>{priceString}</Price>
          </FlexColumnStart>
        </LeftContent>
      )
        : (
          <LeftContentBetween>
            <MinusButton isMini={isMini} onClick={handleChangeAmountMinus}>
              <img src="/images/buttons/cart-buy-minus.svg" alt="" />
            </MinusButton>
            <Amount>{amount}</Amount>
            <PlusButton isMini={isMini} onClick={handleChangeAmountPlus}>
              <img src="/images/buttons/cart-buy-plus.svg" alt="" />
            </PlusButton>
          </LeftContentBetween>
        )}
      <RightContent>
        <RightText isMini={isMini}>
          {t('common.andWin')}
        </RightText>
        <RightText isMini={isMini}>{prize}</RightText>
      </RightContent>
    </ButtonBlock>
  );
};

export default AddToCartStoreButton;

import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { format } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { IUserOrder } from '../../../service/order-api/models';
import OrderApi from '../../../service/order-api/OrderApi';
import { parseAddress } from '../../../utils/parseAddress';
import { FlexColumn, FlexRow, StyledButton } from '../../../style/projectComponents';
import { colors } from '../../../style/colors';
import { EditOrderStatusModal } from '../EditOrderStatusModal';

interface OrderRowProps {
  order: IUserOrder;
}

const CartProduct = styled(FlexColumn)`
  img {
    width: 42px;
    height: 42px;
    border-radius: 6px;
    margin-bottom: 6px;
    border: 1px solid ${colors.gray400};
  }
`;
const Price = styled.p`
  font-size: 11px;
  font-weight: 600;
  color: ${colors.primary};
`;
const Amount = styled.p`
  font-size: 11px;
  font-weight: 600;
`;

const OrderRow: FC<OrderRowProps> = ({ order }) => {
  const { t } = useTranslation();

  const { data: orderInfo } = useQuery(
    ['order_info', order.id],
    () => OrderApi.getOrder({ id: order.id }),
  );
  const orderAddress = orderInfo?.adress && parseAddress(orderInfo?.adress);
  const getDate = (date: string) => format(new Date(date), 'dd MMM yy, HH:mm', { locale: enGB });

  const [editStatusModal, setEditStatusModal] = useState(false);
  const handleEditStatusModal = () => setEditStatusModal((s) => !s);

  return (
    <>
      {orderInfo && editStatusModal
            && <EditOrderStatusModal orderId={order.id} orderStatus={orderInfo.status} closeModal={handleEditStatusModal} />}
      <tr key={order.id}>
        <td>{order.id}</td>
        <td>{getDate(order.createDate)}</td>
        <td>{orderAddress}</td>
        <td>{order.totalPrice}{t('common.egp')} /{order.totalPriceInUsd}{t('common.usd')}</td>
        <td>{orderInfo?.status}</td>
        <td>
          <FlexRow style={{ gap: 6, alignItems: 'start' }}>
            {orderInfo?.orderItems?.items?.map((c) => (
              <CartProduct key={`${c?.productId}+${c?.prizeDetails}`}>
                <img src={c?.imageDownloadLink} alt="" />
                <p>{c?.name}</p>
                <Amount>
                  {c?.amount}
                  {' '}
                  *
                  {' '}
                  {c?.productPrice}
                  EGP
                </Amount>
                <Price>
                  {c?.amount * c?.productPrice}
                  EGP
                </Price>
              </CartProduct>
            ))}
          </FlexRow>
        </td>
        <td>
          <StyledButton
            variant="darkBlue"
            style={{ padding: 4, width: '100%' }}
            onClick={() => setEditStatusModal(true)}
          >
            {t('adminUserDetail.edit')}
          </StyledButton>
        </td>
      </tr>
    </>
  );
};
export default OrderRow;

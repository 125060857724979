import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FlexColumn, StyledButton, Text } from '../../../style/projectComponents';
import { symbols } from '../../../style/symbols';
import ProfileStorage from '../../../storage/ProfileStorage/ProfileStorage';
import { colors } from '../../../style/colors';

const Block = styled(FlexColumn)`
  width: 100%;
  margin-bottom: 16px;
  border-radius: 13px;
  background: #fff;
  padding: 24px 0;
  min-height: 102px;

  h2 {
    margin-top: 8px;
    font-size: 32px;
  }
`;

export const UserBalanceBlock: React.FC = () => {
  const { t } = useTranslation();
  const ProfileStore = useContext(ProfileStorage);
  const balance = ProfileStore.getBalance();
  const navigate = useNavigate();

  return (
    <Block>
      <Text>{t('common.availableBalanceWallet')}</Text>
      <h2>
        {symbols.egp}
        {balance.toFixed(2)}
      </h2>
      <StyledButton
        variant="darkBlue"
        noRadius
        style={{
          height: 50,
          background: colors.primary,
          border: `1px solid ${colors.primary}`,
          marginTop: 24,
        }}
        onClick={() => navigate('/register')}
      >
        {t('common.registerToSeeYourBalance')}
      </StyledButton>
    </Block>
  );
};

import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn,
  FlexRowBetween,
  StyledButton,
} from '../../style/projectComponents';
import UserStorage from '../../storage/UserStorage/UserStorage';
import { LoginModal } from '../global/modal/LoginModal';
import SelectQuantityModal from '../global/modal/SelectQuantityModal';

const Block = styled(FlexColumn)`
  background: #fff;
  box-shadow: 4px 4px 40px 0px #00000026;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  width: calc(100vw - 40px);
  padding: 0 20px;
  height: 160px;
  padding-bottom: 80px;
  z-index: 10;
  @media (min-width: 768px) {
    display: none;
  }
`;
const Price = styled.p`
  font-size: 21px;
`;

interface AddToCartMobileProps {
  price: number;
  id: string;
  isSoldOut?: boolean;
}

export const AddToCartMobile: React.FC<AddToCartMobileProps> = ({ price, id, isSoldOut }) => {
  const { t } = useTranslation();
  const UserStore = useContext(UserStorage);
  const isAuth = !!UserStore.accessToken;
  const [loginModal, setLoginModal] = useState(false);

  const [openMenu, setOpenMenu] = useState(false);
  const handleMenu = () => setOpenMenu((s) => !s);
  const addToCart = () => {
    if (isAuth) {
      setOpenMenu(true);
    } else {
      setLoginModal(true);
    }
  };

  return (
    <Block>
      <LoginModal isOpen={loginModal} setIsOpen={setLoginModal} />
      <SelectQuantityModal handleMenu={handleMenu} openMenu={openMenu} productId={id} />
      <FlexRowBetween style={{ alignItems: 'center', marginBottom: 20 }}>
        <Price>
          {t('market.price')}
        </Price>
        <h2>
          {t('common.egp')}
          {price?.toFixed(2)}
        </h2>
      </FlexRowBetween>
      <StyledButton
        variant="darkBlue"
        onClick={addToCart}
        disabled={isSoldOut}
        style={{ width: '100%', height: 60 }}
        noRadius
      >
        {isSoldOut ? 'Out of stock' : 'Add to Cart'}
      </StyledButton>
    </Block>
  );
};

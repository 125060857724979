import React, { FC } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, FlexRow, SectionTitle, SectionTitleNormal,
} from '../../../../style/projectComponents';
import { colors } from '../../../../style/colors';
import LotteryTicketsApi from '../../../../service/lottery-tickets-api/LotteryTicketsApi';
import { WinnersCards } from '../WinnersCards';
import { SoldOutCards } from './SoldOutCards';

const Block = styled(FlexColumn)`
  width: 100%;
  align-items: start;
  justify-content: start;
  margin-top: 26px;
  margin-bottom: 32px;
  background: ${colors.primary};
  padding: 53px 0 45px 0;
  box-sizing: border-box;
  border-radius: 35px;
  @media (max-width: 768px) {
    width: calc(100vw );
    margin-top: 0;
    border-radius: 0;
    padding-bottom: 0;
  }
`;

const WinnersCardsWrapper = styled(FlexRow)`
  width: 100%;
  gap: 32px;
  justify-content: start;
  overflow: scroll;
  margin-top: 32px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: start;
    gap: 16px;
    height: 700px;
    margin-top: 16px;
  }
`;
const YouImage = styled.img`
  width: 145px;
  @media (max-width: 768px) {
    width: 88px;
  }
`;

export const SoldOutCampaigns: FC = () => {
  const { t } = useTranslation();
  const { data: winners, isLoading } = useQuery(
    'all_tickets_winners',
    () => LotteryTicketsApi.getLotteryTicketsWinners(
      {
        skip: 0,
        take: 20,
      },
    ),
  );
  const winnersItems = winners?.items;
  const mockSoldOut = [
    {
      image: '/images/mock/sold_out.png',
      title: 'Coming soon',
      name: 'DC-00069',
    },
    {
      image: '/images/mock/sold_out.png',
      title: 'Coming soon',
      name: 'DC-00069',
    },
    {
      image: '/images/mock/sold_out.png',
      title: 'Coming soon',
      name: 'DC-00069',
    },
    {
      image: '/images/mock/sold_out.png',
      title: 'Coming soon',
      name: 'DC-00069',
    },
  ];

  return (
    <Block>
      <FlexRow style={{ gap: 8, marginLeft: 32, marginRight: 32 }}>
        <SectionTitle style={{ color: colors.white, marginBottom: 8 }}>{t('common.soldOutCampaigns')}</SectionTitle>
      </FlexRow>
      <SoldOutCards isLoading={isLoading} cards={mockSoldOut} />
    </Block>
  );
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { AdminTable } from '../../../style/projectComponents';
import PromoRow from './PromoRow';
import { IPromoItem } from '../../../service/promo-api/models';

interface AdminCartTableProps {
  promoItems: IPromoItem[];
}

const AdminPromoTable: FC<AdminCartTableProps> = ({ promoItems }) => {
  const { t } = useTranslation();
  return (
    <AdminTable>
      <thead>
        <tr>
          <th style={{ textTransform: 'uppercase' }}>{t('common.id')}</th>
          <th>{t('common.text')}</th>
          <th>{t('common.value')}</th>
          <th>{t('common.endDate')}</th>
          <th>{t('common.useLimit')}</th>
          <th>{t('common.wasUsed')}</th>
        </tr>
      </thead>
      <tbody>
        {promoItems.map((promoItem) => (
          <PromoRow promoItem={promoItem} />
        ))}
      </tbody>
    </AdminTable>
  );
};

export default AdminPromoTable;

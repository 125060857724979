import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn,
  FlexColumnStart,
  FlexRowBetween,
  StyledButton,
} from '../../style/projectComponents';
import { colors } from '../../style/colors';
import { CARTSTAGE } from '../../utils/constants';

const Block = styled(FlexColumn)`
  background: ${colors.white};
  align-items: start;
  width: calc(100% - 40px);
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  padding: 0 20px;
  margin-bottom: 100px;
  @media(min-width: 768px){
    display: none;
  }
`;

const Text = styled.p`
  font-size: 18px;
  color: ${colors.gray400};
`;

const Total = styled.p`
  font-size: 28px;
  font-weight: 700;
`;

interface AmountToPayBlockProps {
  price: number;
  setCartStage: any;
}

export const AmountToPayMobile: React.FC<AmountToPayBlockProps> = ({ price, setCartStage }) => {
  const { t } = useTranslation();
  const subtotalAmount = price * 0.9;
  const vatAmount = price * 0.1;

  return (
    <Block>
      <FlexRowBetween style={{ marginTop: 18, marginBottom: 18 }}>
        <FlexColumnStart>
          <Text>
            {t('common.inclusiveOfVat')}
            {' '}
            {subtotalAmount?.toFixed(2)}
          </Text>
          <Text>
            {t('common.vat')}
            {' '}
            {t('common.egp')}
            {' '}
            {vatAmount?.toFixed(2)}
          </Text>
        </FlexColumnStart>
        <FlexColumnStart style={{ alignItems: 'end' }}>
          <p>{t('common.totalPayableAmount')}</p>
          <Total>
            {t('common.egp')}
            {price.toFixed(2)}
          </Total>
        </FlexColumnStart>
      </FlexRowBetween>
      <StyledButton
        variant="darkBlue"
        onClick={() => setCartStage(CARTSTAGE.CHECKOUT)}
        style={{
          width: '100%', height: 60, fontSize: 17, fontWeight: 700,
        }}
        noRadius
      >
        {t('common.selectPaymentMethod')}
      </StyledButton>
    </Block>
  );
};

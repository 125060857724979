import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { FlexColumn, FlexRowStart } from '../../style/projectComponents';
import { colors } from '../../style/colors';
import { DropdownComponent } from '../global/DropdownComponent';
import { Countries } from '../../service/auth-api/models';
import CurrencyStorage from '../../storage/CurrencyStorage/CurrencyStorage';

const Wrapper = styled(FlexColumn)`
  width: calc(100% - 42px);
`;

const Block = styled(FlexColumn)`
  border-radius: 19px;
  background: ${colors.white};
  width: 100%;
  align-items: start;
  padding: 21px;
`;

const OptionsBlock = styled(FlexColumn)`
  border-radius: 19px;
  background: ${colors.white};
  width: calc(100% + 42px);
  align-items: start;
  padding-top: 24px;
`;

const Title = styled.h5`
  margin-bottom: 24px;
  font-weight: 600;
  margin-left: 11px;
`;

const BlockRow = styled(FlexRowStart)`
  width: 100%;
  height: 47px;
  font-size: 14px;
  font-weight: 500;
  color: ${colors.black};
  background: ${colors.blueDark};
  border-radius: 19px 19px 0 0;
  cursor: pointer;
  gap: 14px;
`;

const AddressInput = styled.input`
  font-size: 11px;
  padding: 16px;
  border-radius: 8px;
  width: calc(100% - 34px);
  border: 1px solid ${colors.mediumGray};
  margin-bottom: 6px;
`;

interface CustomRadioButtonProps {
  checked: boolean;
  isGreen?: boolean;
}

const CustomRadioButton = styled(FlexColumn)<CustomRadioButtonProps>`
  margin-left: 21px;
  cursor: pointer;
  width: 16px;
  height: 16px;
  border: 1.2px solid ${({
    checked,
    isGreen,
  }) => (checked ? (isGreen && colors.accent || colors.blueDark) : colors.gray400)};
  background: ${({ checked, isGreen }) => (checked ? (isGreen && colors.accent || colors.blueDark) : 'none')};
  border-radius: 100%;
`;
const RadioCircle = styled(FlexColumn)<CustomRadioButtonProps>`
  width: 5.75px;
  height: 5.75px;
  background: ${({ checked }) => (checked ? colors.white : 'none')};
  border-radius: 100%;
`;

const DropdownWrapper = styled(FlexColumn)`
  margin-bottom: 6px;
  width: 100%;
`;

interface DeliveryOptionsProps {
  charity: boolean;
  setCharity: any;
  setApartment: any;
  setStreet: any;
  setCityId: any;
  setCountryId: any;
  setArea: any;
  apartment: string;
  street: string;
  area: string;
  cityId: number;
  countryId: number;
  totalTickets: number;
  setCharityModal: any;
  cityList: string[];
  deliveryPrice: number;
  deliveryPriceInUsd: number;
}

export const DeliveryOptions: React.FC<DeliveryOptionsProps> = ({
  charity,
  setCharity,
  apartment,
  setApartment,
  area,
  setArea,
  cityId,
  setCityId,
  setCountryId,
  countryId,
  street,
  setStreet,
  totalTickets,
  setCharityModal,
  cityList,
  deliveryPrice,
  deliveryPriceInUsd,
}) => {
  const { t } = useTranslation();
  const donationText = charity
    ? ` ${totalTickets * 2} ${t('cart.ticketsInsteadOf')} ${totalTickets}`
    : `${t('common.only')} ${totalTickets} ${t('common.tickets')}. ${t('common.selectDonation')} ${totalTickets * 2} ${t('common.tickets')}.`;

  const [openCountryMenu, setOpenCountryMenu] = useState(false);
  const [openCityMenu, setOpenCityMenu] = useState(false);

  const CurrencyStore = useContext(CurrencyStorage);
  const currency = CurrencyStore?.currency;
  const deliveryPriceString = currency === 'EGP' && `${t('common.egp')} ${deliveryPrice?.toFixed(2)}`
        || `${t('common.usd')} ${deliveryPriceInUsd?.toFixed(2)}`;

  return (
    <Wrapper>
      <OptionsBlock>
        <Title>
          {t('cart.deliveryOptions')}
        </Title>
        <BlockRow
          style={{ color: '#fff' }}
          onClick={() => {
            setCharity(true);
            setCharityModal(true);
          }}
        >
          <CustomRadioButton checked={charity} isGreen>
            <RadioCircle checked={charity} />
          </CustomRadioButton>
          <p>
            {t('cart.cakeToCharity')}
          </p>
        </BlockRow>
        <BlockRow
          onClick={() => setCharity(false)}
          style={{ background: colors.accent, borderRadius: '0 0 19px 19px' }}
        >
          <CustomRadioButton checked={!charity}>
            <RadioCircle checked={!charity} />
          </CustomRadioButton>
          <p>
            {t('cart.deliveryEgp')}
            {' '}
            {deliveryPriceString}
          </p>
        </BlockRow>
      </OptionsBlock>
      {totalTickets > 0 && (
        <Block style={{ marginTop: 8 }}>
          <p style={{ fontSize: 16, fontWeight: 500, marginBottom: 8 }}>
            {t('cart.aboutToCharity')}
          </p>
          <p style={{ textAlign: 'start', fontSize: 14 }}>
            {t('cart.makeDonation')}
            <span
              style={{ color: colors.primary, fontWeight: 600, fontSize: 16 }}
            >
              {donationText}
            </span>
            )
          </p>
        </Block>
      )}
      {!charity && (
        <Block style={{ marginTop: 16 }}>
          <Title>
            {t('cart.deliveryAddress')}
          </Title>
          <DropdownWrapper>
            <DropdownComponent
              openMenu={openCountryMenu}
              setOpenMenu={setOpenCountryMenu}
              itemId={countryId}
              setId={setCountryId}
              hasSubTitle
              subtitle={t('auth.country')}
              itemList={Countries}
            />
          </DropdownWrapper>
          {cityList?.length > 0
                    && (
                    <DropdownWrapper>
                      <DropdownComponent
                        openMenu={openCityMenu}
                        setOpenMenu={setOpenCityMenu}
                        itemId={cityId}
                        setId={setCityId}
                        hasSubTitle
                        subtitle={t('common.city')}
                        itemList={cityList}
                      />
                    </DropdownWrapper>
                    )}
          <AddressInput
            value={area}
            onChange={(e) => setArea(e.target.value)}
            placeholder={t('common.area')}
          />
          <AddressInput
            value={street}
            onChange={(e) => setStreet(e.target.value)}
            placeholder={t('cart.streetNameOrNo')}
          />
          <AddressInput
            value={apartment}
            onChange={(e) => setApartment(e.target.value)}
            placeholder={t('cart.apartmentVillaNumber')}
          />
        </Block>
      )}
    </Wrapper>
  );
};

export default observer(DeliveryOptions);

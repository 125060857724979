import { createContext } from 'react';
import { action, autorun, makeAutoObservable } from 'mobx';

export type TToast = {
  id: number
  isVisible: boolean
  isSuccess: boolean
  message: string
};

class GlobalModalStore {
  windows: TToast[] = [];

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    autorun(() => {
    });
  }

  @action
  makeVisible(isSuccess_: boolean, message_: string) {
    const item: TToast = {
      id: this.windows.length,
      isSuccess: isSuccess_,
      isVisible: true,
      message: message_,
    };
    this.windows = this.windows.concat(item);

    setTimeout(() => {
      this.windows = this.windows.filter((it) => it.id !== item.id);
    }, 50000);
  }

  @action
  close(id: number) {
    this.windows = this.windows.filter((it) => it.id !== id);
  }
}

export default createContext(new GlobalModalStore());

import { apiClient } from '../../config/serverConstants';
import {
  IAddProductToCartReq, IAddTicketToCartReq,
  ICartInterface,
  IGetCartReq, IGetCartRes, IPayCartReq,
} from './models';

class CartService implements ICartInterface {
  async getCart(data: IGetCartReq) {
    const response = await apiClient.get('/Cart/getCart', { params: data });
    return response.data as IGetCartRes;
  }

  async addTicketToCart(data: IAddTicketToCartReq) {
    const response = await apiClient.post('/Cart/addTicket', data);
    return response.data as any;
  }

  async addProductToCartReq(data: IAddProductToCartReq) {
    const response = await apiClient.post('/Cart/addProduct', data);
    return response.data as any;
  }

  async removeTicket(data: IAddTicketToCartReq) {
    const response = await apiClient.post('/Cart/removeTicket', data);
    return response.data as any;
  }

  async removeProduct(data: IAddProductToCartReq) {
    const response = await apiClient.post('/Cart/removeProduct', data);
    return response.data as any;
  }

  async payCart(data: IPayCartReq) {
    const response = await apiClient.post('/Cart/payCart', data);
    return response.data as any;
  }

  async refreshCart() {
    const response = await apiClient.post('/Cart/refreshCart');
    return response.data as any;
  }
}

export default new CartService();

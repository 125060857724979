import styled from 'styled-components';
import { ComponentPropsWithoutRef, FC } from 'react';
import { useTranslation } from 'react-i18next';
import { Link as ScrollLink } from 'react-scroll';
import { colors } from '../../../style/colors';
import {
  FlexRowEnd, StyledLink,
} from '../../../style/projectComponents';

const StyledMenuItem = styled(StyledLink)`
  color: ${colors.grayLight};
  font-weight: 400;
  font-size: 16px;
  text-align: left;
  
  @media (max-width: 768px) {
    font-size: 14px;
  }
  :hover {
    color: #000;
    transition: all ease-in 0.3s;
  }
`;

const StyledScrollLink = styled(ScrollLink)`
  color: ${colors.black};
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  cursor: pointer;
  text-align: left;

  :hover {
    color: #000;
    transition: all ease-in 0.3s;
  }
`;

const MenuCol = styled(FlexRowEnd)`
  gap: 24px;
  @media (max-width: 1200px) {
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }
  @media (max-width: 768px) {
    align-items: start;
    gap: 12px;
  }
`;

export const MenuColItem: FC<{
  isScrollLink?: boolean,
  items: {
    title: string,
    link: string
  }[]
} & ComponentPropsWithoutRef<'div'>> = (props) => {
  const { items, isScrollLink = false, ...other } = props;
  const { t } = useTranslation();

  return (
    <MenuCol {...other} className="column_menu">
      {items.map((it, idx) => (isScrollLink
        ? (
          <StyledScrollLink
            spy
            smooth
            key={`${it.link + idx + it.title}`}
            offset={50}
            duration={500}
            to={it.link}
          >
            {t(it.title)}
          </StyledScrollLink>
        )
        : (
          <StyledMenuItem to={it.link} key={`${it.link + idx + it.title}`}>
            {t(it.title)}
          </StyledMenuItem>
        )))}
    </MenuCol>
  );
};

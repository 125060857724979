import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  ErrorText,
  FlexRow, InputAndErrorColumn, StyledButton,
} from '../../../style/projectComponents';
import { ValidationInputComponent } from '../../global/ValidationInputComponent';
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore';
import { queryClient } from '../../../config/query-client';
import UserApi from '../../../service/user-api/UserApi';
import { DropdownComponent } from '../../global/DropdownComponent';
import { Countries, EgyptCitiesMock } from '../../../service/auth-api/models';

const SettingsWrapper = styled(FlexRow)`
  width: 100%;
  gap: 6px;
  justify-content: start;
  flex-wrap: wrap;
`;
const SettingsInputWrapper = styled(FlexRow)`
  width: 300px;
  position: relative;
  align-items: start;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const addressInputs = [
  {
    name: 'area',
    label: 'area',
    type: 'text',
    rules: { required: true },
  },
  {
    name: 'street',
    label: 'street',
    type: 'text',
    rules: { required: true },
  },
  {
    name: 'apart',
    label: 'apart',
    type: 'text',
    rules: { required: true },
  },
];

interface DeliveryAddressProps {
  address: string;
}

export const DeliveryNewAddress: FC<DeliveryAddressProps> = ({ address }) => {
  const { t } = useTranslation();
  const addressExist = address?.length > 0;
  const apartmentData = addressExist && address?.split('-ADR-')?.[0] || '';
  const streetData = addressExist && address?.split('-ADR-')?.[1] || '';
  const areaData = addressExist && address?.split('-ADR-')?.[2] || '';
  const cityData = addressExist && address?.split('-ADR-')?.[3] || '';

  const {
    setError,
    register,
    control,
    watch,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      area: areaData,
      street: streetData,
      apart: apartmentData,
    },
  });

  const GlobalModalStorage = useContext(GlobalModalStore);
  const {
    mutate: newAddressMutate,
    isLoading: isAddressLoading,
  } = useMutation((data: { address: string }) => UserApi.changeAddress(data), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: (data) => {
      GlobalModalStorage.makeVisible(true, 'Delivery Address updated');
      queryClient.invalidateQueries({ queryKey: ['user_address'] });
    },
  });

  const { data: cities } = useQuery(
    ['cities_list'],
    () => UserApi.getCitiesList({
      skip: 0, take: 200,
    }),
  );
  const cityItems = cities?.items;
  const cityStrings = cityItems?.map((c) => c?.name);

  const [openCountryMenu, setOpenCountryMenu] = useState(false);
  const [countryId, setCountryId] = useState(0);

  const [openCityMenu, setOpenCityMenu] = useState(false);
  const [cityId, setCityId] = useState(EgyptCitiesMock?.findIndex((g) => g.toLowerCase() === cityData?.toLowerCase()) || 0);
  const submit = () => {
    const backendAddress = `${getValues('apart')}-ADR-${getValues('street')}-ADR-${getValues('area')}-ADR-${cityStrings?.[cityId]}-ADR-${Countries[countryId]}`;
    newAddressMutate({ address: backendAddress });
  };

  const editAddressTitle = apartmentData?.length > 0 && 'Edit Address' || 'New Address';

  return (
    <>
      <h2 style={{ marginTop: 32, marginBottom: 20 }}>{editAddressTitle}</h2>
      <SettingsWrapper>
        <SettingsInputWrapper>
          <DropdownComponent
            openMenu={openCountryMenu}
            setOpenMenu={setOpenCountryMenu}
            itemId={countryId}
            setId={setCountryId}
            hasSubTitle
            subtitle={t('auth.country')}
            itemList={Countries}
          />
        </SettingsInputWrapper>
        {cityStrings?.length > 0 && (
        <SettingsInputWrapper>
          <DropdownComponent
            openMenu={openCityMenu}
            setOpenMenu={setOpenCityMenu}
            itemId={cityId}
            setId={setCityId}
            hasSubTitle
            subtitle={t('common.city')}
            itemList={cityStrings}
          />
        </SettingsInputWrapper>
        )}
        {addressInputs.map((it, idx) => (
          <InputAndErrorColumn>
            <SettingsInputWrapper>
              <Controller
                // @ts-ignore
                name={it.name}
                control={control}
                rules={it.rules}
                render={({ field: { ref, ...field } }) => (
                  <ValidationInputComponent
                    {...field}
                    name={it.name}
                    type="text"
                    isError={!!errors[it.name]}
                    label={it.label}
                  />
                )}
              />
            </SettingsInputWrapper>
            <ErrorText hasError={!!errors[it.name]}>
              {t('common.wrongText')}
            </ErrorText>
          </InputAndErrorColumn>
        ))}
      </SettingsWrapper>
      <StyledButton
        variant="darkBlue"
        noRadius
        style={{ width: 156, height: 56, marginTop: 8 }}
        onClick={submit}
        disabled={isAddressLoading}
      >
        {t('settings.save')}
      </StyledButton>
    </>
  );
};

import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FlexColumn, AppTextMedium, FlexRow } from '../../../style/projectComponents';
import ExploreCampaignBlock from './ExploreCampaignBlock';
import { IProduct } from '../../../service/product-api/models';
import CartApi from '../../../service/cart-api/CartApi';
import UserStorage from '../../../storage/UserStorage/UserStorage';

const Block = styled(FlexColumn)`
  width: 100%;
  align-items: start;
  justify-content: start;
  margin-top: 26px;
  margin-bottom: 32px;
`;
const CampaignsList = styled(FlexRow)`
  justify-content: start;
  flex-wrap: wrap;
  width: 100%;
  gap: 16px;
`;

interface ExploreCampaignsSectionProps {
  productsItems: IProduct[];
}

export const ExploreCampaignsSection: FC<ExploreCampaignsSectionProps> = ({ productsItems }) => {
  const { t } = useTranslation();
  const UserStore = useContext(UserStorage);
  const isAuth = !!UserStore.accessToken;
  const { data: cart } = useQuery(
    'get_cart_data',
    () => CartApi.getCart({
      skip: 0,
      take: 50,
    }),
    {
      enabled: isAuth,
    },
  );
  const cartItems = cart?.items?.items;

  return (
    <Block>
      <AppTextMedium style={{ marginBottom: 16, zIndex: 2 }}>{t('common.exploreCampaigns')}</AppTextMedium>
      <CampaignsList>
        {productsItems?.length > 0 && productsItems?.map((i) => (
          <ExploreCampaignBlock
            drawDate={i?.event?.drawDate}
            prize={i?.event?.prizeDetails}
            image={i?.event?.imageDownloadLink}
            productImage={i?.imageDownloadLink}
            price={i.price}
            priceInUsd={i.priceInUsd}
            key={i.id}
            id={i.id}
            cartItems={cartItems}
            eventId={i?.event?.id}
            ticketsLimit={i?.event?.ticketsLimit}
            ticketsPurchased={i?.event?.ticketsPurchased}
            inFavorite={i.inFavorite}
            ticketsAmount={i.ticketsAmount}
          />
        ))}
      </CampaignsList>
    </Block>
  );
};

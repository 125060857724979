import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { FlexColumn, LanguageSwitch } from '../../style/projectComponents';
import CurrencyStorage from '../../storage/CurrencyStorage/CurrencyStorage';

const LanguageWrapper = styled(FlexColumn)`
  gap: 15px;
  width: 100%;
`;

interface ChangeLanguageProps {
  handleMenu: () => void;
}

export const ChangeCurrency: React.FC<ChangeLanguageProps> = ({ handleMenu }) => {
  const CurrencyStore = useContext(CurrencyStorage);

  const [currencies, setCurrencies] = useState([
    {
      curr: 'EGP',
      active: true,
      image: '/images/interface/currencies/egp.svg',
    },
    {
      curr: 'USD',
      active: false,
      image: '/images/interface/currencies/usd.svg',
    },
  ].map((it, idx) => (CurrencyStore.currency === it.curr
    ? { ...it, active: true }
    : { ...it, active: false })));

  return (
    <LanguageWrapper>
      {currencies.map((it, idx) => (
        <LanguageSwitch
          onClick={() => {
            setCurrencies(currencies.map((c) => (c.curr === it.curr
              ? { ...c, active: true }
              : { ...c, active: false })));
            CurrencyStore.setCurrency(it.curr);
            handleMenu();
          }}
          active={it.active}
        >
          <p>{it.curr}</p>
          <img src={it.image} alt="" />
        </LanguageSwitch>
      ))}
    </LanguageWrapper>
  );
};

import React, { useMemo, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, ProfileTabTitle,
} from '../../../style/projectComponents';
import { TabHeader } from './TabHeader';
import OrderApi from '../../../service/order-api/OrderApi';
import { Delivery } from '../Delivery/Delivery';
import { generatePagesArray } from '../../../utils/generatePagesArray';
import { PaginationComponent } from '../../global/PaginationComponent';

const Block = styled(FlexColumn)`
  align-items: start;
  width: 100%;
`;

interface DeliveryAddressTabProps {
  action: () => void;
}

export const MyDeliveriesTab: React.FC<DeliveryAddressTabProps> = ({ action }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const itemsPerPage = 3;
  const { data: myOrders } = useQuery(
    ['my_orders_list', page * itemsPerPage],
    () => OrderApi.getUserOrders({
      skip: page * itemsPerPage,
      take: itemsPerPage,
    }),
  );
  const ordersList = myOrders?.orders?.items;
  const totalPages = useMemo(
    () => (myOrders?.orders?.totalCount ? Math.ceil(myOrders?.orders?.totalCount / itemsPerPage) : 1),
    [myOrders?.orders?.totalCount, itemsPerPage],
  );

  return (
    <Block>
      <TabHeader title="My deliveries" action={action} />
      <ProfileTabTitle>
        <img src="/images/buttons/menu/my-deliveries.svg" alt="" />
        <h2>
          {t('common.myDeliveries')}
        </h2>
      </ProfileTabTitle>
      <FlexColumn style={{ width: '100%' }}>
        {ordersList?.map((o, id) => <Delivery orderId={o.id} id={page * itemsPerPage + id} />)}
        <PaginationComponent
          maxPages={totalPages}
          pageId={page}
          pagesNum={generatePagesArray(totalPages, page, 100)}
          setPageId={setPage}
        />
      </FlexColumn>
    </Block>
  );
};

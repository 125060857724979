import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { AppStyle, Page } from '../style/projectComponents';
import { StoreBanner } from '../components/Store/StoreBanner';
import { StoreProducts } from '../components/Store/StoreProducts';
import { Products } from '../components/Store/Products';
import ProductApi from '../service/product-api/ProductApi';

export const StorePage = () => {
  const { data: products } = useQuery(
    'all_products_data',
    () => ProductApi.getAllProducts(
      {
        skip: 0,
        take: 50,
        withActiveEvents: true,
      },
    ),
  );
  const productsItems = products?.items;

  const { data: categories } = useQuery(
    'get_product_categories',
    () => ProductApi.getProductCategories(
      {
        skip: 0,
        take: 50,
      },
    ),
  );
  const [categoriesItems, setCategoriesItems] = useState([{ id: 'All', name: 'All' }]);

  useEffect(() => {
    if (categories?.items?.length > 0) {
      setCategoriesItems([{ id: 'All', name: 'All' }, ...categories.items]);
    }
  }, [categories]);

  const [categoryId, setCategoryId] = useState(0);
  const productsByCategory = categoryId === 0 && productsItems || productsItems?.filter((p) => p.categoryId === categoriesItems?.[categoryId].id);

  return (
    <AppStyle style={{ paddingBottom: 50 }}>
      <Page>
        <StoreBanner />
        {categories && (
        <StoreProducts
          categories={categoriesItems}
          categoryId={categoryId}
          setCategoryId={setCategoryId}
        />
        )}
        {categories && productsByCategory && <Products products={productsByCategory} />}
      </Page>
    </AppStyle>

  );
};

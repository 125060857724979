import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, ProfileTabTitle, FlexColumnStart, StyledButton,
} from '../../../style/projectComponents';
import { TabHeader } from './TabHeader';
import UserApi from '../../../service/user-api/UserApi';
import { DeliveryAddress } from '../Delivery/DeliveryAddress';
import { colors } from '../../../style/colors';
import { DeliveryNewAddress } from '../Delivery/DeliveryNewAddress';

const Block = styled(FlexColumn)`
  align-items: start;
  width: 100%;
`;

const AddressImg = styled.img`
  width: 72px;
  height: 72px;
  margin-bottom: 12px;
`;

interface DeliveryAddressTabProps {
  action: () => void;
}

export const DeliveryAddressTab: React.FC<DeliveryAddressTabProps> = ({ action }) => {
  const { t } = useTranslation();
  const { data: address, isLoading } = useQuery(
    ['user_address'],
    () => UserApi.getAddress(),
  );
  const [editAddress, setEditAddress] = useState(false);

  return (
    <Block>
      <TabHeader title="My Delivery Address" action={action} />
      <ProfileTabTitle>
        <h2>
          {t('common.myDeliveryAddress')}
        </h2>
      </ProfileTabTitle>
      {!isLoading && address && (
        <FlexColumnStart style={{ width: '100%', gap: 8 }}>
          <DeliveryAddress address={address} setEditAddress={setEditAddress} />
        </FlexColumnStart>
      )}
      {!isLoading && !address && (
        <FlexColumn style={{ width: '100%' }}>
          <AddressImg src="/images/interface/address.svg" alt="" />
          <p>{t('common.anySavedAddresses')}</p>
          <StyledButton
            variant="darkBlue"
            noRadius
            style={{
              border: 'none',
              color: '#fff',
              background: colors.primary,
              height: 50,
              width: 180,
              marginTop: 24,
            }}
            onClick={() => setEditAddress(true)}
          >
            {t('common.addAddress')}
          </StyledButton>
        </FlexColumn>
      )}
      {editAddress && !isLoading && <DeliveryNewAddress address={address} />}
    </Block>
  );
};

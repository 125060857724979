import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, StyledButton, FlexRowBetween,
} from '../../../style/projectComponents';
import { DataEmptyComponent } from '../../global/DataEmptyComponent';
import PromoApi from '../../../service/promo-api/PromoApi';
import { PaginationComponent } from '../../global/PaginationComponent';
import { generatePagesArray } from '../../../utils/generatePagesArray';
import AdminPromoTable from './AdminPromoTable';
import { NewPromocodeModal } from '../NewPromocodeModal';

const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1100px;
  justify-content: start;
  background: rgba(255, 255, 255, 0.3);
  padding: 26px 16px;
  border-radius: 8px;
  max-height: 740px;
  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`;

const StatsTitle = styled.p`
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-top: 18px;
  font-family: 'Maswen', sans-serif;
`;

export const AdminPromocodes: FC = () => {
  const [promocodeModal, setpromocodeModal] = useState(false);
  const handlePromocodeModal = () => setpromocodeModal((m) => !m);
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const itemsPerPage = 15;
  const { data: promocodes, isLoading } = useQuery(
    'get_all_promo_codes',
    () => PromoApi.get(
      {
        skip: page * itemsPerPage,
        take: itemsPerPage,
      },
    ),
  );

  const totalPages = useMemo(
    () => (promocodes?.totalCount ? Math.ceil(promocodes?.totalCount / itemsPerPage) : 1),
    [promocodes?.totalCount, itemsPerPage],
  );

  const promoItems = promocodes?.items;

  return (
    <>
      {promocodeModal && <NewPromocodeModal closeModal={handlePromocodeModal} />}
      <OperationsWrapper>
        <FlexRowBetween style={{ alignItems: 'center' }}>
          <StatsTitle>
            {' '}
            {t('adminAnalytics.allPromocodes')}
          </StatsTitle>
          <StyledButton
            variant="darkBlue"
            onClick={handlePromocodeModal}
            noRadius
          >
            {t('adminAnalytics.newPromocode')}
          </StyledButton>
        </FlexRowBetween>
        {!isLoading && promoItems?.length > 0
          ? <AdminPromoTable promoItems={promoItems} />
          : (
            <FlexColumn style={{ width: '100%' }}>
              <DataEmptyComponent />
            </FlexColumn>
          )}
        <PaginationComponent
          maxPages={totalPages}
          pageId={page}
          pagesNum={generatePagesArray(totalPages, page, 100)}
          setPageId={setPage}
        />
      </OperationsWrapper>
    </>
  );
};

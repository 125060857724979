import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FlexColumn, MiniText, StyledButton } from '../../../../style/projectComponents';
import { colors } from '../../../../style/colors';

const Block = styled(FlexColumn)`
  min-width: 260px;
  max-width: 260px;
  min-height: 100%;
  background: #fff;
  align-items: start;
  border-radius: 25px;
  padding: 13px;
  box-sizing: border-box;
  justify-content: space-between;
  h6 {
    @media(max-width: 768px) {
      font-size: 16px;
      width: 100%;
    }
  }
`;
const NameTitle = styled.h5`
  color: ${colors.black};
  margin-top: 12px;
  margin-bottom: 6px;
  font-weight: 900;
  font-size: 17px;
  text-align: start;
  @media(max-width: 768px) {
    width: 100%;
  }
`;

const NameText = styled.h5`
  color: ${colors.black};
  font-weight: 600;
  font-size: 13px;
  text-align: start;
  @media(max-width: 768px) {
    width: 100%;
  }
`;
const Img = styled.img`
  width: 100%;
  height: 162px;
  border-radius: 15px;
  //@media(max-width: 768px) {
  //  width: 100%;
  //}
`;

const ImgWrapper = styled(FlexColumn)`
  width: 100%!important;
  align-items: start;
`;

const InfoWrapper = styled(FlexColumn)`
  margin-top: 32px;
  gap: 6px;
  min-width: 100%;

  @media(max-width: 768px) {
    margin-top: 16px;
    gap: 8px;
  }
`;
const ActionButton = styled(StyledButton)`
  font-size: 14px;
  font-weight: 500;
  width: 100%;
  height: 49px;
  border-radius: 44px;
  padding: 0 17px;
  color: ${colors.primary};
  @media (max-width: 768px) {
    font-size: 13px;
    font-weight: 400;
  }
`;

interface WinnerCardProps {
  title: string;
  name: string;
  image: string;
}

// const formattedDate = format(new Date(announceDate), 'hh:mm, dd MMMM yyyy', {locale: enUS});
export const SoldOutCard: FC<WinnerCardProps> = ({ title, name, image }) => {
  const { t } = useTranslation();
  return (
    <Block>
      <ImgWrapper>
        <Img src={image} alt="" />
        <NameTitle>{title}</NameTitle>
        <NameText>{name}</NameText>
      </ImgWrapper>
      <InfoWrapper>
        <ActionButton variant="green" onClick={() => console.log('click')}>
          {t('common.DrawDateToBeAnnounced')}
        </ActionButton>
      </InfoWrapper>
    </Block>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import jwtDecode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';
import AuthApi from '../service/auth-api/AuthApi';
import { IAuthSigninRequest } from '../service/auth-api/models';
import UserStorage from '../storage/UserStorage/UserStorage';
import { ValidationInputComponent } from '../components/global/ValidationInputComponent';
import {
  AuthContent,
  AuthModal,
  ErrorText,
  FlexRow,
  PassIcon,
  StyledLink,
  StyledButton,
  AuthWrapper,
  AuthInputWrapper, AuthInputColumn, AppStyle,
} from '../style/projectComponents';
import GlobalModalStore from '../storage/GlobalModalStore/GlobalModalStore';
import RegisterLoginButtons from '../components/auth/Register/RegisterLoginButtons';
import { colors } from '../style/colors';
import LanguageStorage from '../storage/LanguageStorage/LanguageStorage';

const AuthPage: React.FC = () => {
  const LanguageStore = useContext(LanguageStorage);
  const UserStore = useContext(UserStorage);
  const {
    control, clearErrors, register, watch, setError, handleSubmit, formState: { errors, isValid },
  } = useForm();

  const { t } = useTranslation();
  const navigate = useNavigate();

  const GlobalModalStorage = useContext(GlobalModalStore);

  const AuthMutation = useMutation((data: IAuthSigninRequest) => AuthApi.signin(data), {
    onError: (error) => {
      setError('badSignin', {
        type: 'manual',
        message: 'auth.errors.password.serverError',
      });
      console.log(error);
      // @ts-ignore
      const errorMessage = error?.response?.data?.message === 'Invalid login or password' && t('auth.errors.password.invalidLogin') || t('auth.errors.password.serverError');
      GlobalModalStorage.makeVisible(false, errorMessage);
    },
    onSuccess: (data) => {
      UserStore.setTokens(data);
      UserStore.setUser(jwtDecode(data.accessToken));
      navigate('/');
    },
  });

  const onSubmit = (data) => {
    AuthMutation.mutate(data);
  };

  const [showPass, setShowPass] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <AppStyle>
        <AuthContent>
          <AuthWrapper>
            <AuthModal>
              <RegisterLoginButtons />
              <AuthInputColumn style={{ margin: '14px 0 12px' }}>
                <AuthInputWrapper style={{ marginBottom: 5 }}>
                  <Controller
                    name="email"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'auth.errors.email.required',
                      },
                      pattern: {
                        value: /^[^@ ]+@[^@ ]+\.[^@ .]{2,}$/,
                        message: 'auth.errors.email.pattern',
                      },
                    }}
                    render={({ field: { ref, ...field } }) => (
                      <ValidationInputComponent
                        {...field}
                        isError={!!errors.email}
                        label={t('auth.email')}
                      />
                    )}
                  />
                </AuthInputWrapper>
                <ErrorText hasError={!!errors.email}>
                  {t(errors?.email?.message.toString())}
                </ErrorText>
              </AuthInputColumn>

              <AuthInputColumn>
                <AuthInputWrapper style={{ marginBottom: 12 }}>
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: 'auth.errors.password.required',
                      },
                    }}
                    render={({ field: { ref, ...field } }) => (
                      <ValidationInputComponent
                        {...field}
                        type={showPass ? 'text' : 'password'}
                        isError={!!errors.password}
                        label={t('auth.password')}
                      />
                    )}
                  />
                  <PassIcon
                    lang={LanguageStore.language}
                    onClick={() => setShowPass((prev) => !prev)}
                    src={showPass ? '/images/show.svg' : '/images/hide.svg'}
                  />
                </AuthInputWrapper>
                <ErrorText hasError={!!errors.password}>
                  {t(errors?.password?.message.toString())}
                </ErrorText>
              </AuthInputColumn>
              <FlexRow style={{ justifyContent: 'space-between', width: '100%' }}>
                <StyledLink
                  to="/forgot"
                  style={{
                    cursor: 'pointer',
                    color: colors.blueDark,
                    fontSize: 16,
                  }}
                >
                  {t('common.forgotPassword')}
                </StyledLink>
                <StyledButton
                  variant="darkBlue"
                  noRadius
                  disabled={!isValid}
                  onClick={() => clearErrors()}
                  style={{ height: 60 }}
                  type="submit"
                >
                  {t('auth.login')}
                </StyledButton>
              </FlexRow>

              <ErrorText style={{ marginTop: 10 }} hasError={!!errors.badSignin}>
                {t(errors?.badSignin?.message.toString())}
              </ErrorText>
            </AuthModal>
          </AuthWrapper>
        </AuthContent>
      </AppStyle>
    </form>
  );
};

export default observer(AuthPage);

import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { FlexRow, FlexRowBetween, FlexColumnStart } from '../../style/projectComponents';
import { IEvent } from '../../service/lottery-api/models';
import { colors } from '../../style/colors';

const Block = styled(FlexRowBetween)`
  background-color: #fff;
  flex: 0 0 auto;
  height: 134px;
  border-radius: 12px;
  justify-content: start;
  gap: 18px;
  width: 388px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Image = styled.img`
  height: 100%;
  width: 116px;
  border-radius: 24px;
`;
const Title = styled.p`
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 6px;
`;
const Description = styled.p`
  font-size: 10px;
  font-weight: 400;
  color: ${colors.gray400};
  margin-bottom: 10px;
`;
const Content = styled(FlexColumnStart)`
  height: 100%;
`;
const Info = styled.p`
  font-weight: 500;
  font-size: 11px;
  color: ${colors.blueDark};
  padding: 11px 17px;
  border-radius: 19px;
  border: 1px solid ${colors.blueDark};
`;
const CircleButton = styled.button`
  background: ${colors.accent};
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: none;
  margin-left: 12px;
  cursor: pointer;

  img {
    width: 15px;
    height: 15px;
  }
`;

interface DrawBlockProps {
  event: IEvent;
  openVideo: any;
}

export const DrawBlock: React.FC<DrawBlockProps> = ({ event, openVideo }) => {
  const { t } = useTranslation();
  const image = event?.imageDownloadLink;
  const formattedDate = format(new Date(event?.drawDate), 'd MMMM', { locale: enUS });
  const openVideoAction = () => {
    if (event?.drawTranslationUrl) {
      openVideo(event?.drawTranslationUrl);
    }
  };

  return (
    <Block>
      <Image src={image} alt="" />
      <Content>
        <Title>
          {t('common.win')}
          {event?.prizeDetails}
        </Title>
        <Description>
          {t('common.youHave')}
          {event?.ticketsPurchased}
          {' '}
          {t('common.tickets')}
        </Description>
        <FlexRow>
          <Info>
            {t('common.wentLiveOn')}
            {formattedDate}
          </Info>
          <CircleButton onClick={openVideoAction}>
            <img src="/images/buttons/arrow-right-black.svg" alt="" />
          </CircleButton>
        </FlexRow>
      </Content>
    </Block>
  );
};

import React, { useContext } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FlexColumnStart } from '../../style/projectComponents';
import { colors } from '../../style/colors';
import ProfileStorage from '../../storage/ProfileStorage/ProfileStorage';
import CurrencyStorage from '../../storage/CurrencyStorage/CurrencyStorage';

const BalanceTitle = styled.p`
  font-size: 10px;
  opacity: 0.5;
  @media(max-width: 768px) {
    font-size: 8px;
    opacity: 1;
  }
`;
const Balance = styled.p`
  font-size: 14px;
  @media(max-width: 768px) {
    font-size: 9px;
    font-weight: 500;
    opacity: 1;
  }
`;
const Block = styled(Link)`
  display: flex;
  gap: 8px;
  text-decoration: none;
  color: ${colors.black};
  @media(max-width: 768px) {
    gap: 4px;
    margin-right: 12px;
  }
`;
const Avatar = styled.img`
  border-radius: 100%;
  width: 24px;
  height: 24px;
`;

const HeaderUserInfo: React.FC = () => {
  const { t } = useTranslation();
  const ProfileStore = useContext(ProfileStorage);
  const balance = ProfileStore.getBalance() || 0;
  const balanceInUsd = ProfileStore.getBalanceInUsd() || 0;
  const CurrencyStore = useContext(CurrencyStorage);
  const currency = CurrencyStore.currency;
  const deliveryPriceString = currency === 'EGP' && t('common.egp') || t('common.usd');
  const balanceByCurrency = currency === 'EGP' && balance || balanceInUsd;

  return (
    <Block to="/profile">
      <FlexColumnStart style={{ alignItems: 'end' }}>
        <BalanceTitle>{t('common.walletBalance')}</BalanceTitle>
        <Balance>
          {balanceByCurrency.toFixed(2)}
          {deliveryPriceString}
        </Balance>
      </FlexColumnStart>
      <Avatar src="/images/interface/profile/avatar-mini.svg" alt="" />
    </Block>
  );
};

export default observer(HeaderUserInfo);

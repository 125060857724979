import React, { FC } from 'react';
import styled from 'styled-components';
import { IAdminCart } from '../../../service/admin-api/models';
import { FlexColumn, FlexRow } from '../../../style/projectComponents';
import { colors } from '../../../style/colors';

interface CartRowProps {
  cart: IAdminCart;
}

const CartProduct = styled(FlexColumn)`
  img {
    width: 42px;
    height: 42px;
    border-radius: 6px;
    margin-bottom: 6px;
    border: 1px solid ${colors.gray400};
  }
`;
const Price = styled.p`
  font-size: 11px;
  font-weight: 600;
  color: ${colors.primary};
`;
const Amount = styled.p`
  font-size: 11px;
  font-weight: 600;
`;

const CartRow: FC<CartRowProps> = ({ cart }) => {
  const cartItems = cart?.cartItems;
  return (
    <tr key={cart.id}>
      <td>{cart.id}</td>
      <td>{cart.firstName}</td>
      <td>{cart.lastName}</td>
      <td>{cart.email}</td>
      <td>
        <FlexRow style={{ gap: 6, alignItems: 'start' }}>
          {cartItems?.map((c) => (
            <CartProduct key={`${c?.productId}+${c?.prizeDetails}`}>
              <img src={c?.imageDownloadLink} alt="" />
              <p>{c?.name}</p>
              <Amount>
                {c?.amount}
                {' '}
                *
                {' '}
                {c?.productPrice}
                EGP
              </Amount>
              <Price>
                {c?.amount * c?.productPrice}
                EGP
              </Price>
            </CartProduct>
          ))}
        </FlexRow>
      </td>
    </tr>
  );
};

export default CartRow;

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  FlexRow, StyledLink,
} from '../../../style/projectComponents';
import { colors } from '../../../style/colors';

const Block = styled(FlexRow)`
  width: 100%;
  background: ${colors.lightGray};
  border-radius: 16px;
  height: 61px;
  
  img {
    transform: translateX(-10px);
  }

  p {
    font-weight: 700;
    font-size: 18px;
    color: ${colors.gray400};
    transform: translateX(-30px);
    font-family: 'HeadingNowTrial', sans-serif;
    @media(max-width: 768px){
      font-size: 14px;
    }
  }
`;
const LoginLink = styled(StyledLink)`
  margin-left: 6px;
  font-weight: 700;
  font-size: 18px;
  text-decoration: underline;
  transform: translateX(-30px);
  font-family: 'HeadingNowTrial', sans-serif;
  @media(max-width: 768px){
    font-size: 14px;
  }
`;

export const AlreadyHaveAccount = () => {
  const { t } = useTranslation();

  return (
    <Block>
      <img src="/images/interface/have-account.svg" alt="" />
      <p>
        {t('auth.already_has')}
      </p>
      <LoginLink to="/login">
        {t('auth.login')}
      </LoginLink>
    </Block>
  );
};

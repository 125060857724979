import React, { FC } from 'react';
import styled from 'styled-components';
import { FlexColumn, FlexRow } from '../../../style/projectComponents';
import { colors } from '../../../style/colors';
import { WinnerCard } from './WinnerCard';
import { CampaignCardSkeleton } from '../../global/skeletons/CampaignCardSkeleton';

const Block = styled(FlexColumn)`
  width: calc(100% - 32px);
  align-items: start;
  justify-content: start;
  margin-top: 26px;
  margin-bottom: 32px;
  background: ${colors.primary};
  padding: 32px;
  border-radius: 35px;
  padding-bottom: 45px;
  padding-right: 0;
  padding-top: 45px;
  @media (max-width: 768px) {
    width: calc(100vw - 32px);
    border-radius: 0;
    padding-bottom: 0;
  }
`;

const WinnersCardsWrapper = styled(FlexRow)<{ isSmall?: boolean }>`
  width: 100%;
  gap: 32px;
  justify-content: start;
  overflow: scroll;
  margin-top: 32px;
  @media (max-width: 768px) {
    flex-wrap: ${({ isSmall }) => (isSmall ? 'nowrap' : 'wrap')};
    flex-direction: ${({ isSmall }) => (isSmall ? 'row' : 'column')};
    align-items: start;
    gap: 16px;
    height: 700px;
    margin-top: 16px;
  }
`;
const YouImage = styled.img`
  width: 145px;
  @media (max-width: 768px) {
    width: 88px;
  }
`;

interface WinnersSectionProps {
  isLoading: boolean;
  winners: any;
  isSmall?: boolean;
}

export const WinnersCards: FC<WinnersSectionProps> = ({ isLoading, winners, isSmall }) => (
  <WinnersCardsWrapper isSmall={isSmall}>
    {isLoading && [0, 1, 2, 3].map((s, id) => <CampaignCardSkeleton id={id} />)}
    {!isLoading && winners?.map((i, id) => (
      <WinnerCard
        announceDate={i.announcementDate}
        ticketNumber={i.code}
        name={`${i.winnerFirstName} ${i.winnerLastName}`}
        prize={i.prizeName}
        image={i.prizeImageDownloadUrl}
        key={`${i.code}+${i.winnerFirstName}+${id}`}
      />
    ))}
  </WinnersCardsWrapper>
);

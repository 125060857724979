import React, { useContext } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, FlexRow, FlexRowBetween, FlexColumnStart, StyledButton,
} from '../../../style/projectComponents';
import { colors } from '../../../style/colors';
import { IAddToFavoriteReq } from '../../../service/product-api/models';
import ProductApi from '../../../service/product-api/ProductApi';
import { queryClient } from '../../../config/query-client';
import { IAddProductToCartReq } from '../../../service/cart-api/models';
import CartApi from '../../../service/cart-api/CartApi';
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore';
import { useCart } from '../../global/CartContext';

const Block = styled(FlexRowBetween)`
  border-radius: 32px;
  align-items: center;
  width: 100%;
`;
const ShoppingCardWrapper = styled(FlexColumn)`
  width: 133px;
  height: 133px;
  border-radius: 12px;
  background: ${colors.white};
  border: 1px solid ${colors.mediumGray};
  img {
    width: 133px;
    height: 133px;
    border-radius: 12px;
  }
`;

const PrizeInfo = styled.p`
  font-weight: 600;
  font-size: 19px;
`;
const Price = styled.p`
  font-weight: 800;
  font-size: 30px;
  color: ${colors.blueDark};
  margin-bottom: 32px;
`;
const Description = styled.p`
  font-weight: 400;
  font-size: 13px;
`;
const SoldOutImage = styled.img`
  width: 150px;
`;

interface WishlistTicketProps {
  prize: string;
  price: number;
  id: string;
  image: string;
  name: string;
  noActive: boolean;
}

export const WishlistTicket: React.FC<WishlistTicketProps> = ({
  prize, id, image, price, name, noActive,
}) => {
  const { t } = useTranslation();
  const GlobalModalStorage = useContext(GlobalModalStore);
  const removeFromFavoriteQuery = useMutation((data: IAddToFavoriteReq) => ProductApi.removeFromFavorite(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['all_fav_products'] });
    },
  });

  const removeFromFav = () => {
    if (!removeFromFavoriteQuery.isLoading) {
      removeFromFavoriteQuery.mutate({ productId: id });
    }
  };

  const { setShowCart } = useCart();
  const addProductMutation = useMutation((data: IAddProductToCartReq) => CartApi.addProductToCartReq(data), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: (data) => {
      GlobalModalStorage.makeVisible(true, 'Successfully added to cart');
      setShowCart(true);
    },
  });

  const addToCart = () => {
    addProductMutation.mutate({ productId: id, amount: 1 });
  };

  return (
    <Block>
      <FlexRow style={{ gap: 12 }}>
        <ShoppingCardWrapper>
          <img src={image} alt="" />
        </ShoppingCardWrapper>
        <FlexColumnStart>
          <PrizeInfo>
            {prize}
          </PrizeInfo>
          <Price>
            {price}
            {t('common.egp')}
          </Price>
          <Description>
            {t('common.buy')}
            {' '}
            {name}
            {' '}
            {t('common.and')}
            <span style={{ color: colors.blueDark }}>{t('user.get2Tickets')}</span>
          </Description>
        </FlexColumnStart>
      </FlexRow>
      <FlexColumn>
        {!noActive && (
          <StyledButton
            variant="darkBlue"
            noRadius
            disabled={addProductMutation.isLoading}
            onClick={addToCart}
            style={{ width: '100%', height: 43 }}
          >
            {t('cart.addToCart')}
          </StyledButton>
        )}
        {noActive && <SoldOutImage src="/images/interface/sold-out.png" alt="" />}
        <p style={{ fontWeight: 500, marginTop: 16, cursor: 'pointer' }} onClick={removeFromFav}>
          {t('cart.removeFromWishlist')}
        </p>
      </FlexColumn>
    </Block>
  );
};

import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  FlexColumn, StyledButton, FlexRowBetween, FlexRow, StatsTitle,
} from '../../../style/projectComponents';
import { DataEmptyComponent } from '../../global/DataEmptyComponent';
import LotteryApi from '../../../service/lottery-api/LotteryApi';
import { AdminEventItem } from '../AdminEventItem';
import { NewEventModal } from '../NewEventModal';
import { PaginationComponent } from '../../global/PaginationComponent';
import { generatePagesArray } from '../../../utils/generatePagesArray';

const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1100px;
  justify-content: start;
  background: rgba(255, 255, 255, 0.3);
  padding: 26px 16px;
  border-radius: 8px;
  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`;

const EventsWrapper = styled(FlexRow)`
  gap: 16px;
  flex-wrap: wrap;
  justify-content: start;
  align-items: start;
`;

export const AdminEvents: FC = () => {
  const { t } = useTranslation();

  const [addModal, setAddModal] = useState(false);
  const handleAddEventModal = () => setAddModal((m) => !m);

  const [page, setPage] = useState(0);
  const itemsPerPage = 3;
  const { data: events, isLoading: allEventsLoading } = useQuery(
    ['all_events_data', page],
    () => LotteryApi.getAllLotteryEvents(
      {
        skip: page * itemsPerPage,
        take: itemsPerPage,
      },
    ),
  );
  const eventsItems = events?.items;
  const totalPages = useMemo(
    () => (events?.totalCount ? Math.ceil(events?.totalCount / itemsPerPage) : 1),
    [events?.totalCount, itemsPerPage],
  );

  return (
    <>
      {addModal && <NewEventModal closeModal={handleAddEventModal} />}
      <OperationsWrapper>
        <FlexRowBetween style={{ alignItems: 'center' }}>
          <StatsTitle>{t('adminAnalytics.events')}</StatsTitle>
          <StyledButton
            variant="darkBlue"
            onClick={handleAddEventModal}
            noRadius
          >
            {t('adminAnalytics.newEvent')}
          </StyledButton>
        </FlexRowBetween>
        {!allEventsLoading && eventsItems?.length > 0
          ? (
            <EventsWrapper>
              {eventsItems?.map((item) => <AdminEventItem event={item} />)}
            </EventsWrapper>
          )
          : <DataEmptyComponent />}
        <PaginationComponent
          maxPages={totalPages}
          pageId={page}
          pagesNum={generatePagesArray(totalPages, page, 100)}
          setPageId={setPage}
        />
      </OperationsWrapper>
    </>
  );
};

import React, { useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import { AppStyle, FlexRowBetween, Page } from '../style/projectComponents';
import { AddToCartComponent } from '../components/Product/AddToCartComponent';
import { ProductColumn } from '../components/Product/ProductColumn';
import { AddToCartMobile } from '../components/Product/AddToCartMobile';
import LotteryApi from '../service/lottery-api/LotteryApi';
import { IEvent } from '../service/lottery-api/models';

const ProductPageWrapper = styled(FlexRowBetween)`
  margin-top: 32px;
  gap: 16px;
`;

export const EventPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [event, setEvent] = useState<IEvent>(null);
  const getEvent = useQuery(
    ['event_data', searchParams.get('id')],
    () => LotteryApi.getLotteryEvent(searchParams.get('id')),
    {
      onSuccess: (data) => {
        setEvent(data);
      },
    },
  );

  return (
    <AppStyle>
      <Page>
        <ProductPageWrapper>
          {event && (
            <ProductColumn
              image={event?.imageDownloadLink}
              name={event.name}
              details={event.prizeDetails}
            />
          )}
          {event && <AddToCartComponent price={0} id={event.id} inFavorite={event.inFavorite} isEvent />}
          {event && <AddToCartMobile price={0} id={event.id} />}
        </ProductPageWrapper>
      </Page>
    </AppStyle>

  );
};

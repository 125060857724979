import React, { useState } from 'react';
import { ValidationInputComponent } from './ValidationInputComponent';
import { DatePicker } from './DatePicker';

type DateInputComponentProps = {
  name: string
  label: string
  register: any
  watch: any;
  setValue: any;
  errors: any;
};

export const DateInputComponent: React.FC<DateInputComponentProps> = ({
  name, register, watch, setValue, errors, label,
}) => {
  const [openBirth, setOpenBirth] = useState(false);

  return (
    <>
      {openBirth && (
        <DatePicker
          name={name}
          open={openBirth}
          setOpen={setOpenBirth}
          date={watch(name)}
          setDate={setValue}
        />
      )}
      <ValidationInputComponent
        {...register(name, { ...{ required: false } })}
        value={`‎\u200e${watch(name)}`}
        onChange={(e) => setValue(name, e.target.value.replace('‎\u200e', ''))}
        isError={!!errors[name]}
        onClick={() => {
          setOpenBirth((prev) => !prev);
        }}
        onBlur={() => {
          setOpenBirth(false);
        }}
        label={label}
        placeholder={label}
        type="text"
      />
    </>
  );
};

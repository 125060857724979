import styled from 'styled-components';
import { FC, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { FlexColumn, FlexColumnStart, FlexRow } from '../../../style/projectComponents';
import { colors } from '../../../style/colors';
import { DoneIcon } from '../../../assets/DoneIcon';
import { Close } from '../../../assets/Close';
import GlobalModalStore, { TToast } from '../../../storage/GlobalModalStore/GlobalModalStore';

const Wrapper = styled(FlexColumnStart)<{ openModal: boolean }>`
  display: ${(props) => (props.openModal ? 'flex' : 'none')};
  width: 18.75rem;
  border: solid 1px #E2E8F0;
  border-radius: 0.75rem;
  padding: 0.64rem 1.5rem 1.41rem;
  background-color: white;

  animation-duration: 0.5s;
  animation-name: slidein;

  @keyframes slidein {
    from {
      margin-right: -100%;
    }

    to {
      margin-right: 0%;
    }
  }



`;

const ImgContainer = styled(FlexColumn)`
  background-color: #ECF1F7;
  width: var(--48, 3rem);
  height: var(--48, 3rem);
  border-radius: 100px;
`;

const CloseContainer = styled(FlexRow)`
  margin-top: 5px;
  width: 100%;
  align-items: end;
  justify-content: end;
  cursor: pointer;
`;

const Content = styled(FlexRow)`
  gap: 1.13rem;
  align-items: center;
`;

const TextWrapper = styled(FlexColumnStart)`
  gap: 0.25rem;

  p {
    font-size: 0.875rem;
  }
`;

const MessageModal: FC<TToast> = (props) => {
  const {
    id, message, isVisible, isSuccess,
  } = props;
  const { t, i18n } = useTranslation();
  const GlobalModalStorage = useContext(GlobalModalStore);

  return (
    <Wrapper openModal={isVisible}>
      <CloseContainer onClick={() => GlobalModalStorage.close(id)}>
        {Close(isSuccess ? colors.primary : colors.redFail)}
      </CloseContainer>
      <Content>
        <ImgContainer>
          {DoneIcon(isSuccess ? colors.primary : colors.redFail)}
        </ImgContainer>
        <TextWrapper>
          <p>{isSuccess ? t('common.accepted') : t('common.errorModal')}</p>
          <p style={{ color: isSuccess ? colors.primary : colors.redFail }}>{message}</p>
        </TextWrapper>
      </Content>
    </Wrapper>
  );
};

export default observer(MessageModal);

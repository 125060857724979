import styled from 'styled-components';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  FlexColumn, FlexColumnStart, FlexRow, StyledButton,
} from '../../style/projectComponents';
import { colors } from '../../style/colors';
import GlobalModalStore from '../../storage/GlobalModalStore/GlobalModalStore';
import { DropdownComponent } from '../global/DropdownComponent';
import { queryClient } from '../../config/query-client';
import { DeliveryStatus, DeliveryStatusesList, IUpdateOrderStatusReq } from '../../service/order-api/models';
import OrderApi from '../../service/order-api/OrderApi';

const StyledForm = styled(FlexColumn)`
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  justify-content: start;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 130;
  overflow: scroll;
`;

const Content = styled(FlexColumnStart)`
  background: ${colors.white};
  border-radius: var(--8, 0.5rem);
  padding: 2.37rem 3.5rem;
  position: relative;
  width: 450px;
  align-items: center;
  margin-top: 150px;

  @media (max-width: 480px) {
    padding: 2.5rem 1rem;
    width: 100%;
    align-items: center;
    border-radius: 0.75rem;
  }
`;

const InnerWrapper = styled(FlexColumn)`
  row-gap: 0.5rem;
  width: calc(100% - 100px);

  h3 {
    margin: 0;
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    width: 100%;
  }

  @media (max-width: 480px) {
    width: calc(100% - 40px);
  }
`;

const CloseButton = styled(FlexRow)`
  align-self: end;
  position: absolute;
  right: 1.6rem;
  top: 1.31rem;
`;

const SettingsWrapper = styled(FlexColumn)`
  width: 100%;
  gap: 6px;
`;
const SettingsInputWrapper = styled(FlexRow)`
  width: 100%;
  position: relative;
  align-items: start;
  @media (max-width: 768px) {
    width: calc(100vw - 40px);
  }
`;

export const EditOrderStatusModal: FC<{ closeModal?: any, orderId: string, orderStatus: DeliveryStatus }> = ({
  closeModal,
  orderId,
  orderStatus,
}) => {
  const { t } = useTranslation();

  const [openCountryMenu, setOpenCountryMenu] = useState(false);
  const [deliveryId, setDeliveryId] = useState(DeliveryStatusesList.findIndex((g) => g === orderStatus) || 0);
  const GlobalModalStorage = useContext(GlobalModalStore);

  const editOrderStatus = useMutation((data: IUpdateOrderStatusReq) => OrderApi.updateOrderStatus(data), {
    onSuccess: (data) => {
      GlobalModalStorage.makeVisible(true, t('common.success'));
      queryClient.refetchQueries(['order_info', orderId]);
      closeModal();
    },
    onError: () => {
      GlobalModalStorage.makeVisible(false, t('common.error'));
    },
  });

  const onSubmit = (data: any) => {
    editOrderStatus.mutate({
      orderId,
      newStatus: DeliveryStatusesList[deliveryId] as DeliveryStatus,
    });
  };

  return (
    <StyledForm
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          closeModal();
        }
      }}
    >
      <Content>
        <CloseButton onClick={closeModal}>
          <img src="/images/ic_cancel.svg" alt="" />
        </CloseButton>

        <InnerWrapper>
          <h3 style={{ marginBottom: 20 }}>
            {t('adminUserDetail.edit')} {t('common.status')}
          </h3>
          <SettingsWrapper>
            <SettingsInputWrapper>
              <DropdownComponent
                openMenu={openCountryMenu}
                setOpenMenu={setOpenCountryMenu}
                itemId={deliveryId}
                setId={setDeliveryId}
                hasSubTitle
                subtitle={t('common.status')}
                itemList={DeliveryStatusesList}
              />
            </SettingsInputWrapper>
          </SettingsWrapper>
          <StyledButton
            variant="darkBlue"
            style={{ width: '100%', height: 56, marginTop: 16 }}
            disabled={editOrderStatus.isLoading}
            noRadius
            onClick={onSubmit}
          >
            {t('common.edit')}
          </StyledButton>
        </InnerWrapper>
      </Content>
    </StyledForm>
  );
};

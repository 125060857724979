import React from 'react';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import {
  FlexColumn,
} from '../../style/projectComponents';
import { colors } from '../../style/colors';

const Block = styled(FlexColumn)`
  gap: 12px;
  width: 259px;
  @media (max-width: 768px) {
    width: 159px;
  }
`;
const GrayBg = styled(FlexColumn)`
  background-image: url("/images/interface/product-cart-gray-bg.svg");
  width: 259px;
  height: 58px;
  background-size: 110%;
  background-repeat: no-repeat;
  @media (max-width: 768px) {
    width: 159px;
    padding: 0 10px;
    height: 42px;
  }

  p {
    font-size: 11px;
    color: ${colors.accent};
    font-weight: 700;
    -webkit-text-stroke: 0.5px ${colors.blueDark};
    text-stroke: 0.5px ${colors.blueDark};
    -webkit-background-clip: text;
    background-clip: text;
    text-transform: uppercase;
  }
`;
const Description = styled.p`
  font-size: 11px;
  color: ${colors.gray400};
  font-weight: 400;
  text-align: center;
`;

interface ProductProps {
  productName: string;
  ticketsAmount: number;
  drawDate: string;
}

export const ProductEventInfo: React.FC<ProductProps> = ({
  productName,
  ticketsAmount,
  drawDate,
}) => {
  const { t } = useTranslation();
  const formattedDrawDate = drawDate && format(new Date(drawDate), 'd MMMM', { locale: enUS });

  return (
    <Block>
      <GrayBg>
        <p>{t('common.buyOne')} {productName} {t('common.andGet')} {ticketsAmount} {t('common.tickets')}</p>
      </GrayBg>
      <Description>{t('adminUserDetail.drawDate')}{': '} {formattedDrawDate} {t('common.orEarlierSoldOut')}</Description>
    </Block>
  );
};

export default observer(ProductEventInfo);

import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { AppStyle, Page } from '../style/projectComponents';
import { PopularCampaignsSection } from '../components/Home/sections/PopularCampaignsSection';
import { ExploreCampaignsSection } from '../components/Home/sections/ExploreCampaignsSection';
import { WinnersSection } from '../components/Home/sections/WinnersSection';
import { IGetAllLotteryEventsRes, ILotteryStatus } from '../service/lottery-api/models';
import LotteryApi from '../service/lottery-api/LotteryApi';
import { OurStoreButton } from '../components/Home/sections/OurStoreButton';
import ProductApi from '../service/product-api/ProductApi';
import { ZERO_EVENT_CODE_ID } from '../config/lockedEvents';
import { SoldOutCampaigns } from '../components/Home/sections/SoldOutSections';

const MainBannerImg = styled.img`
  width: 100vw;
  height: 308px;
  max-width: 1198px;
  margin-top: 90px;
  @media (min-width: 480px) and (max-width: 1220px) {
    max-width: calc(100% - 40px)
  }

  @media (max-width: 480px) {
    max-width: 100%;
    height: 247px;
  }
`;

export const HomePage = () => {
  const [events, setEvents] = useState<IGetAllLotteryEventsRes>(null);
  const eventsItems = events?.items;
  const getAllEventsQuery = useQuery(
    'all_events_data',
    () => LotteryApi.getAllLotteryEvents(
      {
        skip: 0,
        take: 5,
        status: ILotteryStatus.created,
      },
    ),
    {
      onSuccess: (data) => {
        setEvents(data);
      },
    },
  );
  const { data: products } = useQuery(
    'all_products',
    () => ProductApi.getAllProducts(
      {
        skip: 0,
        take: 20,
        withActiveEvents: true,
      },
    ),
  );
  const productsItems = products?.items;
  const filteredProductsItems = productsItems?.filter((p) => p.eventCode !== ZERO_EVENT_CODE_ID);

  const [openPromo, setOpenPromo] = useState(false);
  const handlePromo = () => setOpenPromo((s) => !s);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const imageUrl = screenWidth <= 480 ? '/images/banner/home-main.png' : '/images/banner/home-main-desktop.png';
  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <AppStyle>
        <MainBannerImg src={imageUrl} alt="" />
        <Page style={{ marginTop: 0 }}>
          <PopularCampaignsSection eventsItems={eventsItems} isLoading={getAllEventsQuery.isLoading} />
          <ExploreCampaignsSection productsItems={filteredProductsItems} />
          <OurStoreButton />
          <WinnersSection />
          <SoldOutCampaigns />
        </Page>
      </AppStyle>
    </>
  );
};

import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  AuthContent, AuthModal, AuthTitle, Page, ModalHeader,
} from '../style/projectComponents';
import FirstStep from '../components/auth/Recover/FirstStep';
import SecondStep from '../components/auth/Recover/SecondStep';

export enum REC_STAGE {
  BASE = 'BASE', // email input
  FINAL = 'FINAL', // success
}

export const ForgotPassword: FC = () => {
  const [stage, setStage] = useState(REC_STAGE.BASE);
  const { t } = useTranslation();

  return (
    <Page>
      <AuthContent>
        <AuthModal style={{ padding: 36 }}>
          <ModalHeader>
            <Link to="/login" style={{ height: 14 }}>
              <img
                style={{ cursor: 'pointer' }}
                width={14}
                height={14}
                src="/images/buttons/close.png"
                alt="close"
              />
            </Link>
          </ModalHeader>
          <AuthTitle style={{ width: '100%', textAlign: 'left' }}>
            {stage === REC_STAGE.BASE ? t('forgotPassword.title') : t('forgotPassword.checkEmail')}
          </AuthTitle>

          {(stage === REC_STAGE.BASE
            ? <FirstStep setRecStage={setStage} />
            : <SecondStep />
          )}
        </AuthModal>
      </AuthContent>
    </Page>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FlexColumnStart, FlexRowBetween } from '../../style/projectComponents';
import { colors } from '../../style/colors';
import LanguageStorage from '../../storage/LanguageStorage/LanguageStorage';

const Wrapper = styled(FlexRowBetween)`
  height: 308px;
  width: 100%;
  background: ${colors.blueDark};
  border-radius: 10px;
  color: #fff;
  text-align: left;
  align-items: center;
  position: relative;
  @media(max-width: 768px) {
    flex-direction: column;
    text-align: center;
    border-radius: 0;
    width: 100vw;
    height: 400px;
  }
  img {
    position: absolute;
    right: 0;
    bottom: 0;
    object-fit: contain;
    width: 50%;
    @media(max-width: 768px) {
      width: 100%;
      right: auto;
      max-width: 400px;
    }
  }
`;
const Title = styled.h2`
  font-size: 45px;
  @media(max-width: 768px) {
    font-size: 28px;
    margin-bottom: 12px;
  }
`;
const Description = styled.p`
  font-size: 15px;
  opacity: 0.5;
  font-weight: 500;
`;
const Content = styled(FlexColumnStart)`
  margin:0 70px;
  @media(max-width: 768px) {
    margin-left: 0;
    margin-top: 50px;
    align-items: center;
    padding: 0 32px;
  }
`;

interface StoreBannerProps {
}

export const StoreBanner: React.FC<StoreBannerProps> = () => {
  const { t } = useTranslation();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const imageUrl = screenWidth <= 768 ? '/images/interface/store/store-banner-mobile.png' : '/images/interface/store/store-banner-pc.png';
  const LanguageStore = useContext(LanguageStorage);

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Wrapper>
      <Content>
        <Title>{t('common.exploreProducts')}</Title>
        <Description>{t('common.shopInOurStore')}</Description>
      </Content>
      <img
        src={imageUrl}
        alt=""
        style={{
          right: LanguageStore.language === 'ar' ? 'auto' : 0,
          left: LanguageStore.language === 'ar' ? 0 : 'auto',
        }}
      />
    </Wrapper>

  );
};

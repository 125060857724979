import React, { FC } from 'react';
import { IPromoItem } from '../../../service/promo-api/models';

interface PromoRowProps {
  promoItem: IPromoItem;
}

const PromoRow: FC<PromoRowProps> = ({ promoItem }) => (
  <tr key={promoItem.id}>
    <td>{promoItem.id}</td>
    <td>{promoItem.text}</td>
    <td>{promoItem.value}</td>
    <td>{promoItem.endDate}</td>
    <td>{promoItem.useLimit}</td>
    <td>{promoItem.wasUsed}</td>
  </tr>
);

export default PromoRow;

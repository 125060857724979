import React from 'react';
import styled from 'styled-components/macro';
import {
  Area,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn,
  FlexColumnStart,
  FlexRow,
} from '../../style/projectComponents';
import { colors } from '../../style/colors';

const ChartWrapper = styled(FlexColumn)`
  width: 100%;
  height: 500px;

  line {
    stroke: ${colors.gray050};
  }
`;

interface ChartComponentProps {
  variant?: 'gradient' | 'linear';
  data: any;
  xAxisKey?: string;
  yAxisKey?: string;
  titleKey?: string | string[];
  valueKey?: string | string[];
}

const CustomTooltip = (props) => {
  const {
    active, payload, label, titleKey, valueKey,
  } = props;
  const { t, i18n } = useTranslation();

  const numKeys = ['balance', 'deposit'];

  const handleValues = () => {
    if (typeof valueKey === 'string') {
      const res = numKeys.find((it) => valueKey.toLowerCase().includes(it))
        ? `${Math.abs(Number(payload[0].payload[valueKey])).toFixed(2)}`
        : payload[0].payload[valueKey];
      return [res];
    }
    const res = valueKey.map((it, idx) => {
      let rs;
      if (it === 'balanceDiffPercent') {
        rs = payload[0].payload[it];
        const abs = Math.abs(Number(payload[0].payload[it])).toFixed(2);
        if (rs < 0) {
          return `-${abs} %`;
        }
        return `+${abs} %`;
      }
      if (
        numKeys.find((it_) => it.toLowerCase().includes(it_.toLowerCase()))
      ) {
        rs = payload[0].payload[it];
        const abs = Math.abs(Number(payload[0].payload[it])).toFixed(2);
        if (rs < 0) {
          return `-${abs}`;
        }
        return `${abs}`;
      }
      rs = payload[0].payload[it];
      return rs;
    });
    return res;
  };

  if (active && payload && payload.length) {
    const color = colors.greenSuccess;

    return (
      <FlexColumnStart
        style={{
          // @ts-ignore
          background: colors.lightGrayBg,
          padding: '0.75rem 1rem',
          borderRadius: '0.1875rem',
        }}
      >
        <p
          className="label"
                    // @ts-ignore
          style={{ color: colors.black, fontSize: 14 }}
        >
          {`${label}`}
        </p>
        <FlexRow style={{ alignItems: 'end' }}>
          <p style={{ fontSize: 16, color }}> {handleValues()[0]} </p>
        </FlexRow>
      </FlexColumnStart>
    );
  }

  return null;
};

export const LineChartComponent: React.FC<ChartComponentProps> = (props) => {
  const {
    data,
    titleKey,
    valueKey,
    variant = 'gradient',
    xAxisKey = 'operationDate',
    yAxisKey = 'balanceDiff',
  } = props;

  const formatYAxis = (value) => Math.round(value);

  return (
    <ChartWrapper>
      <ResponsiveContainer
        width="100%"
        height={250}
        key={`rc_${data.length}`}
      >
        <ComposedChart data={data} key={`cc_${data.length}`}>
          <XAxis dataKey={xAxisKey} style={{ fontSize: 14 }} minTickGap={50} />
          <YAxis
            type="number"
                        // @ts-ignore
            tickFormatter={formatYAxis}
            domain={[0]}
            dataKey={yAxisKey}
            style={{ fontSize: 14 }}
          />
          <Tooltip
            content={<CustomTooltip titleKey={titleKey} valueKey={valueKey} />}
            contentStyle={{ fontSize: 14, textAlign: 'left' }}
          />
          {variant === 'gradient' && (
            <Area
              key={`ac_${data.length}`}
              type="monotone"
              dataKey={yAxisKey}
              stroke="#68dbb3"
              strokeWidth={2}
              fill="url(#colorUv)"
            />
          )}
        </ComposedChart>
      </ResponsiveContainer>
    </ChartWrapper>
  );
};

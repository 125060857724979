import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, FlexRow, Modal, StyledButton,
} from '../../../style/projectComponents';
import { colors } from '../../../style/colors';
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore';
import { IAddProductToCartReq } from '../../../service/cart-api/models';
import CartApi from '../../../service/cart-api/CartApi';
import { queryClient } from '../../../config/query-client';
import { useCart } from '../CartContext';

const ContentWrapper = styled(FlexRow)`
  background: ${colors.lightGray};
  height: 160px;
  width: 700px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 6px 20px;
  @media (max-width: 768px) {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
  }
`;
const ModalWrapper = styled(Modal)`
  background: rgba(0, 0, 0, 0.65);
  justify-content: center;
  flex-direction: column;
  z-index: 1100;
`;
const ContentHeader = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  margin-bottom: 20px;

  h3 {
    font-size: 15px;
    font-weight: 600;
    color: ${colors.black};
    font-family: 'HeadingNowTrial', sans-serif;
  }
`;
const AmountButton = styled(FlexColumn)`
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #fff;
  cursor: pointer;
  flex: 0 0 auto; // Prevent shrinking

  p {
    font-size: 14px;
    font-weight: 500;
    color: ${colors.black};
    font-family: 'Maswen', sans-serif;
  }
`;

interface SelectQuanityModalProps {
  openMenu: boolean,
  handleMenu: () => void,
  productId: string
}

const SelectQuantityModal: React.FC<SelectQuanityModalProps> = ({
  openMenu,
  handleMenu,
  productId,
}) => {
  const { t } = useTranslation();
  const availableAmounts = Array.from({ length: 100 }, (v, i) => i + 1);
  const [selectedId, setSelectedId] = useState(0);
  const { setShowCart } = useCart();

  const GlobalModalStorage = useContext(GlobalModalStore);
  const addProductMutation = useMutation((data: IAddProductToCartReq) => CartApi.addProductToCartReq(data), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: (data) => {
      GlobalModalStorage.makeVisible(true, 'Successfully added to cart');
      setShowCart(true);
      queryClient.refetchQueries('get_cart_data');
      handleMenu();
    },
  });

  if (openMenu) {
    return (
      <ModalWrapper onClick={(e) => {
        if (e.target === e.currentTarget) {
          handleMenu();
        }
      }}
      >
        <ContentWrapper>
          <ContentHeader>
            <h3>
              {t('common.selectQuantity')}
            </h3>
            <StyledButton
              variant="darkBlue"
              onClick={() => addProductMutation.mutate({
                productId,
                amount: availableAmounts[selectedId],
              })}
              disabled={addProductMutation.isLoading}
            >
              {t('common.add')}
            </StyledButton>
          </ContentHeader>
          <FlexRow style={{
            gap: 16, overflowX: 'scroll', justifyContent: 'start', width: '100%',
          }}
          >
            {availableAmounts?.map((a, id) => (
              <AmountButton
                style={{ background: id === selectedId && colors.primary }}
                onClick={() => setSelectedId(id)}
              >
                <p>{a}</p>
              </AmountButton>
            ))}
          </FlexRow>
        </ContentWrapper>
      </ModalWrapper>
    );
  }
};

export default SelectQuantityModal;

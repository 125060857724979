export const DoneIcon = (color?: string) => (
  <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="check-circle">
      <path
        id="Vector"
        d="M8.62109 16.4996L13.8736 21.7521L24.3786 11.2471"
        stroke={color || '#00AAC3'}
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
  </svg>
);

import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import React, { useState } from 'react';
import { StyledSection } from '../../global/StyledSection';
import {
  ContainerSocial,
  FlexColumn, FlexColumnStart, FlexRow, FooterCard, FooterTextCard, FooterTextLang,
} from '../../../style/projectComponents';
import { MenuColItem } from './MenuColItem';
import { socialLinks } from '../../../config/socialLinks';
import { InformationCards } from '../../global/InformationCards';
import { CurrencyFooterIcon, LanguageFooterIcon, PhoneFooterIcon } from '../../../assets/footer';
import { colors } from '../../../style/colors';
import { AppGalleryIcon, AppStoreIcon, GooglePlayIcon } from '../../../assets/app-stores';
import LanguageModal from '../../global/modal/LanguageModal';
import CurrencyModal from '../../global/modal/CurrencyModal';

const FooterSectionPage = styled(StyledSection)`
  max-width: 100vw;
  align-items: center;
  width: 100vw;
  padding-left: 0;
  padding-right: 0;
  position: relative;
  background: ${colors.white};
`;

const Inner = styled(FlexColumnStart)`
  gap: 2.125rem;
  max-width: 81.25rem;
  width: 100%;
  box-sizing: border-box;
  margin: 0 auto;
  position: relative;
`;

const FooterRight = styled(Inner)`
  grid-area: 1 / 1 / 3 / 2;
  @media (max-width: 1200px) {
    padding-right: 0;
    grid-area: 1 / 1 / 2 / 2;
  }
  @media (max-width: 768px) {
    flex-direction: column-reverse
  }
`;
const FooterMid = styled(Inner)`
  grid-area: 1 / 2 / 2 / 3;
  @media (max-width: 1200px) {
    display: none;
  }
`;
const FooterLeft = styled(Inner)`
  grid-area: 1 / 3 / 2 / 4;
  @media (max-width: 1200px) {
    grid-area: 3 / 1 / 4 / 2;
  }
`;
const FooterMenu = styled(Inner)`
  grid-area: 2 / 2 / 3 / 4;
  justify-content: end;
  @media (max-width: 1200px) {
    grid-area: 4 / 1 / 5 / 2;
  }
`;
const SocialLinkImage = styled.img`
  cursor: pointer;
  height: 72px;
  @media (max-width: 768px) {
    height: 72px;
  }
`;

const ContainerGrid = styled.div`
  display: grid;
  height: 100%;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: 2fr 1fr;
  grid-column-gap: 103px;
  grid-row-gap: 0;
  padding: 64px 54px 116px;

  @media (max-width: 1300px) {
    padding: 64px 54px 116px;
    width: 70%;
    display: flex;
    flex-direction: column;
    gap: 32px;
  }
`;

const EmailButton = styled.a`
  font-size: 15px;
  font-weight: 600;
  margin: 0 6px;
  color: ${colors.primary};
`;

export const Footer = () => {
  const { t } = useTranslation();
  const location = useLocation();
  const isPromoPage = location.pathname === '/promo';

  const quickLinks = [
    {
      link: '/',
      title: t('menu.footer.drawTerms'),
    },
    {
      link: '/',
      title: t('menu.footer.privacyPolicy'),
    },
    {
      link: '/',
      title: t('menu.footer.userAgreement'),
    },
    {
      link: '/',
      title: t('menu.footer.deleteAccount'),
    },
  ];
  const customerService = [
    {
      link: '/',
      title: t('menu.footer.community'),
    },
    {
      link: '/',
      title: t('menu.footer.faqs'),
    },
    {
      link: '/',
      title: t('menu.footer.howItWorks'),
    },
  ];

  const [openLangModal, setOpenLangModal] = useState(false);
  const handleLangMenu = () => {
    setOpenLangModal((bool) => !bool);
  };
  const [currencyModal, setCurrencyModal] = useState(false);
  const handleCurrencyModal = () => {
    setCurrencyModal((bool) => !bool);
  };

  return (
    <FooterSectionPage style={{ display: isPromoPage && 'none' }}>
      <LanguageModal openMenu={openLangModal} handleMenu={handleLangMenu} />
      <CurrencyModal openMenu={currencyModal} handleMenu={handleCurrencyModal} />
      <ContainerGrid>
        <FooterRight>
          <FlexRow style={{ width: '100%', justifyContent: 'flex-start', gap: 50 }}>
            <FlexRow style={{ gap: 15, cursor: 'pointer' }} onClick={() => setCurrencyModal(true)}>
              <CurrencyFooterIcon />
              <FooterTextLang>{t('common.currency')}</FooterTextLang>
            </FlexRow>
            <FlexRow style={{ gap: 15, cursor: 'pointer' }} onClick={() => setOpenLangModal(true)}>
              <LanguageFooterIcon />
              <FooterTextLang>{t('language.title')}</FooterTextLang>
            </FlexRow>
          </FlexRow>
          <FlexColumn style={{
            gap: 13,
            width: '100%',
          }}
          >
            <FooterCard style={{
              borderColor: colors.accent,
              flexDirection: 'row',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
            >
              <FlexColumn style={{ alignItems: 'start' }}>
                <FooterTextCard>
                  {t('footer.questions')}
                  {' '}
                  ?
                </FooterTextCard>
                <FooterTextCard>
                  {t('footer.chatWithUs')}
                  :
                </FooterTextCard>
              </FlexColumn>
              <PhoneFooterIcon />
            </FooterCard>
            <FooterCard href="tel:+23456799900">
              <FooterTextCard style={{ color: colors.blueLight }}>
                {t('footer.callUs')}
              </FooterTextCard>
              <FooterTextCard>
                +23456799900
              </FooterTextCard>
            </FooterCard>
            <FooterCard href="mailto:holmegy@gmail.com">
              <FooterTextCard style={{ color: colors.blueLight }}>
                {t('auth.emailUs')}
                :
              </FooterTextCard>
              <FooterTextCard>
                {/* eslint-disable-next-line i18next/no-literal-string */}
                <EmailButton>
                  holmegy@gmail.com
                </EmailButton>
              </FooterTextCard>
            </FooterCard>
            {/* <MenuAndLinks> */}
            {/*    <MenuWrapper> */}
            {/*        <Menu> */}
            {/*            <FooterTitle>Quick Links</FooterTitle> */}
            {/*            <MenuColItem items={quickLinks}/> */}
            {/*        </Menu> */}
            {/*    </MenuWrapper> */}
            {/*    <MenuWrapper> */}
            {/*        <Menu> */}
            {/*            <FooterTitle>Customer Service</FooterTitle> */}
            {/*            <MenuColItem items={customerService}/> */}
            {/*        </Menu> */}
            {/*    </MenuWrapper> */}
            {/* </MenuAndLinks> */}
          </FlexColumn>
        </FooterRight>
        <FooterMid>
          <InformationCards />
        </FooterMid>
        <FooterLeft>
          <ContainerSocial>
            {socialLinks.map((linkItem) => (
              <a href={linkItem.link} rel="noreferrer" target="_blank">
                <SocialLinkImage
                  style={{
                    maxWidth: 55,
                    maxHeight: 50,
                  }}
                  src={linkItem.image}
                  key={linkItem.link}
                  alt=""
                />
              </a>
            ))}
          </ContainerSocial>
          <FlexColumn style={{ width: '100%' }}>
            <FooterTextCard style={{
              maxWidth: 312,
              textAlign: 'center',
              fontSize: 14,
            }}
            >
              {t('footer.downloadTheHolm')}
            </FooterTextCard>
            <FlexRow style={{ marginTop: 14, gap: 5 }}>
              <AppStoreIcon />
              <GooglePlayIcon />
              <AppGalleryIcon />
            </FlexRow>
          </FlexColumn>
        </FooterLeft>
        <FooterMenu>
          <MenuColItem items={quickLinks} />
        </FooterMenu>
      </ContainerGrid>
    </FooterSectionPage>
  );
};

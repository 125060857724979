import React from 'react';
import { useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../style/colors';
import { FlexRow, StyledAuthButton } from '../../../style/projectComponents';

const ButtonWrapper = styled(FlexRow)`
  background: ${colors.mediumGray};
  width: 100%;
  border-radius: 16px;
`;

const RegisterLoginButtons: React.FC = () => {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login';
  const { t } = useTranslation();

  return (
    <ButtonWrapper>
      <StyledAuthButton
        selected={isLoginPage}
        to="/login"
        type="button"
      >
        {t('menu.login')}
      </StyledAuthButton>
      <StyledAuthButton
        selected={!isLoginPage}
        to="/register"
        type="button"
      >
        {t('auth.reg_title')}
      </StyledAuthButton>
    </ButtonWrapper>
  );
};

export default RegisterLoginButtons;

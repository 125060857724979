import React, { useContext, useMemo } from 'react';
import styled from 'styled-components';
import { useLocation, useNavigate } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, StyledButton, FlexRowBetween, FlexRow,
} from '../../style/projectComponents';
import CartApi from '../../service/cart-api/CartApi';
import UserStorage from '../../storage/UserStorage/UserStorage';
import { colors } from '../../style/colors';
import { CartTicketMini } from '../Cart/Cart/Ticket/CartTicketMini';
import LanguageStorage from '../../storage/LanguageStorage/LanguageStorage';

interface WrapperProps {
  isHide: boolean;
}

const Wrapper = styled(FlexColumn)<WrapperProps>`
  width: 500px;
  position: fixed;
  right: 50px;
  bottom: 50px;
  z-index: 100;
  display: ${({ isHide }) => (isHide ? 'none' : 'flex')};
  justify-content: end;
  align-items: end;
  border-radius: 32px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const FloatedButton = styled(StyledButton)`
  width: 220px;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 10px;
  background: ${({ variant }) => variant === 'primary' && '#fff'};

  &:hover {
    img {
      filter: ${({ variant }) => variant === 'primary' && 'brightness(400%)'};
    }
  }
`;
const ContinueWrapper = styled(FlexColumn)`
  padding: 20px;
  border-top: 1px solid ${colors.mediumGray};
  width: calc(100% - 40px);
  transform: translateY(-16px);
`;
const ContinueButton = styled(StyledButton)`
  width: 100%;
  height: 60px;
  font-size: 15px;
  font-weight: 600;
`;
const CartItemsWrapper = styled(FlexColumn)`
  padding-top: 18px;
  padding-left: 18px;
  padding-right: 18px;
  width: calc(100% - 36px);
`;
const CartItemsScroll = styled(FlexColumn)`
  height: 200px;
  padding-top: 10px;
  overflow: scroll;
  width: 100%;
  justify-content: start;
`;
const CloseButton = styled(FlexRow)`
  cursor: pointer;
  img {
    width: 32px;
    height: 32px;
  }
`;

interface FloatedCartProps {
  handleCart: any;
}

export const FloatedCart: React.FC<FloatedCartProps> = ({ handleCart }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const hideCart = location?.pathname?.toLowerCase().includes('/admin') || location?.pathname === '/cart';
  const UserStore = useContext(UserStorage);
  const isAuth = !!UserStore.accessToken;
  const LanguageStore = useContext(LanguageStorage);

  const { data: cart } = useQuery(
    'get_cart_data',
    () => CartApi.getCart({
      skip: 0,
      take: 50,
    }),
    {
      enabled: isAuth,
    },
  );
  const cartItems = useMemo(() => cart?.items?.items, [cart?.items?.items]);

  const cartSize = useMemo(() => cartItems?.reduce((acc, c) => acc + c.amount, 0) || 0, [cartItems]);

  return (
    <Wrapper
      isHide={hideCart}
      style={{
        display: !isAuth && 'none',
        background: cartSize > 0 && '#fff',
        alignItems: cartSize > 0 && 'center',
        right: LanguageStore.language === 'ar' ? 'auto' : 50,
        left: LanguageStore.language === 'ar' ? 50 : 'auto',
      }}
    >
      {cartSize === 0 && (
        <FloatedButton onClick={() => navigate('/cart')} variant="darkBlue" noRadius>
          <>{t('cart.shoppingCart')}</>
          <img src="/images/buttons/icons/ShoppingCart.svg" alt="" />
        </FloatedButton>
      )}
      {cartSize > 0
            && (
              <>
                <CartItemsWrapper>
                  <FlexRowBetween style={{ alignItems: 'center' }}>
                    <p style={{ textAlign: 'left' }}>
                      {t('cart.productsInCart')}
                      :
                      {cartSize}
                    </p>
                    <CloseButton onClick={handleCart}>
                      <img src="/images/ic_cancel.svg" alt="" />
                    </CloseButton>
                  </FlexRowBetween>
                  <CartItemsScroll style={{ height: cart?.items?.totalCount === 1 && 160 }}>
                    {cartItems?.map((cart, id) => (
                      <CartTicketMini
                        value={cart.productPrice}
                        prize={cart.prizeName}
                        id={id}
                        amount={cart.amount}
                        image={cart.cartImageDownloadLink}
                        productName={cart.productName}
                        productId={cart.productId}
                        length={cartItems.length}
                        key={`${cart?.productId}`}
                      />
                    ))}
                  </CartItemsScroll>
                </CartItemsWrapper>
                <ContinueWrapper>
                  <ContinueButton variant="green" onClick={() => navigate('/cart')}>
                    {t('cart.continueToCheckout')}
                  </ContinueButton>
                </ContinueWrapper>
              </>
            )}
    </Wrapper>
  );
};

import React, { useContext } from 'react';
import styled from 'styled-components';
import { FlexRow, FlexRowBetween } from '../../../style/projectComponents';
import { colors } from '../../../style/colors';
import LanguageStorage from '../../../storage/LanguageStorage/LanguageStorage';

const Block = styled(FlexRowBetween)`
  width: calc(100% - 25px);
  background: ${colors.white};
  border-bottom: 1px solid ${colors.mediumGray};
  padding: 14px 12.5px;
  cursor: pointer;
  p {
    font-weight: 500;
  }
  @media(max-width: 768px) {
    width: 100%;
  }
`;
const Icon = styled.img`
  height: 21px;
  width: 21px;
`;
const Arrow = styled.img`
  height: 16px;
  width: 16px;
`;

interface MenuComponentProps {
  icon: string;
  title: string;
  action: () => void;
  id: number;
  length: number;
}

export const MenuButton: React.FC<MenuComponentProps> = ({
  icon, title, action, id, length,
}) => {
  const LanguageStore = useContext(LanguageStorage);

  return (
    <Block
      style={{ borderRadius: id === 0 && '19px 19px 0 0' || (length === id + 1) && '0 0 19px 19px' }}
      onClick={action}
    >
      <FlexRow style={{ gap: 14 }}>
        <Icon src={icon} alt="" />
        <p>{title}</p>
      </FlexRow>
      <Arrow
        style={{ transform: LanguageStore.language === 'ar' ? 'rotate(180deg)' : 'rotate(0deg)' }}
        src="/images/buttons/menu/arrow.svg"
        alt=""
      />
    </Block>
  );
};

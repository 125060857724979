import styled from 'styled-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  FlexColumnStart,
  Modal,
  StyledButton,
  FlexRowBetween,
  FlexColumn,
  FlexRow,
} from '../../../style/projectComponents';
import { colors } from '../../../style/colors';

const Content = styled(FlexColumnStart)`
  border-radius: 15px;
  padding: 24px;
  position: relative;
  width: 500px;
  align-items: center;
  background: linear-gradient(165.86deg, #103190 0.65%, #050E2A 145.83%);
  color: ${colors.white};

  h2 {
    margin: 0;
  }

  p {
    font-size: 19px;
  }

  @media (max-width: 480px) {
    width: 100%;
    height: 100%;
    padding: 0;
    justify-content: space-between;
    border-radius: 0;
    padding-top: 100px;
    padding-bottom: 100px;
  }
`;
const CloseButton = styled(FlexRow)`
  width: 20px;
  height: 20px;
  margin-right: 6px;
  margin-top: 6px;

  img {
    width: 20px;
    height: 20px;
    margin-right: 20px;
  }
`;
const ModalWrapper = styled(Modal)`
  background: rgba(0, 0, 0, 0.65);
  justify-content: center;
  flex-direction: column;
`;
const ContinueButton = styled(StyledButton)`
  width: 100%;
  height: 60px;
  font-size: 15px;
  font-weight: 600;
  max-width: 400px;
  @media (max-width: 480px) {
    margin-bottom: 12px;
    width: calc(100% - 32px);
  }
`;
const Image = styled.img`
  width: 100%;
  margin-top: 40px;
  margin-bottom: 32px;
  height: 250px;
  object-fit: cover;
  max-width: 400px;
  @media (max-width: 480px) {
    max-width: 100%;
  }
`;

export const CharityModal: FC<{ setIsOpen?: any, ticketsAmount: number }> = ({ setIsOpen, ticketsAmount }) => {
  const { t } = useTranslation();

  return (
    <ModalWrapper onClick={(e) => {
      if (e.target === e.currentTarget) {
        setIsOpen(false);
      }
    }}
    >
      <Content>
        <FlexRowBetween style={{ marginBottom: 24, justifyContent: 'end' }}>
          <CloseButton onClick={() => setIsOpen(false)}>
            <img
              style={{ cursor: 'pointer' }}
              src="/images/close16.svg"
              alt="close"
            />
          </CloseButton>
        </FlexRowBetween>
        <FlexColumn>
          <p style={{ marginBottom: 16 }}>
            {t('common.wow')}
            !
            <span
              style={{ fontWeight: 900, fontSize: 16 }}
            >
              X
              {ticketsAmount * 2}
            </span>
            {' '}
            {t('common.ticketsYouHaveNow')}
          </p>
          <p>{t('common.youFromYourDonation')}</p>
        </FlexColumn>
        <Image src="/images/interface/charity-congrats.png" alt="" />
        <ContinueButton variant="green" onClick={() => setIsOpen(false)} style={{ fontWeight: 700 }}>
          {t('auth.continue')}
        </ContinueButton>
      </Content>
    </ModalWrapper>
  );
};

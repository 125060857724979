import { createContext } from 'react';
import { action, autorun, makeAutoObservable } from 'mobx';

class CurrencyStorage {
  currency: string = localStorage.currency ? JSON.parse(localStorage.currency) : 'EGP';

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    autorun(() => {
      localStorage.currency = JSON.stringify(this.currency);
    });
  }

  @action
  setCurrency(curr_: string) {
    this.currency = curr_;
  }
}

export default createContext(new CurrencyStorage());

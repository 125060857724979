import { createContext } from 'react';
import { action, autorun, makeAutoObservable } from 'mobx';

class LanguageStorage {
  language: string = localStorage.language ? JSON.parse(localStorage.language) : 'en';

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    autorun(() => {
      localStorage.language = JSON.stringify(this.language);
    });
  }

  @action
  setLanguage(lng_: string) {
    this.language = lng_;
  }
}

export default createContext(new LanguageStorage());

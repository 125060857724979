import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import InputMask from 'react-input-mask';
import { Controller } from 'react-hook-form';
import styled from 'styled-components';
import { CustomStyledInput } from './ValidationInputComponent';
import { DropdownComponent } from './DropdownComponent';
import { phoneCodes } from '../../config/phoneCodes';
import { isPhoneNumberGood } from '../auth/Register/SecUtils';
import { FlexRow } from '../../style/projectComponents';

type PhoneInputComponentProps = {
  name: string
  control: any
  resetField: any
  errors: any;
};

const PhoneWrapper = styled(FlexRow)`
  width: 100%;
  box-sizing: border-box;
  padding: 0;
  gap: 6px;
`;
const MaskWrapper = styled(FlexRow)`
  width: 100%;
  max-width: 180px;
`;

export const PhoneInputComponent: React.FC<PhoneInputComponentProps> = ({
  name, control, resetField, errors,
}) => {
  const { t, i18n } = useTranslation();
  const [itemId, setItemId] = useState(0);
  const [openPhoneMenu, setOpenPhoneMenu] = useState(false);

  // eslint-disable-next-line react/no-unstable-nested-components
  const MaskPhoneInput = (props: any) => {
    const idx = phoneCodes.at(itemId).code;
    const handleMask = () => {
      const { mask } = phoneCodes.at(itemId);
      const { code } = phoneCodes.at(itemId);
      if (Array.isArray(mask)) {
        return `${code} ${mask[0]}`;
      } return `${code} ${mask}`;
    };

    return (
      <InputMask
        style={{ padding: '18px 12px' }}
        mask={handleMask()}
        {...props}
        formatChars={{ '#': '[0-9]' }}
        permanents={[0, idx.length]}
        value={props?.value}
        onChange={props?.onChange}
      >
        {(inputProps) => <CustomStyledInput {...inputProps} />}
      </InputMask>
    );
  };

  return (
    <Controller
      rules={isPhoneNumberGood}
      name={name}
      control={control}
      render={({ field: { ref, ...field } }) => (
        <PhoneWrapper>
          <DropdownComponent
            className="menu"
            itemId={itemId}
            itemList={phoneCodes.map((it) => {
              const ctr = it[`name_${i18n.language}`];
              if (ctr.length > 28) {
                return `${ctr.substring(0, 28)}... (${it.code})`;
              }
              return `${ctr} (${it.code})`;
            })}
            setId={setItemId}
            openMenu={openPhoneMenu}
            setOpenMenu={(open: boolean) => {
              resetField(name);
              setOpenPhoneMenu(open);
            }}
          />
          <MaskWrapper>
            <MaskPhoneInput
              {...field}
              value={field.value}
              isFailed={!!errors[name]}
              className="phone"
              name={name}
              placeholder={t('adminUserDetail.phoneNumber.placeholder')}
              isError={!!errors[name]}
            />
          </MaskWrapper>
        </PhoneWrapper>
      )}
    />
  );
};

import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useQuery } from 'react-query';
import {
  FlexColumn,
  FlexColumnStart,
  FlexRowBetween,
} from '../../style/projectComponents';
import { colors } from '../../style/colors';
import CurrencyStorage from '../../storage/CurrencyStorage/CurrencyStorage';
import UserApi from '../../service/user-api/UserApi';

const Block = styled(FlexColumn)`
  border-radius: 19px;
  background: ${colors.white};
  align-items: start;
  width: 100%;
`;

const Total = styled.p`
  font-size: 28px;
`;

const BlockRow = styled(FlexRowBetween)`
  align-items: start;
  padding: 0 12px;
  width: calc(100% - 24px);
  padding-top: 10px;
  padding-bottom: 10px;
`;

const BlockRowWithBorder = styled(BlockRow)`
  border-top: 1px solid ${colors.lightGrayBg};
`;

interface AmountToPayBlockProps {
  price: number
  totalPriceInUsd: number
  charity: boolean
  discount: number;
  deliveryPrice: number
  deliveryPriceInUsd:number
}

export const AmountToPayBlock: React.FC<AmountToPayBlockProps> = ({
  price, charity, totalPriceInUsd, discount, deliveryPrice, deliveryPriceInUsd,
}) => {
  const { t } = useTranslation();
  const CurrencyStore = useContext(CurrencyStorage);
  const currency = CurrencyStore?.currency;

  const { data: rates } = useQuery(
    ['exchange_rates'],
    () => UserApi.getExchangeRates(),
  );

  const egpUsdRate = rates?.[0]?.rate || 0;
  const discountByCurrency = currency === 'EGP' && discount || discount * egpUsdRate;
  const deliveryPriceString = currency === 'EGP' && t('common.egp') || t('common.usd');
  const currencyPrice = currency === 'EGP' && price || totalPriceInUsd;

  const deliveryPriceByCurrency = charity ? 0 : (currency === 'EGP' && deliveryPrice || deliveryPriceInUsd);
  const totalPrice = currencyPrice + deliveryPriceByCurrency;
  const totalPriceWithDiscount = totalPrice - discountByCurrency;

  const subtotalAmount = totalPriceWithDiscount * 0.9;
  const vatAmount = totalPriceWithDiscount * 0.1;

  return (
    <Block>
      <BlockRow style={{ marginTop: 12 }}>
        <FlexColumnStart>
          <Total>
            {t('common.total')}
          </Total>
          <p>
            {t('common.inclusiveOfVat')}
          </p>
        </FlexColumnStart>
        <Total style={{ fontSize: 24 }}>
          {deliveryPriceString}
          {' '}
          {totalPrice?.toFixed(2)}
        </Total>
      </BlockRow>
      <BlockRowWithBorder>
        <p>
          {t('common.discount')}
        </p>
        <p>
          -{deliveryPriceString}
          {' '}
          {discountByCurrency?.toFixed(2)}
        </p>
      </BlockRowWithBorder>
      <BlockRowWithBorder>
        <p>
          {t('common.subtotal')}
        </p>
        <p>
          {deliveryPriceString}
          {' '}
          {subtotalAmount?.toFixed(2)}
        </p>
      </BlockRowWithBorder>
      <BlockRowWithBorder>
        <p>
          {t('common.vat')}
        </p>
        <p>
          {deliveryPriceString}
          {' '}
          {vatAmount?.toFixed(2)}
        </p>
      </BlockRowWithBorder>
    </Block>
  );
};

export default observer(AmountToPayBlock);

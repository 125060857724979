export const socialLinks = [
  {
    link: 'https://www.facebook.com/',
    image: '/images/social/fb.svg',
  },
  {
    link: 'https://instagram.com/',
    image: '/images/social/instagram.svg',
  },
  {
    link: 'https://tiktok.com/',
    image: '/images/social/tiktok.svg',
  },
  {
    link: 'https://youtube.com/',
    image: '/images/social/youtube.svg',
  },
];

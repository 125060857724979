import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../../style/colors';
import {
  FlexColumnStart, FlexRowBetween, StyledButton, FlexRow,
} from '../../../../style/projectComponents';

const Block = styled(FlexRowBetween)`
  cursor: pointer;
  width: 100%;
  height: 184px;
  border-radius: 30px;
  background: ${colors.blueDark};
  align-items: center;
  overflow: hidden;
  @media (max-width: 768px) {
    height: 108px;
  }
`;

const Title = styled.p`
  font-size: 42px;
  font-weight: 700;
  color: #fff;
  margin-bottom: 8px;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 12px;
  }
`;

const Question = styled.p`
  font-size: 200px;
  font-weight: 700;
  color: #fff;
  margin-left: 35px;
  margin-right: 27px;
  @media (max-width: 768px) {
    font-size: 110px;
  }
`;

const ActionButton = styled(StyledButton)`
  font-size: 12px;
  font-weight: 400;
  width: 200px;
  height: 42px;
  border-radius: 22px;
  @media (max-width: 768px) {
    width: 142px;
    height: 30px;
  }
`;

export const PromoBlockFirst: FC = () => {
  const { t } = useTranslation();

  return (
    <Block>
      <FlexColumnStart style={{ marginLeft: 40, marginRight: 40 }}>
        <Title>{t('common.haveQuestions')}</Title>
        <ActionButton variant="green" onClick={() => console.log('click')}>
          {t('common.whatsAppBot')}
        </ActionButton>
      </FlexColumnStart>
      <FlexRow>
        <img src="/images/mock/banner/banner-left-border.png" alt="" />
        <Question>
          ?
        </Question>
      </FlexRow>
    </Block>
  );
};

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FlexColumnStart } from '../../../../style/projectComponents';
import { colors } from '../../../../style/colors';

const Block = styled(FlexColumnStart)`
`;
const Title = styled.p`
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 4px;
  @media (max-width: 768px) {
    font-size: 10px;
    margin-bottom: 0;
  }
`;
const CardValue = styled.p`
  font-size: 30px;
  font-weight: 700;
  color: ${colors.blueDark};
  margin-bottom: 32px;
  @media (max-width: 768px) {
    margin-bottom: 8px;
    font-size: 13px;
  }
`;
const Description = styled.p`
  font-weight: 400;
  font-size: 13px;
  text-align: left;
  @media (max-width: 768px) {
    font-size: 8px;
  }
`;

interface TicketInfoProps {
  prize: string;
  cardValue: number;
  productName: string;
  ticketsAmount: number;
  cartAmount: number;
  isMini?: boolean;
  id?: number;
}

export const TicketInfo: React.FC<TicketInfoProps> = ({
  prize,
  cardValue,
  productName,
  ticketsAmount,
  cartAmount,
  isMini,
  id,
}) => {
  const even = !(id % 2) || id === 0;
  const { t } = useTranslation();

  return (
    <Block>
      <Title style={{
        fontSize: isMini && 14,
        color: isMini ? (even ? colors.white : colors.primary) : (even ? colors.black : colors.white),
      }}
      >
        {t('common.win')}
        {' '}
        {prize}
      </Title>
      <CardValue style={{
        fontSize: isMini && 14,
        color: isMini ? (even ? colors.accent : colors.black) : (even ? colors.blueDark : colors.accent),
        marginBottom: isMini && 0,
      }}
      >
        {cardValue.toFixed(2)}
        {t('common.egp')}
      </CardValue>
      {!isMini && (
        <Description style={{ color: !even && colors.white }}>
          {t('common.buy')}
          {' '}
          {productName}
          {' '}
          {t('common.and')}
          <span
            style={{ color: even ? colors.blueDark : colors.accent }}
          >
            {t('common.get')}
            {' '}
            {ticketsAmount * cartAmount}
            {' '}
            {t('common.tickets')}
          </span>
        </Description>
      )}
    </Block>
  );
};

import React, { useContext, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useMutation, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, FlexRow, ProfileTabTitle, StyledButton,
} from '../../../style/projectComponents';
import { TabHeader } from './TabHeader';
import OrderApi from '../../../service/order-api/OrderApi';
import { Order } from '../Purchases/Order';
import { colors } from '../../../style/colors';
import { PaginationComponent } from '../../global/PaginationComponent';
import { generatePagesArray } from '../../../utils/generatePagesArray';
import { queryClient } from '../../../config/query-client';
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore';

const Block = styled(FlexColumn)`
  align-items: start;
  width: 100%;
`;
const DonationBlock = styled(FlexColumn)`
  border-radius: 19px;
  background: ${colors.white};
  padding: 21px;
  align-items: start;
  max-width: 390px;
  margin-bottom: 16px;
  margin-top: 8px;
`;
const SendToCharityBlock = styled(FlexColumn)`
  width: 368px;
  gap: 8px;
  margin-top: 24px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const TotalItems = styled(FlexRow)`
  width: calc(100% - 42px);
  border-radius: 19px;
  background: #fff;
  font-size: 21px;
  font-weight: 400;
  height: 57px;
  padding: 0 21px;
  justify-content: space-between;
`;
const ItemsText = styled.p`
  font-size: 24px;
  font-weight: 600;
`;

interface DeliveryAddressTabProps {
  action: () => void;
}

export const PurchasesTab: React.FC<DeliveryAddressTabProps> = ({ action }) => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const itemsPerPage = 3;
  const { data: myOrders } = useQuery(
    ['my_orders_list', page * itemsPerPage],
    () => OrderApi.getUserOrders({
      skip: page * itemsPerPage,
      take: itemsPerPage,
    }),
  );
  const totalPages = useMemo(
    () => (myOrders?.orders?.totalCount ? Math.ceil(myOrders?.orders?.totalCount / itemsPerPage) : 1),
    [myOrders?.orders?.totalCount, itemsPerPage],
  );
  const ordersList = myOrders?.orders?.items;
  const bonusTickets = myOrders?.totalTickets || 0;
  const [charityIds, setCharityIds] = useState([]);
  const [productsAmount, setProductAmount] = useState(0);
  const [ticketsAmount, setTicketsAmount] = useState(0);
  const toggleCharityIds = (id, amount, tickets) => {
    setCharityIds((prevIds) => {
      if (prevIds.includes(id)) {
        setProductAmount((p) => p - amount);
        setTicketsAmount((p) => p - tickets);
        return prevIds.filter((n) => n !== id);
      }
      setProductAmount((p) => p + amount);
      setTicketsAmount((p) => p + tickets);
      return [...prevIds, id];
    });
  };

  const GlobalModalStorage = useContext(GlobalModalStore);
  const {
    mutate: donateToCharityMutate,
    isLoading: isDonateLoading,
  } = useMutation((data: { orderId: string }) => OrderApi.donateToCharity(data), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Charity: Something goes wrong');
    },
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['my_orders_list'] });
    },
  });

  const sendToCharity = () => {
    if (charityIds?.length > 0) {
      charityIds.map((c) => donateToCharityMutate({ orderId: c }));
    }
  };

  return (
    <Block>
      <TabHeader title="Purchases" action={action} />
      <ProfileTabTitle>
        <img src="/images/buttons/menu/purchases.svg" alt="" />
        <h2>{t('common.purchases')}</h2>
      </ProfileTabTitle>
      <DonationBlock>
        <p style={{ fontSize: 16, fontWeight: 500, marginBottom: 8 }}>
          {t('common.aboutToCharity')}
        </p>
        <p style={{ textAlign: 'start', fontSize: 14 }}>
          {t('cart.makeDonation')}
          <span style={{ color: colors.primary, fontWeight: 600, fontSize: 14 }}>
            {' '}
            {bonusTickets * 2}
            {' '}
            {t('cart.ticketsInsteadOf')}
            {bonusTickets}
          </span>
          )
        </p>
      </DonationBlock>
      {ordersList?.map((o, id) => (
        <Order
          orderId={o.id}
          id={page * itemsPerPage + id}
          donationSelected={charityIds.includes(o.id)}
          handleSelect={toggleCharityIds}
        />
      ))}
      <SendToCharityBlock>
        {productsAmount > 0 && (
          <TotalItems>
            <p>{t('common.total')}</p>
            <ItemsText>
              {productsAmount}
              {' '}
              {t('common.items')}
            </ItemsText>
          </TotalItems>
        )}
        {ticketsAmount > 0 && (
          <StyledButton
            variant="green"
            style={{ width: '100%', height: 60 }}
            onClick={sendToCharity}
            disabled={isDonateLoading}
          >
            <p>
              {t('common.sendYouWill')}
              <span style={{ fontWeight: 600 }}>
                {t('common.get')}
                {ticketsAmount}
                {' '}
                {t('common.tickets')}
              </span>
            </p>
          </StyledButton>
        )}
      </SendToCharityBlock>
      <PaginationComponent
        maxPages={totalPages}
        pageId={page}
        pagesNum={generatePagesArray(totalPages, page, 100)}
        setPageId={setPage}
      />
    </Block>
  );
};

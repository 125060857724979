import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  AppTextBlack, AppTextNormal, FlexColumn, StyledButton,
} from '../../../style/projectComponents';
import { colors } from '../../../style/colors';
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore';
import { IAddTicketToCartReq } from '../../../service/cart-api/models';
import CartApi from '../../../service/cart-api/CartApi';

const Block = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  max-height: 252px;
  width: calc(186px - 34px);
  flex: 0 0 auto; // Prevent shrinking
  padding: 28px 17px 20px;
  box-shadow: 4px 8px 24px 0 #0000000A;
  border-radius: 15px;
  background: #fff;
  img {
    width: 100%;
    height: 104px;
    object-fit: cover;
    margin-bottom: 16px;
    border-radius: 10px;
  }
  @media(max-width: 768px) {
    padding: 15px 14px;
    width: 155px;
  }
`;

interface CampaignCardProps {
  image: string;
  prize: string;
  id: string
  isMock?: boolean;
}

export const CampaignCard: FC<CampaignCardProps> = ({
  image, prize, id, isMock,
}) => {
  const { t } = useTranslation();
  const GlobalModalStorage = useContext(GlobalModalStore);
  const addTicketMutation = useMutation((data: IAddTicketToCartReq) => CartApi.addTicketToCart(data), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: (data) => {
      GlobalModalStorage.makeVisible(true, 'Successfully added to cart');
    },
  });
  const addTicketToCart = () => addTicketMutation.mutate({ eventId: id, amount: 1 });

  return (
    <Block>
      <img src={image} alt="" />
      <AppTextBlack>
        {t('cart.getChanceTo')}
        <span style={{ color: colors.primary }}>{t('common.win')}</span>
      </AppTextBlack>
      <AppTextNormal style={{ fontWeight: 500, fontSize: 15 }}>{prize}</AppTextNormal>
      <StyledButton variant="darkBlue" style={{ marginTop: 16 }} onClick={addTicketToCart} disabled={isMock}>
        <p>{t('cart.addToCart')}</p>
      </StyledButton>
    </Block>
  );
};

import React, { FC } from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../style/colors';
import {
  Circle,
  FlexColumnBetween,
  FlexColumnStart, FlexRow,
  OnlyDesktopCircle,
} from '../../../style/projectComponents';
import ProductApi from '../../../service/product-api/ProductApi';
import LotteryApi from '../../../service/lottery-api/LotteryApi';

const Block = styled(FlexRow)`
  width: calc(50% - 16px);
  height: 180px;
  border-radius: 15px;
  background: #fff;
  position: relative;
  overflow: hidden;
  justify-content: start;
  gap: 30px;
  @media (max-width: 920px) {
    padding-bottom: 0;
    width: calc(100% - 16px);
  }
`;
const PrizeDescription = styled.h1`
  font-weight: 700;
  font-size: 16px;
  color: ${colors.black};
  text-align: left;
  margin-bottom: 6px;
`;
const Description = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: ${colors.gray400};
`;

const DrawDate = styled.p`
  font-weight: 400;
  font-size: 10px;
  color: ${colors.gray400};
`;

const PrizeInfo = styled(FlexColumnBetween)`
  align-items: start;
  padding: 20px 0;
  height: calc(100% - 40px);
`;
const TicketImage = styled.img`
  width: 155px;
  height: 100%;
  background: ${colors.gray400};
`;

interface UserTicketProps {
  id: string;
  drawDate: string;
  prize: string;
  productId: string;
  eventId: string;
  isArchive?: boolean;
}

export const UserTicket: FC<UserTicketProps> = ({
  id,
  drawDate,
  prize,
  productId,
  eventId,
  isArchive,
}) => {
  const { t } = useTranslation();
  const formattedDate = format(new Date(drawDate), 'dd MMMM yyyy, hh:mm', { locale: enUS });
  const { data: product } = useQuery(
    ['product_data', productId],
    () => ProductApi.getProduct(productId),
  );
  const { data: event } = useQuery(
    ['event_data', eventId],
    () => LotteryApi.getLotteryEvent(eventId),
  );

  return (
    <Block>
      <Circle top={-22.5} left={-22.5} mini style={{ background: colors.mediumGray }} />
      <Circle top={-22.5} right={-22.5} mini style={{ background: colors.mediumGray }} />
      <Circle bottom={-22.5} left={-22.5} mini style={{ background: colors.mediumGray }} />
      <Circle bottom={-22.5} right={-22.5} mini style={{ background: colors.mediumGray }} />
      <OnlyDesktopCircle top={-22.5} left={127.5} mini style={{ background: colors.mediumGray }} />
      <OnlyDesktopCircle bottom={-22.5} left={127.5} mini style={{ background: colors.mediumGray }} />
      <TicketImage src={event?.imageDownloadLink} alt="" style={{ filter: isArchive && 'grayscale(1)' }} />
      <PrizeInfo>
        <FlexColumnStart>
          <PrizeDescription>{prize}</PrizeDescription>
          <Description>
            {t('common.product')}
            :
            {product?.name}
          </Description>
        </FlexColumnStart>
        <FlexColumnStart>
          <DrawDate>
            {t('adminUserDetail.drawDate')}
            :
            {formattedDate}
          </DrawDate>
          <p style={{
            fontSize: 10, fontWeight: 700, width: '100%', textAlign: 'left',
          }}
          >
            {t('common.no')}
            .:
            {id}
          </p>
        </FlexColumnStart>
      </PrizeInfo>
    </Block>
  );
};

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FlexColumn, ProfileTabTitle } from '../../../style/projectComponents';
import { TabHeader } from './TabHeader';
import { UserBalanceBlock } from './UserBalanceBlock';

const Block = styled(FlexColumn)`
  align-items: start;
  width: 100%;
`;

interface WalletTabProps {
  action: () => void;
  isNotAuth: boolean
}

export const WalletTab: React.FC<WalletTabProps> = ({ action, isNotAuth }) => {
  const { t } = useTranslation();

  return (
    <Block>
      <TabHeader title="Wallet" action={action} isNotAuth={isNotAuth} />
      <ProfileTabTitle>
        <img src="/images/buttons/menu/wallet.svg" alt="" />
        <h2>{t('common.wallet')}</h2>
      </ProfileTabTitle>
      <UserBalanceBlock />
    </Block>
  );
};

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FlexColumnStart, FlexRow, FlexRowBetween } from '../../../style/projectComponents';
import { DeliveryStatus, IOrder } from '../../../service/order-api/models';
import { colors } from '../../../style/colors';
import { capitalizeFirstLetter } from '../../../utils/capitalizeFirstLetter';

const Block = styled(FlexRowBetween)`
  align-items: center;
`;
const Title = styled.p`
  font-weight: 600;
  font-size: 18px;
`;
const Address = styled.p`
  font-weight: 400;
  font-size: 12px;
  color: ${colors.gray400};
  text-align: left;
`;
const Amount = styled.p`
  font-size: 12px;
  font-weight: 600;
  color: ${colors.blueDark};
`;
const Status = styled.p`
  font-size: 18px;
  font-weight: 600;
  color: ${colors.blueDark};
`;
const ProductImage = styled.img`
  border-radius: 12px;
  border: 1px solid ${colors.mediumGray};
  height: 133px;
  width: 133px;
  object-fit: contain;
  margin-right: 16px;
  @media (max-width: 768px) {
    height: 112px;
    width: 112px;
  }
`;

interface CheckmarkProps {
  isClicked?: boolean;
}

const CheckmarkWrapper = styled(FlexRow)<CheckmarkProps>`
  height: 24px;
  width: 24px;
  border-radius: 5px;
  border: 2px solid ${colors.blueDark};
  cursor: pointer;
  background: ${({ isClicked }) => (isClicked ? colors.blueDark : 'none')};
`;

const Checkmark = styled.img<CheckmarkProps>`
  display: ${({ isClicked }) => (isClicked ? 'block' : 'none')};
  width: 12px;
`;

interface PurchaseProps {
  id: number;
  order: IOrder;
  address: string;
  isClicked: boolean;
  handleClick: any;
  availableForCharity: boolean;
  orderId: string;
  totalProductsAmount: number;
  totalTicketsAmount: number;
  isDelivery?: boolean
  status?: DeliveryStatus
}

export const OrderItem: React.FC<PurchaseProps> = ({
  id,
  order,
  address,
  isClicked,
  handleClick,
  availableForCharity,
  orderId,
  totalProductsAmount,
  totalTicketsAmount,
  isDelivery,
  status,
}) => {
  const { t } = useTranslation();
  const statusString = status && (status === DeliveryStatus.confirmed || status === DeliveryStatus.inDelivery) && 'Waiting for delivery' || status;

  return (
    <Block>
      <FlexRow style={{ alignItems: 'center' }}>
        <ProductImage src={order?.imageDownloadLink} alt="" />
        <FlexColumnStart>
          {isDelivery && <Status>{capitalizeFirstLetter(statusString)}</Status>}
          <Title>{order?.name}</Title>
          <Address>
            {t('common.order')}
            #
            {id + 1}
          </Address>
          <Address>{address}</Address>
          <Amount>
            {order?.amount}
            {' '}
            {t('common.items')}
          </Amount>
        </FlexColumnStart>
      </FlexRow>
      {(availableForCharity && !isDelivery)
        ? (
          <CheckmarkWrapper onClick={() => handleClick(orderId, totalProductsAmount, totalTicketsAmount)} isClicked={isClicked}>
            <Checkmark isClicked={isClicked} src="/images/buttons/icons/checkmark.svg" />
          </CheckmarkWrapper>
        ) : <div />}

    </Block>
  );
};

import styled from 'styled-components';
import React, { FC, useEffect, useState } from 'react';
import YouTube, { YouTubeProps } from 'react-youtube';
import { FlexColumnStart, FlexRow } from '../../../style/projectComponents';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: fixed;
  width: 100vw;
  height: calc(100vh - 115px);
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 13000;
  @media(max-width: 480px) {
    height: 100vh;
  }
`;

const Content = styled(FlexColumnStart)`
  border-radius: 15px;
  background-color: black;
  padding: 24px;
  position: relative;
  width: 500px;
  height: 100vh;
  align-items: center;

  @media (max-width: 480px) {
    width: 100%;
    padding: 0;
    align-items: center;
    justify-content: space-between;
    border-radius: 0;
  }
`;

const InnerWrapper = styled(FlexColumnStart)`
  row-gap: 6px;
  width: 100%;

  h3 {
    margin: 0;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
  }

  img {
    width: 45px;
  }

  p {
    font-size: 21px;
    text-align: center;
    width: 100%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const CloseButton = styled(FlexRow)`
  width: 24px;
  height: 24px;
  margin-right: 12px;
  margin-top: 12px;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const VideoModal: FC<{ videoUrl: string, setIsOpen?: any }> = ({ videoUrl, setIsOpen }) => {
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const opts: YouTubeProps['opts'] = {
    height: windowSize.height - 180,
    width: windowSize.width >= 500 && 500 || windowSize.width,
    playerVars: {
      autoplay: 0,
      iv_load_policy: 3,
      rel: 0,
      showinfo: 0,
    },
  };

  return (
    <StyledForm
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          setIsOpen(false);
        }
      }}
    >
      <Content>
        <FlexColumnStart style={{ width: '100%', alignItems: 'end', marginBottom: 16 }}>
          <CloseButton onClick={() => setIsOpen(false)}>
            <img src="/images/close16.svg" alt="" />
          </CloseButton>
        </FlexColumnStart>

        <InnerWrapper>
          <YouTube videoId={videoUrl} opts={opts} />
        </InnerWrapper>
      </Content>
    </StyledForm>
  );
};

import styled from 'styled-components';
import React, {
  FC, useCallback, useContext, useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import {
  ErrorText, FlexColumn, FlexColumnStart, FlexRow, StyledButton,
} from '../../style/projectComponents';
import { colors } from '../../style/colors';
import ProductApi from '../../service/product-api/ProductApi';
import GlobalModalStore from '../../storage/GlobalModalStore/GlobalModalStore';
import { ValidationInputComponent } from '../global/ValidationInputComponent';
import { SetPhotoComponent } from '../global/SetPhotoComponent';
import { queryClient } from '../../config/query-client';
import { ICategory } from '../../service/product-api/models';
import { DropdownComponent } from '../global/DropdownComponent';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  justify-content: start;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 130;
  overflow: scroll;
`;

const Content = styled(FlexColumnStart)`
  background: ${colors.white};
  border-radius: var(--8, 0.5rem);
  padding: 2.37rem 3.5rem;
  position: relative;
  width: 450px;
  align-items: center;
  margin-top: 50px;

  @media (max-width: 480px) {
    padding: 2.5rem 1rem;
    width: 100%;
    align-items: center;
    border-radius: 0.75rem;
  }
`;

const InnerWrapper = styled(FlexColumn)`
  row-gap: 0.5rem;
  width: calc(100% - 100px);

  h3 {
    margin: 0;
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    width: 100%;
  }

  @media (max-width: 480px) {
    width: calc(100% - 40px);
  }
`;

const CloseButton = styled(StyledButton)`
  align-self: end;
  position: absolute;
  right: 1.6rem;
  top: 1.31rem;
`;
const SettingsInputWrapper = styled(FlexRow)`
  width: 100%;
  max-width: 400px;
  position: relative;
  align-items: start;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const newProductInputs = [
  {
    name: 'name',
    label: 'Name',
    type: 'text',
    rules: { required: true },
  },
  {
    name: 'details',
    label: 'Details',
    type: 'text',
    rules: { required: true },
  },
  {
    name: 'eventCode',
    label: 'Event code',
    type: 'text',
    rules: { required: true },
  },
  {
    name: 'ticketsAmount',
    label: 'Tickets amount',
    type: 'number',
    rules: { required: true },
  },
  {
    name: 'price',
    label: 'Price',
    type: 'number',
    rules: { required: true },
  },
];

export const NewProductModal: FC<{ closeModal?: any, categories: ICategory[] }> = ({ closeModal, categories }) => {
  const [cartImageBinary, setCartImageBinary] = useState(null);
  const [fileCartUrl, setFileCartUrl] = useState(null);

  const [imageBinary, setImageBinary] = useState(null);
  const [fileUrl, setFileUrl] = useState(null);
  const [isFilesGood, setIsFilesGood] = useState(true);

  const handleChangeImage = useCallback(async (event) => {
    const addedFiles = event.target.files;

    if (addedFiles && addedFiles.length > 0) {
      const file = addedFiles[0];
      const url = URL.createObjectURL(file);

      if (['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
        console.log('good file');
        setIsFilesGood(true);
        setImageBinary(file);
        setFileUrl(url);
      } else {
        console.log('bad file');
        setIsFilesGood(false);
      }
    }
  }, []);
  const handleChangeCartImage = useCallback(async (event) => {
    const addedFiles = event.target.files;

    if (addedFiles && addedFiles.length > 0) {
      const file = addedFiles[0];
      const url = URL.createObjectURL(file);

      if (['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
        console.log('good file');
        setIsFilesGood(true);
        setCartImageBinary(file);
        setFileCartUrl(url);
      } else {
        console.log('bad file');
        setIsFilesGood(false);
      }
    }
  }, []);

  const {
    setError,
    control,
    watch,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm();

  const { t } = useTranslation();
  const GlobalModalStorage = useContext(GlobalModalStore);

  const {
    mutate: addProductMutation,
    isLoading: isAddProductLoading,
  } = useMutation((data: FormData) => ProductApi.createProduct(data), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: (data) => {
      GlobalModalStorage.makeVisible(true, 'Product created');
      queryClient.invalidateQueries({ queryKey: ['all_products'] });
      closeModal();
    },
  });

  const [categoryId, setCategoryId] = useState(0);
  const [openCategoryMenu, setOpenCategoryMenu] = useState(false);

  const onSend = () => {
    const formData = new FormData();
    formData.append('name', getValues('name'));
    formData.append('details', getValues('details'));
    formData.append('eventCode', getValues('eventCode'));
    formData.append('ticketsAmount', getValues('ticketsAmount').toString());
    formData.append('price', getValues('price').toString());
    formData.append('cartImage', cartImageBinary);
    formData.append('image', imageBinary);
    formData.append('categoryId', categories?.length > 0 ? categories[categoryId]?.id : '');
    addProductMutation(formData);
  };

  return (
    <StyledForm
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          closeModal();
        }
      }}
    >
      <Content>
        <CloseButton
          onClick={closeModal}
          variant="text"
        >
          <img src="/images/ic_cancel.svg" alt="" />
        </CloseButton>

        <InnerWrapper>
          <h3 style={{ marginBottom: 20 }}>
            {t('adminAnalytics.newProduct')}
          </h3>
          <p>{t('common.image')}</p>
          <SetPhotoComponent
            fileUrl={fileUrl}
            handleChangeFile={handleChangeImage}
          />
          <p>{t('common.cartImage')}</p>
          <SetPhotoComponent
            fileUrl={fileCartUrl}
            handleChangeFile={handleChangeCartImage}
          />
          {newProductInputs.map((it, idx) => (
            <FlexColumn style={{ width: '100%' }}>
              <SettingsInputWrapper>
                <Controller
                  // @ts-ignore
                  name={it.name}
                  control={control}
                  rules={it.rules}
                  render={({ field: { ref, ...field } }) => (
                    <ValidationInputComponent
                      {...field}
                      name={it.name}
                      type="text"
                      isError={!!errors[it.name]}
                      label={t(it.label)}
                    />
                  )}
                />
              </SettingsInputWrapper>
              <ErrorText hasError={!!errors[it.name]}>
                {t(errors[it.name]?.message?.toString())}
              </ErrorText>
            </FlexColumn>
          ))}
          {categories?.length > 0 && (
            <DropdownComponent
              openMenu={openCategoryMenu}
              setOpenMenu={setOpenCategoryMenu}
              itemId={categoryId}
              setId={setCategoryId}
              hasSubTitle
              subtitle={t('common.category')}
              itemList={categories.map((c) => c.name)}
            />
          )}
          <StyledButton
            variant="darkBlue"
            noRadius
            disabled={!isValid || !imageBinary || isAddProductLoading || !isFilesGood}
            onClick={onSend}
            style={{ width: '100%', height: 60, fontSize: 15 }}
          >
            {t('common.add')}
          </StyledButton>
        </InnerWrapper>
      </Content>
    </StyledForm>
  );
};

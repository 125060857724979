import React, { useContext } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FlexColumn, FlexRowStart, StyledButton } from '../../style/projectComponents';
import { colors } from '../../style/colors';
import { CardPayMethod } from './CardPayMethod';
import { IPayCartReq } from '../../service/cart-api/models';
import CartApi from '../../service/cart-api/CartApi';
import GlobalModalStore from '../../storage/GlobalModalStore/GlobalModalStore';
import { CARTSTAGE } from '../../utils/constants';

const Block = styled(FlexColumn)`
    border-radius: 19px;
    background: ${colors.white};
    padding: 21px;
    width: calc(100% - 42px);
    align-items: start;
`;
const Title = styled.h5`
    margin-bottom: 36px;
`;

interface PaymentMethodsProps {
  setCartStage: any;
  charity: boolean;
  address: string;
  useWalletBalance: boolean;
  cityId: number
}

export const PaymentMethods: React.FC<PaymentMethodsProps> = ({
  setCartStage, charity, address, useWalletBalance, cityId,
}) => {
  const { t } = useTranslation();
  const GlobalModalStorage = useContext(GlobalModalStore);
  const payCartMutation = useMutation((data: IPayCartReq) => CartApi.payCart(data), {
    onError: () => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: () => {
      GlobalModalStorage.makeVisible(true, 'Successfully transaction');
      setCartStage(CARTSTAGE.FINAL);
    },
  });

  return (
    <Block>
      <Title>
        {t('common.paymentMethod')}
      </Title>
      <FlexRowStart style={{ width: '100%', marginBottom: 32 }}>
        <CardPayMethod selected />
      </FlexRowStart>
      <StyledButton
        variant="darkBlue"
        disabled={payCartMutation.isLoading}
        onClick={() => payCartMutation.mutate({
          useWallet: useWalletBalance,
          donateToCharity: charity,
          adress: !charity ? address : null,
          promoCode: '',
          cityId,
        })}
        style={{ width: '100%', height: 60 }}
        noRadius
      >
        {t('common.payNow')}
      </StyledButton>
    </Block>
  );
};

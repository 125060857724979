import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { intervalToDuration } from 'date-fns';
import {
  FlexColumn, FlexColumnStart, FlexRow, StyledButton,
} from '../../style/projectComponents';
import { EditProductModal } from '../AdminComponents/EditProductModal';
import { IAddToFavoriteReq, ICategory, IProduct } from '../../service/product-api/models';
import SelectQuantityModal from '../global/modal/SelectQuantityModal';
import { colors } from '../../style/colors';
import ProductApi from '../../service/product-api/ProductApi';
import { ICartItem } from '../../service/cart-api/models';
import CurrencyStorage from '../../storage/CurrencyStorage/CurrencyStorage';
import { AddToCartStoreButton } from '../Product/AddToCartStoreButton';
import { IEvent } from '../../service/lottery-api/models';
import { ProductEventInfo } from '../Product/ProductEventInfo';
import { CampaignProgressBar } from '../Home/sections/CampaignProgressBar';
import { ZERO_EVENT_CODE_ID } from '../../config/lockedEvents';

const Block = styled(FlexColumnStart)`
  position: relative;
  overflow: hidden;
  border-radius: 20px;
`;
const ImageWrapper = styled(FlexColumn)<{ isAdmin: boolean }>`
  width: ${({ isAdmin }) => (isAdmin ? '180px' : '277px')};
  height: ${({ isAdmin }) => (isAdmin ? '220px' : '335px')};
  background: ${colors.blueDark};
  border-radius: 20px;
  margin-bottom: 12px;
  cursor: pointer;
  justify-content: space-between;
  @media (max-width: 768px) {
    width: 158px;
    height: 228px;
  }
`;
const ImageBg = styled(FlexColumn)`
  background: #fff;
  height: 100%;
  width: 100%;
  border-radius: 20px;
  padding-bottom: 5px;
`;
const ProductImage = styled.img<{ isAdmin: boolean }>`
  width: ${({ isAdmin }) => (isAdmin ? '160px' : '240px')};
  height: ${({ isAdmin }) => (isAdmin ? '160px' : '240px')};
  max-height: 240px;
  border-radius: 12px;
  object-fit: contain;
  @media (max-width: 768px) {
    width: 140px;
    max-height: 140px;
  }
`;
const Title = styled.p`
  font-size: 24px;
  font-weight: 600;
  @media (max-width: 768px) {
    font-size: 16px;
  }
`;
const Price = styled.p`
  font-size: 21px;
  opacity: 0.5;
  font-weight: 500;
  @media (max-width: 768px) {
    font-size: 14px;
  }
`;

const ButtonsWrapper = styled(FlexRow)`
  width: 100%;
  margin-top: 16px;
  @media (max-width: 920px) {
    width: 100%;
  }
`;
const SoldOutImage = styled.img`
  width: 150px;
`;

const LikeButton = styled(FlexRow)`
  padding: 5px;
  border-radius: 100%;
  cursor: pointer;
  background: none;
  user-select: none;
  position: absolute;
  top: 75px;
  right: 12px;
  @media (max-width: 920px) {
    right: 5px;
  }
  @media (max-width: 768px) {
    top: 65px;
  }

  img {
    width: 22px;
    height: 22px;
    object-fit: contain;
    @media (max-width: 920px) {
      width: 15px;
      height: 15px;
    }
  }
`;

const BlockHeader = styled(FlexRow)`
  background: ${colors.blueDark};
  width: 100%;
  height: 32px;

  p {
    font-size: 9px;
    font-weight: 400;
    color: transparent;
    -webkit-text-stroke: 0.6px #fff;
    text-stroke: 0.6px #fff;
    -webkit-background-clip: text;
    background-clip: text;
    text-transform: uppercase;
  }
`;

interface ProductProps {
  product: IProduct;
  categories?: ICategory[];
  isAdmin?: boolean;
  cartItems: ICartItem[];
  eventsItems: IEvent[];
}

export const Product: React.FC<ProductProps> = ({
  product, categories, isAdmin, cartItems, eventsItems,
}) => {
  const {
    name, price, imageDownloadLink, id, priceInUsd,
  } = product;
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [editModal, setEditModal] = useState(false);
  const handleEditModal = () => setEditModal((s) => !s);
  const clickOnBlock = () => {
    if (isAdmin) {
      handleEditModal();
    } else {
      navigate(`/product?id=${id}`);
    }
  };

  const productNoEvent = product?.eventCode === ZERO_EVENT_CODE_ID;
  const isSoldOut = (product?.event?.ticketsPurchased >= product?.event?.ticketsLimit || !product?.eventId);
  const firstEventByProduct = eventsItems?.find((e) => e.products?.some((i) => i.id === product.id));
  const productPrize = !productNoEvent && firstEventByProduct?.prizeDetails || '';
  const drawDate = !productNoEvent && firstEventByProduct?.drawDate || '';
  const ticketsLimit = !productNoEvent && firstEventByProduct?.ticketsLimit;
  const ticketsPurchased = !productNoEvent && firstEventByProduct?.ticketsPurchased;

  const [openMenu, setOpenMenu] = useState(false);
  const handleMenu = () => setOpenMenu((s) => !s);

  const [inFav, setInFav] = useState(product?.inFavorite);
  const addToFavoriteQuery = useMutation((data: IAddToFavoriteReq) => ProductApi.addToFavorite(data), {
    onSuccess: () => {
      setInFav(true);
    },
  });

  const removeFromFavoriteQuery = useMutation((data: IAddToFavoriteReq) => ProductApi.removeFromFavorite(data), {
    onSuccess: () => {
      setInFav(false);
    },
  });

  const handleFavoriteProduct = () => {
    if (!inFav) {
      if (!addToFavoriteQuery.isLoading) {
        addToFavoriteQuery.mutate({ productId: id });
      }
    } else if (!removeFromFavoriteQuery.isLoading) {
      removeFromFavoriteQuery.mutate({ productId: id });
    }
  };

  const productAmountInCart = cartItems?.find((c) => c.productId === id)?.amount || 0;
  const CurrencyStore = useContext(CurrencyStorage);
  const currency = CurrencyStore?.currency || 'EGP';
  const priceByCurrency = currency === 'EGP' && price || priceInUsd;

  const startDate = new Date(Date.now());
  const endDate = new Date(drawDate);
  const duration = drawDate && intervalToDuration({ start: startDate, end: endDate });
  const { days } = duration;
  const hours = duration?.hours + duration?.days * 24; // Добавляем дни к часам
  const { minutes } = duration;
  const { seconds } = duration;
  const formattedDuration = days && days <= 1 && `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}` || days && `${days} days`;
  const closingText = startDate && endDate && startDate?.getTime() > endDate?.getTime() && 'CLOSED' || `Closing in ${formattedDuration}`;

  return (
    <>
      {editModal && <EditProductModal product={product} closeModal={handleEditModal} categories={categories} />}
      <SelectQuantityModal handleMenu={handleMenu} openMenu={openMenu} productId={product.id} />
      <Block>
        {!isAdmin && (
        <LikeButton
          style={{ background: inFav && colors.accent }}
          onClick={handleFavoriteProduct}
        >
          <img src="/images/buttons/icons/like.svg" alt="" />
        </LikeButton>
        )}
        <ImageWrapper
          onClick={clickOnBlock}
          isAdmin={isAdmin}
          style={{ justifyContent: productNoEvent ? 'end' : 'space-between' }}
        >
          {!productNoEvent && !isAdmin && (
            <BlockHeader>
              <p>{closingText}</p>
            </BlockHeader>
          )}
          <ImageBg>
            {!productNoEvent && ticketsLimit > 0
                        && (
                        <CampaignProgressBar
                          ticketsPurchased={ticketsPurchased}
                          ticketsLimit={ticketsLimit}
                          isProduct
                        />
                        )}
            <ProductImage src={imageDownloadLink} alt="" isAdmin={isAdmin} />
          </ImageBg>
        </ImageWrapper>
        <Title>{name}</Title>
        <Price>
          {currency}
          {priceByCurrency.toFixed(2)}
        </Price>
        {!isAdmin && productNoEvent
                && (
                <StyledButton
                  variant="darkBlue"
                  onClick={() => setOpenMenu(true)}
                  style={{
                    marginTop: 16, width: '100%', height: 47, borderRadius: 12,
                  }}
                >
                  <p>{t('cart.addToCart')}</p>
                </StyledButton>
                )}
        {!isAdmin && !isSoldOut && !productNoEvent
                && (
                <ButtonsWrapper>
                  <AddToCartStoreButton
                    action={() => setOpenMenu(true)}
                    isMini
                    price={price}
                    priceInUsd={priceInUsd}
                    currency={currency}
                    productId={product?.id}
                    amount={productAmountInCart}
                    prize={productPrize}
                  />
                </ButtonsWrapper>
                )}
        {!isAdmin && isSoldOut && !productNoEvent
                && (
                <ButtonsWrapper style={{ background: colors.accent, borderRadius: 12 }}>
                  <SoldOutImage src="/images/interface/sold-out.png" alt="" />
                </ButtonsWrapper>
                )}
        {!isSoldOut && !productNoEvent && !isAdmin && (
        <ButtonsWrapper>
          <ProductEventInfo
            drawDate={drawDate}
            productName={product?.name}
            ticketsAmount={product?.ticketsAmount}
          />
        </ButtonsWrapper>
        )}
      </Block>
    </>
  );
};

export default observer(Product);

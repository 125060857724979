import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FlexColumn, StoreSectionTitle } from '../../style/projectComponents';
import { CategorySelector } from './CategorySelector';
import { ICategory } from '../../service/product-api/models';

const Wrapper = styled(FlexColumn)`
  margin-top: 32px;
  align-items: start;
  padding: 0 20px;
  width: 100%;
`;

interface StoreProductsProps {
  categories: ICategory[];
  categoryId: number;
  setCategoryId: any;
}

export const StoreProducts: React.FC<StoreProductsProps> = ({ categories, categoryId, setCategoryId }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <StoreSectionTitle style={{ marginBottom: 16 }}>{t('common.exploreProducts')}</StoreSectionTitle>
      <CategorySelector
        categories={categories}
        categoryId={categoryId}
        setCategoryId={setCategoryId}
      />
    </Wrapper>
  );
};

import React, { useContext, useEffect, useState } from 'react';
import {
  BrowserRouter, Navigate, Route, Routes as RoutesDom,
} from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { AppStyle, FlexColumn } from './style/projectComponents';
import UserStorage from './storage/UserStorage/UserStorage';
import AppProvider from './AppProvider';
import { adminRoutes, privateRoutes, publicRoutes } from './utils/routes';
import { Footer } from './components/Home/Footer/Footer';
import { MobileMenu } from './components/global/MobileMenu';
import WelcomeBonusModal from './components/global/modal/WelcomeBonusModal';
import ProfileStorage from './storage/ProfileStorage/ProfileStorage';
import PromoModal from './components/global/modal/PromoModal';
import BonusModal from './components/global/modal/BonusModal';
import { CartProvider } from './components/global/CartContext';
import HeaderComponent from './components/global/HeaderComponent';
import { ProfilePage } from './pages/ProfilePage';
import LanguageStorage from './storage/LanguageStorage/LanguageStorage';
import { colors } from './style/colors';
import { LogoutModal } from './components/global/modal/LogoutModal';

const Content = styled(FlexColumn)`
  justify-content: start;
  width: 100vw;
  min-height: 800px;
  height: 100%;
  transition: all 0.25s linear;
  left: 0;
  top: 0;
  z-index: 1;
  overflow: hidden;
  @media (max-width: 480px) {
    &.shrink {
      align-items: end;
      width: 20vw;
      min-height: auto;
      //height: 80vh;
      height: 100%;
      margin-right: 80vw;
      margin-top: 5vh;
      margin-bottom: 15vw;
      border-radius: 0 50px 50px 0;
      box-shadow: 0px 4px 19.9px 0px #0035CD30;
    }
  }
`;

function App() {
  const UserStore = useContext(UserStorage);
  const isAuth = !!UserStore.accessToken;
  const ProfileStore = useContext(ProfileStorage);
  const isWelcome = ProfileStore.getIsWelcome();
  const isPromo = ProfileStore.getIsPromo();
  const isBonus = ProfileStore.getIsBonus();
  const profile = ProfileStore.getProfile();
  const isAdmin = UserStore.isAdmin();
  const [showWelcome, setShowWelcome] = useState(false);
  const [openPromo, setOpenPromo] = useState(false);
  const [openBonus, setOpenBonus] = useState(false);
  const handlePromo = () => setOpenPromo((s) => !s);
  const handleBonus = () => setOpenBonus((s) => !s);
  const LanguageStore = useContext(LanguageStorage);

  useEffect(() => {
    if (!isWelcome && profile?.balance === 10) {
      setShowWelcome(true);
      const timer = setTimeout(() => {
        setShowWelcome(false);
        ProfileStore.setWelcome(true);
      }, 10000);
      return () => clearTimeout(timer);
    }
  }, [isWelcome, profile]);
  //
  // useEffect(() => {
  //   if (!isPromo) {
  //     const timer = setTimeout(() => {
  //       setOpenPromo(true);
  //       ProfileStore.setPromo(true);
  //     }, 300000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [isPromo, profile]);

  // useEffect(() => {
  //   if (!isBonus) {
  //     const timer = setTimeout(() => {
  //       setOpenBonus(true);
  //       ProfileStore.setBonus(true);
  //     }, 900000);
  //     return () => clearTimeout(timer);
  //   }
  // }, [isBonus, profile]);
  const [shrink, setShrink] = useState(false);
  const [visibleLogout, setVisibleLogout] = useState(false);

  return (
    <AppProvider>
      <AppStyle style={{
        direction: LanguageStore.language === 'ar' ? 'rtl' : 'ltr',
        background: shrink && (isAuth ? colors.pageBg : colors.pageBg),
        minHeight: '100vh',
        height: `${shrink ? '100vh' : 'auto'}`,
      }}
      >
        <Content className={shrink ? 'shrink' : ''}>
          <BrowserRouter>
            {visibleLogout && <LogoutModal setIsOpen={setVisibleLogout} />}
            <HeaderComponent shrink={shrink} />
            <CartProvider>
              <PromoModal openMenu={openPromo} handleMenu={handlePromo} />
              <BonusModal openMenu={openBonus} handleMenu={handleBonus} />
              {showWelcome && <WelcomeBonusModal />}
              {UserStore.accessToken
                ? (
                  <RoutesDom>
                    {privateRoutes.map((it, idx) => (
                      <Route
                        path={it.path}
                        Component={it.component}
                      />
                    ))}
                    <Route
                      path="profile/*"
                      element={
                        <ProfilePage />
                                            }
                    />
                    <Route path="/*" element={<Navigate to="/" replace />} />
                    {isAdmin && (
                    <>
                      {adminRoutes.map((it, idx) => (
                        <Route
                          path={it.path}
                          Component={it.component}
                        />
                      ))}
                      <Route
                        path="/admin/*"
                        element={<Navigate to="/admin/dashboard" replace />}
                      />
                    </>
                    )}
                  </RoutesDom>
                )
                : (
                  <RoutesDom>
                    {publicRoutes.map((it, idx) => (
                      <Route
                        path={it.path}
                        Component={it.component}
                      />
                    ))}
                    <Route path="/*" element={<Navigate to="/login" replace />} />
                  </RoutesDom>
                )}
              <Footer />
              <MobileMenu setShrink={setShrink} shrink={shrink} setVisibleLogout={setVisibleLogout} />
            </CartProvider>
          </BrowserRouter>
        </Content>
      </AppStyle>
    </AppProvider>

  );
}

export default observer(App);

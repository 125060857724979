import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, FlexRow, FlexRowBetween, FlexRowStart,
} from '../../style/projectComponents';
import { colors } from '../../style/colors';
import LanguageStorage from '../../storage/LanguageStorage/LanguageStorage';

interface BlockProps {
  firstEl: boolean;
  lastEl: boolean;
}

const Block = styled(FlexRowBetween)<BlockProps>`
  border-radius: 11px;
  height: 76px;
  background: ${colors.white};
  width: 100%;
  align-items: center;

`;

export const Text = styled.p`
  font-size: 14px;
  font-weight: 900;
  color: ${colors.white};
`;

const CartItems = styled(FlexColumn)`
  width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 332px;
  max-width: 332px;
  @media (max-width: 480px) {
    min-width: auto;
  }
`;

const TextCart = styled(Text)`
  @media (max-width: 768px) {
    font-size: 10px;
  }
`;
const BlockWrapper = styled(FlexRowStart)`
  padding-left: 24px;
  padding-right: 24px;
  @media (max-width: 768px) {
    padding-left: 12px;
    padding-right: 12px;
  }
`;

interface CartTicketProps {
  style?: React.CSSProperties
}

export const InformationCards: React.FC<CartTicketProps> = ({
  style,
}) => {
  const { t } = useTranslation();
  const LanguageStore = useContext(LanguageStorage);

  return (
    <CartItems style={style}>
      <Block
        firstEl
        lastEl={false}
        style={{
          background: colors.primary,
          rotate: '1.35deg',
          zIndex: 1,
        }}
      >
        <BlockWrapper>
          <FlexRow style={{ gap: 16 }}>
            <TextCart>
              {t('footer.getInformation')}
            </TextCart>
          </FlexRow>
          <img
            src="/images/buttons/footer/top-info-icon.svg"
            alt=""
            style={{
              position: 'absolute',
              right: LanguageStore.language === 'ar' ? 'auto' : 13,
              left: LanguageStore.language === 'ar' ? 13 : 'auto',
            }}
          />
        </BlockWrapper>
      </Block>
      <Block
        firstEl
        lastEl={false}
        style={{
          background: colors.blackMedium,
          rotate: '-2.36deg',
        }}
      >
        <BlockWrapper>
          <FlexRow style={{ gap: 16 }}>
            <FlexRow style={{ gap: 16 }}>
              <TextCart>
                {t('footer.aboutOur')}
              </TextCart>
            </FlexRow>
            <img
              src="/images/buttons/footer/bottom-info-icon.svg"
              alt=""
              style={{
                position: 'absolute',
                right: LanguageStore.language === 'ar' ? 'auto' : 13,
                left: LanguageStore.language === 'ar' ? 13 : 'auto',
              }}
            />
          </FlexRow>
        </BlockWrapper>
      </Block>
    </CartItems>
  );
};

import {
  IPromoCreateReq, IPromoDeactivateReq, IPromoGetReq, IPromoGetRes, IPromoInterface,

} from './models';
import { apiClient } from '../../config/serverConstants';

class PromoService implements IPromoInterface {
  async create(data: IPromoCreateReq) {
    const response = await apiClient.post('/Promo/create', data);
    return response.data as string;
  }

  async deactivate(data: IPromoDeactivateReq) {
    const response = await apiClient.post('/Promo/deactivate', data);
    return response.data as boolean;
  }

  async get(data: IPromoGetReq) {
    const response = await apiClient.post('/Promo/get', data);
    return response.data as IPromoGetRes;
  }
}

export default new PromoService();

import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FlexColumnStart, FlexRowBetween, StyledButtonWhite } from '../../../style/projectComponents';
import { colors } from '../../../style/colors';

const Block = styled(FlexRowBetween)`
  cursor: pointer;
  width: 100%;
  height: 170px;
  border-radius: 35px;
  background: ${colors.blueDark};
  justify-content: center;
  align-items: center;
  color: #fff;
  overflow: hidden;
  img {
    height: 170px;
    @media(max-width: 768px) {
      width: 100%;
      height: 250px;
      object-fit: contain;
      object-position: right bottom;
    }
  }
  @media(max-width: 780px) {
    flex-direction: column;
    height: 385px;
  }
`;
const TitleText = styled.p`
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  margin-bottom: 8px;
  margin-left: 28px;

  @media(max-width: 768px) {
    margin-top: 32px;
  }
`;
const DescriptionText = styled.p`
  font-size: 15px;
  font-weight: 500;
  padding-bottom: 16px;
  text-align: left;
  margin-left: 28px;
`;

export const OurStoreButton: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const imageUrl = screenWidth <= 768 ? '/images/mock/tshirts-mobile.png' : '/images/mock/tshirts.png';

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <Block>
      <FlexColumnStart style={{ width: '100%', paddingRight: 28 }}>
        <TitleText>{t('common.ourStore')}</TitleText>
        <DescriptionText>{t('common.shopTheRightNow')}</DescriptionText>
        <StyledButtonWhite style={{ width: 140, height: 35, marginLeft: 28 }} onClick={() => navigate('/store')}>
          {t('common.goToStore')}
        </StyledButtonWhite>
      </FlexColumnStart>
      <img src={imageUrl} alt="" />
    </Block>
  );
};

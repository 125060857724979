import {
  ICurrentUserResponse,
  IGetCitiesListRes,
  IGetExchangeRatesRes,
  IGetUsersListReq,
  IGetUsersListRes,
  IUpdateUserBalanceReq,
  IUpdateUserReq,
  IUserInterface,
} from './models';
import { apiClient } from '../../config/serverConstants';

class UserService implements IUserInterface {
  async getUsersList(data: IGetUsersListReq) {
    const response = await apiClient.get('/Users/list', { params: data });
    return response.data as IGetUsersListRes;
  }

  async getCurrentUser() {
    const response = await apiClient.get('/Users/me');
    return response.data as ICurrentUserResponse;
  }

  async updateUserData(data: IUpdateUserReq) {
    const response = await apiClient.post('/Users/update', data);
    return response.data as any;
  }

  async updateUserBalance(data: IUpdateUserBalanceReq) {
    const response = await apiClient.post('/Users/update-balance', data);
    return response.data as any;
  }

  async getAddress() {
    const response = await apiClient.get('/Users/getUserAddress');
    return response.data as string;
  }

  async changeAddress(data: { address: string }) {
    const response = await apiClient.post('/Users/changeAddress', data);
    return response.data as any;
  }

  async getExchangeRates() {
    const response = await apiClient.get('/Wallet/getExchangeRates');
    return response.data as IGetExchangeRatesRes;
  }

  async getCitiesList(data: IGetUsersListReq) {
    const response = await apiClient.get('/Users/cities-list', { params: data });
    return response.data as IGetCitiesListRes;
  }
}

export default new UserService();

import React, { FC, useState } from 'react';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { ICurrentUserResponse } from '../../../service/user-api/models';
import { FlexColumnStart, StyledButton } from '../../../style/projectComponents';
import { UserBalanceModal } from '../UserBalanceModal';
import { EditUserInfoModal } from '../EditUserInfoModal';

interface UserRowProps {
  user: ICurrentUserResponse;
}

const UserRow: FC<UserRowProps> = ({ user }) => {
  const { t } = useTranslation();
  const [balanceModal, setBalanceModal] = useState(false);
  const [editUserModal, setEditUserModal] = useState(false);
  const handleBalanceModal = () => setBalanceModal((s) => !s);
  const handleEditUserModal = () => setEditUserModal((s) => !s);
  const navigate = useNavigate();

  return (
    <>
      {balanceModal && <UserBalanceModal userId={user.id} balance={user.balance} closeModal={handleBalanceModal} />}
      {editUserModal && <EditUserInfoModal user={user} closeModal={handleEditUserModal} />}
      <tr key={user.id}>
        <td>{user.id}</td>
        <td>{user.firstName}</td>
        <td>{user.lastName}</td>
        <td>{user.email}</td>
        <td>{user.phoneNumber}</td>
        <td>{user.birthDate && format(new Date(user.birthDate), 'dd MMMM yyyy', { locale: enUS })}</td>
        <td>{user.registeredOn && format(new Date(user.registeredOn), 'dd MMMM yyyy', { locale: enUS })}</td>
        <td>{user.balance}</td>
        <td>{user.roles[0]}</td>
        <td>
          <FlexColumnStart style={{ gap: 6 }}>
            {/* <StyledButton */}
            {/*  variant="darkBlue" */}
            {/*  style={{ padding: 4, width: 82 }} */}
            {/*  onClick={() => navigate(`/admin/user?id=${user.id}`)} */}
            {/* > */}
            {/*  {t('common.moreInfo')} */}
            {/* </StyledButton> */}
            <StyledButton
              variant="darkBlue"
              style={{ padding: 4, width: '100%' }}
              onClick={() => setEditUserModal(true)}
            >
              {t('adminUserDetail.editUser')}
            </StyledButton>
            <StyledButton
              variant="darkBlue"
              style={{ padding: 4, width: '100%' }}
              onClick={() => setBalanceModal(true)}
            >
              {t('assets.balance')}
            </StyledButton>
          </FlexColumnStart>
        </td>
      </tr>
    </>
  );
};

export default UserRow;

import React, { useContext } from 'react';
import styled from 'styled-components';
import {
  Route, Routes, useLocation, useNavigate, useSearchParams,
} from 'react-router-dom';
import { AppStyle, FlexRowBetween, Page } from '../style/projectComponents';
import { ProfileMenu } from '../components/Profile/Menu/ProfileMenu';
import { PersonalDetailsTab } from '../components/Profile/Tabs/PersonalDetailsTab';
import { WishlistTab } from '../components/Profile/Tabs/WishlistTab';
import { WalletTab } from '../components/Profile/Tabs/WalletTab';
import { OffersTab } from '../components/Profile/Tabs/OffersTab';
import { NotificationsTab } from '../components/Profile/Tabs/NotificationsTab';
import { ActiveTicketsTab } from '../components/Profile/Tabs/ActiveTicketsTab';
import { SettingsTab } from '../components/Profile/Tabs/SettingsTab';
import { ArchiveTicketsTab } from '../components/Profile/Tabs/ArchiveTicketsTab';
import { DeliveryAddressTab } from '../components/Profile/Tabs/DeliveryAddressTab';
import { PurchasesTab } from '../components/Profile/Tabs/PurchasesTab';
import { MyDeliveriesTab } from '../components/Profile/Tabs/MyDeliveriesTab';
import UserStorage from '../storage/UserStorage/UserStorage';

export enum PROFILE_PAGES {
  NONE = 'none',
  USERDATA = 'userdata',
  WISHLIST = 'wishlist',
  WALLET = 'wallet',
  OFFERS = 'offers',
  TICKETS = 'tickets',
  ARCHIVE_TICKETS = 'archive-tickets',
  PAYOPTIONS = 'payoptions',
  SETTINGS = 'settings',
  NOTIFICATIONS = 'notifications',
  DELIVERY_ADDRESS = 'delivery-address',
  MY_DELIVERIES = 'my-deliveries',
  PURCHASES = 'purchases',
}

const Content = styled(FlexRowBetween)`
  margin-top: 36px;
  gap: 24px;
  padding-bottom: 36px;
  @media (max-width: 720px) {
    flex-direction: column;
  }
`;

export const ProfilePage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const action = searchParams.get('action');
  const navigate = useNavigate();
  const location = useLocation();

  const setProfileBaseState = () => navigate('/profile');

  const UserStore = useContext(UserStorage);
  const isAuth = !!UserStore.accessToken;

  return (
    <AppStyle>
      <Page>
        <Content>
          {isAuth && <ProfileMenu hideMenu={location.pathname !== '/profile'} />}
          <Routes>
            <Route
              path={`${PROFILE_PAGES.USERDATA}`}
              element={<PersonalDetailsTab action={setProfileBaseState} />}
            />
            <Route
              path={`${PROFILE_PAGES.WISHLIST}`}
              element={<WishlistTab action={setProfileBaseState} />}
            />
            <Route
              path={`${PROFILE_PAGES.WALLET}`}
              element={<WalletTab action={setProfileBaseState} isNotAuth={!isAuth} />}
            />
            <Route path={`${PROFILE_PAGES.OFFERS}`} element={<OffersTab action={setProfileBaseState} />} />
            <Route
              path={`${PROFILE_PAGES.TICKETS}`}
              element={<ActiveTicketsTab action={setProfileBaseState} isNotAuth={!isAuth} />}
            />
            <Route
              path={`${PROFILE_PAGES.ARCHIVE_TICKETS}`}
              element={<ArchiveTicketsTab action={setProfileBaseState} />}
            />
            <Route
              path={`${PROFILE_PAGES.SETTINGS}`}
              element={<SettingsTab action={setProfileBaseState} />}
            />
            <Route
              path={`${PROFILE_PAGES.NOTIFICATIONS}`}
              element={<NotificationsTab action={setProfileBaseState} />}
            />
            <Route
              path={`${PROFILE_PAGES.PURCHASES}`}
              element={<PurchasesTab action={setProfileBaseState} />}
            />
            <Route
              path={`${PROFILE_PAGES.DELIVERY_ADDRESS}`}
              element={<DeliveryAddressTab action={setProfileBaseState} />}
            />
            <Route
              path={`${PROFILE_PAGES.MY_DELIVERIES}`}
              element={<MyDeliveriesTab action={setProfileBaseState} />}
            />
          </Routes>
        </Content>
      </Page>
    </AppStyle>

  );
};

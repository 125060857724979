import React, { useContext } from 'react';
import styled from 'styled-components';
import { format, intervalToDuration } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { Link } from 'react-router-dom';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FlexRow, FlexRowBetween, FlexColumnStart } from '../../style/projectComponents';
import { IEvent } from '../../service/lottery-api/models';
import { colors } from '../../style/colors';
import GlobalModalStore from '../../storage/GlobalModalStore/GlobalModalStore';
import { IAddProductToCartReq } from '../../service/cart-api/models';
import CartApi from '../../service/cart-api/CartApi';
import { queryClient } from '../../config/query-client';
import UserStorage from '../../storage/UserStorage/UserStorage';
import { useCart } from '../global/CartContext';

const Block = styled(FlexRowBetween)`
  background-color: #fff;
  flex: 0 0 auto;
  height: 134px;
  border-radius: 12px;
  justify-content: start;
  gap: 18px;
  width: 388px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Image = styled.img`
  height: 100%;
  width: 116px;
  border-radius: 24px;
`;
const Title = styled.p`
  font-size: 15px;
  font-weight: 700;
  margin-bottom: 6px;
`;
const Description = styled.p`
  font-size: 10px;
  font-weight: 400;
  color: ${colors.gray400};
  margin-bottom: 2px;
  margin-top: 16px;
`;
const Content = styled(FlexColumnStart)`
  height: 100%;
`;
const Info = styled.p`
  font-weight: 500;
  font-size: 11px;
  color: ${colors.blueDark};
  padding: 11px 17px;
  border-radius: 19px;
  border: 1px solid ${colors.blueDark};
`;
const CircleButton = styled.button`
  background: ${colors.accent};
  width: 37px;
  height: 37px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100%;
  border: none;
  margin-left: 12px;
  cursor: pointer;

  img {
    width: 15px;
    height: 15px;
  }
`;

const ProductLink = styled(Link)`
  font-size: 10px;
  font-weight: 400;
  color: ${colors.gray400};
  margin-bottom: 10px;
`;

interface DrawBlockProps {
  event: IEvent;
  openVideo: any;
}

export const DrawUpcomingBlock: React.FC<DrawBlockProps> = ({ event, openVideo }) => {
  const { t } = useTranslation();
  const image = event?.imageDownloadLink;
  const drawDate = format(new Date(event?.drawDate), 'd MMMM yyyy, hh:mm', { locale: enUS });

  const firstProduct = event?.products?.[0];

  const startDate = new Date(Date.now());
  const endDate = new Date(event?.drawDate);
  const duration = intervalToDuration({ start: startDate, end: endDate });
  const hide = duration?.days >= 3 || !firstProduct;
  const hours = duration.hours + duration.days * 24; // Добавляем дни к часам
  const { minutes } = duration;
  const { seconds } = duration;
  const formattedDuration = `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}`;

  const UserStore = useContext(UserStorage);
  const isAuth = !!UserStore.accessToken;
  const GlobalModalStorage = useContext(GlobalModalStore);
  const { setShowCart } = useCart();
  const addProductMutation = useMutation((data: IAddProductToCartReq) => CartApi.addProductToCartReq(data), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: (data) => {
      GlobalModalStorage.makeVisible(true, 'Successfully added to cart');
      setShowCart(true);
      queryClient.refetchQueries('get_cart_data');
    },
  });

  const addToCart = () => {
    addProductMutation.mutate({ productId: firstProduct?.id, amount: 1 });
  };

  return (
    <Block style={{ display: !hide && 'none' }}>
      <Image src={image} alt="" />
      <Content>
        <Title>
          {t('common.win')}
          {event?.prizeDetails}
        </Title>
        {firstProduct && (
          <ProductLink to={`/product/?id=${firstProduct?.id}`}>
            {t('common.product')}
            :
            <span
              style={{ textDecoration: 'underline' }}
            >
              {firstProduct?.name}
            </span>
          </ProductLink>
        )}
        <FlexRow>
          <Info>
            {t('common.closingIn')}
            {formattedDuration}
          </Info>
          {isAuth && firstProduct && (
            <CircleButton onClick={addToCart}>
              <img src="/images/buttons/shopping-cart.svg" alt="" />
            </CircleButton>
          )}
        </FlexRow>
        <Description>
          {t('adminUserDetail.drawDate')}
          {drawDate}
          {' '}

        </Description>
        <p style={{ fontWeight: 700, fontSize: 9 }}>
          {t('common.youHave')}
          {event?.ticketsPurchased}
          {' '}
          {t('common.tickets')}
          {' '}
        </p>
      </Content>
    </Block>
  );
};

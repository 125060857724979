import styled from 'styled-components';
import React, { FC, useState } from 'react';
import { format } from 'date-fns';
import { enGB } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { colors } from '../../style/colors';
import { IEvent } from '../../service/lottery-api/models';
import { FlexColumn, FlexRowBetween, StyledButton } from '../../style/projectComponents';
import { DrawModal } from './DrawModal';
import { SelectWinnerModal } from './SelectWinnerModal';
import { EditEventModal } from './EditEventModal';
import { ReCreateEventModal } from './ReCreateEventModal';

const Block = styled(FlexColumn)`
  border-radius: 27px;
  border: 1px solid ${colors.pageBg};
  width: 300px;
  padding: 20px 12px;
  background: #fff;
`;
const Text = styled.p`
  font-size: 12px;
  padding: 4px;
  text-align: left;
`;
const Image = styled.img`
  width: 100%;
  height: 240px;
  object-fit: cover;
  border-radius: 12px;
  margin-bottom: 24px;
`;

export const AdminEventItem: FC<{ event: IEvent }> = ({ event }) => {
  const { t } = useTranslation();
  const getDate = (date: string) => format(new Date(date), 'dd MMM yy, HH:mm', { locale: enGB });
  const productsList = event?.products?.map((p) => p.name).join(', ');

  const [drawModal, setDrawModal] = useState(false);
  const [winnerModal, setWinnerModal] = useState(false);

  const [editModal, setEditModal] = useState(false);
  const handleEditModal = () => setEditModal((m) => !m);

  const [reCreateModal, setReCreateModal] = useState(false);
  const handleReCreateModal = () => setReCreateModal((m) => !m);

  return (
    <>
      {editModal && <EditEventModal closeModal={handleEditModal} event={event} />}
      {drawModal && <DrawModal eventId={event?.id} closeModal={() => setDrawModal(false)} />}
      {reCreateModal && <ReCreateEventModal closeModal={handleReCreateModal} event={event} />}
      {winnerModal
            && (
              <SelectWinnerModal
                closeModal={() => setWinnerModal(false)}
                eventId={event?.id}
              />
            )}
      <Block>
        <Image src={event?.imageDownloadLink} alt="" />
        <FlexRowBetween style={{ borderBottom: `1px solid ${colors.pageBg}` }}>
          <Text>{event?.code}</Text>
          <Text>{event?.status?.toUpperCase()}</Text>
        </FlexRowBetween>
        <Text style={{ width: '100%' }}>
          {t('common.name')}
          :
          {event?.name}
        </Text>
        <Text style={{ width: '100%' }}>
          {t('adminUserDetail.prizeDetails')}
          :
          {event?.prizeDetails}
        </Text>
        <Text style={{ width: '100%' }}>
          {t('adminUserDetail.productDetails')}
          :
          {event?.productDetails}
        </Text>
        <Text style={{ width: '100%' }}>
          {t('common.id')}
          :
          {event?.id}
        </Text>
        <Text style={{ width: '100%' }}>
          {t('adminUserDetail.startDate')}
          :
          {event?.startDate && getDate(event?.startDate)}
        </Text>
        <Text style={{ width: '100%' }}>
          {t('adminUserDetail.drawDate')}
          :
          {event?.drawDate && getDate(event?.drawDate)}
        </Text>
        <Text style={{ width: '100%' }}>
          {t('adminUserDetail.announceDate')}
          :
          {event?.winnerAnnouncementDate && getDate(event?.winnerAnnouncementDate)}
        </Text>
        <Text style={{ width: '100%' }}>
          {t('adminUserDetail.translationURL')}
          :
          {event?.drawTranslationUrl || '...'}
        </Text>
        <Text style={{ width: '100%' }}>
          {t('adminUserDetail.limit')}
          :
          {event?.ticketsPurchased}
          /
          {event?.ticketsLimit}
        </Text>
        <Text style={{ width: '100%' }}>
          {t('adminUserDetail.products')}
          :
          {productsList}
        </Text>
        <StyledButton
          variant="darkBlue"
          style={{
            width: '100%',
            marginTop: 8,
            background: colors.primary,
            borderColor: colors.primary,
          }}
          noRadius
          onClick={() => setEditModal(true)}
        >
          {t('adminUserDetail.edit')}
          :
        </StyledButton>
        {!event?.drawTranslationUrl
                && (
                  <StyledButton
                    variant="darkBlue"
                    style={{ width: '100%', marginTop: 8 }}
                    noRadius
                    onClick={() => setDrawModal(true)}
                  >
                    {t('adminUserDetail.startDrawing')}
                  </StyledButton>
                )}
        {event?.drawTranslationUrl
                && (
                  <StyledButton
                    variant="darkBlue"
                    style={{ width: '100%', marginTop: 8 }}
                    noRadius
                    onClick={() => setWinnerModal(true)}
                  >
                    {t('adminUserDetail.selectWinners')}
                  </StyledButton>
                )}
        {event?.status === 'finished' && (
          <StyledButton
            variant="darkBlue"
            style={{ width: '100%', marginTop: 8 }}
            noRadius
            onClick={() => setReCreateModal(true)}
          >
            {t('common.update')}
          </StyledButton>
        )}
      </Block>
    </>
  );
};

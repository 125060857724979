import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, StyledButton,
  FlexRowBetween,
} from '../../style/projectComponents';
import { IProduct } from '../../service/product-api/models';
import UserStorage from '../../storage/UserStorage/UserStorage';
import { LoginModal } from '../global/modal/LoginModal';
import SelectQuantityModal from '../global/modal/SelectQuantityModal';
import { colors } from '../../style/colors';

const Block = styled(FlexColumn)`
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  border-radius: 28px;
  padding: 28px 38px;
  max-width: 450px;
  box-sizing: border-box;
  @media (max-width: 768px) {
    padding: 21px 28px;
    width: calc(100% - 56px);
    margin-right: 22px;
    margin-top: 22px;
    max-width: 100%;
  }

  h3 {
    text-align: left;
    font-size: 22px;
    font-weight: 700;
    margin: 4px 10px 0;
    width: 230px;
    @media (max-width: 768px) {
      width: 175px;
      font-size: 18px;
    }
  }
`;
const ProductImg = styled.img`
  display: none;
  @media (max-width: 768px) {
    display: block;
    width: 87px;
    height: 71px;
  }
`;

const AddToCartButton = styled(StyledButton)`
  margin-top: 28px;
  width: 100%;
  height: 52px;
  border-radius: 10px;
  
  p {
    font-size: 17px;
    font-weight: 600;
  }

  @media (max-width: 768px) {
    margin-top: 21px;
  }
`;
const PriceInfoBlock = styled(FlexColumn)`
  width: 135px;
  height: 85px;
  background: ${colors.blueDark};
  font-family: 'Maswen', sans-serif;
  border-radius: 11px;

  p {
    font-size: 20px;
    font-weight: 400;
  }

  @media (max-width: 768px) {
    display: none;
  }
`;

interface ProductBlockProps {
  product: IProduct;
}

export const ProductBlock: React.FC<ProductBlockProps> = ({ product }) => {
  const { t } = useTranslation();
  const UserStore = useContext(UserStorage);
  const isAuth = !!UserStore.accessToken;
  const [loginModal, setLoginModal] = useState(false);

  const [openMenu, setOpenMenu] = useState(false);
  const handleMenu = () => setOpenMenu((s) => !s);

  const addToCart = () => {
    if (isAuth) {
      setOpenMenu(true);
    } else {
      setLoginModal(true);
    }
  };

  return (
    <Block>
      <LoginModal isOpen={loginModal} setIsOpen={setLoginModal} />
      <SelectQuantityModal handleMenu={handleMenu} openMenu={openMenu} productId={product.id} />
      <FlexRowBetween style={{ width: '100%', alignItems: 'start' }}>
        <h3>
          {t('common.buy')}
          {product?.name}
          {' '}
          {t('common.andGetChanceToWin')}
          {product?.event?.prizeName}
        </h3>
        <PriceInfoBlock>
          <p>
            {t('common.egp')}
            {product?.price}
          </p>
        </PriceInfoBlock>
        <ProductImg src={product?.cartImageDownloadLink} alt="" />
      </FlexRowBetween>
      <AddToCartButton variant="green" onClick={addToCart}>
        <p>{t('cart.addToCart')}</p>
      </AddToCartButton>
    </Block>
  );
};

import React, { useContext } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { FlexRow } from '../../../../style/projectComponents';
import { colors } from '../../../../style/colors';
import CartApi from '../../../../service/cart-api/CartApi';
import { IAddProductToCartReq } from '../../../../service/cart-api/models';
import GlobalModalStore from '../../../../storage/GlobalModalStore/GlobalModalStore';
import { queryClient } from '../../../../config/query-client';
import { useCart } from '../../../global/CartContext';

const Block = styled(FlexRow)`
  gap: 2px;
  flex-direction: column-reverse;
`;

const ValueButton = styled.button`
  background: #fff;
  border: none;
  border-radius: 100%;
  height: 25px;
  width: 25px;
  font-weight: 600;
  font-size: 14px;
`;
const Amount = styled(ValueButton)`
  background: none;
  color: #fff;
`;
const MobilePlusButton = styled(ValueButton)`
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${colors.accent};
  color: #000;
`;

interface CartTicketProps {
  cartAmount: number;
  productId: string;
  even: boolean;
}

export const TicketButtonsMini: React.FC<CartTicketProps> = ({ cartAmount, productId, even }) => {
  const GlobalModalStorage = useContext(GlobalModalStore);

  const { setShowCart } = useCart();
  const addProductMutation = useMutation((data: IAddProductToCartReq) => CartApi.addProductToCartReq(data), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: (data) => {
      setShowCart(true);
      queryClient.refetchQueries('get_cart_data');
    },
  });

  const removeProductMutation = useMutation((data: IAddProductToCartReq) => CartApi.removeProduct(data), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: (data) => {
      queryClient.refetchQueries('get_cart_data');
    },
  });

  const handleChangeAmountMinus = () => {
    if (cartAmount > 0) {
      removeProductMutation.mutate({
        productId,
        amount: 1,
      });
    }
  };
  const handleChangeAmountPlus = () => {
    addProductMutation.mutate({
      productId,
      amount: 1,
    });
  };

  return (
    <Block>
      <ValueButton onClick={handleChangeAmountMinus} style={{ cursor: 'pointer', letterSpacing: '-0.8px' }}>
        —
      </ValueButton>
      <Amount>
        {cartAmount}
      </Amount>
      <MobilePlusButton
        onClick={handleChangeAmountPlus}
        style={{
          cursor: 'pointer',
          background: !even && colors.black,
          color: !even && colors.white,
        }}
      >
        +
      </MobilePlusButton>
    </Block>
  );
};

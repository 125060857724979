import styled from 'styled-components';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import {
  ErrorText,
  FlexColumn, FlexColumnStart, FlexRow, StyledButton,
} from '../../style/projectComponents';
import { colors } from '../../style/colors';
import GlobalModalStore from '../../storage/GlobalModalStore/GlobalModalStore';
import { ValidationInputComponent } from '../global/ValidationInputComponent';
import { ICurrentUserResponse, IUpdateUserReq } from '../../service/user-api/models';
import { settingsInputs } from '../Profile/Tabs/SettingsUtils';
import { DateInputComponent } from '../global/DateInputComponent';
import { DropdownComponent } from '../global/DropdownComponent';
import {
  Countries, Genders, ICountry, IGender,
} from '../../service/auth-api/models';
import UserApi from '../../service/user-api/UserApi';
import { queryClient } from '../../config/query-client';
import formatDateCustom, { formatDateCustomDDMMYY } from '../../utils/formatDateCustom';

const StyledForm = styled(FlexColumn)`
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  justify-content: start;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 130;
  overflow: scroll;
`;

const Content = styled(FlexColumnStart)`
  background: ${colors.white};
  border-radius: var(--8, 0.5rem);
  padding: 2.37rem 3.5rem;
  position: relative;
  width: 450px;
  align-items: center;
  margin-top: 150px;

  @media (max-width: 480px) {
    padding: 2.5rem 1rem;
    width: 100%;
    align-items: center;
    border-radius: 0.75rem;
  }
`;

const InnerWrapper = styled(FlexColumn)`
  row-gap: 0.5rem;
  width: calc(100% - 100px);

  h3 {
    margin: 0;
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    width: 100%;
  }

  @media (max-width: 480px) {
    width: calc(100% - 40px);
  }
`;

const CloseButton = styled(FlexRow)`
  align-self: end;
  position: absolute;
  right: 1.6rem;
  top: 1.31rem;
`;

const SettingsWrapper = styled(FlexColumn)`
  width: 100%;
  gap: 6px;
`;
const SettingsInputWrapper = styled(FlexRow)`
  width: 100%;
  position: relative;
  align-items: start;
  @media (max-width: 768px) {
    width: calc(100vw - 40px);
  }
`;

export const EditUserInfoModal: FC<{ closeModal?: any, user: ICurrentUserResponse }> = ({
  closeModal,
  user,
}) => {
  const { t } = useTranslation();

  const {
    control,
    setValue,
    register,
    watch,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      firstName: user.firstName,
      lastName: user.lastName,
      birthDate: formatDateCustomDDMMYY(user.birthDate),
    },
  });

  const [openGenderMenu, setOpenGenderMenu] = useState(false);
  const [genderId, setGenderId] = useState(Genders.findIndex((g) => g.toLowerCase() === user.gender) || 0);
  const [openCountryMenu, setOpenCountryMenu] = useState(false);
  const [countryId, setCountryId] = useState(Countries.findIndex((g) => g.toLowerCase() === user.country) || 0);
  const GlobalModalStorage = useContext(GlobalModalStore);

  const editProfileQuery = useMutation((data: IUpdateUserReq) => UserApi.updateUserData(data), {
    onSuccess: (data) => {
      GlobalModalStorage.makeVisible(true, t('common.success'));
      queryClient.refetchQueries('all_users_list');
    },
    onError: () => {
      GlobalModalStorage.makeVisible(false, t('common.error'));
    },
  });

  const onSubmit = (data: any) => {
    editProfileQuery.mutate({
      userId: user?.id,
      firstName: getValues('firstName'),
      lastName: getValues('lastName'),
      birthDate: formatDateCustom(getValues('birthDate')),
      gender: Genders[genderId] as IGender,
      country: Countries[countryId] as ICountry,
    });
  };

  return (
    <StyledForm
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          closeModal();
        }
      }}
    >
      <Content>
        <CloseButton onClick={closeModal}>
          <img src="/images/ic_cancel.svg" alt="" />
        </CloseButton>

        <InnerWrapper>
          <h3 style={{ marginBottom: 20 }}>
            {t('adminUserDetail.editUser')}
          </h3>
          <SettingsWrapper>
            {settingsInputs.map((it, idx) => (
              <FlexColumn style={{ width: '100%' }}>
                <SettingsInputWrapper>
                  <Controller
                                        // @ts-ignore
                    name={it.name}
                    control={control}
                    rules={it.rules}
                    render={({ field: { ref, ...field } }) => (
                      <ValidationInputComponent
                        {...field}
                        name={it.name}
                        type="text"
                        isError={!!errors[it.name]}
                        label={t(it.label)}
                      />
                    )}
                  />
                </SettingsInputWrapper>
                <ErrorText hasError={!!errors[it.name]}>
                  {t(errors[it.name]?.message?.toString())}
                </ErrorText>
              </FlexColumn>
            ))}
            <SettingsInputWrapper>
              <DateInputComponent
                name="birthDate"
                label={t('adminUserDetail.birthDate.placeholder')}
                register={register}
                setValue={setValue}
                errors={errors}
                watch={watch}
              />
            </SettingsInputWrapper>
            <SettingsInputWrapper>
              <DropdownComponent
                openMenu={openGenderMenu}
                setOpenMenu={setOpenGenderMenu}
                itemId={genderId}
                setId={setGenderId}
                hasSubTitle
                subtitle={t('auth.gender')}
                itemList={Genders}
              />
            </SettingsInputWrapper>
            <SettingsInputWrapper>
              <DropdownComponent
                openMenu={openCountryMenu}
                setOpenMenu={setOpenCountryMenu}
                itemId={countryId}
                setId={setCountryId}
                hasSubTitle
                subtitle={t('auth.country')}
                itemList={Countries}
              />
            </SettingsInputWrapper>
          </SettingsWrapper>
          <StyledButton
            variant="darkBlue"
            style={{ width: '100%', height: 56, marginTop: 16 }}
            disabled={!isValid || editProfileQuery.isLoading}
            noRadius
            onClick={onSubmit}
          >
            {t('common.edit')}
          </StyledButton>
        </InnerWrapper>
      </Content>
    </StyledForm>
  );
};

import React, { FC } from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../style/colors';
import { FlexRow } from '../../../style/projectComponents';

interface CheckmarkProps {
  isClicked?: boolean;
}

const CheckmarkWrapper = styled(FlexRow)<CheckmarkProps>`
  height: 24px;
  width: 24px;
  border-radius: 5px;
  border: 2px solid ${colors.blueDark};
  cursor: pointer;
  background: ${({ isClicked }) => (isClicked ? colors.blueDark : 'none')};
`;

const LinkText = styled(Link)`
  font-weight: 400;
  font-size: 13px;
  color: ${colors.black};
  border-bottom: 1px solid ${colors.black};
  text-decoration: none;
`;

const Checkmark = styled.img<CheckmarkProps>`
  display: ${({ isClicked }) => (isClicked ? 'block' : 'none')};
  width: 12px;
`;

export type UserAgreementComponentProps = {
  isClicked: boolean
  handleClick: () => void;
};

export const UserAgreementComponent: FC<UserAgreementComponentProps> = ({
  isClicked,
  handleClick,
}) => {
  const { t, i18n } = useTranslation();

  return (
    <FlexRow style={{
      width: '100%', gap: 10, justifyContent: 'start', marginTop: 16,
    }}
    >
      <CheckmarkWrapper onClick={handleClick} isClicked={isClicked}>
        <Checkmark isClicked={isClicked} src="/images/buttons/icons/checkmark.svg" />
      </CheckmarkWrapper>
      <p>
        {t('auth.userAgreement.1')}
        {' '}
        <LinkText to="/">{t('auth.userAgreement.2')}</LinkText>
        {' '}
        {t('auth.userAgreement.3')}
        {' '}
        <LinkText to="/">{t('auth.userAgreement.4')}</LinkText>
      </p>
    </FlexRow>
  );
};

export const colors = {
  accent: '#CDFF05',
  primary: '#F1750A',
  lightGrayBg: '#F0F0F0',
  lightGray: '#F7F7F7',
  lightGrayBorder: '#F4F4F4',
  darkGray: '#343330',
  gray050: '#CCCCCC',
  textLight: 'rgb(144,144,144)',
  gray400: '#696969',
  grayMedium: '#4F4F4F',
  mediumGray: '#EAEAEA',
  pageBg: '#F6F6F6',
  gray: '#AAB0B8',
  blueGray: '#E2E8F0',
  blueDark: '#103190',
  blueLight: '#96A4CE',
  purple: '#862CF8',
  secondary: '#858585',
  grayLight: '#A0A0A0',
  buttonHover: 'rgba(40,46,54,0.5)',
  greenSuccess: '#50c878',
  redFail: '#f1507b',
  redInput: '#f1507b',
  white: '#FFFFFF',
  black: '#222222',
  blackMedium: '#2F2F2F',
  redForErrors: '#F14F0A',
};

export const gradients = {
  primary: 'linear-gradient(180deg, #103190 -15.62%, #862CF8 87.5%)',
  primaryWebkit: '180deg, #103190 -15.62%, #862CF8 87.5%',
  green: 'linear-gradient(293.5deg, #286D91 9.12%, #11D52A 86.96%)',
  orange: 'linear-gradient(279.8deg, #FF0000 0%, #FF9900 93.23%)',
};

export const boxShadows = {
  authButton: '1px 2px 5px 0px #00000026',
};

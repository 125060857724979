import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, FlexRow,
  ProfileTabTitle,
} from '../../../style/projectComponents';
import { TabHeader } from './TabHeader';
import LotteryTicketsApi from '../../../service/lottery-tickets-api/LotteryTicketsApi';
import { UserTicket } from '../Ticket/UserTicket';
import { colors } from '../../../style/colors';
import { PROFILE_PAGES } from '../../../pages/ProfilePage';

const Block = styled(FlexColumn)`
  align-items: start;
  width: 100%;
`;
const Content = styled(FlexRow)`
  gap: 32px;
  width: 100%;
  flex-wrap: wrap;
  align-items: start;
  justify-content: start;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ButtonsWrapper = styled(FlexRow)`
  width: 430px;
  box-shadow: 1px 2px 5px 0px #00000026;
  height: 60px;
  border-radius: 16px;
  background: ${colors.mediumGray};
  margin-bottom: 32px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Button = styled.div`
  border-radius: 16px;
  width: 50%;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.black};
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ArchiveTicketsTabProps {
  action: () => void;
  isActive?: boolean;
  isMobileMenu?: boolean;
  setProfilePage?: any;
}

export const ArchiveTicketsTab: React.FC<ArchiveTicketsTabProps> = ({
  action, isActive, isMobileMenu, setProfilePage,
}) => {
  const { t } = useTranslation();
  const { data: tickets } = useQuery(
    'user_lottery_tickets',
    () => LotteryTicketsApi.getAllLotteryTickets(
      {
        skip: 0,
        take: 25,
      },
    ),
  );
  const ticketsItems = tickets?.items;
  const location = useLocation();
  const isThisPage = (location.pathname === '/profile/archive-tickets') || isActive;

  const navigate = useNavigate();
  const navigateToTickets = () => {
    if (isMobileMenu) {
      setProfilePage(PROFILE_PAGES.TICKETS);
    } else navigate('/profile/tickets');
  };
  const navigateToArchive = () => {
    if (isMobileMenu) {
      setProfilePage(PROFILE_PAGES.ARCHIVE_TICKETS);
    } else navigate('/profile/archive-tickets');
  };

  return (
    <Block>
      <FlexRow style={{ width: '100%' }}>
        <ButtonsWrapper>
          <Button onClick={navigateToTickets}>
            {t('menu.footer.activeTickets')}
          </Button>
          <Button onClick={navigateToArchive} style={{ background: isThisPage && colors.white }}>
            {t('common.ticketsHistory')}
          </Button>
        </ButtonsWrapper>
      </FlexRow>
      <TabHeader title="Tickets History" action={action} />
      <ProfileTabTitle>
        <h2>{t('common.ticketsHistory')}</h2>
      </ProfileTabTitle>
      <Content>
        {ticketsItems?.filter((t) => t.isArchive)?.map((ticket) => (
          <UserTicket
            id={ticket.code}
            key={`${ticket.code}+${ticket.productId}`}
            isArchive
            productId={ticket.productId}
            prize={ticket.prizeDetails}
            drawDate={ticket.drawDate}
            eventId={ticket.eventId}
          />
        ))}
      </Content>
    </Block>
  );
};

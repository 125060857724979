import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn,
  FlexRow,
  ProfileTabTitle, StyledButton,
} from '../../../style/projectComponents';
import { TabHeader } from './TabHeader';
import LotteryTicketsApi from '../../../service/lottery-tickets-api/LotteryTicketsApi';
import { UserTicket } from '../Ticket/UserTicket';
import { colors } from '../../../style/colors';
import { PROFILE_PAGES } from '../../../pages/ProfilePage';

const Block = styled(FlexColumn)`
  align-items: start;
  width: 100%;
`;
const Content = styled(FlexRow)`
  gap: 32px;
  width: 100%;
  flex-wrap: wrap;
  align-items: start;
  justify-content: start;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

const ButtonsWrapper = styled(FlexRow)`
  width: 430px;
  box-shadow: 1px 2px 5px 0px #00000026;
  height: 60px;
  border-radius: 16px;
  background: ${colors.mediumGray};
  margin-bottom: 32px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Button = styled.div`
  border-radius: 16px;
  width: 50%;
  font-size: 18px;
  font-weight: 500;
  color: ${colors.black};
  text-decoration: none;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`;

interface ActiveTicketsTabProps {
  action: () => void;
  isNotAuth: boolean
  isActive?: boolean;
  setProfilePage?: any;
  isMobileMenu?: boolean;
}

export const ActiveTicketsTab: React.FC<ActiveTicketsTabProps> = ({
  action,
  isNotAuth,
  isActive,
  setProfilePage,
  isMobileMenu,
}) => {
  const { t } = useTranslation();
  const { data: tickets } = useQuery(
    'user_lottery_tickets',
    () => LotteryTicketsApi.getAllLotteryTickets(
      {
        skip: 0,
        take: 30,
      },
    ),
    {
      enabled: !isNotAuth,
    },
  );
  const ticketsItems = tickets?.items;
  const isActiveTicketsExist = ticketsItems?.some((t) => !t.isArchive);
  const isThisPage = (location.pathname === '/profile/tickets') || isActive;

  const navigate = useNavigate();
  const navigateToTickets = () => {
    if (isMobileMenu) {
      setProfilePage(PROFILE_PAGES.TICKETS);
    } else navigate('/profile/tickets');
  };
  const navigateToArchive = () => {
    if (isMobileMenu) {
      setProfilePage(PROFILE_PAGES.ARCHIVE_TICKETS);
    } else navigate('/profile/archive-tickets');
  };

  return (
    <Block>
      <FlexRow style={{ width: '100%' }}>
        <ButtonsWrapper>
          <Button onClick={navigateToTickets} style={{ background: isThisPage && colors.white }}>
            {t('menu.footer.activeTickets')}
          </Button>
          <Button onClick={navigateToArchive}>
            {t('common.ticketsHistory')}
          </Button>
        </ButtonsWrapper>
      </FlexRow>
      <TabHeader title="Active Tickets" action={action} isNotAuth={isNotAuth} />
      <ProfileTabTitle>
        <h2>
          {t('menu.footer.activeTickets')}
        </h2>
      </ProfileTabTitle>
      <Content>
        {!isActiveTicketsExist && (
        <FlexColumn style={{ width: '100%' }}>
          <img src="/images/buttons/menu/ticket.svg" alt="" style={{ width: 45, marginBottom: 6 }} />
          <p>{t('common.ahStillPurchase')}</p>
          <p>{t('common.thereShowYouYet')}</p>
          <StyledButton
            variant="darkBlue"
            noRadius
            style={{
              height: 50,
              background: colors.primary,
              border: `1px solid ${colors.primary}`,
              marginTop: 24,
            }}
            onClick={() => (!isNotAuth ? navigate('/store') : navigate('/register'))}
          >
            {!isNotAuth ? t('cart.startShopping') : t('common.registerForShopping')}
          </StyledButton>
        </FlexColumn>
        )}
        {ticketsItems?.filter((t) => !t.isArchive)?.map((ticket) => (
          <UserTicket
            id={ticket.code}
            key={`${ticket.code}+${ticket.productId}`}
            productId={ticket.productId}
            prize={ticket.prizeDetails}
            drawDate={ticket.drawDate}
            eventId={ticket.eventId}
          />
        ))}
      </Content>
    </Block>
  );
};

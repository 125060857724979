import React, { useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, FlexRow, Modal, StyledButton,
} from '../../../style/projectComponents';
import ProductApi from '../../../service/product-api/ProductApi';
import { PROMO_PRODUCT_ID } from '../../../config/lockedEvents';
import SelectQuantityModal from './SelectQuantityModal';
import { colors } from '../../../style/colors';

const ContentWrapper = styled(FlexColumn)`
  background: linear-gradient(165.86deg, #103190 0.65%, #050E2A 145.83%);
  width: 502px;
  border-radius: 15px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const LangModalWrapper = styled(Modal)`
  background: rgba(0, 0, 0, 0.65);
  justify-content: center;
  flex-direction: column;
`;
const ModalHeader = styled(FlexRow)`
  width: 100%;
  justify-content: end;
  height: 60px;
`;

const Title = styled.h3`
  font-size: 30px;
  font-weight: 700;
  margin: 0;
  color: ${colors.accent};
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
const Description = styled.p`
  font-size: 18px;
  margin-bottom: 24px;
  color: ${colors.white};
  @media (max-width: 768px) {
    font-size: 15px;
    margin-bottom: 16px;
  }
`;
const BonusImg = styled.img`
  width: 368px;
  height: 213px;
  object-fit: contain;
  margin-bottom: 50px;
  @media (max-width: 768px) {
    width: 335px;
    height: 189px;
    margin-bottom: 46px;
  }
`;
const ButtonWrapper = styled(FlexRow)`
  width: 368px;
  margin-bottom: 36px;
  @media (max-width: 768px) {
    width: 335px;
  }
`;

const WinImage = styled.img`
  width: 192px;
  position: absolute;
  right: 70px;
  bottom: 120px;
`;

interface LanguageModalProps {
  openMenu: boolean,
  handleMenu: () => void
}

const BonusModal: React.FC<LanguageModalProps> = ({
  openMenu,
  handleMenu,
}) => {
  const { t } = useTranslation();
  const { data: product } = useQuery(
    ['product_data', PROMO_PRODUCT_ID],
    () => ProductApi.getProduct(PROMO_PRODUCT_ID),
  );

  const [openQuantityMenu, setOpenQuantityMenu] = useState(false);
  const handleQuantityMenu = () => setOpenQuantityMenu((s) => !s);

  if (openMenu) {
    return (
      <>
        <SelectQuantityModal handleMenu={handleQuantityMenu} openMenu={openQuantityMenu} productId={product?.id} />
        <LangModalWrapper onClick={(e) => {
          if (e.target === e.currentTarget) {
            handleMenu();
          }
        }}
        >
          <ContentWrapper>
            <ModalHeader>
              <img
                style={{ cursor: 'pointer' }}
                onClick={handleMenu}
                width={20}
                height={20}
                src="/images/close16.svg"
                alt="close"
              />
            </ModalHeader>
            <Title>
              {t('common.win')}
              {product?.event?.prizeName?.toUpperCase()}
            </Title>
            <Description>
              {t('common.buy')}
              {product?.name}
              {' '}
              {t('common.for')}
              {product?.price?.toFixed(2)}
              {t('common.egp')}
            </Description>
            <BonusImg src="/images/interface/bonus.png" alt="bonus-image" />
            <WinImage src="/images/interface/win.svg" alt="" />
            <ButtonWrapper>
              <StyledButton
                variant="green"
                style={{ width: '100%', height: 60, fontSize: 15 }}
                noRadius
                onClick={() => setOpenQuantityMenu(true)}
              >
                {t('cart.addToCart')}
              </StyledButton>
            </ButtonWrapper>
          </ContentWrapper>
        </LangModalWrapper>
      </>
    );
  }
};

export default BonusModal;

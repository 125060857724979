import styled from 'styled-components';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation, useQuery } from 'react-query';
import {
  FlexColumn,
  FlexColumnStart,
  FlexRow,
  FlexRowBetween,
  StyledButton,
} from '../../style/projectComponents';
import { colors } from '../../style/colors';
import GlobalModalStore from '../../storage/GlobalModalStore/GlobalModalStore';
import { queryClient } from '../../config/query-client';
import LotteryTicketsApi from '../../service/lottery-tickets-api/LotteryTicketsApi';

const StyledForm = styled(FlexColumn)`
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  justify-content: start;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 130;
  overflow: scroll;
`;

const Content = styled(FlexColumnStart)`
  background: ${colors.white};
  border-radius: var(--8, 0.5rem);
  padding: 2.37rem 3.5rem;
  position: relative;
  width: 450px;
  align-items: center;
  margin-top: 150px;
  margin-bottom: 100px;

  @media (max-width: 480px) {
    padding: 2.5rem 1rem;
    width: 100%;
    align-items: center;
    border-radius: 0.75rem;
  }
`;

const InnerWrapper = styled(FlexColumn)`
  row-gap: 0.5rem;
  width: calc(100% - 100px);

  h3 {
    margin: 0;
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    width: 100%;
  }

  @media (max-width: 480px) {
    width: calc(100% - 40px);
  }
`;

const CloseButton = styled(FlexRow)`
  align-self: end;
  position: absolute;
  right: 1.6rem;
  top: 1.31rem;
`;
const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 100%;
  @media (max-width: 480px) {
    width: 100%;
    max-width: 380px;
    min-width: 280px;
    label {
      left: 20px;
    }
  }
`;
const TicketRow = styled(FlexRowBetween)`
  border-bottom: 1px solid ${colors.textLight};
  align-items: center;
  height: 44px;
`;

export const SelectWinnerModal: FC<{ closeModal?: any, eventId: string }> = ({
  closeModal,
  eventId,
}) => {
  const { t } = useTranslation();
  const GlobalModalStorage = useContext(GlobalModalStore);
  const {
    mutate: selectWinnerMutate,
    isLoading: isSelectLoading,
  } = useMutation((data: { ticketNumber: string }) => LotteryTicketsApi.lotteryTicketsSelectWinner(data), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: (data) => {
      GlobalModalStorage.makeVisible(true, 'Winner selected');
      queryClient.invalidateQueries({ queryKey: ['lottery_tickets', eventId] });
    },
  });

  const { data: lotteryTickets } = useQuery(
    ['lottery_tickets', eventId],
    () => LotteryTicketsApi.getLotteryTicketsByEventId({
      eventId,
      skip: 0,
      take: 200,
    }),
  );
  console.log(lotteryTickets);
  const lotteryTicketsItems = lotteryTickets?.items;

  const onSend = (ticketNumber: string) => {
    selectWinnerMutate({ ticketNumber });
  };

  return (
    <StyledForm onClick={(e) => {
      if (e.target === e.currentTarget) {
        closeModal();
      }
    }}
    >
      <Content>
        <CloseButton onClick={closeModal}>
          <img src="/images/ic_cancel.svg" alt="" />
        </CloseButton>

        <InnerWrapper>
          <h3 style={{ marginBottom: 20 }}>
            {t('adminUserDetail.startDrawing')}
          </h3>

          <FlexColumn style={{ width: '100%' }}>
            {lotteryTicketsItems?.map((ticket) => (
              <TicketRow>
                <p>{ticket?.code}</p>
                {!ticket?.winner && (
                  <StyledButton
                    variant="darkBlue"
                    noRadius
                    disabled={isSelectLoading}
                    onClick={() => onSend(ticket?.code)}
                    style={{ fontSize: 12 }}
                  >
                    {t('common.win')}
                  </StyledButton>
                )}
                {ticket?.winner && <p style={{ fontWeight: 700, color: colors.greenSuccess }}>WINNER</p>}
              </TicketRow>
            ))}
          </FlexColumn>
        </InnerWrapper>
      </Content>
    </StyledForm>
  );
};

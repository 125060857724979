import React, { FC } from 'react';
import styled from 'styled-components';
import { Oval } from 'react-loader-spinner';
import { FlexColumn } from '../../../style/projectComponents';
import { colors } from '../../../style/colors';

const Block = styled(FlexColumn)`
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 186px;
  padding: 37.5px 17px;
  box-shadow: 4px 8px 24px 0 #0000000A;
  border-radius: 20px;
  background: #fff;
  animation: 1.5s infinite zoomIn;
  @keyframes zoomIn {
    0% {
      transform: scale(1);
    }
    100% {
      transform: scale(1.025);
    }
  }
`;
interface CampaignCardSkeletonProps {
  id: number
}
export const CampaignCardSkeleton: FC<CampaignCardSkeletonProps> = ({ id }) => (
  <Block style={{ animationDelay: `${id * 0.25}s` }}>
    <Oval
      height={30}
      width={30}
      color={colors.gray400}
      wrapperStyle={{}}
      wrapperClass=""
      visible
      ariaLabel="oval-loading"
      secondaryColor={colors.gray400}
      strokeWidth={2}
      strokeWidthSecondary={2}
    />
  </Block>
);

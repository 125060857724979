import styled from 'styled-components';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import { format } from 'date-fns';
import { ru } from 'date-fns/locale';
import { FlexColumn, StatsTitle, StatsWrapper } from '../../style/projectComponents';
import { StatisticsComponent } from '../../components/DashboardComponents/StatisticsComponent';
import { colors } from '../../style/colors';
import { AdminProducts } from '../../components/AdminComponents/AdminAnalytics/AdminProducts';
import { AdminEvents } from '../../components/AdminComponents/AdminAnalytics/AdminEvents';
import { AdminProductsCategories } from '../../components/AdminComponents/AdminAnalytics/AdminProductsCategories';
import UserApi from '../../service/user-api/UserApi';
import { AdminUsers } from '../../components/AdminComponents/AdminAnalytics/AdminUsers';
import AdminApi from '../../service/admin-api/AdminApi';
import { AdminCartStats } from '../../components/AdminComponents/AdminAnalytics/AdminCartStats';
import { AdminPromocodes } from '../../components/AdminComponents/AdminAnalytics/AdminPromocodes';
import { LineChartComponent } from '../../components/DashboardComponents/LineChartComponent';
import OrderApi from '../../service/order-api/OrderApi';
import { AdminOrdersStats } from '../../components/AdminComponents/AdminAnalytics/AdminOrdersStats';

const Page = styled(FlexColumn)`
  width: 100%;
  background-color: ${colors.pageBg};
`;

const StyledBg = styled(FlexColumn)`
  background-color: ${colors.pageBg};
  width: 100vw;
  padding: 24px;
  height: auto;
  box-sizing: border-box;
  min-height: 100vh;
  justify-content: flex-start;
  gap: 24px;
  margin-top: 80px;
`;

const ChartWrapper = styled(FlexColumn)`
  width: 100%;
  max-height: 380px;
  max-width: 1100px;
  justify-content: start;
  padding: 26px 16px;
  margin: 24px;
  border-radius: 8px;
  @media (max-width: 1100px) {
    max-width: calc(100vw - 16px);
  }
`;

export const AdminAnalytics = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const itemsPerPage = 10;
  const { data: users, isLoading: usersListLoading } = useQuery(
    ['all_users_list', page],
    () => UserApi.getUsersList(
      {
        skip: page * itemsPerPage,
        take: itemsPerPage,
      },
    ),
  );

  const [cartPage, setCartPage] = useState(0);
  const { data: cartStats, isLoading: cartStatsLoading } = useQuery(
    ['admin_cart_stats', cartPage],
    () => AdminApi.getCartsStatistic(
      {
        skip: cartPage * itemsPerPage,
        take: itemsPerPage,
      },
    ),
  );

  const { data: userStats, isLoading: userStatsLoading } = useQuery(
    ['admin_user_stats'],
    () => AdminApi.getUsersStatistic({}),
  );
  const userStatsItems = userStats?.newUsersByDate;
  const parsedUserRegistrations = userStatsItems && Object.keys(userStatsItems).map((key) => [key, userStatsItems[key]]);
  const parsedUserRegistrationsArray = parsedUserRegistrations?.map((i) => ({ date: i[0], registrationsCount: i[1] }));

  const [ordersPage, setOrdersPage] = useState(0);
  const { data: orders, isLoading: ordersStatsLoading } = useQuery(
    ['get_all_orders', ordersPage],
    () => OrderApi.getAllOrders({
      skip: ordersPage * itemsPerPage,
      take: itemsPerPage,
    }),
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const getDate = (date: string) => format(new Date(date), 'dd.MM.yy', { locale: ru });

  return (
    <Page>
      <StyledBg>
        <StatsWrapper>
          <StatisticsComponent
            title={users?.totalCount || 0}
            description={t('adminAnalytics.totalUsers')}
          />
        </StatsWrapper>
        <ChartWrapper>
          <StatsTitle>{t('adminAnalytics.userRegistrationsPerDay')}</StatsTitle>
          {parsedUserRegistrationsArray?.length > 0 && (
            <LineChartComponent
              titleKey={['date']}
              valueKey={['registrationsCount']}
              xAxisKey="date"
              yAxisKey="registrationsCount"
              data={parsedUserRegistrationsArray?.map((it) => ({
                ...it,
                date: getDate(it.date),
              }))}
            />
          )}
        </ChartWrapper>
        <AdminOrdersStats
          orders={orders?.orders?.items}
          isLoading={ordersStatsLoading}
          totalCount={orders?.orders?.totalCount}
          itemsPerPage={itemsPerPage}
          page={ordersPage}
          setPage={setOrdersPage}
        />
        <AdminCartStats
          cartStats={cartStats?.items}
          isLoading={cartStatsLoading}
          totalCount={cartStats?.totalCount}
          itemsPerPage={itemsPerPage}
          page={cartPage}
          setPage={setCartPage}
        />
        <AdminUsers
          users={users?.items}
          page={page}
          setPage={setPage}
          isLoading={usersListLoading}
          totalCount={users?.totalCount}
          itemsPerPage={itemsPerPage}
        />
        <AdminProductsCategories />
        <AdminPromocodes />
        <AdminProducts />
        <AdminEvents />
      </StyledBg>
    </Page>
  );
};

import React from 'react';
import styled from 'styled-components';
import { FlexColumn } from '../../../style/projectComponents';
import { UserAvatarAndName } from './UserAvatarAndName';
import { MenuComponent } from './MenuComponent';
import { EmailUs } from './EmailUs';
import { MenuRightComponent } from './MenuRightComponent';

interface MenuProps {
  hideMenu?: boolean
}

const Menu = styled(FlexColumn)<MenuProps>`
  width: 375px;
  gap: 16px;
  @media (max-width: 720px) {
    display: ${({ hideMenu }) => (hideMenu ? 'none' : 'flex')};
    width: 100%;
  }
`;

interface ProfileMenuProps {
  hideMenu?: boolean;
  isRightMenu?: boolean;
  setProfilePage?: any;
  setVisibleLogout?: any;
}

export const ProfileMenu: React.FC<ProfileMenuProps> = ({
  hideMenu, isRightMenu, setProfilePage, setVisibleLogout,
}) => (
  <Menu hideMenu={hideMenu}>
    <UserAvatarAndName />
    {isRightMenu ? <MenuRightComponent setProfilePage={setProfilePage} setVisibleLogout={setVisibleLogout} />
      : <MenuComponent />}
    <EmailUs />
  </Menu>
);

import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn,
  MiniText,
} from '../../../style/projectComponents';
import { colors } from '../../../style/colors';

const Block = styled(FlexColumn)`
  width: calc(260px - 30px);
  height: calc(320px - 30px);
  background: #fff;
  align-items: center;
  border-radius: 25px;
  padding: 15px;
  @media(max-width: 768px) {
    height: calc(306px - 30px);
  }
  img {
    height: 141px;
    width: 230px;
    border-radius: 35px;
    @media(max-width: 768px) {
      border-radius: 10px;
    }
  }
  h6 {
    @media(max-width: 768px) {
      font-size: 16px;
      width: 100%;
    }
  }
`;
const CongratsTitle = styled.h5`
  color: ${colors.primary};
  margin-top: 9px;
  margin-bottom: 8px;
  font-weight: 700;
  font-size: 21px;
  @media(max-width: 768px) {
    width: 100%;
  }
`;
const InfoWrapper = styled(FlexColumn)`
  margin-top: 32px;
  gap: 6px;
  width: 100%;
  p {
    width: 100%;
  }
  @media(max-width: 768px) {
    margin-top: 16px;
    gap: 8px;
  }
`;

interface WinnerCardProps {
  announceDate: string;
  ticketNumber: string;
  name: string;
  prize: string;
  image: string;
}

export const WinnerCard: FC<WinnerCardProps> = ({
  announceDate, ticketNumber, name, prize, image,
}) => {
  // const formattedDate = format(new Date(announceDate), 'hh:mm, dd MMMM yyyy', {locale: enUS});
  const { t } = useTranslation();
  const formattedDate = announceDate;

  return (
    <Block>
      <img src={image} alt="" />
      <CongratsTitle>{t('cart.congratulations')}</CongratsTitle>
      <h6>{name}</h6>
      {prize && (
        <h6 style={{ height: 40 }}>
          {t('cart.ofWinning')}
          {prize}
        </h6>
      )}
      <InfoWrapper>
        <MiniText>
          {t('cart.ticketNo')}
          :
          {ticketNumber}
        </MiniText>
        <MiniText>
          {t('cart.announcedOn')}
          :
          {formattedDate}
        </MiniText>
      </InfoWrapper>
    </Block>
  );
};

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FlexRowStart } from '../../../style/projectComponents';
import { colors } from '../../../style/colors';

const Block = styled(FlexRowStart)`
  width: 100%;
  background: #fff;
  border-radius: 19px;
  height: 63px;

  p {
    font-size: 15px;
    font-weight: 500;
    margin:0 16px;
  }
`;
const EmailButton = styled.a`
  font-size: 15px;
  font-weight: 600;
  margin: 0 6px;
  color: ${colors.primary};
`;

export const EmailUs: React.FC = () => {
  const { t } = useTranslation();

  return (
    <Block>
      <p>
        {t('auth.emailUs')}
        :
      </p>
      {/* eslint-disable-next-line i18next/no-literal-string */}
      <EmailButton href="mailto:holmegy@gmail.com">
        holmegy@gmail.com
      </EmailButton>
    </Block>
  );
};

import React, { ComponentPropsWithoutRef, useContext, useState } from 'react';
import styled from 'styled-components';
import { StyledInput } from '../../style/projectComponents';
import { colors } from '../../style/colors';
import LanguageStorage from '../../storage/LanguageStorage/LanguageStorage';

type ValidationInputComponentProps = {
  value?: string;
  isError?: any;
  label?: string;
  ref?: any;
};

export const ValidationLabel = styled.label<{ isFocused?: any, isError?: boolean }>`
  display: ${({ isFocused }) => (isFocused ? 'block' : 'none')};
  font-size: 10px;
  position: absolute;
  left: 20px;
  margin-top: 10px;
  transition: all 1s;
  color: ${({ isError }) => (isError ? colors.redForErrors : colors.black)};
  @media (max-width: 480px) {
    left: 20px;
  }
 
`;

export const CustomStyledInput = styled(StyledInput)<{ isError?: boolean }>`
  width: 100%;
  position: relative;
  ::placeholder {
      color: ${({ isError }) => (isError ? colors.redForErrors : colors.black)};
  }
`;
export const ValidationInputComponent: React.FC<ValidationInputComponentProps & ComponentPropsWithoutRef<'input'>> = (props) => {
  const {
    value, isError, label, ref, ...other
  } = props;
  const [isFocused, setIsFocused] = useState(false);
  const LanguageStore = useContext(LanguageStorage);

  return (
    <>
      <CustomStyledInput
        {...other}
        ref={ref}
        value={value}
        label={label}
        isError={isError}
        placeholder={isFocused ? '' : label || other.placeholder}
        onChange={other.onChange}
        isFailed={isError}
        onBlur={() => setIsFocused(false)}
        onFocus={() => setIsFocused(true)}
      />
      <ValidationLabel
        style={{
          left: LanguageStore.language === 'ar' ? 'auto' : 20,
          right: LanguageStore.language === 'ar' ? 20 : 'auto',
        }}
        isError={isError}
        isFocused={isFocused || value}
      >
        {label}
      </ValidationLabel>
    </>
  );
};

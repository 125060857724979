import React, { createContext, useState, useContext } from 'react';
import { FloatedCart } from './FloatedCart';

const CartContext = createContext(null);

export const useCart = () => useContext(CartContext);

export const CartProvider = ({ children }) => {
  const [showCart, setShowCart] = useState(true);
  const handleCart = () => setShowCart((s) => !s);

  return (
    <CartContext.Provider value={{ showCart, setShowCart }}>
      {children}
      {showCart && <FloatedCart handleCart={handleCart} /> }
    </CartContext.Provider>
  );
};

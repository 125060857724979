import React, { useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FlexColumn } from '../../style/projectComponents';
import { colors } from '../../style/colors';
import { CheckComponent } from '../auth/Register/CheckComponent';
import ProfileStorage from '../../storage/ProfileStorage/ProfileStorage';
import CurrencyStorage from '../../storage/CurrencyStorage/CurrencyStorage';

const Wrapper = styled(FlexColumn)`
  width: calc(100% - 20px);
`;

const Block = styled(FlexColumn)`
  border-radius: 19px;
  background: ${colors.white};
  width: 100%;
  align-items: start;
  padding: 26px 10px;
`;

interface AvailableBalanceProps {
  isActive: boolean;
  setIsActive: any;
}

export const AvailableBalance: React.FC<AvailableBalanceProps> = ({ isActive, setIsActive }) => {
  const { t } = useTranslation();
  const ProfileStore = useContext(ProfileStorage);
  const balance = ProfileStore.getBalance() || 0;
  const balanceInUsd = ProfileStore.getBalanceInUsd() || 0;

  const CurrencyStore = useContext(CurrencyStorage);
  const currency = CurrencyStore?.currency;
  const balanceByCurrency = currency === 'EGP' && balance || balanceInUsd;

  return (
    <Wrapper>
      <Block>
        <CheckComponent
          text={`${t('common.availableBalance')}: ${balanceByCurrency.toFixed(2)}${currency}`}
          isActive={isActive}
          handleClick={() => setIsActive((prevState) => !prevState)}
        />
      </Block>
    </Wrapper>
  );
};

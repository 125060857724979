import styled from 'styled-components';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  FlexColumn, FlexColumnStart, FlexRow, StyledButton,
} from '../../../style/projectComponents';
import { colors } from '../../../style/colors';

const StyledForm = styled.form<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  flex-direction: column;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 130;
`;

export const Title = styled.h2`
  font-weight: 900;
  font-size: 30px;
  color: ${colors.accent};
`;

const Content = styled(FlexColumnStart)`
  border-radius: 15px;
  box-sizing: border-box;
  padding: 78px 36px 47px;
  position: relative;
  width: 503px;
  height: 617px;
  align-items: center;
  margin: 0 20px !important;
  background:  linear-gradient(165.86deg, #103190 0.65%, #050E2A 145.83%);
  @media (max-width: 480px) {
    padding: 2.5rem 1rem;
    width: 100%;
    align-items: center;
    border-radius: 0.75rem;
  }
`;

const InnerWrapper = styled(FlexColumnStart)`
  row-gap: 10px;
  width: 100%;
  z-index: 10;

  h3 {
    margin: 0;
    font-size: 32px;
    font-weight: 700;
    text-align: center;
  }

  img {
    width: 45px;
  }

  p {
    font-size: 21px;
    text-align: center;
    width: 100%;
  }

  @media (max-width: 480px) {
    width: 100%;
  }
`;

const Button = styled(StyledButton)`
  width: 100%;
  height: 60px;
  font-size: 17px;
  font-weight: 700
`;

const CloseButton = styled(FlexRow)`
  align-self: end;
  position: absolute;
  cursor: pointer;
  right: 1.6rem;
  padding: 6px;
  top: 1.31rem;
`;

export const LoginModal: FC<{ setIsOpen?: any, isOpen?: boolean }> = ({ setIsOpen, isOpen }) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <StyledForm
      isOpen={isOpen}
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          setIsOpen(false);
        }
      }}
    >
      <Content>
        <CloseButton onClick={() => setIsOpen(false)}>
          <img src="/images/close_white.svg" alt="" />
        </CloseButton>
        <img
          src="/images/interface/logon_modal_icon.png"
          alt=""
          style={{
            position: 'absolute', width: 495, zIndex: 1, bottom: 73,
          }}
        />

        <InnerWrapper style={{ justifyContent: 'space-between', flexGrow: 1 }}>
          <InnerWrapper>
            <FlexRow style={{ width: '100%' }}>
              <Title>
                {t('auth.get10egp')}
              </Title>
              <img
                src="/images/interface/green-lightning.svg"
                alt=""
                style={{
                  height: 56,
                }}
              />
            </FlexRow>
            <p style={{ color: 'white', fontSize: 18 }}>
              {t('menu.loginConfirm')}
            </p>
          </InnerWrapper>
          <InnerWrapper style={{ rowGap: 15 }}>
            <Button
              onClick={() => navigate('/register')}
              variant="green"
              noRadius
              style={{}}
            >
              {t('auth.reg_title_now')}
            </Button>
            <Button
              variant="orange"
              noRadius
              onClick={() => navigate('/login')}
              style={{ width: '100%', height: 60 }}
            >
              {t('auth.login')}
            </Button>
          </InnerWrapper>
        </InnerWrapper>
      </Content>
    </StyledForm>
  );
};

import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { boxShadows, colors } from './colors';

export const FlexRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
`;
export const FlexRowEnd = styled.div`
  display: flex;
  align-items: center;
  justify-content: end;
  flex-direction: row;
  width: 100%;
`;
export const FlexRowStart = styled.div`
  display: flex;
  align-items: center;
  justify-content: start;
  flex-direction: row;
  width: 100%;
`;
export const FlexColumn = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
export const FlexColumnBetween = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
`;
export const FlexColumnStart = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
`;
export const FlexRowBetween = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
`;
export const FlexRowResponsive = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`;

export const FlexRowBetweenResponsive = styled.div`
  display: flex;
  align-items: start;
  justify-content: space-between;
  flex-direction: row;
  width: 100%;
  @media (max-width: 768px) {
    align-items: center;
    flex-direction: column;
  }
`;

interface StyledButtonProps {
  variant?: 'primary' | 'text' | 'darkBlue' | 'green' | 'orange';
  noRadius?: boolean;
  disabled?: boolean;
}

export const StyledButton = styled.button<StyledButtonProps>`
  border-radius: ${({ noRadius }) => (noRadius ? '12px' : '15px')};
  cursor: pointer;
  background: ${({
    variant,
    disabled,
  }) => variant === 'darkBlue' && (disabled ? colors.mediumGray : colors.blueDark)
          || variant === 'green' && colors.accent
          || variant === 'orange' && colors.primary
          || 'transparent'};
  padding: 8px 28px;
  border: ${({
    variant,
    disabled,
  }) => ((variant === 'text' || variant === 'green' || variant === 'orange') ? 'none' : (disabled ? 'none' : `2px solid ${colors.blueDark}`))};
  color: ${({ variant }) => variant === 'text' && colors.black
          || variant === 'green' && colors.black
          || variant === 'primary' && colors.blueDark || colors.white};
  box-sizing: border-box;
  font-size: 12px;
  font-weight: 600;

  &:hover {
    background: ${({
    variant,
    disabled,
  }) => variant === 'darkBlue' && (disabled ? colors.mediumGray : colors.accent)
            || variant === 'green' && colors.blueDark
            || variant === 'orange' && colors.redForErrors
            || 'transparent'};
    border: ${({
    variant,
    disabled,
  }) => ((variant === 'text' || variant === 'green' || variant === 'orange')
    ? 'none'
    : (disabled ? 'none' : `2px solid ${colors.accent}`))};

    color: ${({ variant }) => variant === 'text' && colors.black
            || variant === 'green' && colors.white
            || variant === 'orange' && colors.white
            || variant === 'primary' && colors.accent
            || colors.black
};
  }

  :disabled {
    background-color: ${colors.mediumGray};
    color: ${colors.darkGray};
  }
`;

interface StyledAuthButtonProps {
  selected?: boolean;
}

interface StyledButtonWhiteProps {
  noRadius?: boolean;
}

export const StyledButtonWhite = styled.button<StyledButtonWhiteProps>`
  border-radius: ${({ noRadius }) => (noRadius ? '12px' : '33px')};
  width: 160px;
  cursor: pointer;
  background: ${colors.white};
  padding: 8px 28px;
  border: none;
  color: ${colors.black}
  box-sizing: border-box;
  font-size: 15px;
  font-weight: 600;

  :disabled {
    background-color: ${colors.lightGray};
    color: ${colors.white};
  }
`;

export const StyledAuthButton = styled(Link)<StyledAuthButtonProps>`
  cursor: pointer;
  border-radius: 16px;
  box-shadow: ${({ selected }) => (selected ? boxShadows.authButton : 'none')};
  background: ${({ selected }) => (selected ? colors.white : 'none')};
  width: 100%;
  height: 60px;
  border: none;
  color: ${colors.black};
  font-size: 18px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
`;

export const StyledRegisterButton = styled(StyledButton)`
  margin-top: 14px;
  border-radius: 6px;
  height: 54px;
  background: black;
  font-weight: 600;
`;

StyledButton.defaultProps = {
  variant: 'primary',
};

StyledRegisterButton.defaultProps = {
  variant: 'primary',
};

export const StyledLink = styled(Link)`
  color: ${colors.primary};
  text-decoration: none;
  cursor: pointer;
  font-size: 0.9rem;
  line-height: 1.2rem;
  font-style: normal;

  :hover {
    opacity: 0.8;
    transition: all 0.3s ease;
  }
`;

export const LanguageSwitch = styled(FlexRow)<{ active?: boolean }>`
  cursor: pointer;
  font-size: 17px;
  font-weight: 700;
  text-transform: capitalize;
  color: ${({ active }) => (active ? colors.black : colors.white)};
  background: ${({ active }) => (active ? colors.accent : colors.blueDark)};
  width: 100%;
  height: 60px;
  border-radius: 12px;
  gap: 10px;

  p {
    font-size: 17px;
    font-weight: 700;
  }

  :hover {
    color: ${(props) => props.active === false && colors.secondary};
    transition: all 0.3s ease;
  }
`;

LanguageSwitch.defaultProps = {
  active: false,
};

export const StyledButtonTransparent = styled(StyledButton)<{ mini?: boolean }>`
  background: none;
  border-radius: 10px;
  padding: ${(props) => (props.mini ? '10px 14px' : '15px')};
  display: flex;
  cursor: pointer;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: 8px;
  font-size: ${(props) => (props.mini ? '13px' : '16px')};
  font-weight: ${(props) => (props.mini ? '400' : '500')};
  border: 1px solid ${colors.primary};
  color: ${colors.primary};

  p {
    font-size: ${(props) => (props.mini ? '13px' : '16px')};
    margin: 0;
    padding: 0;
  }

  img {
    width: 24px;
    height: 24px;
  }
`;

interface StyledInputProps {
  isFailed?: boolean;
  value?: string;
  label?: string;
}

export const StyledInput = styled.input<StyledInputProps>`
  outline: none;
  font-size: 16px;
  padding: 18px 20px;
  padding-top: ${({ value }) => value && '28px'};
  padding-bottom: ${({ value }) => value && '8px'};
  margin: 0;
  border-radius: 12px;
  border: 2px solid ${({ isFailed }) => (isFailed ? colors.redForErrors : colors.mediumGray)};

  &:focus {
    background: #fff;
    border: 2px solid ${({ isFailed }) => (isFailed ? colors.redForErrors : 'rgb(118, 118, 118)')};
    padding-top: ${({ label }) => label && '28px'};
    padding-bottom: ${({ label }) => label && '8px'};
  }
`;

export const Modal = styled(FlexColumn)`
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  justify-content: start;
  background: #fff;
  z-index: 999;
  overflow-y: scroll;

  * {
    box-sizing: border-box;
  }
`;

export const AppStyle = styled(FlexColumn)`
  width: 100vw;
  max-width: 100vw;
  overflow-x: hidden;
  min-height: 800px;
  height: 100%;
  background: ${colors.pageBg};
`;

export const Page = styled(FlexColumn)`
  width: 100%;
  max-width: 1198px;
  height: 100%;
  min-height: 100vh;
  justify-content: start;
  margin-top: 90px;
  @media (max-width: 1200px) {
    max-width: calc(100vw - 40px);
  }
`;
export const AuthContent = styled(FlexColumn)`
  width: 100vw;
  justify-content: center;
`;

export const AuthWrapper = styled(FlexRow)`
  width: 100%;
  align-items: start;
  margin-top: 50px;
  //@media (max-width: 1020px) {
  //  flex-direction: column;
  //}
`;

export const AuthModal = styled(FlexColumn)`
  padding: 66px 82px;
  border-radius: 16px;
  background: #fff;
  max-width: 600px;
  @media (max-width: 768px) {
    width: calc(100% - 40px);
    max-width: 100%;
    padding: 35px 20px;
  }
`;

export const AuthTitle = styled.h1`
  font-weight: 700;
  font-size: 26px;
  font-family: 'HeadingNowTrial', sans-serif;
`;

export const PassIcon = styled.img<{ lang: string }>`
  position: absolute;
  cursor: pointer;
  left: ${({ lang }) => (lang === 'ar' ? '14px' : 'auto')};
  right: ${({ lang }) => (lang === 'ar' ? 'auto' : '14px')};
  top: 50%;
  transform: translateY(-50%);
  width: 28px;
`;

type IErrorTextProps = {
  hasError?: boolean
};
export const ErrorText = styled.p<IErrorTextProps>`
  color: ${colors.primary};
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  width: 100%;
  min-height: 14px;
  display: ${(props) => (props.hasError ? 'block' : 'none')};
`;

ErrorText.defaultProps = {
  hasError: true,
};

export const InfoText = styled.p`
  color: ${colors.black};
  font-size: 15px;
  font-weight: 400;
  width: 100%;
  min-height: 14px;
  cursor: pointer;
  text-align: center;
`;

export const StatsWrapper = styled(FlexRowBetweenResponsive)`
  max-width: 1130px;
  margin-top: 20px;
  justify-content: start;

  @media (min-width: 780px) and (max-width: 1100px) {
    gap: 2rem;
    h1 {
      font-size: 1.125rem;
    }

    p {
      font-size: 0.75rem;
    }
  }

  @media (min-width: 480px) and (max-width: 780px) {
    gap: 2rem;
    font-size: 1.25rem;
  }
  @media (max-width: 768px) {
    flex-direction: row;
  }

  @media (max-width: 480px) {
    gap: 1.25rem;
    width: 100%;
    flex-direction: column;
  }
`;

export const AuthInputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 440px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const AuthInputColumn = styled(FlexColumnStart)`
  width: 100%;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const StyledBg = styled(FlexColumn)`
  background: ${colors.pageBg};
  box-sizing: border-box;
  width: 100vw;
  padding: 24px;
  height: auto;
  min-height: 100vh;
  justify-content: flex-start;
  padding-bottom: 100px;
  margin-top: 54px;
  @media (min-width: 780px) and (max-width: 1100px) {
    padding: 3.5rem;
  }
`;

interface TabsButtonProps {
  clicked: boolean;
  variant?: 'light';
}

export const TabsButton = styled.button<TabsButtonProps>`
  padding: 8px;
  background: ${({ clicked, variant }) => (clicked ? (variant === 'light' ? 'rgba(0,0,0,0.04)' : '#000')
    : (variant === 'light' ? 'none' : 'rgba(0,0,0,0.07)'))};
  color: ${({ clicked, variant }) => (clicked ? (variant === 'light' ? '#000' : '#fff') : `${colors.textLight}`)};
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-size: 14px;
`;

export const AppTitleNormal = styled.h3`
  margin: 0;
`;
export const AppTextMedium = styled.p`
  font-size: 24px;
  font-weight: 700;
`;
export const AppTextNormal = styled.p`
  font-size: 15px;
  font-weight: 400;
`;
export const AppTextBlack = styled.p`
  font-size: 15px;
  font-weight: 700;
`;

export const Text = styled.p`
  font-size: 16px;
  font-weight: 400;
`;

export const SectionTitle = styled.h4`
  margin: 0;
  font-size: 50px;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;
export const SectionTitleNormal = styled.h4`
  font-size: 15px;
  margin-bottom: 16px;
`;
export const MiniText = styled.p`
  font-size: 11px;
  color: ${colors.secondary};
  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

export const AuthText = styled.p`
  font-size: 15px;
  font-weight: 400;
  text-align: left;
`;

export const ModalHeader = styled(FlexRow)`
  width: 100%;
  justify-content: end;
  margin-bottom: 16px;
`;

export const StyledAdvise = styled.p`
  margin-top: 10px;
  color: ${colors.gray};
  font-size: 12px;
  font-weight: 400;
  text-align: left;
  width: 440px;
  min-height: 14px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

export const ProfileTabTitle = styled(FlexRowStart)`
  gap: 6px;
  margin-bottom: 20px;
  display: flex;
  @media (max-width: 720px) {
    display: none;
  }
`;

export const Circle = styled.div<{ top?: number, bottom?: number, left?: number, right?: number, mini?: boolean }>`
  border-radius: 100%;
  width: ${({ mini }) => (mini ? '45px' : '70px')};
  height: ${({ mini }) => (mini ? '45px' : '70px')};
  background: ${colors.pageBg};
  position: absolute;
  top: ${({ top }) => `${top}px`};
  bottom: ${({ bottom }) => `${bottom}px`};
  left: ${({ left }) => `${left}px`};
  right: ${({ right }) => `${right}px`};
  z-index: 1;
  @media (max-width: 920px) {
    display: none;
  }
`;
export const OnlyDesktopCircle = styled(Circle)`
  @media (max-width: 920px) {
    display: none;
  }
`;
export const OnlyMobileCircle = styled(Circle)`
  @media (min-width: 920px) {
    display: none;
  }
`;
export const StoreSectionTitle = styled.h2`
  font-size: 28px;
  @media (max-width: 768px) {
    font-size: 24px;
  }
`;

export const LikeButton = styled(FlexColumn)`
  width: 68px;
  height: 68px;
  border-radius: 100%;
  box-shadow: -4.33px 4.33px 25.99px 0px #00000033;
  cursor: pointer;
  background: #fff;
  user-select: none;
  @media (max-width: 920px) {
    width: 40px;
    height: 40px;
  }

  img {
    width: 22px;
    object-fit: contain;
    @media (max-width: 920px) {
      width: 15px;
    }
  }
`;

export const StatsTitle = styled.p`
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-top: 18px;
  font-family: 'Maswen', sans-serif;
`;

export const AdminTable = styled.table`
  font-size: 13px;
  text-align: left;
  border-collapse: collapse;
  width: 100%;

  thead {
    background: #fff;
    height: 62px;
    border-radius: 24px;
  }

  tr {
  }

  td {
    padding-right: 5px;
    padding-top: 16px;
    padding-bottom: 16px;
    padding-left: 5px;
    border-bottom: 1px solid #E0E5E6;
  }

  th {
    font-size: 14px;
    font-weight: 500;
    color: rgba(0, 0, 0, 0.5);
    padding-bottom: 8px;
    padding-top: 8px;
    text-align: center;
    min-width: 100px;
  }
`;

export const ModalWrapper = styled(FlexRow)`
  width: 500px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  padding: 50px;
  padding-bottom: 30px;
  padding-top: 70px;
  position: relative;
  background: linear-gradient(165.86deg, #103190 0.65%, #050E2A 145.83%);

  h3 {
    width: 100%;
    text-align: center;
    color: ${colors.accent};
    margin: 0;
    margin-bottom: 56px;
    @media (max-width: 768px) {
      font-size: 32px;
      font-weight: 900;
      padding-top: 24px;
      margin-top: 80px;
    }
  }

  @media (max-width: 768px) {
    width: 100%;
    height: 100%;
    padding: 12px;
    padding-top: 120px;
  }
`;

export const RightMenuWrapper = styled(FlexRow)`
  width: 500px;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  height: 100%;
  padding: 60px;
  position: relative;
  background: ${colors.pageBg};
  overflow: scroll;

  transform: translateX(100%); /* Элемент изначально находится за пределами видимости справа */
  animation: slideIn 0.35s forwards; /* Применяем анимацию */

  @keyframes slideIn {
    from {
      transform: translateX(100%); /* Начальная позиция (за пределами экрана) */
    }
    to {
      transform: translateX(0); /* Конечная позиция (на экране) */
    }
  }
  @media (max-width: 768px) {
    animation: none;
    width: 79vw;
    height: 100%;
    padding: 18px;
    padding-bottom: 80px;
    padding-top: 40px;
    transform: translateX(0%);
    animation: none;
    transition: all 0.25s linear;
  }
`;

export const CloseButton = styled(FlexRow)`
  position: absolute;
  top: 20px;
  right: 20px;
`;
export const RightMenuCloseButton = styled(FlexRow)`
  position: absolute;
  top: 20px;
  right: 20px;
`;

export const FooterTextLang = styled.p`
  font-size: 12px;
  color: ${colors.blueDark};
  font-weight: 500;
  cursor: pointer;
`;

export const FooterCard = styled.a`
  width: 100%;
  height: 70px;
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  padding: 0 14px 0 20px;
  border: 3px solid ${colors.blueLight};
  box-sizing: border-box;
  border-radius: 10px;
  cursor: pointer;
  text-decoration: none;
`;

export const FooterTextCard = styled.p`
  font-size: 16px;
  color: ${colors.black};
  font-weight: 500;
  text-align: start;
`;

export const ContainerSocial = styled(FlexRow)`
  width: 100%;
  justify-content: space-between;
  padding: 0 18.5px;
  box-sizing: border-box;
  @media (max-width: 1200px) {
    justify-content: center;
    gap: 11px;
  }
`;

export const InputAndErrorColumn = styled(FlexColumn)`
  width: auto;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

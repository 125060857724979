import React, { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { useSearchParams } from 'react-router-dom';
import styled from 'styled-components';
import ProductApi from '../service/product-api/ProductApi';
import { AppStyle, FlexRowBetween, Page } from '../style/projectComponents';
import HeaderComponent from '../components/global/HeaderComponent';
import { AddToCartComponent } from '../components/Product/AddToCartComponent';
import { ProductColumn } from '../components/Product/ProductColumn';
import { AddToCartMobile } from '../components/Product/AddToCartMobile';

const ProductPageWrapper = styled(FlexRowBetween)`
  margin-top: 32px;
  gap: 16px;
  @media(max-width: 768px){
    margin-top: 0;
  }
`;

const PageWrapper = styled(Page)`
  @media(max-width: 768px){
    max-width: 100%;
  }
`;

export const ProductPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [product, setProduct] = useState(null);
  const getProductQuery = useQuery(
    ['product_data', searchParams.get('id')],
    () => ProductApi.getProduct(searchParams.get('id')),
    {
      onSuccess: (data) => {
        setProduct(data);
      },
    },
  );
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const isSoldOut = (product?.event?.ticketsPurchased >= product?.event?.ticketsLimit || !product?.eventId);

  return (
    <AppStyle>
      <PageWrapper>
        <ProductPageWrapper>
          {product && (
            <ProductColumn
              image={product?.imageDownloadLink}
              name={product.name}
              isSoldOut={isSoldOut}
              details={product.details}
            />
          )}
          {product && <AddToCartComponent price={product.price} id={product.id} inFavorite={product.inFavorite} isSoldOut={isSoldOut} />}
          {product && <AddToCartMobile price={product.price} id={product.id} isSoldOut={isSoldOut} />}
        </ProductPageWrapper>
      </PageWrapper>
    </AppStyle>

  );
};

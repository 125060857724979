import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { FlexRow, FlexRowBetween } from '../../../../style/projectComponents';
import { colors } from '../../../../style/colors';
import { TicketInfo } from './TicketInfo';
import { TicketButtons } from './TicketButtons';
import ProductApi from '../../../../service/product-api/ProductApi';

interface BlockProps {
  firstEl: boolean;
  lastEl: boolean;
}

const Block = styled(FlexRowBetween)<BlockProps>`
  border-radius: 32px;
  background: ${colors.white};
  height: 190px;
  width: calc(100% - 60px);
  align-items: center;
  padding: 0 30px;
  @media (max-width: 768px) {
    height: 83px;
    padding: 0 10px;
    width: calc(100vw - 20px);
    border-radius: ${({ firstEl, lastEl }) => firstEl && '14px 14px 0 0' || lastEl && '0 0 14px 14px' || 0};
    border-bottom: ${({ lastEl }) => !lastEl && `1px solid ${colors.mediumGray}`};
  }
`;

const ProductImage = styled.img`
  border-radius: 12px;
  border: 1px solid ${colors.mediumGray};
  height: 133px;
  width: 133px;
  object-fit: contain;
  background: ${colors.white};
  @media (max-width: 768px) {
    height: 60px;
    width: 60px;
  }
`;

interface CartTicketProps {
  value: number;
  prize: string;
  id: number;
  length: number;
  productId: string;
  amount: number;
  productName: string;
  image: string;
}

export const CartTicket: React.FC<CartTicketProps> = ({
  value,
  prize,
  id,
  length,
  productId,
  productName,
  image,
  amount,
}) => {
  const { data: product } = useQuery(
    ['product_data', productId],
    () => ProductApi.getProduct(productId),
  );

  const firstEl = id === 0;
  const lastEl = id === (length - 1);
  const even = !(id % 2) || id === 0;

  return (
    <Block
      firstEl={firstEl}
      lastEl={lastEl}
      style={{
        background: even ? colors.white : colors.black,
        rotate: even ? '-1.2deg' : '1.2deg',
      }}
    >
      <FlexRow style={{ gap: 16 }}>
        <ProductImage src={image} alt="" />
        <TicketInfo
          cardValue={value}
          prize={prize}
          id={id}
          productName={productName}
          ticketsAmount={product?.ticketsAmount || 0}
          cartAmount={amount}
        />
      </FlexRow>
      <TicketButtons cartAmount={amount} productId={productId} even={even} />
    </Block>
  );
};

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, FlexRow, StyledButton,
} from '../../style/projectComponents';
import { colors } from '../../style/colors';

const Wrapper = styled(FlexColumn)`
  gap: 22px;
  width: 100%;
`;

const Block = styled(FlexRow)`
  border-radius: 19px;
  background: ${colors.white};
  width: 100%;
  height: 71px;
  box-sizing: border-box;
  padding: 15px;
  gap: 7px;
`;

const InputWrapper = styled(FlexRow)`
  width: 300px;
  position: relative;
  height: 100%;
  align-items: start;
  @media (max-width: 768px) {
    width: calc(100vw - 40px);
  }
`;

const ColoredButton = styled(StyledButton)`
  font-size: 12px;
  font-weight: 600;
  min-width: 109px;
  height: 100%;
`;

interface PromocodeProps {
  promo: string
  setPromo: any;
}

const StyledInput = styled.input`
  outline: none;
  font-size: 16px;
  padding: 18px 20px;
  margin: 0;
  border-radius: 12px;
  border: 2px solid ${colors.mediumGray};
  width: 100%;
`;

export const Promocode: React.FC<PromocodeProps> = ({ promo, setPromo }) => {
  const { t } = useTranslation();

  return (
    <Wrapper>
      <Block>
        <InputWrapper>
          <StyledInput
            value={promo}
            onChange={(e) => {
              setPromo(e.target.value);
            }}
            style={{
              height: '100%',
              boxSizing: 'border-box',
              fontSize: 11,
            }}
            placeholder={t('common.promocode')}
            type="text"
          />
        </InputWrapper>
        <ColoredButton
          variant="primary"
          style={{
            border: `1px solid ${colors.mediumGray}`,
            color: colors.blueDark,
          }}
          noRadius
        >
          {t('common.apply')}
        </ColoredButton>
      </Block>
    </Wrapper>
  );
};

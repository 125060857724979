import React from 'react';
import styled from 'styled-components';
import {
  FlexRow, FlexRowBetween,
} from '../../../style/projectComponents';
import { colors } from '../../../style/colors';

const Block = styled(FlexRowBetween)`
  align-items: center;
  padding: 15px 22px;
  border-top: 1px solid ${colors.mediumGray};
  width: calc(100% - 44px);
  @media(max-width: 768px) {
    width: 100%;
  }
`;
const Title = styled.p`
  font-size: 14px;
`;
const ToggleSwitch = styled(FlexRow)<{ isActive: boolean }>`
  width: 34px;
  border-radius: 100px;
  background: ${({ isActive }) => (isActive ? colors.primary : colors.mediumGray)};
  justify-content: ${({ isActive }) => (isActive ? 'end' : 'start')};
  padding: 3px;
  cursor: pointer;
  label {
    background: #fff;
    width: 19px;
    height: 19px;
    border-radius: 100%;
  }
  @media(max-width: 768px) {
    height: 24px;
  }
`;

interface NotificationItemProps {
  title: string,
  isActive: boolean
  id: number
  toggle: (id: number) => void;
}

export const NotificationItem: React.FC<NotificationItemProps> = ({
  title, isActive, id, toggle,
}) => (
  <Block>
    <Title>{title}</Title>
    <ToggleSwitch onClick={() => toggle(id)} isActive={isActive}>
      <label />
    </ToggleSwitch>
  </Block>
);

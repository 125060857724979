import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import {
  FlexColumn, FlexRowBetween, Modal, Page,
} from '../../../style/projectComponents';
import { ProductBlock } from '../../PromoPage/ProductBlock';
import ProductApi from '../../../service/product-api/ProductApi';
import { PROMO_PRODUCT_ID } from '../../../config/lockedEvents';

const ModalWrapper = styled(Modal)`
  background-image: url("/images/promo/bg.png");
  background-position: center;
  background-size: cover;
  justify-content: start;
`;

const ProductWrapper = styled(FlexColumn)`
  width: 100%;
  height: calc(100vh - 150px);
  align-items: end;
  justify-content: space-between;
  min-height: 500px;
  @media (max-width: 768px) {
    justify-content: end;
  }
`;
const WinImage = styled.img`
  width: 383px;
  height: 205px;
  @media (max-width: 768px) {
    width: 240px;
    height: 128px;
  }
`;
const WinImageWrapper = styled(FlexRowBetween)`
  align-items: start;
  @media (max-width: 768px) {
    padding-left: 20px;
  }
`;
const CloseIcon = styled(FlexColumn)`
  cursor: pointer;
  width: 32px;
  height: 32px;
  padding: 22px;
  border-radius: 100%;
  img {
    width: 32px;
    height: 32px;
  }
  @media (max-width: 768px) {
    position: absolute;
    top: 32px;
    right: 32px;
  }
`;
const PageWrapper = styled(Page)`
  background: none;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

const ProductImg = styled.img`
  display: block;
  width: 166px;
  height: 135px;
  margin-bottom: 20px;
  @media (max-width: 768px) {
    display: none;
  }
`;

interface LanguageModalProps {
  openMenu: boolean,
  handleMenu: () => void
}

const PromoModal: React.FC<LanguageModalProps> = ({
  openMenu,
  handleMenu,
}) => {
  const { data: product } = useQuery(
    ['product_data', PROMO_PRODUCT_ID],
    () => ProductApi.getProduct(PROMO_PRODUCT_ID),
  );

  if (openMenu) {
    return (
      <ModalWrapper>
        <PageWrapper>
          <ProductWrapper>
            <WinImageWrapper>
              <WinImage src="/images/interface/win.svg" alt="" />
              <CloseIcon onClick={handleMenu}>
                <img src="/images/close_white.svg" alt="" />
              </CloseIcon>
            </WinImageWrapper>
            <FlexColumn style={{ alignItems: 'end' }}>
              <ProductImg src={product?.cartImageDownloadLink} alt="" />
              {product && <ProductBlock product={product} />}
            </FlexColumn>
          </ProductWrapper>
        </PageWrapper>
      </ModalWrapper>
    );
  }
};

export default PromoModal;

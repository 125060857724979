import React, { FC, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, StyledButton, FlexRowBetween, FlexRow,
} from '../../../style/projectComponents';
import { DataEmptyComponent } from '../../global/DataEmptyComponent';
import ProductApi from '../../../service/product-api/ProductApi';
import { colors } from '../../../style/colors';
import { NewCategoryModal } from '../NewCategoryModal';

const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1100px;
  justify-content: start;
  background: rgba(255, 255, 255, 0.3);
  padding: 26px 16px;
  border-radius: 8px;
  max-height: 740px;
  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`;

const StatsTitle = styled.p`
  font-size: 24px;
  margin-bottom: 20px;
  width: 100%;
  text-align: left;
  padding-left: 24px;
  padding-top: 18px;
  font-family: 'Maswen', sans-serif;
`;

const CategoryItem = styled(FlexColumn)`
  padding: 12px;
  border-radius: 8px;
  border: 1px solid ${colors.blueDark};
  font-size: 14px;
`;
const CategoriesWrapper = styled(FlexRow)`
  gap: 12px;
  flex-wrap: wrap;
  align-items: start;
  justify-content: start;
  width: 100%;
  margin-top: 32px;
`;

export const AdminProductsCategories: FC = () => {
  const [createCategoryModal, setCreateCategoryModal] = useState(false);
  const handleCreateCategoryModal = () => setCreateCategoryModal((m) => !m);
  const { t } = useTranslation();

  const { data: categories, isLoading } = useQuery(
    'get_product_categories',
    () => ProductApi.getProductCategories(
      {
        skip: 0,
        take: 100,
      },
    ),
  );
  const categoriesItems = categories?.items;

  return (
    <>
      {createCategoryModal && <NewCategoryModal closeModal={handleCreateCategoryModal} />}
      <OperationsWrapper>
        <FlexRowBetween style={{ alignItems: 'center' }}>
          <StatsTitle>
            {' '}
            {t('adminAnalytics.allCategories')}
          </StatsTitle>
          <StyledButton
            variant="darkBlue"
            onClick={handleCreateCategoryModal}
            noRadius
          >
            {t('adminAnalytics.newCategory')}
          </StyledButton>
        </FlexRowBetween>
        {!isLoading && categoriesItems?.length > 0
          ? (
            <CategoriesWrapper>
              {categoriesItems?.map((c) => <CategoryItem>{c.name}</CategoryItem>)}
            </CategoriesWrapper>
          )
          : <DataEmptyComponent />}
      </OperationsWrapper>
    </>
  );
};

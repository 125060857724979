import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FlexColumn, ProfileTabTitle } from '../../../style/projectComponents';
import { TabHeader } from './TabHeader';
import { PromoBlockFirst } from '../../Home/sections/promo/PromoBlockFirst';
import { PromoBlockSecond } from '../../Home/sections/promo/PromoBlockSecond';

const Block = styled(FlexColumn)`
  align-items: start;
  width: 100%;
`;

const OffersWrapper = styled(FlexColumn)`
  gap: 10px;
  width: 100%;
`;

interface OffersTabProps {
  action: () => void;
}

export const OffersTab: React.FC<OffersTabProps> = ({ action }) => {
  const { t } = useTranslation();

  return (
    <Block>
      <TabHeader title="Offers" action={action} />
      <ProfileTabTitle>
        <img src="/images/buttons/menu/offer.svg" alt="" />
        <h2>{t('common.offers')}</h2>
      </ProfileTabTitle>
      <OffersWrapper>
        <PromoBlockSecond />
        <PromoBlockFirst />
      </OffersWrapper>
    </Block>
  );
};

import React from 'react';
import styled from 'styled-components';
import { format } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { useTranslation } from 'react-i18next';
import { FlexColumn, FlexRow } from '../../style/projectComponents';
import { colors } from '../../style/colors';

const Block = styled(FlexColumn)`
  width: 75px;
  height: 75px;
  border-radius: 100%;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  flex: 0 0 auto;
  cursor: pointer;
  overflow: hidden;
  justify-content: start;
`;

const LiveBlock = styled(FlexRow)`
  width: 75px;
  height: 20px;
  background: ${colors.accent};
  gap: 4px;
  p {
    font-size: 10px;
    font-weight: 700;
  }
`;
const BlinkAnimation = styled.p`
  font-size: 10px;
  font-weight: 700;
  animation: blink-animation 1s steps(1, end) infinite;
  @keyframes blink-animation {
    0% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    100% {
      opacity: 1;
    }
  }
`;
const GreyBg = styled(FlexColumn)`
  width: 100%;
  height: 100%;
  background: rgba(68, 65, 65, 0.75);
  p {
    color: #fff;
    font-size: 10px;
    font-weight: 500;
  }
`;

interface DrawPreviewProps {
  image: string;
  translationUrl: string;
  openVideo: any;
  drawDate: string
}

export const DrawPreview: React.FC<DrawPreviewProps> = ({
  image,
  translationUrl,
  openVideo,
  drawDate,
}) => {
  const { t } = useTranslation();
  const openVideoAction = () => {
    if (translationUrl) {
      openVideo(translationUrl);
    }
  };

  const formattedDrawDate = format(new Date(drawDate), 'dd MMMM yyyy', { locale: enUS });

  return (
    <Block style={{ backgroundImage: `url(${image})`, border: `2px solid ${translationUrl ? colors.accent : colors.blueDark}` }} onClick={openVideoAction}>
      {translationUrl && (
        <LiveBlock>
          <BlinkAnimation>🔴</BlinkAnimation>
          <p>{t('common.live')}</p>
        </LiveBlock>
      )}
      {!translationUrl && (
        <GreyBg>
          <p>{t('adminUserDetail.drawDate')}</p>
          <p>{formattedDrawDate}</p>
        </GreyBg>
      )}
    </Block>
  );
};

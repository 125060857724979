import React, { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  AppTextNormal,
  FlexColumn,
  FlexRowStart,
} from '../../../style/projectComponents';
import { colors } from '../../../style/colors';

const Block = styled(FlexColumn)<{ isProduct: boolean }>`
  width: ${({ isProduct }) => (isProduct ? '257px' : '328px')};
  margin-top: ${({ isProduct }) => isProduct && '14px'};
  align-items: start;
  @media (max-width: 480px) {
    width: ${({ isProduct }) => (isProduct ? '149px' : '300px')};
    margin-top: ${({ isProduct }) => isProduct && '8px'};
  }
`;
const ProgressBar = styled(FlexRowStart)`
  background: #E1E1E1;
  border-radius: 64px;
  height: 22px;
  margin-bottom: 16px;
`;
const Progress = styled(FlexRowStart)`
  background: ${colors.accent};
  border-radius: 64px;
  height: 22px;
  position: relative;
`;
const ProgressBigDot = styled.img`
  position: absolute;
  width: 14px;
  height: 14px;
`;
const ProgressSmallDot = styled.img`
  position: absolute;
  width: 3px;
  height: 3px;
`;

interface CampaignProgressBarProps {
  ticketsPurchased: number;
  ticketsLimit: number;
  isProduct?: boolean;
}

export const CampaignProgressBar: FC<CampaignProgressBarProps> = ({ ticketsPurchased, ticketsLimit, isProduct }) => {
  const { t } = useTranslation();
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
      });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const formattedTicketsPurchased = ticketsPurchased > ticketsLimit ? ticketsLimit : ticketsPurchased;
  const progressBarWidth = isProduct && 257 || (windowSize?.width < 480 ? 300 : 328);
  const progress = 1 * progressBarWidth;
  const dotsLength = Math.floor((progress - 40) / 11);
  const arr = new Array(dotsLength > 0 && dotsLength || 1).fill(0);

  return (
    <Block isProduct={isProduct}>
      <ProgressBar style={{ marginBottom: isProduct && 7 }}>
        <Progress style={{ width: `${progress}px`, minWidth: 54 }}>
          <ProgressBigDot style={{ left: 3 }} src="/images/interface/progress-bar/big-dot.svg" alt="" />
          <ProgressBigDot style={{ right: 3 }} src="/images/interface/progress-bar/big-dot.svg" alt="" />
          {arr?.length > 0 && arr?.map((i, id) => (
            <ProgressSmallDot
              style={{ left: 26 + id * 11, display: progress < 40 + id * 11 && id !== 0 && 'none' }}
              src="/images/interface/progress-bar/small-dot.svg"
              alt=""
            />
          ))}
        </Progress>
      </ProgressBar>
      <AppTextNormal style={{ fontSize: isProduct && 9 }}>
        <span
          style={{ fontWeight: 900, color: colors.primary }}
        >
          {formattedTicketsPurchased}
        </span>
        {' '}
        {t('common.soldOutOf')}
        {' '}
        {ticketsLimit}
      </AppTextNormal>
    </Block>
  );
};

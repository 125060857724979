export type IAuthSigninRequest = {
  email: string,
  password: string
};

export type ITokens = {
  accessToken: string,
  refreshToken: string
};

export type IAuthSignupRequest = {
  firstName: string,
  lastName: string,
  email: string,
  phoneNumber: string,
  password: string,
  birthDate: string,
  gender: IGender,
  country: ICountry
};

export type IConfirmEmailRequest = {
  email: string,
  password: string,
  code: string
};

export type IConfirmPhoneRequest = {
  code: string
};

export type IRequestEmailConfirmation = {
  email: string
};

export type IChangePasswordRequest = {
  userId: string,
  currentPassword: string,
  newPassword: string
};

export enum IGender {
  male = 'male',
  female = 'female',
}

export const Genders = ['Male', 'Female'];
export const Countries = ['Egypt'];
export const EgyptCitiesMock = [
  'Cairo',
  'Alexandria',
  'New Cairo',
  'El Rehab',
  'Helwan',
  'Giza',
  '6th of October',
  'El Sheikh Zayed',
  '10th of Ramadan',
  'Badr City',
  'El Shorouk',
  'El Obour',
  'Madinaty',
  'Port Said',
  'Ismailia',
  'Suez',
  'Sokhna',
  'Beheira',
  'Capital Business',
  'Damietta',
  'Kafr El Sheikh',
  'Gharbia',
  'Menoufia',
  'Sharkia',
  'Kalioubia',
  'Damanhour',
  'Mansoura',
  'Elsaf',
  'Sakkara',
  'Elbadrashien',
  'Elhawamdyia',
  'Elkhattaba',
  'Dahshour',
  'Owaism',
  'Elharraniya',
  'Shabramant',
  'Atfeeh',
  'Abo elnomrous',
  'Burkash',
  'Arab mosaeed',
  'Elayaat',
  'Fayoum',
  'Bani Sweif',
  'Menia',
  'Assiout',
  'Sohag',
  'Qena',
  'Luxor',
  'Aswan',
  'Sharm El Sheikh',
  'Hurgada',
  'Oyoun Moussa',
  'Rass Sedr',
  'South Sinai',
  'North Coast',
  'Marsa Matrouh',
  'Dahab',
  'El Tour',
  "Nweiba'a",
  'North Sinai',
  'Marsa Alam',
  'Arish',
  'Gouna',
  'Safaga',
  'Red Sea',
  'Taba',
  'Kosseir',
  'EL Wadi El Gedid',
  'Shalatin',
  'Elsalloum',
  'Elwahat',
  'Siwa Oasis',
  'Saint Katherin',
  'Elsebaeya aswan',
  'Abou Sembel',
  'Halayeb',
  'Wady elnatroon',
];

export enum ICountry {
  egypt = 'egypt',
  india = 'india',
}

export type IResetPasswordRequest = {
  userId: string,
  resetToken: string,
  newPassword: string
};

export type IAuthInterface = {
  signin(data: IAuthSigninRequest)
  refreshToken(data: ITokens)
  signup(data: IAuthSignupRequest)
  resetPassword(data: IResetPasswordRequest)
  forgotPassword(email: string)
  changePassword(data: IChangePasswordRequest)
};

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  CloseButton, Modal, ModalWrapper,
} from '../../../style/projectComponents';
import { ChangeLanguage } from '../ChangeLanguage';

const LangModalWrapper = styled(Modal)`
  background: rgba(0, 0, 0, 0.65);
  justify-content: center;
  flex-direction: column;
  z-index: 130;
  @media (max-width: 768px) {
    background: none;
  }
`;
const LanguageModalBlock = styled(ModalWrapper)`
  @media (max-width: 768px) {
    margin-top: 80px;
    border-radius: 0;
  }
`;

interface LanguageModalProps {
  openMenu: boolean,
  handleMenu: () => void
}

const LanguageModal: React.FC<LanguageModalProps> = ({
  openMenu,
  handleMenu,
}) => {
  const { t } = useTranslation();

  if (openMenu) {
    return (
      <LangModalWrapper onClick={(e) => {
        if (e.target === e.currentTarget) {
          handleMenu();
        }
      }}
      >
        <LanguageModalBlock>
          <CloseButton>
            <img
              style={{ cursor: 'pointer' }}
              onClick={handleMenu}
              width={20}
              height={20}
              src="/images/buttons/cancel-white.svg"
              alt="close"
            />
          </CloseButton>
          <h3>
            {t('language.title')}
          </h3>
          <ChangeLanguage handleMenu={handleMenu} />
        </LanguageModalBlock>
      </LangModalWrapper>
    );
  }
};

export default LanguageModal;

import { apiClient } from '../../config/serverConstants';
import {
  IAddToFavoriteReq,
  IBuyProductReq,
  ICreateCategoryReq,
  IGetAllProductsReq,
  IGetAllProductsRes, IGetProductCategoriesRes, IGetProductsFavoriteReq,
  IProduct,
  IProductInterface,
} from './models';

class ProductService implements IProductInterface {
  async createProduct(data: FormData) {
    const response = await apiClient.post('/Product/create', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data as string;
  }

  async updateProduct(data: FormData) {
    const response = await apiClient.post('/Product/update', data, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });
    return response.data as string;
  }

  async createCategory(data: ICreateCategoryReq) {
    const response = await apiClient.post('/Product/createCategory', data);
    return response.data as string;
  }

  async buyProduct(data: IBuyProductReq) {
    const response = await apiClient.post('/Product/buy', data);
    return response.data as any;
  }

  async getProductCategories(data: IGetAllProductsReq) {
    const response = await apiClient.get('/Product/getProductCategories', { params: data });
    return response.data as IGetProductCategoriesRes;
  }

  async getAllProducts(data?: IGetAllProductsReq) {
    const response = await apiClient.post('/Product/search', data);
    return response.data as IGetAllProductsRes;
  }

  async getUserProducts(data?: IGetAllProductsReq) {
    const response = await apiClient.get('/Product/getUserProducts', { params: data });
    return response.data as IGetAllProductsRes;
  }

  async getProduct(id: string) {
    const response = await apiClient.get(`/Product/${id}`);
    return response.data as IProduct;
  }

  async addToFavorite(data: IAddToFavoriteReq) {
    const response = await apiClient.post('/Product/addToFavorite', data);
    return response.data as any;
  }

  async removeFromFavorite(data: IAddToFavoriteReq) {
    const response = await apiClient.post('/Product/removeFromFavorite', data);
    return response.data as any;
  }

  async getProductFavorites(data: IGetProductsFavoriteReq) {
    const response = await apiClient.get('/Product/favorite', { params: data });
    return response.data as IGetAllProductsRes;
  }
}

export default new ProductService();

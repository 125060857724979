import { IProduct } from '../product-api/models';

export type IGetAllLotteryEventsReq = {
  skip: number,
  take: number,
  status?: ILotteryStatus
};

export type IGetLotteryWinners = {
  skip: number,
  take: number,
};

export type IGetLotteryEventReq = {
  id: string
};

export type IAddToFavoriteEventReq = {
  eventId: string
};

export type IUpdateLotteryEventReq = {
  eventId: string,
  name: string,
  prizeDetails: string,
  prizeName: string,
  drawTranslationUrl: string,
  status: ILotteryStatus,
  drawDate: string
};

export type IStartDrawingReq = {
  eventId: string,
  translationUrl: string,
};

export type IGetAllLotteryEventsRes = {
  items: IEvent[],
  totalCount: number
};

export type IEvent = {
  id: string,
  name: string,
  code: string,
  prizeDetails: string,
  prizeName: string,
  productDetails: string,
  ticketsPurchased: number,
  ticketsLimit: number,
  inFavorite: boolean,
  imageDownloadLink: string,
  drawTranslationUrl: string,
  winnerAnnouncementDate: string,
  startDate: string,
  drawDate: string,
  status: ILotteryStatus
  products: IProduct[],
  imageId: string
};

export enum ILotteryStatus {
  created = 'created',
  inProgress = 'inProgress',
  finished = 'finished',
  canceled = 'canceled',
}

export type ICreateLotteryEventReq = {
  name: string,
  code: string,
  prizeDetails: string,
  prizeName: string,
  productDetails: string,
  ticketsLimit: string,
  image: File | null,
  imageId: string | null,
  drawDate: string,
};

export type ILotteryInterface = {
  getAllLotteryEvents(data: IGetLotteryWinners)
  getUserFavoriteEvents(data: IGetLotteryWinners)
  addToFavoriteEvent(data: IAddToFavoriteEventReq)
  removeFromFavoriteEvent(data: IAddToFavoriteEventReq)
  createLotteryEvent(data: ICreateLotteryEventReq)
};

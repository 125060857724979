import { initReactI18next } from 'react-i18next';
import { createInstance } from 'i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import translation_en from './languages/translation_en.json';
import translation_ar from './languages/translation_ar.json';

const resources = {
  en: {
    translation: translation_en,
  },
  ar: {
    translation: translation_ar,
  },
};

const i18n = createInstance(
  {
    resources,
    lng: 'en',
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false,
    },
  },
);

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init();

export default i18n;

import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn,
  FlexRowBetween,
  StyledButton,
  FlexColumnStart,
  FlexRow,
  LikeButton,
} from '../../style/projectComponents';
import { colors } from '../../style/colors';
import ProductApi from '../../service/product-api/ProductApi';
import { IAddToFavoriteReq } from '../../service/product-api/models';
import GlobalModalStore from '../../storage/GlobalModalStore/GlobalModalStore';
import { IAddProductToCartReq } from '../../service/cart-api/models';
import CartApi from '../../service/cart-api/CartApi';
import LotteryApi from '../../service/lottery-api/LotteryApi';
import { IAddToFavoriteEventReq } from '../../service/lottery-api/models';
import { queryClient } from '../../config/query-client';
import UserStorage from '../../storage/UserStorage/UserStorage';
import { LoginModal } from '../global/modal/LoginModal';

const Column = styled(FlexColumn)`
  width: calc(35vw - 8px);
  gap: 22px;
  @media (max-width: 768px) {
    display: none;
  }
`;
const Block = styled(FlexColumn)`
  background: #fff;
  padding: 24px;
  width: calc(100% - 48px);
  border-radius: 32px;
`;
const PriceTitle = styled.p`
  font-size: 28px;
`;
const Description = styled.p`
  font-size: 14px;
`;
const Price = styled.p`
  font-size: 24px;
`;

const ShareContent = styled(FlexRow)`
  gap: 24px;
  margin-top: 24px;
`;

interface AddToCartComponentProps {
  price: number;
  id: string;
  inFavorite: boolean;
  isEvent?: boolean;
  isSoldOut?: boolean;
}

export const AddToCartComponent: React.FC<AddToCartComponentProps> = ({
  price, id, inFavorite, isEvent, isSoldOut,
}) => {
  const { t } = useTranslation();
  const addToFavoriteQuery = useMutation((data: IAddToFavoriteReq) => ProductApi.addToFavorite(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['product_data'] });
    },
  });
  const removeFromFavoriteQuery = useMutation((data: IAddToFavoriteReq) => ProductApi.removeFromFavorite(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['product_data'] });
    },
  });

  const addToFavoriteEventQuery = useMutation((data: IAddToFavoriteEventReq) => LotteryApi.addToFavoriteEvent(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['product_data'] });
    },
  });
  const removeFromFavoriteEventQuery = useMutation((data: IAddToFavoriteEventReq) => LotteryApi.removeFromFavoriteEvent(data), {
    onSuccess: (data) => {
      queryClient.invalidateQueries({ queryKey: ['product_data'] });
    },
  });

  const handleFavoriteProduct = () => {
    if (!inFavorite) {
      if (!addToFavoriteQuery.isLoading) {
        addToFavoriteQuery.mutate({ productId: id });
      }
    } else if (!removeFromFavoriteQuery.isLoading) {
      removeFromFavoriteQuery.mutate({ productId: id });
    }
  };

  const handleFavoriteEvent = () => {
    if (!inFavorite) {
      if (!addToFavoriteEventQuery.isLoading) {
        addToFavoriteEventQuery.mutate({ eventId: id });
      }
    } else if (!removeFromFavoriteEventQuery.isLoading) {
      removeFromFavoriteEventQuery.mutate({ eventId: id });
    }
  };

  const handleAddToFavorite = () => {
    if (isEvent) {
      handleFavoriteEvent();
    } else handleFavoriteProduct();
  };

  const GlobalModalStorage = useContext(GlobalModalStore);

  const addProductMutation = useMutation((data: IAddProductToCartReq) => CartApi.addProductToCartReq(data), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: (data) => {
      GlobalModalStorage.makeVisible(true, 'Successfully added to cart');
    },
  });

  const UserStore = useContext(UserStorage);
  const isAuth = !!UserStore.accessToken;
  const navigate = useNavigate();
  const [loginModal, setLoginModal] = useState(false);

  const addToCart = () => {
    if (isAuth) {
      addProductMutation.mutate({ productId: id, amount: 1 });
    } else {
      setLoginModal(true);
    }
  };

  return (
    <Column>
      <LoginModal isOpen={loginModal} setIsOpen={setLoginModal} />
      <Block>
        <FlexRowBetween style={{ alignItems: 'center' }}>
          <FlexColumnStart>
            <PriceTitle>{t('market.price')}</PriceTitle>
            <Description>{t('common.inclusiveOfVat')}</Description>
          </FlexColumnStart>
          <Price>
            {t('common.egp')}
            {price?.toFixed(2)}
          </Price>
        </FlexRowBetween>
        <StyledButton
          variant="darkBlue"
          noRadius
          disabled={isSoldOut || addProductMutation.isLoading}
          onClick={addToCart}
          style={{
            width: '100%', height: 60, marginBottom: 12, marginTop: 16,
          }}
        >
          {isSoldOut ? 'Out of stock' : 'Add to Cart'}
        </StyledButton>
      </Block>
      <ShareContent>
        <LikeButton style={{ background: inFavorite && colors.blueDark }} onClick={handleAddToFavorite}>
          <img src="/images/buttons/icons/like.svg" alt="" style={{ filter: inFavorite && 'invert(1)' }} />
        </LikeButton>
        <LikeButton>
          <img src="/images/buttons/icons/share.png" alt="" />
        </LikeButton>
      </ShareContent>
    </Column>
  );
};

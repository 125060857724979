import styled from 'styled-components';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  FlexColumn, FlexColumnStart, FlexRow, StyledButton,
} from '../../style/projectComponents';
import { colors } from '../../style/colors';
import GlobalModalStore from '../../storage/GlobalModalStore/GlobalModalStore';
import { queryClient } from '../../config/query-client';
import { ValidationInputComponent } from '../global/ValidationInputComponent';
import UserApi from '../../service/user-api/UserApi';
import { IUpdateUserBalanceReq } from '../../service/user-api/models';

const StyledForm = styled(FlexColumn)`
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  justify-content: start;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 130;
  overflow: scroll;
`;

const Content = styled(FlexColumnStart)`
  background: ${colors.white};
  border-radius: var(--8, 0.5rem);
  padding: 2.37rem 3.5rem;
  position: relative;
  width: 450px;
  align-items: center;
  margin-top: 150px;

  @media (max-width: 480px) {
    padding: 2.5rem 1rem;
    width: 100%;
    align-items: center;
    border-radius: 0.75rem;
  }
`;

const InnerWrapper = styled(FlexColumn)`
  row-gap: 0.5rem;
  width: calc(100% - 100px);

  h3 {
    margin: 0;
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    width: 100%;
  }

  @media (max-width: 480px) {
    width: calc(100% - 40px);
  }
`;

const CloseButton = styled(FlexRow)`
  align-self: end;
  position: absolute;
  right: 1.6rem;
  top: 1.31rem;
`;
const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 100%;
  @media (max-width: 480px) {
    width: 100%;
    max-width: 380px;
    min-width: 280px;
    label {
      left: 20px;
    }
  }
`;

export const UserBalanceModal: FC<{ closeModal?: any, userId: string, balance: number }> = ({
  closeModal,
  userId,
  balance,
}) => {
  const { t } = useTranslation();
  const GlobalModalStorage = useContext(GlobalModalStore);
  const [diff, setDiff] = useState('0');

  const {
    mutate: updateBalanceMutation,
    isLoading: isUpdating,
  } = useMutation((data: IUpdateUserBalanceReq) => UserApi.updateUserBalance(data), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: (data) => {
      GlobalModalStorage.makeVisible(true, 'Balance updated');
      queryClient.invalidateQueries({ queryKey: ['all_users_list'] });
      closeModal();
    },
  });

  const onSend = () => {
    updateBalanceMutation({
      userId,
      diff: parseFloat(diff),
    });
  };

  return (
    <StyledForm onClick={(e) => {
      if (e.target === e.currentTarget) {
        closeModal();
      }
    }}
    >
      <Content>
        <CloseButton onClick={closeModal}>
          <img src="/images/ic_cancel.svg" alt="" />
        </CloseButton>

        <InnerWrapper>
          <h3 style={{ marginBottom: 20 }}>
            {t('common.balance')}
          </h3>
          <p style={{ width: '100%', marginBottom: 12 }}>
            {t('common.userBalance')}
            :
            {balance?.toFixed(2)}
          </p>
          <InputWrapper>
            <ValidationInputComponent
              label="Balance Diff"
              type="number"
              value={diff}
              onChange={(e) => {
                setDiff(e.target.value);
              }}
              placeholder="Balance diff"
            />
          </InputWrapper>
          <StyledButton
            variant="darkBlue"
            noRadius
            disabled={isUpdating || !diff}
            onClick={onSend}
            style={{
              width: '100%', height: 60, fontSize: 15, padding: 0,
            }}
          >
            {t('common.updateBalance')}
          </StyledButton>
        </InnerWrapper>
      </Content>
    </StyledForm>
  );
};

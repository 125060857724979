import styled from 'styled-components';
import React, { FC, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { FlexColumnStart, FlexRow, StyledButton } from '../../../style/projectComponents';
import UserStorage from '../../../storage/UserStorage/UserStorage';
import { colors } from '../../../style/colors';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1002;
`;

const Content = styled(FlexColumnStart)`
  border-radius: var(--8, 0.5rem);
  padding: 2.37rem 3.5rem;
  position: relative;
  width: 400px;
  align-items: center;
  background: linear-gradient(165.86deg, #103190 0.65%, #050E2A 145.83%);

  @media (max-width: 480px) {
    padding: 2.5rem 1rem;
    width: 100%;
    align-items: center;
    border-radius: 0.75rem;
    height: 100vh;
  }
`;

const InnerWrapper = styled(FlexColumnStart)`
  row-gap: 0.5rem;
  width: calc(100% - 100px);

  p {
    color: ${colors.white};
  }

  h3 {
    margin: 0;
    font-size: 32px;
    font-weight: 900;
    text-align: center;
    width: 100%;
    color: ${colors.accent};
  }

  @media (max-width: 480px) {
    width: 100%;
    align-items: center;
  }
`;

const CloseButton = styled(FlexRow)`
  align-self: end;
  position: absolute;
  right: 1.6rem;
  top: 1.31rem;
  cursor: pointer;

  img {
    width: 24px;
    height: 24px;
  }
`;

export const LogoutModal: FC<{ setIsOpen?: any }> = ({ setIsOpen }) => {
  const { t } = useTranslation();
  const UserStore = useContext(UserStorage);
  const navigate = useNavigate();
  return (
    <StyledForm
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          setIsOpen(false);
        }
      }}
    >
      <Content>
        <CloseButton onClick={() => setIsOpen(false)}>
          <img src="/images/close16.svg" alt="" />
        </CloseButton>

        <InnerWrapper>
          <h3>
            {t('menu.logout')}
          </h3>

          <p style={{
            fontSize: 18, textAlign: 'center', width: '100%', marginBottom: 36,
          }}
          >
            {t('menu.exitConfirm')}
          </p>
          <StyledButton
            variant="darkBlue"
            noRadius
            onClick={() => setIsOpen(false)}
            style={{
              width: 'calc(100% - 40px)',
              height: 60,
              fontSize: 15,
              border: `2px solid ${colors.accent}`,
              background: colors.accent,
              color: '#000',
            }}
          >
            {t('menu.cancel')}
          </StyledButton>
          <StyledButton
            variant="primary"
            noRadius
            onClick={() => {
              UserStore.logout();
              navigate('/');
            }}
            style={{
              width: 'calc(100% - 40px)',
              height: 60,
              fontSize: 15,
              border: `2px solid ${colors.primary}`,
              color: colors.primary,
              background: colors.primary,
            }}
          >
            <p>{t('menu.logoutConfirm')}</p>
          </StyledButton>
        </InnerWrapper>
      </Content>
    </StyledForm>
  );
};

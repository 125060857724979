import React, { FC } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, FlexRow, FlexRowResponsive, AppTextMedium,
} from '../../../style/projectComponents';
import { CampaignCard } from './CampaignCard';
import { IEvent } from '../../../service/lottery-api/models';
import { CampaignCardSkeleton } from '../../global/skeletons/CampaignCardSkeleton';
import { PromoBlockFirst } from './promo/PromoBlockFirst';
import { PromoBlockSecond } from './promo/PromoBlockSecond';

const Block = styled(FlexColumn)`
  width: 100%;
  align-items: start;
  justify-content: start;
  margin-top: 26px;
`;
const CampaignsWrapper = styled(FlexRowResponsive)`
  gap: 16px;
  margin-top: 32px;
  margin-bottom: 32px;
  width: 100%;
  @media (max-width: 768px) {
    margin-bottom: 16px;
  }
`;

const CardsWrapper = styled(FlexRow)`
  gap: 16px;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch; // Optional: for smooth scrolling on iOS
  width: 100%;
  justify-content: start;
`;

interface PopularCampaignsSectionProps {
  eventsItems: IEvent[];
  isLoading: boolean;
}

const mockEvents = [
  { imageDownloadLink: '/images/mock/soon-events.png', prizeDetails: 'Soon', id: '0' },
  { imageDownloadLink: '/images/mock/soon-events.png', prizeDetails: 'Soon', id: '1' },
  { imageDownloadLink: '/images/mock/soon-events.png', prizeDetails: 'Soon', id: '2' },
  { imageDownloadLink: '/images/mock/soon-events.png', prizeDetails: 'Soon', id: '3' },
  { imageDownloadLink: '/images/mock/soon-events.png', prizeDetails: 'Soon', id: '4' },
  { imageDownloadLink: '/images/mock/soon-events.png', prizeDetails: 'Soon', id: '5' },
];

// TODO: добавить скролл вправо в мобилке для CampaignCard
export const PopularCampaignsSection: FC<PopularCampaignsSectionProps> = ({ eventsItems, isLoading }) => {
  const { t } = useTranslation();

  return (
    <Block>
      <AppTextMedium style={{ marginBottom: 16 }}>
        {t('common.popularCampaigns')}
      </AppTextMedium>
      <CardsWrapper>
        {mockEvents?.map((i) => (
          <CampaignCard
            image={i?.imageDownloadLink}
            prize={i?.prizeDetails}
            id={i.id}
            isMock
            key={i.id}
          />
        ))}
        {isLoading && !mockEvents && [0, 1, 2, 3, 4].map((s, id) => <CampaignCardSkeleton id={id} />)}
      </CardsWrapper>
      <CampaignsWrapper>
        <PromoBlockFirst />
        <PromoBlockSecond />
      </CampaignsWrapper>
    </Block>
  );
};

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import {
  FlexColumn,
  FlexColumnStart,
  StoreSectionTitle,
} from '../../style/projectComponents';

const Column = styled(FlexColumn)`
  width: calc(65vw - 48px);
  background: #fff;
  padding: 20px;
  border-radius: 20px;
  position: relative;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const Description = styled.p`
  font-size: 16px;
  text-align: left;
  margin-top: 32px;
  @media (max-width: 768px) {
    margin-top: 24px;
    font-size: 14px;
  }
`;
const ProductImage = styled.img`
  width: 100%;
  max-width: 500px;
  margin-bottom: 24px;
  max-height: 500px;
  object-fit: contain;
  @media (max-width: 768px) {
    width: 310px;
    height: 310px;
  }
`;
const ArrowImage = styled.img`
  width: 24px;
  height: 24px;
`;
const ArrowImageWrapper = styled(Link)`
  display: none;
  @media (max-width: 768px) {
    display: block;
    width: 24px;
    height: 24px;
    position: absolute;
    top: 24px;
    left: 24px;
  }
`;

const SoldOutImage = styled.img`
  width: 260px;
  position: absolute;
  bottom: 150px;;
`;

interface AddToCartComponentProps {
  image: string;
  name: string;
  details: string;
  isSoldOut?: boolean;
}

export const ProductColumn: React.FC<AddToCartComponentProps> = ({
  image, name, details, isSoldOut,
}) => (
  <Column>
    <ArrowImageWrapper to="/store">
      <ArrowImage src="/images/buttons/arrow-back.svg" alt="" />
    </ArrowImageWrapper>
    {image && <ProductImage src={image} alt="" />}
    {isSoldOut && <SoldOutImage src="/images/interface/sold-out.png" alt="" />}
    <FlexColumnStart style={{ width: '100%' }}>
      <StoreSectionTitle>{name}</StoreSectionTitle>
      <Description>{details}</Description>
    </FlexColumnStart>
  </Column>
);

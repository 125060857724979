import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import styled from 'styled-components';
import { FlexColumn, InfoText } from '../../../style/projectComponents';

const Block = styled(FlexColumn)`
  width: 400px;
  align-items: start;
  justify-content: start;
  @media(max-width: 768px) {
    width: 100%;
  }
`;

const SecondStep: FC = () => {
  const { t, i18n } = useTranslation();

  return (
    <Block>
      <InfoText>
        {t('forgotPassword.letterSent')}
      </InfoText>
    </Block>
  );
};

export default observer(SecondStep);

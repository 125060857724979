import React, { useContext } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { FlexRow } from '../../style/projectComponents';
import { Product } from './Product';
import { ICategory, IProduct } from '../../service/product-api/models';
import UserStorage from '../../storage/UserStorage/UserStorage';
import CartApi from '../../service/cart-api/CartApi';
import LotteryApi from '../../service/lottery-api/LotteryApi';

const Wrapper = styled(FlexRow)`
  gap: 30px;
  justify-content: start;
  align-items: start;
  width: 100%;
  margin-top: 24px;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    gap: 16px;
  }
`;

interface ProductsProps {
  products: IProduct[]
  smallGap?: boolean
  categories?: ICategory[]
  isAdmin?: boolean;
}

export const Products: React.FC<ProductsProps> = ({
  products, smallGap, categories, isAdmin,
}) => {
  const UserStore = useContext(UserStorage);
  const isAuth = !!UserStore.accessToken;
  const { data: cart } = useQuery(
    'get_cart_data',
    () => CartApi.getCart({
      skip: 0,
      take: 50,
    }),
    {
      enabled: isAuth,
    },
  );
  const cartItems = cart?.items?.items;

  const { data: events, isLoading: allEventsLoading } = useQuery(
    ['all_events_data'],
    () => LotteryApi.getAllLotteryEvents(
      {
        skip: 0,
        take: 50,
      },
    ),
  );
  const eventsItems = events?.items;

  return (
    <Wrapper style={{ gap: smallGap && 16 }}>
      {products?.map((i) => (
        <Product
          product={i}
          key={i.id}
          categories={categories}
          isAdmin={isAdmin}
          cartItems={cartItems}
          eventsItems={eventsItems}
        />
      ))}
    </Wrapper>
  );
};

import { apiClient } from '../../config/serverConstants';
import {
  IGetAllLotteryTicketsRes, IGetAllLotteryTicketsWinnersRes, IGetLotteryTicketsByEventIdReq,
  ILotteryTicketsBuy, ILotteryTicketsInterface,
} from './models';
import { IGetLotteryWinners } from '../lottery-api/models';

class LotteryTicketsService implements ILotteryTicketsInterface {
  async buyLotteryTickets(data: ILotteryTicketsBuy) {
    const response = await apiClient.post('/LotteryTickets/buy', data);
    return response.data as any;
  }

  async lotteryTicketsSelectWinner(data: { ticketNumber: string }) {
    const response = await apiClient.post('/LotteryTickets/selectWinner', data);
    return response.data as any;
  }

  async getAllLotteryTickets(data: IGetLotteryWinners) {
    const response = await apiClient.get('/LotteryTickets', { params: data });
    return response.data as IGetAllLotteryTicketsRes;
  }

  async getLotteryTicketsWinners(data: IGetLotteryWinners) {
    const response = await apiClient.get('/LotteryTickets/winners', { params: data });
    return response.data as IGetAllLotteryTicketsWinnersRes;
  }

  async getLotteryTicketsByEventId(data: IGetLotteryTicketsByEventIdReq) {
    const response = await apiClient.get(`/LotteryTickets/${data.eventId}`, {
      params: {
        skip: data.skip,
        take: data.take,
      },
    });
    return response.data as IGetAllLotteryTicketsRes;
  }
}

export default new LotteryTicketsService();

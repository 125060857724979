import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, RightMenuWrapper } from '../../../style/projectComponents';
import { ProfileMenu } from '../../Profile/Menu/ProfileMenu';
import { PersonalDetailsTab } from '../../Profile/Tabs/PersonalDetailsTab';
import { PROFILE_PAGES } from '../../../pages/ProfilePage';
import { WishlistTab } from '../../Profile/Tabs/WishlistTab';
import { WalletTab } from '../../Profile/Tabs/WalletTab';
import { ArchiveTicketsTab } from '../../Profile/Tabs/ArchiveTicketsTab';
import { OffersTab } from '../../Profile/Tabs/OffersTab';
import { SettingsTab } from '../../Profile/Tabs/SettingsTab';
import { ActiveTicketsTab } from '../../Profile/Tabs/ActiveTicketsTab';
import { NotificationsTab } from '../../Profile/Tabs/NotificationsTab';
import { PurchasesTab } from '../../Profile/Tabs/PurchasesTab';
import { DeliveryAddressTab } from '../../Profile/Tabs/DeliveryAddressTab';
import { MyDeliveriesTab } from '../../Profile/Tabs/MyDeliveriesTab';

const RightMenu = styled(Modal)`
  background: none;
  justify-content: start;
  align-items: end;
  flex-direction: column;
  z-index: 129;
`;

interface LanguageModalProps {
  openMenu: boolean,
  handleMenu: () => void
  setShrink: any
  setVisibleLogout: any
}

const RightSideMenu: React.FC<LanguageModalProps> = ({
  openMenu,
  handleMenu,
  setShrink,
  setVisibleLogout,
}) => {
  const [profilePage, setProfilePage] = useState(PROFILE_PAGES.NONE);

  if (openMenu) {
    return (
      <>
        <RightMenu onClick={(e) => {
          if (e.target === e.currentTarget) {
            handleMenu();
            setShrink(false);
          }
        }}
        >
          <RightMenuWrapper>
            {profilePage === PROFILE_PAGES.NONE
                        && (
                        <ProfileMenu
                          hideMenu={false}
                          isRightMenu
                          setProfilePage={setProfilePage}
                          setVisibleLogout={setVisibleLogout}
                        />
                        )}
            {profilePage === PROFILE_PAGES.USERDATA
                        && <PersonalDetailsTab action={() => setProfilePage(PROFILE_PAGES.NONE)} />}
            {profilePage === PROFILE_PAGES.WISHLIST
                        && <WishlistTab action={() => setProfilePage(PROFILE_PAGES.NONE)} />}
            {profilePage === PROFILE_PAGES.WALLET
                        && <WalletTab action={() => setProfilePage(PROFILE_PAGES.NONE)} isNotAuth={false} />}
            {profilePage === PROFILE_PAGES.OFFERS
                        && <OffersTab action={() => setProfilePage(PROFILE_PAGES.NONE)} />}
            {profilePage === PROFILE_PAGES.TICKETS
                        && (
                        <ActiveTicketsTab
                          action={() => setProfilePage(PROFILE_PAGES.NONE)}
                          isNotAuth={false}
                          setProfilePage={setProfilePage}
                          isActive={profilePage === PROFILE_PAGES.TICKETS}
                          isMobileMenu
                        />
                        )}
            {profilePage === PROFILE_PAGES.ARCHIVE_TICKETS
                        && (
                        <ArchiveTicketsTab
                          action={() => setProfilePage(PROFILE_PAGES.NONE)}
                          setProfilePage={setProfilePage}
                          isActive={profilePage === PROFILE_PAGES.ARCHIVE_TICKETS}
                          isMobileMenu
                        />
                        )}
            {profilePage === PROFILE_PAGES.SETTINGS
                        && <SettingsTab action={() => setProfilePage(PROFILE_PAGES.NONE)} />}
            {profilePage === PROFILE_PAGES.NOTIFICATIONS
                        && <NotificationsTab action={() => setProfilePage(PROFILE_PAGES.NONE)} />}
            {profilePage === PROFILE_PAGES.PURCHASES
                        && <PurchasesTab action={() => setProfilePage(PROFILE_PAGES.NONE)} />}
            {profilePage === PROFILE_PAGES.DELIVERY_ADDRESS
                        && <DeliveryAddressTab action={() => setProfilePage(PROFILE_PAGES.NONE)} />}
            {profilePage === PROFILE_PAGES.MY_DELIVERIES
                        && <MyDeliveriesTab action={() => setProfilePage(PROFILE_PAGES.NONE)} />}

          </RightMenuWrapper>
        </RightMenu>
      </>
    );
  }
};

export default RightSideMenu;

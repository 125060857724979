import React, { useContext } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FlexRow, StyledButton } from '../../../../style/projectComponents';
import { colors } from '../../../../style/colors';
import CartApi from '../../../../service/cart-api/CartApi';
import { IAddProductToCartReq } from '../../../../service/cart-api/models';
import GlobalModalStore from '../../../../storage/GlobalModalStore/GlobalModalStore';
import { queryClient } from '../../../../config/query-client';
import { useCart } from '../../../global/CartContext';

const Block = styled(FlexRow)`
  gap: 6px;
`;

const ValueButton = styled.button`
  background: ${colors.lightGrayBorder};
  border: none;
  border-radius: 10px;
  width: 70px;
  height: 47px;
  font-weight: 600;
  font-size: 12px;
  @media(max-width: 768px) {
    font-size: 5px;
    width: 30px;
    height: 21px;
    border-radius: 4px;
  }
`;
const Amount = styled(ValueButton)`
`;
const StyledPlusButton = styled(StyledButton)`
  font-weight: 600;
  width: 145px;
  height: 47px;
  border: 1px solid ${colors.lightGrayBg};
  @media(max-width: 768px){
    padding: 0;
    width: 64px;
    height: 21px;
    border-radius: 5px;
    p {
      font-size: 6px;
    }
  }
`;
const MobilePlusButton = styled(ValueButton)`
  display: none;
`;

interface CartTicketProps {
  cartAmount: number;
  productId: string;
  even?: boolean;
}

export const TicketButtons: React.FC<CartTicketProps> = ({ cartAmount, productId, even }) => {
  const GlobalModalStorage = useContext(GlobalModalStore);
  const { setShowCart } = useCart();
  const { t } = useTranslation();

  const addProductMutation = useMutation((data: IAddProductToCartReq) => CartApi.addProductToCartReq(data), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: (data) => {
      setShowCart(true);
      queryClient.refetchQueries('get_cart_data');
    },
  });

  const removeProductMutation = useMutation((data: IAddProductToCartReq) => CartApi.removeProduct(data), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: (data) => {
      queryClient.refetchQueries('get_cart_data');
    },
  });

  const handleChangeAmountMinus = () => {
    if (cartAmount > 0) {
      removeProductMutation.mutate({
        productId,
        amount: 1,
      });
    }
  };
  const handleChangeAmountPlus = () => {
    addProductMutation.mutate({
      productId,
      amount: 1,
    });
  };

  return (
    <Block>
      <ValueButton onClick={handleChangeAmountMinus} style={{ cursor: 'pointer', letterSpacing: '-0.8px' }}>
        —
      </ValueButton>
      <Amount>
        {cartAmount}
      </Amount>
      <StyledPlusButton
        onClick={handleChangeAmountPlus}
        style={{
          background: !even && colors.accent,
          color: !even && colors.black,
          borderColor: !even && colors.accent,
        }}
        variant="primary"
        noRadius
      >
        <p>
          {t('common.addMore')}
        </p>
      </StyledPlusButton>
      <MobilePlusButton onClick={handleChangeAmountPlus} style={{ cursor: 'pointer' }}>
        +
      </MobilePlusButton>
    </Block>
  );
};

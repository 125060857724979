import React from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FlexColumn, ProfileTabTitle } from '../../../style/projectComponents';
import { TabHeader } from './TabHeader';
import { WishlistTicket } from './WishlistTicket';
import ProductApi from '../../../service/product-api/ProductApi';

const Block = styled(FlexColumn)`
  align-items: start;
  width: 100%;
`;

const TicketsWrapper = styled(FlexColumn)`
  width: 100%;
  gap: 12px;
  justify-content: start;
  align-items: start;
`;

interface PersonalDetailsTabProps {
  action: () => void;
}

export const WishlistTab: React.FC<PersonalDetailsTabProps> = ({ action }) => {
  const { t } = useTranslation();
  const { data: products } = useQuery(
    'all_fav_products',
    () => ProductApi.getProductFavorites(
      {
        skip: 0,
        take: 30,
      },
    ),
  );
  const productsItems = products?.items;

  return (
    <Block>
      <TabHeader title="Wishlist" action={action} />
      <ProfileTabTitle>
        <h2>{t('menu.wishlist')}</h2>
      </ProfileTabTitle>
      <TicketsWrapper>
        {productsItems?.map((i) => (
          <WishlistTicket
            prize="EGP100,000 Mock" // TODO : prize details после обновы на бэке и картинку
            image={i?.cartImageDownloadLink}
            price={i.price}
            name={i.name}
            id={i.id}
            noActive={!i.eventId}
            key={i.id}
          />
        ))}
      </TicketsWrapper>
    </Block>
  );
};

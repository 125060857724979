import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { FlexColumn, FlexRow, FlexRowBetween } from '../../style/projectComponents';
import CartApi from '../../service/cart-api/CartApi';
import { colors } from '../../style/colors';
import UserStorage from '../../storage/UserStorage/UserStorage';
import {
  CartIcon, DrawsIcon, HomeIcon, PromoIcon, TicketsIcon, WalletIcon,
} from '../../assets/mobile-menu';
import RightSideMenu from './modal/RightSideMenu';
import RightSideProfileMenu from './modal/RightSideProfileMenu';

const Wrapper = styled(FlexRowBetween)`
  height: 62px;
  border-radius: 80px;
  width: calc(100vw - 40px);
  position: fixed;
  bottom: 24px;
  z-index: 1000;
  align-items: center;
  box-shadow: 4px 8px 24px 0 #0000000A;
  max-width: 500px;
  @media (max-width: 768px) {
    height: 70px;
    display: flex;
    background: #fff;
    width: calc(100% - 40px);
    padding: 0 10px;
  }
`;
const MobileLink = styled(Link)`
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  align-items: center;
  text-decoration: none;
  text-transform: none;
  justify-content: space-between;
  background: #fff;
  border-radius: 100%;
  width: 70px;
  height: 70px;
  padding: 12px 20px;

  &:hover {
    background: ${colors.accent};
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }

  img {
    width: 25px;
    height: 25px;
    margin-bottom: 6px;
  }

  p {
    font-size: 8px;
    width: 500px;
    color: #222;
  }
`;
const CartLink = styled(MobileLink)`
  position: relative;
  background: ${colors.accent};

  img {
    height: 24px;
    width: 24px;
    margin-bottom: 8px;
    @media (max-width: 768px) {
      width: 24px;
      height: 24px;
    }
  }

  @media (max-width: 768px) {
    width: 52px;
    height: 52px;
  }
`;
const MenuButton = styled(FlexColumn)`
  width: 70px;
  height: 70px;
  background: #103190;
  border-radius: 100%;
  padding: 9px 20px 12px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-decoration: none;
  cursor: pointer;

  p {
    color: #fff;
    font-size: 8px;
    width: 500px;
  }

  @media (max-width: 768px) {
    width: 50px;
    height: 50px;
  }

  img {
    width: 32px;
    height: 32px;
    object-fit: contain;
    object-position: right;
    transform: translateX(3px) translateY(-5px);
  }
`;
const CartAmount = styled(FlexRow)`
  border-radius: 20px;
  background: #fff;
  font-size: 13px;
  position: absolute;
  top: -5px;
  right: 5px;
  padding-left: 3px;
  padding-right: 3px;
  font-weight: 500;
`;

const MiniText = styled.span`
  color: ${colors.black};
  font-size: 11px;
  font-weight: 500;
`;

const links = [
  { link: '/', image: HomeIcon, title: 'menu.home' },
  { link: '/draws', image: DrawsIcon, title: 'menu.draws' },
  { link: '/profile/tickets', image: TicketsIcon, title: 'menu.tickets' },
  { link: '/profile/wallet', image: WalletIcon, title: 'menu.wallet' },
  // {link: "/cart", image: "/images/mobile-menu/cart.svg", title: "Cart"},
];

interface MobileMenuProps {
  setShrink: any;
  shrink: boolean;
  setVisibleLogout: any;
}

export const MobileMenu: React.FC<MobileMenuProps> = ({ setShrink, shrink, setVisibleLogout }) => {
  const { t } = useTranslation();
  const location = useLocation();
  const isHide = location.pathname === '/promo' || location.pathname.includes('admin');
  const UserStore = useContext(UserStorage);
  const isAuth = !!UserStore.accessToken;

  const { data: cart } = useQuery(
    'get_cart_data',
    () => CartApi.getCart({
      skip: 0,
      take: 50,
    }),
    {
      enabled: isAuth,
    },
  );
  const cartItems = cart?.items?.items;
  const cartSize = cartItems?.reduce((acc, c) => acc + c.amount, 0) || 0;

  const [openRightMenu, setOpenRightMenu] = useState(false);
  const handleRightMenu = () => setOpenRightMenu((s) => !s);

  const [openProfileMenu, setOpenProfileMenu] = useState(false);
  const handleProfileMenu = () => setOpenProfileMenu((s) => !s);

  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <RightSideMenu openMenu={openRightMenu} handleMenu={handleRightMenu} setShrink={setShrink} />
      <RightSideProfileMenu openMenu={openProfileMenu} handleMenu={handleProfileMenu} setShrink={setShrink} setVisibleLogout={setVisibleLogout} />
      <Wrapper style={{ display: (isHide || shrink) && 'none' }}>
        {links.map((l) => (
          <MobileLink
            style={{
              background: location.pathname === l.link ? colors.blueDark : colors.white,
            }}
            key={l.title}
            to={l.link}
          >
            <l.image
              stroke={location.pathname === l.link ? colors.white : colors.black}
              fill={location.pathname === l.link ? colors.white : colors.black}
            />
            <MiniText style={{
              color: location.pathname === l.link ? colors.white : colors.black,
            }}
            >
              {t(l.title)}
            </MiniText>
          </MobileLink>
        ))}
        <CartLink to="/cart" style={{ background: cartSize > 0 && colors.accent }}>
          {cartSize > 0 && <CartAmount>{cartSize}</CartAmount>}
          <CartIcon />
          {/* <img src="/src/assets/mobile-menu/cart.svg" alt=""/> */}
          <MiniText>{t('menu.cart')}</MiniText>
        </CartLink>
        <MenuButton onClick={() => {
          if (isAuth && windowSize.width <= 768) {
            handleProfileMenu();
            setShrink(true);
          } else {
            handleRightMenu();
            setShrink(true);
          }
        }}
        >
          <PromoIcon />
          {/* <img src="/src/assets/mobile-menu/promo.svg" alt=""/> */}
          <MiniText style={{
            color: colors.white,
          }}
          >
            {t('footer.menu')}
          </MiniText>
        </MenuButton>
      </Wrapper>
    </>
  );
};

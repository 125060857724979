export type IGetUserOrdersReq = {
  skip: number,
  take: number
};

export type IGetUserOrdersRes = {
  orders: {
    items: IUserOrder[],
    totalCount: number
  }
  totalTickets: number
};

export type IGetOrdersRes = {
  orderItems: {
    items: IOrder[],
    totalCount: number
  },
  donateToCharity: boolean
  adress: string
  totalPrice: number
  status: DeliveryStatus,
  createDate: string
};

export enum DeliveryStatus {
  created = 'created',
  confirmed = 'confirmed',
  inDelivery = 'inDelivery',
  delivered = 'delivered',
  cancelled = 'cancelled',
}

export const DeliveryStatusesList = [
  'created',
  'confirmed',
  'inDelivery',
  'delivered',
  'cancelled',
];

export type IGetOrderReq = {
  id: string
};

export type IUpdateOrderStatusReq = {
  orderId: string,
  newStatus: DeliveryStatus
};

export type IUserOrder = {
  id: string
  status: DeliveryStatus
  createDate: string
  totalPrice: number
  totalPriceInUsd: number
};
export type IOrder = {
  productId: string,
  productName: string,
  productPrice: number,
  name: string,
  imageDownloadLink: string,
  prizeDetails: string,
  prizeName: string,
  amount: number
  productTicketsAmount: number
};

export type IOrderInterface = {
  getUserOrders(data: IGetUserOrdersReq)
  getOrder(data: IGetOrderReq)
  updateOrderStatus(data: IUpdateOrderStatusReq)
  donateToCharity(data: { orderId: string })
};

import { useForm } from 'react-hook-form';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { useNavigate } from 'react-router-dom';
import jwtDecode from 'jwt-decode';
import styled from 'styled-components';
import {
  ErrorText,
  FlexColumn, FlexRowBetween,
  StyledButton, Text,
} from '../../../style/projectComponents';
import AuthApi from '../../../service/auth-api/AuthApi';
import { IConfirmEmailRequest, IConfirmPhoneRequest, IRequestEmailConfirmation } from '../../../service/auth-api/models';
import UserStorage from '../../../storage/UserStorage/UserStorage';
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore';
import { INITIAL_STATE_REG, RegisterContext } from '../../../pages/RegisterPage';
import { colors } from '../../../style/colors';
import OtpInput from '../../global/OtpInput';

const Title = styled.p`
  font-size: 20px;
  margin-top: 25px;
  font-weight: 900;
  font-family: 'HeadingNowTrial', sans-serif;
`;
const Block = styled(FlexColumn)`
  width: 430px;
  align-items: start;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const ReSendCodeButton = styled(StyledButton)`
  padding: 0;
  font-size: 16px;
  color: ${colors.blueDark};
  border-bottom: 1px solid ${colors.blueDark};
  border-radius: 0;
`;

export type SecondStepProps = {
  otpMethod: string;
};

export const SecondStep: FC<SecondStepProps> = ({ otpMethod }) => {
  const UserStore = useContext(UserStorage);
  const { formUser, setFormUser } = useContext(RegisterContext);

  const {
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      email: formUser.email,
      password: formUser.password,
      code: '',
    },
  });

  const { t, i18n } = useTranslation();
  const navigate = useNavigate();

  const GlobalModalStorage = useContext(GlobalModalStore);

  const confirmEmailMutation = useMutation((data: IConfirmEmailRequest) => AuthApi.confirmEmail(data), {
    onError: (error: any) => {
      if (error?.response?.data?.errorCode === 'ERR_AUTHUSER_EXIST') {
        GlobalModalStorage.makeVisible(false, t('auth.errors.password.serverRegErrorUserExist'));
      } else GlobalModalStorage.makeVisible(false, t('auth.errors.password.serverRegError'));
    },
    onSuccess: (data) => {
      UserStore.setTokens(data);
      UserStore.setUser(jwtDecode(data.accessToken));
      setFormUser(INITIAL_STATE_REG);
      navigate('/');
    },
  });

  const confirmWhatsAppMutation = useMutation((data: IConfirmPhoneRequest) => AuthApi.confirmPhone(data), {
    onError: (error: any) => {
      if (error?.response?.data?.errorCode === 'ERR_AUTHUSER_EXIST') {
        GlobalModalStorage.makeVisible(false, t('auth.errors.password.serverRegErrorUserExist'));
      } else GlobalModalStorage.makeVisible(false, t('auth.errors.password.serverRegError'));
    },
    onSuccess: (data) => {
      UserStore.setTokens(data);
      UserStore.setUser(jwtDecode(data.accessToken));
      setFormUser(INITIAL_STATE_REG);
      navigate('/');
    },
  });

  const requestEmailConfirmMutation = useMutation((data: IRequestEmailConfirmation) => AuthApi.requestEmailConfirmation(data), {
    onError: (error: any) => {
      GlobalModalStorage.makeVisible(false, 'Re-send Email code error');
    },
    onSuccess: (data) => {
      console.log('Req Email Success');
    },
  });

  const requestPhoneConfirmMutation = useMutation(() => AuthApi.requestWhatsappConfirmation(), {
    onError: (error: any) => {
      GlobalModalStorage.makeVisible(false, t('auth.errors.password.serverRegError'));
    },
    onSuccess: (data) => {
      console.log('Req Phone Success');
    },
  });

  const reSendCodeAction = () => {
    if (otpMethod === 'Email') {
      requestEmailConfirmMutation.mutate({ email: getValues('email') });
    } else {
      requestPhoneConfirmMutation.mutate();
    }
  };
  const [otp, setOtp] = useState('');

  // TODO: timer на resend code

  const onSubmit = (data) => {
    console.log('submit');
    if (otpMethod === 'Email') {
      confirmEmailMutation.mutate({
        email: data.email,
        password: data.password,
        code: otp,
      });
    } else {
      confirmWhatsAppMutation.mutate({
        code: otp,
      });
    }
  };
  const { code } = getValues();

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block>
        <Title>{t('auth.enterCode')}</Title>
        <Text>{t('auth.yourEmail')}</Text>
        <Text style={{ marginBottom: 24, marginTop: 6 }}>{getValues('email')}</Text>
        <OtpInput
          value={otp}
          onChange={setOtp}
          numInputs={6}
          renderSeparator={<span style={{ marginRight: 16 }} />}
          containerStyle={{
            width: '100%',
            justifyContent: 'space-between',
          }}
          inputStyle={{
            height: 57,
            width: 20,
            fontSize: 16,
            padding: '0 15px',
            color: confirmEmailMutation.isError || confirmWhatsAppMutation.isError
              ? colors.redForErrors : colors.black,
            border: `2px solid ${confirmEmailMutation.isError || confirmWhatsAppMutation.isError
              ? colors.redForErrors : colors.mediumGray}`,
            borderRadius: 12,
          }}
          renderInput={(props) => <input {...props} />}
        />
        <ErrorText
          style={{
            fontSize: 16,
            marginTop: 16,
            color: colors.redForErrors,
          }}
          hasError={confirmEmailMutation.isError || confirmWhatsAppMutation.isError}
        >
          {t('auth.verification')}
        </ErrorText>
        <FlexRowBetween style={{ marginTop: 16, width: '100%' }}>
          <ReSendCodeButton
            type="button"
            onClick={reSendCodeAction}
            variant="text"
          >
            {t('auth.resendCode')}
          </ReSendCodeButton>
          <StyledButton
            onSubmit={handleSubmit(onSubmit)}
            variant="darkBlue"
            noRadius
            style={{ height: 60, width: 170 }}
            type="submit"
          >
            {t('common.next')}
          </StyledButton>
        </FlexRowBetween>
      </Block>
    </form>
  );
};

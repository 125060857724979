import React from 'react';
import styled from 'styled-components';
import { FlexRowBetween } from '../../../style/projectComponents';

const Block = styled(FlexRowBetween)`
  align-items: center;
  width: 100%;
  margin-bottom: 32px;
  cursor: pointer;

  h2 {
    font-size: 24px;
  }

  display: none;
  @media (max-width: 720px) {
    display: flex;
  }
`;

interface TabHeaderProps {
  title: string;
  action: () => void;
  isNotAuth?: boolean;
}

export const TabHeader: React.FC<TabHeaderProps> = ({ title, action, isNotAuth }) => (
  <Block onClick={action}>
    {!isNotAuth && <img src="/images/buttons/menu/arrow-left.svg" alt="" />}
    <h2>{title}</h2>
    <div />
  </Block>
);

import { HomePage } from '../pages/HomePage';
import AuthPage from '../pages/AuthPage';
import { RegistrationPage } from '../pages/RegisterPage';
import { AdminAnalytics } from '../pages/AdminPages/AdminAnalytics';
import { ForgotPassword } from '../pages/ForgotPassword';
import { ResetPassword } from '../pages/ResetPassword';
import { CartPage } from '../pages/CartPage';
import { ProfilePage } from '../pages/ProfilePage';
import { StorePage } from '../pages/StorePage';
import { ProductPage } from '../pages/ProductPage';
import { EventPage } from '../pages/EventPage';
import { DrawPage } from '../pages/DrawPage';
import { AdminUserPage } from '../components/AdminComponents/AdminUserPage/AdminUserPage';

export const publicRoutes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/login',
    component: AuthPage,
  },
  {
    path: '/register',
    component: RegistrationPage,
  },
  {
    path: '/forgot',
    component: ForgotPassword,
  },
  {
    path: '/reset',
    component: ResetPassword,
  },
  {
    path: '/store',
    component: StorePage,
  },
  {
    path: '/product/:id?',
    component: ProductPage,
  },
  {
    path: '/event/:id?',
    component: EventPage,
  },
  {
    path: '/draws',
    component: DrawPage,
  },
  {
    path: '/cart',
    component: CartPage,
  },
  {
    path: '/profile/*',
    component: ProfilePage,
  },
];

export const privateRoutes = [
  {
    path: '/',
    component: HomePage,
  },
  {
    path: '/cart',
    component: CartPage,
  },
  {
    path: '/profile',
    component: ProfilePage,
  },
  {
    path: '/store',
    component: StorePage,
  },
  {
    path: '/product/:id?',
    component: ProductPage,
  },
  {
    path: '/event/:id?',
    component: EventPage,
  },
  {
    path: '/reset',
    component: ResetPassword,
  },
  {
    path: '/store',
    component: StorePage,
  },
  {
    path: '/draws',
    component: DrawPage,
  },
];

export const adminRoutes = [
  {
    path: '/admin/dashboard',
    component: AdminAnalytics,
  },
  {
    path: '/admin/user/:id?',
    component: AdminUserPage,
  },
];

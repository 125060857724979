import React, { useContext } from 'react';
import styled from 'styled-components';
import { FlexColumn } from '../../../style/projectComponents';
import { colors } from '../../../style/colors';
import ProfileStorage from '../../../storage/ProfileStorage/ProfileStorage';

const Block = styled(FlexColumn)`
  border-radius: 19px;
  background: ${colors.lightGray};
  width: 100%;
  padding: 37px 0;
  box-shadow: 0 4px 32.7px 0 #00000021;

  img {
    width: 125px;
    height: 125px;
  }

  p {
    font-size: 13px;
  }

  h2 {
    margin-top: 10px;
  }
`;

interface UserAvatarAndNameProps {
}

export const UserAvatarAndName: React.FC<UserAvatarAndNameProps> = () => {
  const ProfileStore = useContext(ProfileStorage);
  const profile = ProfileStore.getProfile();
  const userName = profile.firstName;
  const userLastName = profile.lastName;
  const userEmail = profile.email;

  return (
    <Block>
      <img src="/images/interface/profile/avatar.svg" alt="" />
      <h2>
        {userName}
        {' '}
        {userLastName}
      </h2>
      <p>{userEmail}</p>
    </Block>
  );
};

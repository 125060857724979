import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import {
  FlexRow, FooterTextLang, StyledButton, StyledLink,
} from '../../style/projectComponents';
import MenuModal from './modal/MenuModal';
import UserStorage from '../../storage/UserStorage/UserStorage';
import LanguageModal from './modal/LanguageModal';
import HeaderUserInfo from './HeaderUserInfo';
import { colors } from '../../style/colors';
import { LanguageFooterIcon } from '../../assets/footer';

interface HeaderComponentProps {
  shrink: boolean
}

const Header = styled.div<{ isHide: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  width: calc(100vw - 30px);
  border-radius: 15px 15px 40px 40px;
  padding: 10px 15px;
  box-shadow: 4px 4px 24px 0px #00000028;
  background: #fff;
  position: fixed;
  top: 0;
  z-index: 1000;
  max-width: 1198px;
  @media (max-width: 768px) {
    width: 100%;
    display: ${({ isHide }) => (isHide ? 'none' : 'flex')};
    max-width: 100vw;
    padding: 10px 0;
    border-radius: 0;
  }
`;

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin: auto;
  padding: 0 10px;
`;

const RightWrapper = styled(FlexRow)`
  align-items: center;
  column-gap: 24px;
  @media (max-width: 600px) {
    width: auto;
  }
`;
const BtnWrapper = styled(FlexRow)`
  @media (max-width: 768px) {
    display: none;
  }
`;

const HeaderLink = styled(StyledLink)`
  color: #000;
`;
const LinksWrapper = styled(FlexRow)`
  gap: 32px;
  @media (max-width: 1200px) {
    display: none;
  }
`;

const HeaderButtonsWrapper = styled(FlexRow)`
  border-radius: 10px;
  gap: 24px;
  background: ${colors.pageBg};
  @media (max-width: 768px) {
    gap: 12px;
  }
`;
const HolmButton = styled(Link)`
  background-image: url("/images/buttons/header/holm.svg");
  background-size: cover;
  height: 50px;
  width: 135px;
  background-position: center;
  @media (max-width: 768px) {
    width: 86px;
    border-radius: 5px;
    height: 41px;
  }
`;
const StoreButton = styled(Link)`
  background-image: url("/images/buttons/header/store.svg");
  height: 50px;
  width: 192px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  @media (max-width: 768px) {
    height: 41px;
    width: 120px;
  }
`;

const DesktopSpacer = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`;
const StyledRegisterButton = styled(StyledButton)`
  height: 40px;
  width: 170px;
  @media (max-width: 768px) {
    background: ${colors.blueDark};
    width: auto;
    color: #fff;
    font-size: 10px;
    padding: 12px 16px;
  }
`;

const HeaderComponent: React.FC<HeaderComponentProps> = ({
  shrink,
}) => {
  const UserStore = useContext(UserStorage);
  const [menuOpen, setMenuOpen] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();
  const isAuth = !!UserStore.accessToken;
  const isAdminPaths = location.pathname.includes('admin');
  const isHide = location.pathname === '/promo';

  const { t } = useTranslation();
  const [openLangModal, setOpenLangModal] = useState(false);
  const handleLangMenu = () => {
    setOpenLangModal((bool) => !bool);
  };

  const isAdmin = UserStore.isAdmin();

  return (
    <Header className="header" isHide={isHide || shrink}>
      {menuOpen && (
        <MenuModal
          isAuth={isAuth}
          open={menuOpen}
          handleLangMenu={handleLangMenu}
          handleMenu={() => setMenuOpen((prev) => !prev)}
        />
      )}
      <LanguageModal openMenu={openLangModal} handleMenu={handleLangMenu} />
      <BtnWrapper>
        <FlexRow style={{ gap: 15, cursor: 'pointer' }} onClick={handleLangMenu}>
          <LanguageFooterIcon />
          <FooterTextLang>{t('language.current')}</FooterTextLang>
        </FlexRow>
      </BtnWrapper>
      <Content>
        <DesktopSpacer />
        {isAuth && isAdmin && (
        <LinksWrapper>
          <HeaderLink
            to={!isAdminPaths ? '/admin/dashboard' : '/'}
          >
            {t('menu.adminPanel')}
          </HeaderLink>
        </LinksWrapper>
        )}

        <HeaderButtonsWrapper>
          <HolmButton to="/" />
          <StoreButton to="/store" />
        </HeaderButtonsWrapper>

        <RightWrapper>
          {!isAuth
                    && (
                    <StyledRegisterButton
                      onClick={() => navigate('/login')}
                      variant="green"
                      type="button"
                    >
                      {t('auth.registerAndLogin')}
                    </StyledRegisterButton>
                    )}
          {isAuth && <HeaderUserInfo />}
        </RightWrapper>
      </Content>
    </Header>
  );
};

export default observer(HeaderComponent);

import React, { useContext } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { FlexColumn, FlexRow, StyledButton } from '../../../style/projectComponents';
import { CartTicket } from './Ticket/CartTicket';
import { ICartItem } from '../../../service/cart-api/models';
import ProductApi from '../../../service/product-api/ProductApi';
import { Products } from '../../Store/Products';
import { colors } from '../../../style/colors';
import UserStorage from '../../../storage/UserStorage/UserStorage';

const Column = styled(FlexColumn)`
  width: calc(65vw - 16px);
  gap: 28px;
  align-items: start;
  @media (max-width: 768px) {
    width: 100%;
  }
`;

const CartTitle = styled.h4`
  font-size: 43px;
  text-align: left;
  font-weight: 700;
  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 0;
  }
`;
const CartTitleMini = styled(CartTitle)`
  font-size: 26px;
  font-weight: 700;
  margin-top: 16px;
  @media (max-width: 768px) {
    font-size: 21px;
  }
`;
const CartTickets = styled(FlexColumn)`
  width: 100%;
  gap: 8px;
  @media (max-width: 768px) {
    gap: 0;
  }
`;
const RecommendationsBlock = styled(FlexRow)`
  width: 100%;
  gap: 16px;
  justify-content: start;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

interface CartColumnProps {
  cartItems: ICartItem[];
}

export const CartColumn: React.FC<CartColumnProps> = ({ cartItems }) => {
  const { t } = useTranslation();
  const { data: products } = useQuery(
    'all_products_data',
    () => ProductApi.getAllProducts(
      {
        skip: 0,
        take: 4,
        withActiveEvents: true,
      },
    ),
  );
  const productsItems = products?.items;
  const sortedProductsItems = cartItems && productsItems?.filter((prod) => !cartItems.some((c) => c.productId === prod.id));
  const navigate = useNavigate();

  const UserStore = useContext(UserStorage);
  const isAuth = !!UserStore.accessToken;

  return (
    <Column>
      <CartTitle>{t('menu.cart')}</CartTitle>
      <CartTickets>
        {cartItems?.length > 0 && cartItems?.map((cart, id) => (
          <CartTicket
            value={cart.productPrice}
            prize={cart.prizeName}
            id={id}
            amount={cart.amount}
            image={cart.cartImageDownloadLink}
            productName={cart.productName}
            productId={cart.productId}
            length={cartItems.length}
            key={`${cart?.productId}`}
          />
        ))}
        {(!cartItems || cartItems?.length <= 0) && (
        <FlexColumn style={{ width: '100%' }}>
          <img src="/images/buttons/menu/ticket.svg" alt="" style={{ width: 45, marginBottom: 6 }} />
          <p>{t('cart.cartEmpty')}</p>
          <StyledButton
            variant="darkBlue"
            noRadius
            style={{
              height: 50,
              background: colors.primary,
              border: `1px solid ${colors.primary}`,
              marginTop: 24,
            }}
            onClick={() => (isAuth ? navigate('/store') : navigate('/register'))}
          >
            {isAuth ? t('cart.startShopping') : t('common.registerForShopping')}
          </StyledButton>
        </FlexColumn>
        )}
      </CartTickets>
      {sortedProductsItems?.length > 0 && cartItems?.length > 0 && (
        <FlexColumn>
          <CartTitleMini>
            {' '}
            {t('cart.peopleTogether')}
          </CartTitleMini>
          <RecommendationsBlock>
            {sortedProductsItems && <Products products={sortedProductsItems} smallGap />}
          </RecommendationsBlock>
        </FlexColumn>
      )}
    </Column>
  );
};

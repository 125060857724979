import styled from 'styled-components';
import React, { FC, useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import { Controller, useForm } from 'react-hook-form';
import { format, parse } from 'date-fns';
import { enUS } from 'date-fns/locale';
import {
  ErrorText, FlexColumn, FlexColumnStart, FlexRow, StyledButton,
} from '../../style/projectComponents';
import { colors } from '../../style/colors';
import GlobalModalStore from '../../storage/GlobalModalStore/GlobalModalStore';
import { ValidationInputComponent } from '../global/ValidationInputComponent';
import { queryClient } from '../../config/query-client';
import LotteryApi from '../../service/lottery-api/LotteryApi';
import { IEvent, IUpdateLotteryEventReq } from '../../service/lottery-api/models';
import { DateInputComponent } from '../global/DateInputComponent';

const StyledForm = styled.form`
  display: flex;
  flex-direction: column;
  position: fixed;
  background-color: rgba(0, 0, 0, 0.7);
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  justify-content: center;
  align-items: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 130;
  overflow: scroll;
`;

const Content = styled(FlexColumnStart)`
  background: ${colors.white};
  border-radius: var(--8, 0.5rem);
  padding: 2.37rem 3.5rem;
  position: relative;
  width: 450px;
  align-items: center;
  margin-top: 200px;

  @media (max-width: 480px) {
    padding: 2.5rem 1rem;
    width: 100%;
    align-items: center;
    border-radius: 0.75rem;
  }
`;

const InnerWrapper = styled(FlexColumn)`
  row-gap: 0.5rem;
  width: calc(100% - 100px);

  h3 {
    margin: 0;
    font-size: 32px;
    font-weight: 400;
    text-align: center;
    width: 100%;
  }

  @media (max-width: 480px) {
    width: calc(100% - 40px);
  }
`;

const CloseButton = styled(StyledButton)`
  align-self: end;
  position: absolute;
  right: 1.6rem;
  top: 1.31rem;
`;
const SettingsInputWrapper = styled(FlexRow)`
  width: 100%;
  max-width: 400px;
  position: relative;
  align-items: start;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;

export const editEventInputs = [
  {
    name: 'name',
    label: 'common.name',
    type: 'text',
    rules: { required: true },
  },
  {
    name: 'prizeDetails',
    label: 'adminUserDetail.prizeDetails',
    type: 'text',
    rules: { required: true },
  },
  {
    name: 'prizeName',
    label: 'adminUserDetail.prizeName',
    type: 'text',
    rules: { required: true },
  },
  {
    name: 'drawTranslationUrl',
    label: 'adminUserDetail.translationURL',
    type: 'text',
    rules: { required: true },
  },
];

export const EditEventModal: FC<{ closeModal?: any, event: IEvent }> = ({ closeModal, event }) => {
  const dateFormat = 'dd/MM/yyyy';
  const formattedDate = format(new Date(event?.drawDate), 'dd/MM/yyyy', { locale: enUS });

  const {
    setError,
    register,
    control,
    watch,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      name: event?.name,
      prizeDetails: event?.prizeDetails,
      prizeName: event?.prizeName,
      drawTranslationUrl: event?.drawTranslationUrl,
      drawDate: formattedDate,
    },
  });

  const { t } = useTranslation();
  const GlobalModalStorage = useContext(GlobalModalStore);

  const {
    mutate: updateEventMutation,
    isLoading: isUpdating,
  } = useMutation((data: IUpdateLotteryEventReq) => LotteryApi.updateLotteryEvent(data), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: (data) => {
      GlobalModalStorage.makeVisible(true, 'Event updated');
      queryClient.invalidateQueries({ queryKey: ['all_events_data'] });
      closeModal();
    },
  });

  const [status, setStatus] = useState(event?.status);

  const onSend = () => {
    const date = parse(getValues('drawDate'), dateFormat, new Date());
    const utcDateString = date.toISOString();
    updateEventMutation({
      eventId: event?.id,
      name: getValues('name'),
      prizeDetails: getValues('prizeDetails'),
      prizeName: getValues('prizeName'),
      drawTranslationUrl: getValues('drawTranslationUrl'),
      drawDate: utcDateString,
      status,
    });
  };

  return (
    <StyledForm
      onClick={(e) => {
        if (e.target === e.currentTarget) {
          closeModal();
        }
      }}
    >
      <Content>
        <CloseButton
          onClick={closeModal}
          variant="text"
        >
          <img src="/images/ic_cancel.svg" alt="" />
        </CloseButton>

        <InnerWrapper>
          <h3 style={{ marginBottom: 20 }}>
            {t('common.editEvent')}
          </h3>
          {editEventInputs.map((it, idx) => (
            <FlexColumn style={{ width: '100%' }}>
              <SettingsInputWrapper>
                <Controller
                  // @ts-ignore
                  name={it.name}
                  control={control}
                  rules={it.rules}
                  render={({ field: { ref, ...field } }) => (
                    <ValidationInputComponent
                      {...field}
                      name={it.name}
                      type="text"
                      isError={!!errors[it.name]}
                      label={t(it.label)}
                    />
                  )}
                />
              </SettingsInputWrapper>
              <ErrorText hasError={!!errors[it.name]}>
                {t(errors[it.name]?.message?.toString())}
              </ErrorText>
            </FlexColumn>
          ))}
          <FlexColumn style={{ width: '100%' }}>
            <SettingsInputWrapper>
              <DateInputComponent
                name="drawDate"
                label={t('adminUserDetail.drawDate')}
                register={register}
                setValue={setValue}
                errors={errors}
                watch={watch}
              />
            </SettingsInputWrapper>
          </FlexColumn>
          <StyledButton
            variant="darkBlue"
            noRadius
            disabled={!isValid || isUpdating}
            onClick={onSend}
            style={{ width: '100%', height: 60, fontSize: 15 }}
          >
            {t('common.update')}
          </StyledButton>
        </InnerWrapper>
      </Content>
    </StyledForm>
  );
};

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  CloseButton, Modal, ModalWrapper,
} from '../../../style/projectComponents';
import { ChangeCurrency } from '../ChangeCurrency';

const CurrencyModalWrapper = styled(Modal)`
  background: rgba(0, 0, 0, 0.65);
  justify-content: center;
  flex-direction: column;
  z-index: 130;
  @media (max-width: 768px) {
    background: none;
  }
`;

const CurrencyModalBlock = styled(ModalWrapper)`
  @media (max-width: 768px) {
    margin-top: 80px;
    border-radius: 0;
  }
`;

interface CurrencyModalProps {
  openMenu: boolean,
  handleMenu: () => void
}

const CurrencyModal: React.FC<CurrencyModalProps> = ({
  openMenu,
  handleMenu,
}) => {
  const { t } = useTranslation();

  if (openMenu) { return (
    <CurrencyModalWrapper onClick={(e) => {
      if (e.target === e.currentTarget) {
        handleMenu();
      }
    }}
    >
      <CurrencyModalBlock>
        <CloseButton>
          <img
            style={{ cursor: 'pointer' }}
            onClick={handleMenu}
            width={32}
            height={32}
            src="/images/buttons/cancel-white.png"
            alt="close"
          />
        </CloseButton>
        <h3>
          {t('common.currency')}
        </h3>
        <ChangeCurrency handleMenu={handleMenu} />
      </CurrencyModalBlock>
    </CurrencyModalWrapper>
  ); }
};

export default CurrencyModal;

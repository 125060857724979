import React, { FC } from 'react';
import styled from 'styled-components';
import { SoldOutCard } from './SoldOutCard';
import { colors } from '../../../../style/colors';
import { FlexColumn, FlexRow } from '../../../../style/projectComponents';
import { CampaignCardSkeleton } from '../../../global/skeletons/CampaignCardSkeleton';

const Block = styled(FlexColumn)`
  width: 100%;
  align-items: start;
  justify-content: start;
  margin-top: 26px;
  margin-bottom: 32px;
  background: ${colors.primary};
  padding: 32px;
  border-radius: 35px;
  padding-bottom: 45px;
  padding-right: 0;
  padding-top: 45px;
  @media (max-width: 768px) {
    width: calc(100vw - 32px);
    border-radius: 0;
    padding-bottom: 0;
  }
`;

const WinnersCardsWrapper = styled(FlexRow)<{ isSmall?: boolean }>`
  width: 100%;
  gap: 32px;
  justify-content: start;
  box-sizing: border-box;
  overflow: scroll;
  margin-top: 32px;
  height: auto;
  min-height: 308px;
  padding-left: 32px;
  padding-right: 32px;
  align-items: initial;
  @media (max-width: 768px) {
    gap: 16px;
  }
`;
const YouImage = styled.img`
  width: 145px;
  @media (max-width: 768px) {
    width: 88px;
  }
`;

interface WinnersSectionProps {
  isLoading: boolean;
  cards: any;
  isSmall?: boolean;
}

export const SoldOutCards: FC<WinnersSectionProps> = ({ isLoading, cards, isSmall }) => (
  <WinnersCardsWrapper isSmall={isSmall}>
    {isLoading && [0, 1, 2, 3].map((s, id) => <CampaignCardSkeleton id={id} />)}
    {!isLoading && cards?.map((i, id) => (
      <SoldOutCard
        title={`${i.title}`}
        name={i.name}
        image={i.image}
        key={`${i.code}+${i.winnerFirstName}+${id}`}
      />
    ))}
  </WinnersCardsWrapper>
);

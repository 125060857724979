import React, { FC } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { observer } from 'mobx-react-lite';
import { useMutation } from 'react-query';
import { ValidationInputComponent } from '../../global/ValidationInputComponent';
import {
  AuthText, ErrorText, FlexRow, FlexRowEnd, StyledButton,
} from '../../../style/projectComponents';
import { REC_STAGE } from '../../../pages/ForgotPassword';
import { isEmailGood } from '../Register/SecUtils';
import AuthApi from '../../../service/auth-api/AuthApi';

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 430px;
  @media (max-width: 480px) {
    width: 100%;
    label {
      left: 20px;
    }
  }
`;

type IFormValues = {
  email: string
};

type IInputs = {
  name: 'email',
  label: string,
  type: string,
  rules: any
};

const baseInputs: IInputs[] = [
  {
    name: 'email',
    label: 'Email',
    type: 'text',
    rules: isEmailGood,
  },
];

const FirstStep: FC<{ setRecStage: (e: any) => void }> = (props) => {
  const { setRecStage } = props;
  const { t } = useTranslation();

  const { control, handleSubmit, formState: { errors, isValid } } = useForm<IFormValues>({
    defaultValues: {
      email: '',
    },
  });

  const forgotQuery = useMutation((email: string) => AuthApi.forgotPassword(email), {
    onSuccess: (data) => {
      setRecStage(REC_STAGE.FINAL);
    },
  });

  const onSubmit = (data) => {
    forgotQuery.mutate(data.email);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {baseInputs.map((it, idx) => (
        <>
          <InputWrapper style={{ marginBottom: 5, marginTop: idx !== 0 && 10 }}>
            <Controller
              name={it.name}
              control={control}
              rules={it.rules}
              render={({ field: { ref, ...field } }) => (
                <ValidationInputComponent
                  {...field}
                  type={it.type}
                  isError={!!errors[it.name]}
                  label={t(it.label)}
                />
              )}
            />
          </InputWrapper>
          <ErrorText hasError={!!errors[it.name]} style={{ maxWidth: 300 }}>
            {t(errors[it.name]?.message?.toString())}
          </ErrorText>
        </>
      ))}

      <AuthText style={{ marginBottom: 30, marginTop: 8, maxWidth: 400 }}>
        {t('common.exploreProducts')}
        .
      </AuthText>
      <FlexRowEnd>
        <StyledButton
          variant="darkBlue"
          noRadius
          disabled={forgotQuery.isLoading || !isValid || forgotQuery.isSuccess}
          style={{ height: 60, width: 170 }}
          type="submit"
        >
          {t('auth.send')}
        </StyledButton>
      </FlexRowEnd>
    </form>
  );
};

export default observer(FirstStep);

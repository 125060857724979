import {
  IAuthInterface,
  IAuthSigninRequest,
  IAuthSignupRequest, IChangePasswordRequest,
  IConfirmEmailRequest, IConfirmPhoneRequest, IRequestEmailConfirmation,
  IResetPasswordRequest,
  ITokens,
} from './models';
import { apiClient } from '../../config/serverConstants';

class AuthService implements IAuthInterface {
  async signin(data: IAuthSigninRequest) {
    const response = await apiClient.post('/Auth/signIn', data);
    return response.data as ITokens;
  }

  async signup(data: IAuthSignupRequest) {
    const response = await apiClient.post('/Auth/signUp', data);
    return response.data as any;
  }

  async requestEmailConfirmation(data: IRequestEmailConfirmation) {
    const response = await apiClient.post('/Auth/requestEmailConfirmation', data);
    return response.data as any;
  }

  async requestWhatsappConfirmation() {
    const response = await apiClient.post('/Auth/requestWhatsappConfirmation');
    return response.data as any;
  }

  async confirmEmail(data: IConfirmEmailRequest) {
    const response = await apiClient.post('/Auth/confirmEmail', data);
    return response.data as ITokens;
  }

  async confirmPhone(data: IConfirmPhoneRequest) {
    const response = await apiClient.post('/Auth/confirmPhoneByWhatsapp', data);
    return response.data as ITokens;
  }

  async resetPassword(data: IResetPasswordRequest) {
    const response = await apiClient.post('/Auth/resetPassword', data);
    return response.data as string;
  }

  async forgotPassword(email: string) {
    const response = await apiClient.post('/Auth/forgotPassword', {
      email,
    });
    return response.data as string;
  }

  async refreshToken(data: ITokens) {
    const response = await apiClient.post('/Auth/refreshToken', data);
    return response.data as ITokens;
  }

  async changePassword(data: IChangePasswordRequest) {
    const response = await apiClient.post('/Auth/changePassword', data);
    return response.data as any;
  }
}

export default new AuthService();

import styled from 'styled-components';
import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { useTranslation } from 'react-i18next';
import { colors } from '../../../style/colors';
import { FlexRow } from '../../../style/projectComponents';
import CurrencyStorage from '../../../storage/CurrencyStorage/CurrencyStorage';

const Block = styled(FlexRow)`
  background: ${colors.accent};
  width: 426px;
  height: 70px;
  border-radius: 14px;
  position: absolute;
  top: 100px;
  z-index: 100;
  font-size: 17px;
  font-weight: 600;
  @media (max-width: 480px) {
    font-size: 14px;  
    width: calc(100% - 20px);
  }
`;

const WelcomeBonusModal = () => {
  const { t } = useTranslation();
  const CurrencyStore = useContext(CurrencyStorage);
  const currency = CurrencyStore?.currency;
  const bonusAmount = currency === 'EGP' && '10.00' || '0.2';

  return (
    <Block>
      {t('common.yourHolmWalletBalance')}
      {' '}
      {currency}
      {bonusAmount}
    </Block>
  );
};

export default observer(WelcomeBonusModal);

import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
  FlexRowBetween,
  Modal,
  RightMenuWrapper,
  FlexColumn,
  FlexColumnStart,
  FlexRow,
  FooterTextLang,
  FooterCard,
  FooterTextCard,
  ContainerSocial,
} from '../../../style/projectComponents';
import { colors } from '../../../style/colors';
import { InformationCards } from '../InformationCards';
import LanguageModal from './LanguageModal';
import CurrencyModal from './CurrencyModal';
import { CurrencyRightMenuIcon, LanguageRightMenuIcon } from '../../../assets/right-menu';
import { PhoneFooterIcon } from '../../../assets/footer';
import { socialLinks } from '../../../config/socialLinks';
import { RightMenuColItem } from './RightMenuColItem';

const RightMenu = styled(Modal)`
  background: none;
  justify-content: start;
  align-items: end;
  flex-direction: column;
  z-index: 1001;
`;

const HolmButton = styled(Link)`
  background: ${colors.blueDark};
  background-image: url("/images/buttons/header/holm.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 90px;
  width: 160px;
  margin-top: 16px;
  border-radius: 9px;
  @media(max-width: 480px){
    width: 140px;
    height: 70px;
    margin-top: 32px;
  }
`;
const StoreButton = styled(Link)`
  background-image: url("/images/buttons/header/merch-store-vertical.svg");
  height: 80px;
  width: 180px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-bottom: 10px;
  margin-top: 90px;
  @media(max-width: 480px){
    width: 140px;
    height: 70px;
  }
`;
const WhiteIcon = styled(FlexColumn)`
  background: rgba(16, 49, 144, 0.44);
  width: 52px;
  height: 52px;
  padding: 12px;
  border-radius: 12px;
  margin-right: 10px;
`;

const EmailButton = styled.a`
  font-size: 15px;
  font-weight: 600;
  color: ${colors.black};
`;

const SocialLinkImage = styled.img`
  cursor: pointer;
  height: 72px;
  @media (max-width: 768px) {
    height: 72px;
  }
`;

const Inner = styled(FlexRow)`
  column-gap: 5.375rem;
  align-items: flex-start;

  .desc {
    row-gap: 0.625rem;

    span {
      font-size: 1rem;
      color: #AAB0B8;
    }

    p {
      font-size: 1.125rem;
      text-align: start;
      padding-right: 40px;
      width: 100%;
    }
  }

  @media (max-width: 900px) {
    flex-direction: column;
  }
`;

const FooterMenu = styled(Inner)`
  grid-template-columns: 1fr 1fr;
  gap: 10px;
  justify-content: end;
`;

const MenuBlock = styled(RightMenuWrapper)`
  background: ${colors.pageBg};
  @media (max-width: 768px) {
    background: ${colors.pageBg};
  }
`;

interface LanguageModalProps {
  openMenu: boolean,
  handleMenu: () => void
  setShrink: any
}

const RightSideMenu: React.FC<LanguageModalProps> = ({
  openMenu,
  handleMenu,
  setShrink,
}) => {
  const { t } = useTranslation();

  const [openLangModal, setOpenLangModal] = useState(false);
  const handleLangMenu = () => {
    setOpenLangModal((bool) => !bool);
  };
  const [currencyModal, setCurrencyModal] = useState(false);
  const handleCurrencyModal = () => {
    setCurrencyModal((bool) => !bool);
  };

  const quickLinks = [
    {
      link: '/',
      title: t('menu.footer.drawTerms'),
    },
    {
      link: '/',
      title: t('menu.footer.privacyPolicy'),
    },
    {
      link: '/',
      title: t('menu.footer.userAgreement'),
    },
    {
      link: '/',
      title: t('menu.footer.deleteAccount'),
    },
  ];

  if (openMenu) {
    return (
      <>
        <LanguageModal openMenu={openLangModal} handleMenu={handleLangMenu} />
        <CurrencyModal openMenu={currencyModal} handleMenu={handleCurrencyModal} />
        <RightMenu onClick={(e) => {
          if (e.target === e.currentTarget) {
            handleMenu();
            setShrink(false);
          }
        }}
        >
          <MenuBlock>
            <FlexRowBetween style={{ marginBottom: 30 }}>
              <FlexColumn style={{ alignItems: 'end' }}>
                <img src="/images/interface/arrows/blue.svg" alt="" width={70} />
                <HolmButton to="/" />
              </FlexColumn>
              <FlexColumnStart>
                <StoreButton to="/store" />
                <img
                  src="/images/interface/arrows/green.svg"
                  alt=""
                  width={50}
                  style={{ marginLeft: 15 }}
                />
              </FlexColumnStart>
            </FlexRowBetween>
            <InformationCards />
            <FlexRow
              style={{
                gap: 15, cursor: 'pointer', marginTop: 32, marginBottom: 16,
              }}
              onClick={() => setOpenLangModal(true)}
            >
              <WhiteIcon>
                <LanguageRightMenuIcon />
              </WhiteIcon>
              <FooterTextLang style={{ fontSize: 14, color: colors.blueDark }}>{t('language.title')}</FooterTextLang>
            </FlexRow>
            <FlexRow style={{ gap: 15, cursor: 'pointer' }} onClick={() => setCurrencyModal(true)}>
              <WhiteIcon>
                <CurrencyRightMenuIcon />
              </WhiteIcon>
              <FooterTextLang
                style={{ fontSize: 14, color: colors.blueDark }}
              >{t('common.currency')}
              </FooterTextLang>
            </FlexRow>

            <FlexColumn style={{
              gap: 13,
              width: '100%',
              marginTop: 24,
            }}
            >
              <FooterCard style={{
                border: '2px solid #CDFF05',
                background: 'rgba(250,250,250,0.2)',
                flexDirection: 'row',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
              >
                <FlexColumn style={{ alignItems: 'start' }}>
                  <FooterTextCard style={{ color: colors.black }}>
                    {t('footer.questions')}
                    {' '}
                    ?
                  </FooterTextCard>
                  <FooterTextCard style={{ color: colors.black }}>
                    {t('footer.chatWithUs')}
                    :
                  </FooterTextCard>
                </FlexColumn>
                <PhoneFooterIcon />
              </FooterCard>
              <FooterCard href="tel:+23456799900">
                <FooterTextCard style={{ color: colors.blueLight }}>
                  {t('footer.callUs')}
                </FooterTextCard>
                <FooterTextCard style={{ color: colors.black }}>
                  +23456799900
                </FooterTextCard>
              </FooterCard>
              <FooterCard href="mailto:holmegy@gmail.com">
                <FooterTextCard style={{ color: colors.blueLight }}>
                  {t('auth.emailUs')}
                  :
                </FooterTextCard>
                <FooterTextCard>
                  {/* eslint-disable-next-line i18next/no-literal-string */}
                  <EmailButton>
                    holmegy@gmail.com
                  </EmailButton>
                </FooterTextCard>
              </FooterCard>
            </FlexColumn>

            <ContainerSocial style={{ marginTop: 40, marginBottom: 40 }}>
              {socialLinks.map((linkItem) => (
                <a href={linkItem.link} rel="noreferrer" target="_blank">
                  <SocialLinkImage
                    style={{
                      maxWidth: 55,
                      maxHeight: 50,
                    }}
                    src={linkItem.image}
                    key={linkItem.link}
                    alt=""
                  />
                </a>
              ))}
            </ContainerSocial>
            <RightMenuColItem items={[quickLinks[0], quickLinks[1]]} />
            <RightMenuColItem items={[quickLinks[2], quickLinks[3]]} />
            <FooterMenu />
          </MenuBlock>
        </RightMenu>
      </>
    );
  }
};

export default RightSideMenu;

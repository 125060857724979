export const headerAuthLinks = [
  {
    to: '/store',
    text: 'menu.store',
  },
  {
    to: '/cart',
    text: 'menu.cart',
  },
];

export const headerNotAuthLinks = [
  {
    to: 'profit',
    text: 'menu.profit',
  },
  {
    to: 'principles',
    text: 'menu.principles',
  },
  {
    to: 'plans',
    text: 'menu.plans',
  },
  {
    to: 'cooperation',
    text: 'menu.cooperation',
  },
];

export const adminLinks = [
  {
    to: '/admin/dashboard',
    text: 'menu.dashboard',
  },
  {
    to: '/admin/withdraw',
    text: 'menu.withdrawRequests',
  },
  {
    to: '/admin/withdraw-history',
    text: 'menu.withdrawHistory',
  },
  {
    to: '/admin/operations',
    text: 'menu.historyOperations',
  },
  {
    to: '/admin/invest-pools',
    text: 'Invest Pools',
  },
  {
    to: '/admin/currencies',
    text: 'Currencies',
  },
];

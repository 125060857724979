import styled from 'styled-components';
import React, {
  ComponentPropsWithoutRef, FC, useContext, useEffect,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { adminLinks, headerAuthLinks } from '../headerLinks';
import UserStorage from '../../../storage/UserStorage/UserStorage';
import { colors } from '../../../style/colors';
import {
  FlexColumn,
  FlexColumnStart,
  FlexRow,
  StyledButton,
  StyledLink,
} from '../../../style/projectComponents';

const Box = styled(FlexColumnStart)<{ isAuth?: boolean, isOpen?: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  width: 100vw;
  height: 100vh;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.25);
  @media (max-width: 600px) {
    width: 100%;
  }
`;

const Wrapper = styled(FlexColumnStart)`
  position: fixed;
  width: 100vw;
  background-color: #fff;
  z-index: 100;
  right: 0;
  top: 0;
  padding-bottom: 32px;
`;

const CustomStyledLink = styled(StyledLink)`
  color: ${colors.black};
  font-size: 22px;
  font-weight: 400;
  line-height: 110%;

  &[data-admin-btn] {
    flex-direction: row;
    display: flex;
    align-items: center;
  }

  :hover {
    opacity: 1;
    color: #fff;
  }
`;

export type IMenuModalProps = {
  isAuth?: boolean;
  open: boolean;
  handleMenu: () => void;
  handleLangMenu: () => void;
};
const BtnWrapper = styled(FlexColumn)`
  gap: 8px;
  margin-top: 32px;
  width: 100%;
  align-items: center;
  justify-content: center;

  button {
    width: 100%;
  }

  @media (min-width: 768px) {
    display: none;
  }
`;

const SettingsWrapper = styled(FlexRow)`
  gap: 16px;
  width: 100%;
`;
const MenuHeader = styled(FlexRow)`
  width: 100%;
  justify-content: end;
  margin-top: 20px;
`;
const Close = styled(FlexColumn)`
  margin-right: 24px;

  img {
    width: 21px;
    height: 21px;
  }
`;

const MenuModal: FC<IMenuModalProps & ComponentPropsWithoutRef<'div'>> = (props) => {
  const {
    open, handleMenu, isAuth, handleLangMenu, ...other
  } = props;
  const { t } = useTranslation();
  const location = useLocation();
  const isAdminPaths = location.pathname.includes('admin');
  const UserStore = useContext(UserStorage);
  const isAdmin = UserStore.isAdmin();
  const navigate = useNavigate();

  useEffect(() => {
    if (open) {
      document.body.style.overflowY = 'hidden';
    } else {
      document.body.style.overflowY = 'auto';
    }
  }, [open]);

  const closeModal = () => {
    if (open) {
      handleMenu();
    }
  };

  return (
    <Box
      isOpen={open}
      onClick={(e) => {
        if (e.currentTarget === e.target) {
          closeModal();
        }
      }}
      isAuth={isAuth}
    >
      <Wrapper>
        <MenuHeader>
          <Close onClick={closeModal}>
            <img src="/images/buttons/icons/close-black.svg" alt="close" />
          </Close>
        </MenuHeader>
        <SettingsWrapper>
          <FlexRow>
            <img src="/images/countries/egypt-flag.png" alt="" style={{ marginRight: 4, width: 21 }} />
            <p style={{ fontSize: 16, color: colors.darkGray }}>EGP</p>
          </FlexRow>
          <StyledButton onClick={handleLangMenu} variant="text" type="button" style={{ padding: 4 }}>
            {t('language.current')}
          </StyledButton>
        </SettingsWrapper>

        <FlexColumn style={{ width: '100%', paddingTop: 24, gap: 24 }}>
          {(isAuth && isAdminPaths
            ? adminLinks
            : headerAuthLinks
          ).map((it, idx) => (
            isAuth
                        && (
                          <CustomStyledLink to={it.to}>
                            {t(it.text)}
                          </CustomStyledLink>
                        )
          ))}

          {isAuth && isAdmin && (
            <CustomStyledLink
              data-admin-btn
              to={!isAdminPaths ? '/admin/dashboard' : '/'}
            >
              <p>{t('menu.adminPanel')}</p>
              <img src="/images/door.svg" alt="" />
            </CustomStyledLink>
          )}
        </FlexColumn>

        {!isAuth && (
          <BtnWrapper>
            <StyledButton
              variant="darkBlue"
              style={{ width: 158, height: 51, borderRadius: 66 }}
              onClick={() => navigate('/login')}
            >
              {t('auth.login')}
            </StyledButton>
            <StyledButton
              variant="primary"
              style={{ width: 158, height: 51, borderRadius: 66 }}
              onClick={() => navigate('/register')}
            >
              <p>{t('auth.reg_title')}</p>
            </StyledButton>
          </BtnWrapper>
        )}
      </Wrapper>
    </Box>
  );
};

export default observer(MenuModal);

import React, { useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, FlexColumnStart, MiniText, ProfileTabTitle, Text,
} from '../../../style/projectComponents';
import { TabHeader } from './TabHeader';
import { NotificationItem } from './NotificationItem';

const Block = styled(FlexColumn)`
  align-items: start;
  width: 100%;
`;

interface NotificationsTabProps {
  action: () => void;
}

const Content = styled(FlexColumn)`
  border-radius: 19px;
  background: #fff;
  padding: 8px 0;
  align-items: start;
  text-align: left;
  width: 100%;
  max-width: 430px;
  @media (max-width: 768px) {
    max-width: 100%;
  }
`;
const TextWrapper = styled(FlexColumnStart)`
  padding: 14px 22px;
`;

export const NotificationsTab: React.FC<NotificationsTabProps> = ({ action }) => {
  const { t } = useTranslation();
  const notificationMock = [
    {
      title: 'Emails',
      isActive: true,
    },
    {
      title: 'WhatsApp',
      isActive: false,
    },
  ];
  const [notifyList, setNotifyList] = useState(notificationMock);
  const toggleById = (id: number) => {
    const newList = notifyList.slice();
    newList[id].isActive = !newList[id].isActive;
    setNotifyList(newList);
  };

  return (
    <Block>
      <TabHeader title="Notifications" action={action} />
      <ProfileTabTitle>
        <img src="/images/buttons/menu/notify.svg" alt="" />
        <h2>
          {t('common.notifications')}
        </h2>
      </ProfileTabTitle>
      <Content>
        <TextWrapper>
          <Text>{t('common.promotionalNotifications')}</Text>
          <MiniText>{t('common.offersAndNewsletter')}</MiniText>
        </TextWrapper>
        {notifyList?.map((n, id) => (
          <NotificationItem
            title={n.title}
            isActive={n.isActive}
            id={id}
            toggle={toggleById}
          />
        ))}
      </Content>
    </Block>
  );
};

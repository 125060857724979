import { FC } from 'react';
import styled from 'styled-components';
import { FlexColumn } from '../../../style/projectComponents';
import { colors } from '../../../style/colors';

interface BlockProps {
  selected: boolean
}

const Block = styled(FlexColumn)<BlockProps>`
  cursor: pointer;
  border: 3px solid ${({ selected }) => (selected ? colors.blueDark : colors.lightGrayBg)};
  width: 137px;
  height: 87px;
  border-radius: 12px;
  img {
    width: 26px;
  }
  @media(max-width: 768px) {
    width: 50%;
  }
`;

export type OtpMethodButtonProps = {
  selected: boolean
  title: string
  id: number
  setId: (number) => void;
};

export const OtpMethodButton: FC<OtpMethodButtonProps> = ({
  selected, title, id, setId,
}) => (
  <Block selected={selected} onClick={() => setId(id)}>
    <img src={`images/buttons/icons/${title.toLowerCase()}.png`} alt="" />
    <p>{title}</p>
  </Block>
);

import React, { FC } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, FlexRow, SectionTitle, SectionTitleNormal,
} from '../../../style/projectComponents';
import { colors } from '../../../style/colors';
import LotteryTicketsApi from '../../../service/lottery-tickets-api/LotteryTicketsApi';
import { WinnersCards } from './WinnersCards';

const Block = styled(FlexColumn)`
  width: calc(100% - 32px);
  align-items: start;
  justify-content: start;
  margin-top: 26px;
  margin-bottom: 32px;
  background: ${colors.primary};
  padding:45px 32px;
  border-radius: 35px;
  @media (max-width: 768px) {
    width: calc(100vw - 32px);
    border-radius: 0;
    padding-bottom: 0;
    margin-bottom: 0;
  }
`;

const WinnersCardsWrapper = styled(FlexRow)`
  width: 100%;
  gap: 32px;
  justify-content: start;
  overflow: scroll;
  margin-top: 32px;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    flex-direction: column;
    align-items: start;
    gap: 16px;
    height: 700px;
    margin-top: 16px;
  }
`;
const YouImage = styled.img`
  width: 145px;
  @media (max-width: 768px) {
    width: 88px;
  }
`;

export const WinnersSection: FC = () => {
  const { t } = useTranslation();
  const { data: winners, isLoading } = useQuery(
    'all_tickets_winners',
    () => LotteryTicketsApi.getLotteryTicketsWinners(
      {
        skip: 0,
        take: 20,
      },
    ),
  );
  const winnersItems = winners?.items;
  const mockWinners = [
    {
      announcementDate: 'soon',
      code: 'soon',
      prizeImageDownloadUrl: '/images/mock/coming-soon-grey.png',
      winnerFirstName: 'Soon',
      winnerLastName: '',
      prizeName: '',
    },
    {
      announcementDate: 'soon',
      code: 'soon',
      prizeImageDownloadUrl: '/images/mock/coming-soon-grey.png',
      winnerFirstName: 'Soon',
      winnerLastName: '',
      prizeName: '',
    },
    {
      announcementDate: 'soon',
      code: 'soon',
      prizeImageDownloadUrl: '/images/mock/coming-soon-grey.png',
      winnerFirstName: 'Soon',
      winnerLastName: '',
      prizeName: '',
    },
    {
      announcementDate: 'soon',
      code: 'soon',
      prizeImageDownloadUrl: '/images/mock/coming-soon-grey.png',
      winnerFirstName: 'Soon',
      winnerLastName: '',
      prizeName: '',
    },
  ];

  return (
    <Block>
      <FlexRow style={{ gap: 8 }}>
        <SectionTitle style={{ color: colors.white, marginBottom: 8, textTransform: 'uppercase' }}>{t('common.winners')}</SectionTitle>
        <YouImage src="/images/interface/you-green-image.png" alt="" />
      </FlexRow>
      <SectionTitleNormal style={{ color: colors.white }}>
        {t('common.allSection')}
      </SectionTitleNormal>
      <WinnersCards isLoading={isLoading} winners={mockWinners} />
    </Block>
  );
};

import React from 'react';
import styled from 'styled-components';
import {
  AppTextMedium, AppTextNormal, AppTitleNormal, FlexColumn, FlexRow,
} from '../../style/projectComponents';

const Block = styled(FlexColumn)`
  width: 300px;
  border-radius: 12px;
  color: rgba(0, 0, 0, .8);
  height: 80px;
  background: #fff;
  padding: 10px;

  h1 {
    font-size: 22px;
    margin: 0;
    padding-bottom: 11px;
    width: 100%;
    text-align: center;
    font-weight: 500;
  }

  p {
    font-size: 16px;
    text-align: center;
    font-weight: 400;
    margin: 0;
    width: 100%;
  }

  @media (max-width: 1100px) {
    height: 6.8125rem;

    h1 {
      font-size: 1.125rem;
    }

    p {
      font-size: 0.75rem;
    }
  }

  @media (max-width: 780px) {
    width: 12.5rem;
    height: 6.625rem;
    padding: 0 var(--16, 1rem);
  }

  @media (max-width: 480px) {
    height: 5.8125rem;
    width: 100%;
    h1 {
      font-size: 1.25rem;
    }

    p {
      font-size: 0.875rem;
    }
  }
`;

interface StatisticsComponentProps {
  title: number;
  description: string;
  symbol?: string;
}

export const StatisticsComponent: React.FC<StatisticsComponentProps> = ({ title, description, symbol }) => (
  <Block>
    <AppTextNormal style={{ marginBottom: 8 }}>{description}</AppTextNormal>
    <FlexRow style={{ alignItems: 'end' }}>
      <AppTitleNormal style={{ marginRight: 8, fontSize: 28 }}>{title}</AppTitleNormal>
      <AppTextMedium style={{ marginBottom: 2, fontWeight: 600, fontSize: 14 }}>{symbol}</AppTextMedium>
    </FlexRow>
  </Block>
);

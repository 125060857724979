import React, { createContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  AppStyle,
  AuthContent, AuthModal, AuthWrapper, Page,
} from '../style/projectComponents';
import { IAuthSignupRequest, ICountry, IGender } from '../service/auth-api/models';
import RegisterLoginButtons from '../components/auth/Register/RegisterLoginButtons';
import { FirstStep } from '../components/auth/Register/FirstStep';
import { SecondStep } from '../components/auth/Register/SecondStep';

export enum STAGE {
  BASE = 'BASE',
  FINAL = 'FINAL',
}

const RegisterModal = styled(AuthModal)`
  max-width: 600px;
  @media (max-width: 768px) {
    max-width: 100%;
    padding: 35px 20px;
  }
`;

export const RegisterContext = createContext(null);
export const INITIAL_STATE_REG: IAuthSignupRequest = {
  firstName: '',
  lastName: '',
  email: '',
  birthDate: '01/01/2000',
  password: '',
  phoneNumber: '',
  gender: IGender.male,
  country: ICountry.egypt,
};

export const RegistrationPage: React.FC = () => {
  const [stage, setStage] = useState(STAGE.BASE);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [formUser, setFormUser] = useState<IAuthSignupRequest>(INITIAL_STATE_REG);
  const OTPMethods = ['Email', 'WhatsApp'];
  const [otpSelected, setOtpSelected] = useState(0);

  return (
    <AppStyle>
      <AuthContent style={{ paddingTop: 120, paddingBottom: 100 }}>
        <AuthWrapper>
          <RegisterModal>
            <RegisterLoginButtons />
            <RegisterContext.Provider value={{ formUser, setFormUser }}>
              {(stage === STAGE.BASE
                ? (
                  <FirstStep
                    setRegStage={setStage}
                    otpSelected={otpSelected}
                    setOtpSelected={setOtpSelected}
                    OTPMethods={OTPMethods}
                  />
                )
                : <SecondStep otpMethod={OTPMethods[otpSelected]} />
              )}
            </RegisterContext.Provider>
          </RegisterModal>
        </AuthWrapper>
      </AuthContent>
    </AppStyle>
  );
};

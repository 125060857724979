import React, { useContext } from 'react';
import styled from 'styled-components';
import { useMutation } from 'react-query';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, FlexRow, Modal, StyledButton,
} from '../../../style/projectComponents';
import { ICartItem } from '../../../service/cart-api/models';
import { colors } from '../../../style/colors';
import { queryClient } from '../../../config/query-client';
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore';
import CartApi from '../../../service/cart-api/CartApi';

const ContentWrapper = styled(FlexColumn)`
  background: #fff;
  width: 540px;
  border-radius: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 6px 20px;
  padding-top: 32px;
  @media (max-width: 768px) {
    width: 100%;
  }
`;
const ModalWrapper = styled(Modal)`
  background: rgba(0, 0, 0, 0.65);
  justify-content: center;
  flex-direction: column;
`;

const Title = styled.h3`
  font-size: 32px;
  font-weight: 700;
  margin: 0;
  margin-bottom: 16px;
  @media (max-width: 768px) {
    font-size: 24px;
    margin-bottom: 10px;
  }
`;
const Description = styled.p`
  font-size: 16px;
  margin-bottom: 24px;
  @media (max-width: 768px) {
    font-size: 15px;
    margin-bottom: 16px;
  }
`;
const ButtonWrapper = styled(FlexRow)`
  width: 368px;
  margin-bottom: 36px;
  margin-top: 24px;
  @media (max-width: 768px) {
    width: 335px;
  }
`;
const ItemsWrapper = styled(FlexRow)`
  flex-wrap: wrap;
  justify-content: start;
  gap: 12px;
`;
const Amount = styled.p`
  font-size: 14px;
  font-weight: 600;
  color: ${colors.blueDark};
  margin-top: 8px;
`;
const ItemImage = styled.img`
  width: 100px;
  height: 100px;
`;

interface LanguageModalProps {
  openMenu: boolean,
  handleMenu: () => void,
  cartItems: ICartItem[]
}

const CartCleanModal: React.FC<LanguageModalProps> = ({
  openMenu,
  handleMenu,
  cartItems,
}) => {
  const { t } = useTranslation();
  const GlobalModalStorage = useContext(GlobalModalStore);
  const {
    mutate: cleanCartMutation,
    isLoading: isCleanLoading,
  } = useMutation(() => CartApi.refreshCart(), {
    onError: (error) => {
      GlobalModalStorage.makeVisible(false, 'Something goes wrong');
    },
    onSuccess: (data) => {
      GlobalModalStorage.makeVisible(true, 'Cart updated');
      queryClient.refetchQueries('get_cart_data');
      handleMenu();
    },
  });
  const itemsToRemove = cartItems?.filter((c) => !c?.withActiveEvent);

  if (openMenu) {
    return (
      <ModalWrapper onClick={(e) => {
        if (e.target === e.currentTarget) {
          handleMenu();
        }
      }}
      >
        <ContentWrapper>
          <Title>{t('common.soldOut')}</Title>
          <Description>
            {t('common.theFollowingItems')}
            {' '}
          </Description>
          <ItemsWrapper>
            {itemsToRemove?.map((i) => (
              <FlexColumn key={i?.productId}>
                <ItemImage src={i?.cartImageDownloadLink} alt="" />
                <Amount>
                  {i?.amount}
                  {' '}
                  {t('common.items')}
                </Amount>
              </FlexColumn>
            ))}
          </ItemsWrapper>
          <ButtonWrapper>
            <StyledButton
              variant="green"
              style={{ width: '100%', height: 60, fontSize: 15 }}
              noRadius
              disabled={isCleanLoading}
              onClick={() => cleanCartMutation()}
            >
              {t('cart.cleanCart')}
            </StyledButton>
          </ButtonWrapper>
        </ContentWrapper>
      </ModalWrapper>
    );
  }
};

export default CartCleanModal;

import React, { FC, useMemo, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'react-query';
import {
  FlexColumn, StyledButton, FlexRowBetween, StatsTitle,
} from '../../../style/projectComponents';
import { DataEmptyComponent } from '../../global/DataEmptyComponent';
import ProductApi from '../../../service/product-api/ProductApi';
import { Products } from '../../Store/Products';
import { NewProductModal } from '../NewProductModal';
import { PaginationComponent } from '../../global/PaginationComponent';
import { generatePagesArray } from '../../../utils/generatePagesArray';

const OperationsWrapper = styled(FlexColumn)`
  width: 100%;
  max-width: 1100px;
  justify-content: start;
  background: rgba(255, 255, 255, 0.3);
  padding: 26px 16px;
  border-radius: 8px;
  @media (max-width: 768px) {
    max-width: calc(100vw - 16px);
  }
`;

export const AdminProducts: FC = () => {
  const { t } = useTranslation();
  const [page, setPage] = useState(0);
  const itemsPerPage = 15;
  const { data: products, isLoading: isProductsLoading } = useQuery(
    ['all_products', page],
    () => ProductApi.getAllProducts(
      {
        skip: page * itemsPerPage,
        take: itemsPerPage,
      },
    ),
  );
  const totalPages = useMemo(
    () => (products?.totalCount ? Math.ceil(products?.totalCount / itemsPerPage) : 1),
    [products?.totalCount, itemsPerPage],
  );
  const productsItems = products?.items;
  const [addProductModal, setAddProductModal] = useState(false);
  const handleAddProductModal = () => setAddProductModal((m) => !m);

  const { data: categories } = useQuery(
    'get_product_categories',
    () => ProductApi.getProductCategories(
      {
        skip: 0,
        take: 100,
      },
    ),
  );
  const categoriesItems = categories?.items;

  return (
    <>
      {addProductModal && <NewProductModal closeModal={handleAddProductModal} categories={categoriesItems} />}
      <OperationsWrapper>
        <FlexRowBetween style={{ alignItems: 'center' }}>
          <StatsTitle>{t('adminAnalytics.products')}</StatsTitle>
          <StyledButton
            variant="darkBlue"
            onClick={handleAddProductModal}
            noRadius
          >
            {t('adminAnalytics.newProduct')}
          </StyledButton>
        </FlexRowBetween>
        {!isProductsLoading && productsItems?.length > 0
          ? <Products products={productsItems} categories={categoriesItems} isAdmin />
          : <DataEmptyComponent />}
        <PaginationComponent
          maxPages={totalPages}
          pageId={page}
          pagesNum={generatePagesArray(totalPages, page, 100)}
          setPageId={setPage}
        />
      </OperationsWrapper>
    </>
  );
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { IAdminCart } from '../../../service/admin-api/models';
import CartRow from './CartRow';
import { AdminTable } from '../../../style/projectComponents';

interface AdminCartTableProps {
  cartStats: IAdminCart[];
}

const AdminCartTable: FC<AdminCartTableProps> = ({ cartStats }) => {
  const { t } = useTranslation();
  return (
    <AdminTable>
      <thead>
        <tr>
          <th style={{ textTransform: 'uppercase' }}>{t('common.id')}</th>
          <th>{t('auth.firstName')}</th>
          <th>{t('auth.lastName')}</th>
          <th>{t('auth.email')}</th>
          <th>{t('menu.cart')}</th>
          <th>{t('menu.cart')}</th>
        </tr>
      </thead>
      <tbody>
        {cartStats.map((cart) => (
          <CartRow cart={cart} />
        ))}
      </tbody>
    </AdminTable>
  );
};

export default AdminCartTable;

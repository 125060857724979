import React from 'react';
import { useTranslation } from 'react-i18next';
import {
  AuthContent, AuthModal, AuthTitle, FlexRow, Page,
} from '../style/projectComponents';
import Content from '../components/auth/RecoveryUpdate/Content';

export const ResetPassword = () => {
  const { t } = useTranslation();

  return (
    <Page>
      <AuthContent>
        <AuthModal>
          <FlexRow style={{
            marginBottom: 20,
            position: 'relative',
            alignItems: 'center',
            width: '100%',
            height: 'fit-content',
          }}
          >
            <AuthTitle>
              {t('forgotPassword.title')}
            </AuthTitle>
          </FlexRow>
          <Content />
        </AuthModal>
      </AuthContent>
    </Page>
  );
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { FlexRow } from '../../../style/projectComponents';
import { colors } from '../../../style/colors';

const Text = styled.p<{ xl?: boolean }>`
    font-weight: 500;
    line-height: 18px;
    text-align: start;
    white-space: pre-wrap
`;

const ToggleSwitch = styled(FlexRow)<{ isActive: boolean }>`
  width: 34px;
  height: 18px;
  border-radius: 100px;
  background: ${({ isActive }) => (isActive ? colors.primary : colors.mediumGray)};
  justify-content: ${({ isActive }) => (isActive ? 'end' : 'start')};
  padding: 3px;
  cursor: pointer;
  label {
    background: #fff;
    width: 19px;
    height: 19px;
    border-radius: 100%;
  }
`;

export const CheckComponent: FC<{ isActive: boolean, handleClick: () => void, text?: string, textXl?: boolean }> = ({
  isActive,
  handleClick,
  text = 'auth.noUsCitizen',
  textXl = false,
}) => {
  const { t } = useTranslation();

  return (
    <FlexRow
      className="checkbox"
      style={{
        gap: 9,
      }}
    >
      <ToggleSwitch onClick={handleClick} isActive={isActive}>
        <label />
      </ToggleSwitch>
      <Text xl={textXl}>{t(text)}</Text>
    </FlexRow>
  );
};

import axios from 'axios';

export const SERVER_URL = (window.location.host === 'localhost:3000') ? 'https://egypt-lottery.di-dev.ru' : 'https://egypt-lottery.di-dev.ru';

export const apiClient = axios.create({
  baseURL: SERVER_URL,
  headers: {
    'Content-type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*',
    'Access-Control-Allow-Headers': '*',
  },
});

apiClient.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${JSON.parse(localStorage.accessToken)}`;
  return config;
});

apiClient.interceptors.response.use(
  (config) => config,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && error.config && !error.config._isRetry) {
      originalRequest._isRetry = true;
      try {
        const response = await axios.post(`${SERVER_URL}/Auth/refreshToken`, {
          accessToken: JSON.parse(localStorage.accessToken),
          refreshToken: JSON.parse(localStorage.refreshToken),
        });
        localStorage.setItem('accessToken', JSON.stringify(response.data.accessToken));
        localStorage.setItem('refreshToken', JSON.stringify(response.data.refreshToken));
        return await apiClient.request(originalRequest);
      } catch (e) {
        localStorage.clear();
        window.location.href = '/login';
      }
    }
    throw error;
  },
);

import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import {
  FlexColumn, LanguageSwitch,
} from '../../style/projectComponents';
import LanguageStorage from '../../storage/LanguageStorage/LanguageStorage';
import i18n from '../../config/i18n';

const LanguageWrapper = styled(FlexColumn)`
  gap: 15px;
  width: 100%;
`;

interface ChangeLanguageProps {
  handleMenu: () => void;
}

export const ChangeLanguage: React.FC<ChangeLanguageProps> = ({ handleMenu }) => {
  const LanguageStore = useContext(LanguageStorage);

  const changeLanguage = (lng: string) => {
    i18n.changeLanguage(lng);
  };

  const [langs, setLangs] = useState([
    {
      lang: 'en',
      active: true,
    },
    {
      lang: 'ar',
      active: false,
    },
  ].map((it, idx) => (LanguageStore.language === it.lang
    ? { ...it, active: true }
    : { ...it, active: false })));

  const getLanguageDescription = (lang: string) => lang === 'en' && 'English' || lang === 'ar' && 'العربية';

  return (
    <LanguageWrapper>
      {langs.map((it, idx) => (
        <LanguageSwitch
          onClick={() => {
            setLangs(langs.map((lng) => (lng.lang === it.lang
              ? { ...lng, active: true }
              : { ...lng, active: false })));
            changeLanguage(it.lang);
            LanguageStore.setLanguage(it.lang);
            handleMenu();
          }}
          active={it.active}
        >
          {getLanguageDescription(it.lang)}
        </LanguageSwitch>
      ))}
    </LanguageWrapper>
  );
};

const formatDateCustom = (date: string): string => {
  const parts = date.split('/');
  return `${parts[2]}-${parts[1]}-${parts[0]}`;
};

export const formatDateCustomDDMMYY = (date: string): string => {
  const parts = date.split('/');
  return `${parts[1]}/${parts[0]}/${parts[2]}`;
};

export default formatDateCustom;

import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { FlexColumn, FlexRow } from '../../style/projectComponents';
import { colors } from '../../style/colors';

interface BlockProps {
  selected?: boolean;
}
const Block = styled(FlexColumn)<BlockProps>`
  border-radius: 19px;
  border: 1px solid ${({ selected }) => (selected ? colors.purple : colors.lightGrayBg)};
  width: 100%;
  height: 105px;
  cursor: pointer;
  background: #fff;
  img {
    width: 30px;
  }
  p {
    font-size: 14px;
  }
`;

interface PayMethodProps {
  selected: boolean;
}

export const CardPayMethod: React.FC<PayMethodProps> = ({ selected }) => {
  const { t } = useTranslation();

  return (
    <Block selected={selected}>
      <p style={{ marginBottom: 8 }}>
        {t('common.credit')}
        /
        {t('common.debitCards')}
      </p>
      <FlexRow style={{ gap: 4 }}>
        <img src="/images/interface/cards/visa.png" alt="" />
        <img src="/images/interface/cards/mc.png" alt="" />
        <img src="/images/interface/cards/ae.png" alt="" />
      </FlexRow>
    </Block>
  );
};

import React, { useContext, useState } from 'react';
import styled from 'styled-components';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  ErrorText,
  FlexColumn, FlexRow, InputAndErrorColumn, ProfileTabTitle, StyledButton,
} from '../../../style/projectComponents';
import { ValidationInputComponent } from '../../global/ValidationInputComponent';
import { settingsInputs } from './SettingsUtils';
import { TabHeader } from './TabHeader';
import ProfileStorage from '../../../storage/ProfileStorage/ProfileStorage';
import { DropdownComponent } from '../../global/DropdownComponent';
import { DateInputComponent } from '../../global/DateInputComponent';
import {
  Countries, Genders, ICountry, IGender,
} from '../../../service/auth-api/models';
import UserApi from '../../../service/user-api/UserApi';
import { IUpdateUserReq } from '../../../service/user-api/models';
import { queryClient } from '../../../config/query-client';
import formatDateCustom, { formatDateCustomDDMMYY } from '../../../utils/formatDateCustom';
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore';

const Block = styled(FlexColumn)`
  align-items: start;
  width: 100%;
`;

const SettingsWrapper = styled(FlexRow)`
  width: 100%;
  gap: 6px;
  justify-content: start;
  flex-wrap: wrap;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
const SettingsInputWrapper = styled(FlexRow)`
  width: 300px;
  position: relative;
  align-items: start;
  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }
`;

interface PersonalDetailsTabProps {
  action: any;
}

export const PersonalDetailsTab: React.FC<PersonalDetailsTabProps> = ({ action }) => {
  const { t } = useTranslation();
  const ProfileStore = useContext(ProfileStorage);
  const profile = ProfileStore.getProfile();
  const {
    control,
    setValue,
    clearErrors,
    handleSubmit,
    register,
    watch,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      firstName: profile.firstName,
      lastName: profile.lastName,
      birthDate: formatDateCustomDDMMYY(profile.birthDate),
    },
  });

  const [openGenderMenu, setOpenGenderMenu] = useState(false);
  const [genderId, setGenderId] = useState(Genders.findIndex((g) => g.toLowerCase() === profile.gender) || 0);
  const [openCountryMenu, setOpenCountryMenu] = useState(false);
  const [countryId, setCountryId] = useState(Countries.findIndex((g) => g.toLowerCase() === profile.country) || 0);
  const GlobalModalStorage = useContext(GlobalModalStore);

  const editProfileQuery = useMutation((data: IUpdateUserReq) => UserApi.updateUserData(data), {
    onSuccess: (data) => {
      GlobalModalStorage.makeVisible(true, t('common.success'));
      queryClient.refetchQueries('profile_data');
    },
    onError: () => {
      GlobalModalStorage.makeVisible(false, t('common.error'));
    },
  });

  const onSubmit = (data: any) => {
    editProfileQuery.mutate({
      userId: profile?.id,
      firstName: data.firstName,
      lastName: data.lastName,
      birthDate: formatDateCustom(data.birthDate),
      gender: Genders[genderId] as IGender,
      country: Countries[countryId] as ICountry,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Block>
        <TabHeader title="Personal Details" action={action} />
        <ProfileTabTitle>
          <h2>{t('user.personalDetails')}</h2>
        </ProfileTabTitle>
        <SettingsWrapper>
          {settingsInputs.map((it, idx) => (
            <InputAndErrorColumn>
              <SettingsInputWrapper>
                <Controller
                                    // @ts-ignore
                  name={it.name}
                  control={control}
                  rules={it.rules}
                  render={({ field: { ref, ...field } }) => (
                    <ValidationInputComponent
                      {...field}
                      name={it.name}
                      type="text"
                      isError={!!errors[it.name]}
                      label={t(it.label)}
                    />
                  )}
                />
              </SettingsInputWrapper>
              <ErrorText hasError={!!errors[it.name]}>
                {t(errors[it.name]?.message?.toString())}
              </ErrorText>
            </InputAndErrorColumn>
          ))}
          <SettingsInputWrapper>
            <DateInputComponent
              name="birthDate"
              label={t('adminUserDetail.birthDate.placeholder')}
              register={register}
              setValue={setValue}
              errors={errors}
              watch={watch}
            />
          </SettingsInputWrapper>
          <SettingsInputWrapper>
            <DropdownComponent
              openMenu={openGenderMenu}
              setOpenMenu={setOpenGenderMenu}
              itemId={genderId}
              setId={setGenderId}
              hasSubTitle
              subtitle={t('auth.gender')}
              itemList={Genders}
            />
          </SettingsInputWrapper>
          <SettingsInputWrapper>
            <DropdownComponent
              openMenu={openCountryMenu}
              setOpenMenu={setOpenCountryMenu}
              itemId={countryId}
              setId={setCountryId}
              hasSubTitle
              subtitle={t('auth.country')}
              itemList={Countries}
            />
          </SettingsInputWrapper>
        </SettingsWrapper>
        <StyledButton
          variant="darkBlue"
          style={{ width: 158, height: 56, marginTop: 16 }}
          disabled={!isValid || editProfileQuery.isLoading}
          noRadius
          onClick={() => clearErrors()}
          type="submit"
        >
          {t('common.edit')}
        </StyledButton>
      </Block>
    </form>
  );
};

import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { ICurrentUserResponse } from '../../../service/user-api/models';
import UserRow from './UserRow';
import { AdminTable } from '../../../style/projectComponents';

interface UsersTableProps {
  users: ICurrentUserResponse[];
}

const UsersTable: FC<UsersTableProps> = ({ users }) => {
  const { t } = useTranslation();
  return (
    <AdminTable>
      <thead>
        <tr>
          <th style={{ textTransform: 'uppercase' }}>{t('common.id')}</th>
          <th>{t('auth.firstName')}</th>
          <th>{t('auth.lastName')}</th>
          <th>{t('auth.email')}</th>
          <th>{t('settings.inputs.phone.title')}</th>
          <th>{t('common.birthDate')}</th>
          <th>{t('common.registeredOn')}</th>
          <th>{t('assets.balance')}</th>
          <th>{t('common.role')}</th>
          <th>{t('assets.actions')}</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user) => (
          <UserRow user={user} />
        ))}
      </tbody>
    </AdminTable>
  );
};

export default UsersTable;

import {
  IAdminInterface,
  IGetCartStatsReq,
  IGetCartStatsRes,
  IGetOrdersStatsReq,
  IGetOrdersStatsRes,
  IGetUsersStatsReq,
  IGetUsersStatsRes,

} from './models';
import { apiClient } from '../../config/serverConstants';

class AdminService implements IAdminInterface {
  async getCartsStatistic(data: IGetCartStatsReq) {
    const response = await apiClient.get('/Admin/getCartsStatistic', { params: data });
    return response.data as IGetCartStatsRes;
  }

  async getUsersStatistic(data: IGetUsersStatsReq) {
    const response = await apiClient.get('/Admin/getUsersStatistic', { params: data });
    return response.data as IGetUsersStatsRes;
  }

  async getOrdersStatistic(data: IGetOrdersStatsReq) {
    const response = await apiClient.get('/Admin/getOrdersStatistic', { params: data });
    return response.data as IGetOrdersStatsRes;
  }
}

export default new AdminService();

import React from 'react';
import { Oval } from 'react-loader-spinner';
import { colors } from '../../style/colors';

export const PageLoader: React.FC = () => (
  <Oval
    height={30}
    width={30}
    color={colors.primary}
    wrapperStyle={{}}
    wrapperClass=""
    visible
    ariaLabel="oval-loading"
    secondaryColor={colors.primary}
    strokeWidth={2}
    strokeWidthSecondary={2}
  />
);

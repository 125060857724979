export const settingsInputs = [
  {
    name: 'firstName',
    label: 'auth.firstName',
    type: 'text',
    rules: { required: true },
  },
  {
    name: 'lastName',
    label: 'auth.lastName',
    type: 'text',
    rules: { required: true },
  },
  // {
  //     name: 'email',
  //     label: 'auth.email',
  //     type: 'text',
  //     rules: isEmailGood
  // },
];

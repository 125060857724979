import { Controller, useForm } from 'react-hook-form';
import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  AuthInputWrapper,
  ErrorText,
  FlexColumn,
  FlexColumnStart,
  FlexRow,
  FlexRowEnd,
  PassIcon,
  StyledAdvise,
  StyledButton,
} from '../../../style/projectComponents';
import { ValidationInputComponent } from '../../global/ValidationInputComponent';
import AuthApi from '../../../service/auth-api/AuthApi';
import { Countries, Genders, IAuthSignupRequest } from '../../../service/auth-api/models';
import { registerInputs, isPhoneNumberGood } from './SecUtils';
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore';
import formatDateCustom from '../../../utils/formatDateCustom';
import { RegisterContext, STAGE } from '../../../pages/RegisterPage';
import { DropdownComponent } from '../../global/DropdownComponent';
import { OtpMethodButton } from './OtpMethodButton';
import { UserAgreementComponent } from './UserAgreementComponent';
import { DateInputComponent } from '../../global/DateInputComponent';
import { PhoneInputComponent } from '../../global/PhoneInputComponent';
import { colors } from '../../../style/colors';
import { AlreadyHaveAccount } from './AlreadyHaveAccount';
import { InformationCards } from '../../global/InformationCards';
import LanguageStorage from '../../../storage/LanguageStorage/LanguageStorage';

type IFormValues = {
  phoneNumber: string,
  password: string,
  email: string,
  retypePassword: string,
  badSignup: any
  birthDate: string
};

export type IFinalInput = {
  name: any,
  label: string,
  type: string,
  rules: any
};

const OTPMethodTitle = styled.p`
  font-size: 16px;
  margin-top: 11px;
  margin-bottom: 12px;
  font-weight: 500;
  @media (max-width: 768px) {
    font-weight: 700;
  }
`;
const Title = styled.h1`
  font-size: 24px;
  text-align: left;
  width: 700px;
  font-weight: 900;
  margin-top: 19px;
  margin-bottom: 19px;
  //text-transform: uppercase;
  @media (max-width: 768px) {
    font-size: 17px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`;
const HowWorkLink = styled.a`
  font-family: 'HeadingNowTrial', sans-serif;
  font-size: 16px;
`;
const HowCharityWorks = styled.a`
  margin-top: 8px;

  p {
    font-weight: 500;
    font-size: 15px;
    margin-left: 14px;
    margin-top: 2px;
  }

  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export type IFirstStepProps = {
  setRegStage: (e: STAGE) => void;
  otpSelected: number;
  setOtpSelected: any;
  OTPMethods: string[];
};

export const FirstStep: FC<IFirstStepProps> = ({
  setRegStage, otpSelected, setOtpSelected, OTPMethods,
}) => {
  const { formUser, setFormUser } = useContext(RegisterContext);

  const {
    control,
    clearErrors,
    setValue,
    resetField,
    setError,
    register,
    watch,
    handleSubmit,
    getValues,
    formState: { errors, isValid },
  } = useForm<IFormValues>({
    defaultValues: {
      phoneNumber: formUser.phoneNumber,
      email: formUser.email,
      password: formUser.password,
      retypePassword: '',
      birthDate: '01/01/2000',
    },
  });

  const { t, i18n } = useTranslation();

  const GlobalModalStorage = useContext(GlobalModalStore);

  const signupMutation = useMutation((data: IAuthSignupRequest) => AuthApi.signup(data), {
    onError: (error: any) => {
      setError('badSignup', {
        type: 'manual',
        message: 'auth.errors.password.serverError',
      });
      if (error?.response?.data?.errorCode === 'ERR_AUTHUSER_EXIST') {
        GlobalModalStorage.makeVisible(false, t('auth.errors.password.serverRegErrorUserExist'));
      } else GlobalModalStorage.makeVisible(false, t('auth.errors.password.serverRegError'));
    },
    onSuccess: (data) => {
      setRegStage(STAGE.FINAL);
    },
  });

  const phone: IFinalInput = {
    name: 'phoneNumber',
    label: 'auth.phone',
    type: 'phone',
    rules: isPhoneNumberGood,
  };

  const [showPass, setShowPass] = useState(false);
  const [showRetypePass, setShowRetypePass] = useState(false);
  const LanguageStore = useContext(LanguageStorage);

  const handleCheckType = (name: string, typeDefault: any) => {
    switch (name) {
      case 'password':
        return showPass ? 'text' : 'password';
      case 'retypePassword':
        return showRetypePass ? 'text' : 'password';
      default:
        return typeDefault;
    }
  };

  const advisePassword = 'settings.inputs.password.errors.pattern';

  const [openGenderMenu, setOpenGenderMenu] = useState(false);
  const [genderId, setGenderId] = useState(0);

  const [openCountryMenu, setOpenCountryMenu] = useState(false);
  const [countryId, setCountryId] = useState(0);

  const [agreeClicked, setAgreeClicked] = useState(false);
  const handleClickAgree = () => {
    setAgreeClicked((a) => !a);
  };

  const onSubmit = (data) => {
    const updatedFormUser = {
      ...formUser,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      gender: data.gender,
      country: data.country,
      password: data.password,
      phoneNumber: data.phoneNumber,
    };

    const { birthDate } = updatedFormUser;
    const sendData = {
      ...updatedFormUser,
      birthDate: formatDateCustom(birthDate),
    };

    setFormUser(updatedFormUser);
    signupMutation.mutate(sendData);
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%' }}>
      <FlexColumn>
        <AuthInputWrapper>
          <Title>
            {t('auth.registration.title.1')}
            {' '}
            <span style={{ color: '#F14F0A' }}>{t('auth.registration.title.2')}</span>
            <br />
            {t('auth.registration.title.3')}
          </Title>
        </AuthInputWrapper>
      </FlexColumn>
      {registerInputs.map((it, idx) => (
        <FlexColumn>
          <AuthInputWrapper style={{ marginBottom: 5, marginTop: idx !== 0 && 10 }}>
            <Controller
              name={it.name}
              control={control}
              rules={it.name === 'retypePassword' ? it.rules(watch) : it.rules}
              render={({ field: { ref, ...field } }) => (
                <ValidationInputComponent
                  {...field}
                  name={it.name}
                  type={handleCheckType(it.name, it.type)}
                  isError={!!errors[it.name]}
                  label={t(it.label)}
                />
              )}
            />
            {it.name === 'password' && (
              <PassIcon
                lang={LanguageStore.language}
                onClick={() => setShowPass((prev) => !prev)}
                src={showPass ? '/images/show.svg' : '/images/hide.svg'}
              />
            )}

            {it.name === 'retypePassword' && (
              <PassIcon
                lang={LanguageStore.language}
                onClick={() => setShowRetypePass((prev) => !prev)}
                src={showRetypePass ? '/images/show.svg' : '/images/hide.svg'}
              />
            )}
          </AuthInputWrapper>
          {it.name === 'password' && !errors[it.name]
                        && (
                          <StyledAdvise>
                            {t(advisePassword)}
                          </StyledAdvise>
                        )}
          <ErrorText hasError={!!errors[it.name]} style={{ maxWidth: 300 }}>
            {t(errors[it.name]?.message?.toString())}
          </ErrorText>
        </FlexColumn>
      ))}

      <FlexColumn>
        <AuthInputWrapper style={{ marginBottom: 5, marginTop: 10 }}>
          <DateInputComponent
            name="birthDate"
            label={t('adminUserDetail.birthDate.placeholder')}
            register={register}
            setValue={setValue}
            errors={errors}
            watch={watch}
          />
        </AuthInputWrapper>
      </FlexColumn>
      <FlexColumn>
        <AuthInputWrapper style={{ marginBottom: 5, marginTop: 10 }}>
          <DropdownComponent
            openMenu={openGenderMenu}
            setOpenMenu={setOpenGenderMenu}
            itemId={genderId}
            setId={setGenderId}
            hasSubTitle
            subtitle={t('auth.gender')}
            itemList={Genders}
          />
        </AuthInputWrapper>
      </FlexColumn>
      <FlexColumn>
        <AuthInputWrapper style={{ marginBottom: 5, marginTop: 10 }}>
          <DropdownComponent
            openMenu={openCountryMenu}
            setOpenMenu={setOpenCountryMenu}
            itemId={countryId}
            setId={setCountryId}
            hasSubTitle
            subtitle={t('common.category')}
            itemList={Countries}
          />
        </AuthInputWrapper>
      </FlexColumn>
      <FlexColumn>
        <AuthInputWrapper style={{ marginBottom: 5, marginTop: 10 }}>
          <PhoneInputComponent name="phoneNumber" errors={errors} control={control} resetField={resetField} />
        </AuthInputWrapper>
        <ErrorText hasError={!!errors[phone.name]} style={{ maxWidth: 300, marginBottom: 10 }}>
          {t(errors[phone.name]?.message?.toString())}
        </ErrorText>
      </FlexColumn>
      <FlexColumnStart>
        <OTPMethodTitle>{t('auth.selectMethod')}</OTPMethodTitle>
        <FlexRow style={{ gap: 8, width: '100%', justifyContent: 'start' }}>
          {OTPMethods.map((m, id) => (
            <OtpMethodButton
              id={id}
              setId={setOtpSelected}
              selected={otpSelected === id}
              key={m}
              title={m}
            />
          ))}
        </FlexRow>
      </FlexColumnStart>
      <UserAgreementComponent handleClick={handleClickAgree} isClicked={agreeClicked} />
      <FlexRowEnd style={{ marginTop: 16 }}>
        <StyledButton
          variant="darkBlue"
          noRadius
          disabled={!agreeClicked}
          style={{
            height: 60, width: 170, fontSize: 16, fontWeight: 600,
          }}
          type="submit"
        >
          {t('common.next')}
        </StyledButton>
      </FlexRowEnd>
      <FlexRow style={{ marginTop: 16, marginBottom: 21 }}>
        <AlreadyHaveAccount />
      </FlexRow>
      <FlexColumnStart>
        <InformationCards style={{
          marginBottom: 12,
        }}
        />
        <StyledButton
          variant="darkBlue"
          noRadius
          style={{
            marginTop: 12,
            fontSize: 16,
            fontWeight: 900,
            height: 61,
            width: 171,
            border: 'none',
            background: colors.primary,
          }}
        >
          {t('auth.emailUs')}
        </StyledButton>
      </FlexColumnStart>
    </form>
  );
};

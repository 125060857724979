import {
  action, autorun, computed, makeAutoObservable,
} from 'mobx';
import { createContext } from 'react';
import { ICurrentUserResponse } from '../../service/user-api/models';
import { ICountry, IGender } from '../../service/auth-api/models';

export const initialProfile: ICurrentUserResponse = {
  id: '',
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  birthDate: '',
  registeredOn: '',
  country: ICountry.egypt,
  gender: IGender.male,
  balance: 0,
  balanceInUsd: 0,
  roles: [],
};

class ProfileStorage {
  profile: ICurrentUserResponse = localStorage.profile ? JSON.parse(localStorage.profile) : initialProfile;

  welcome: boolean = localStorage.welcome ? JSON.parse(localStorage.welcome) : false;

  promo: boolean = localStorage.promo ? JSON.parse(localStorage.promo) : false;

  bonus: boolean = localStorage.bonus ? JSON.parse(localStorage.bonus) : false;

  constructor() {
    makeAutoObservable(this, {}, { autoBind: true });
    autorun(() => {
      localStorage.profile = JSON.stringify(this.profile);
      localStorage.welcome = JSON.stringify(this.welcome);
      localStorage.promo = JSON.stringify(this.promo);
      localStorage.bonus = JSON.stringify(this.bonus);
    });
  }

  @action setProfile(newProfile: ICurrentUserResponse) {
    this.profile = newProfile;
  }

  @computed getProfile(): ICurrentUserResponse {
    return this.profile;
  }

  @action setWelcome(newWelcome: boolean) {
    this.welcome = newWelcome;
  }

  @computed getIsWelcome(): boolean {
    return this.welcome;
  }

  @action setPromo(newPromo: boolean) {
    this.promo = newPromo;
  }

  @computed getIsPromo(): boolean {
    return this.promo;
  }

  @action setBonus(newBonus: boolean) {
    this.bonus = newBonus;
  }

  @computed getIsBonus(): boolean {
    return this.bonus;
  }

  @computed getBalance(): number {
    return this?.profile?.balance || 0;
  }

  @computed getBalanceInUsd(): number {
    return this?.profile?.balanceInUsd || 0;
  }

  @action
  clear() {
    this.profile = initialProfile;
  }
}

export default createContext(new ProfileStorage());

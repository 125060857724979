import React from 'react';
import styled from 'styled-components/macro';
import { FlexColumn } from '../../style/projectComponents';
import { colors } from '../../style/colors';

interface SetPhotoComponentProps {
  fileUrl: string
  handleChangeFile: any
}

const SetPhotoButtonWrapper = styled(FlexColumn)`
  width: 100%;
  height: 220px;
  border-radius: 16px;
  margin-bottom: 12px;
  cursor: pointer;
  background-size: cover;
  background: ${colors.blueDark};
  border: 1px solid rgb(219, 219, 219);
`;
const SetPhotoButton = styled.input`
  width: 120px;
  height: 120px;
  opacity: 0;
  cursor: pointer;
  position: relative;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
`;

export const SetPhotoComponent: React.FC<SetPhotoComponentProps> = ({
  fileUrl,
  handleChangeFile,
}) => (
  <SetPhotoButtonWrapper
    style={{
      backgroundImage: fileUrl ? `url(${fileUrl})` : 'url(/images/buttons/upload-image.jpeg)',
      backgroundSize: 'cover',
    }}
  >
    <SetPhotoButton accept=".png,.jpg,.jpeg" onChange={handleChangeFile} type="file" />
  </SetPhotoButtonWrapper>
);

import React, { useContext, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useQuery } from 'react-query';
import { AppStyle, FlexRowBetween, Page } from '../style/projectComponents';
import { PaymentColumn } from '../components/Cart/PaymentColumn';
import { CartColumn } from '../components/Cart/Cart/CartColumn';
import { CartFinalStage } from '../components/Cart/CartFinalStage';
import CartApi from '../service/cart-api/CartApi';
import { AmountToPayMobile } from '../components/Cart/AmountToPayMobile';
import CartCleanModal from '../components/global/modal/CartCleanModal';
import UserApi from '../service/user-api/UserApi';
import { CharityModal } from '../components/global/modal/CharityModal';
import { CARTSTAGE } from '../utils/constants';
import UserStorage from '../storage/UserStorage/UserStorage';
import { ZERO_EVENT_CODE_ID } from '../config/lockedEvents';

const CartPageWrapper = styled(FlexRowBetween)`
  margin-top: 32px;
  gap: 32px;
`;

export const CartPage = () => {
  const [cartStage, setCartStage] = useState(CARTSTAGE.BASE);

  const UserStore = useContext(UserStorage);
  const isAuth = !!UserStore.accessToken;

  const { data: cart } = useQuery(
    'get_cart_data',
    () => CartApi.getCart({
      skip: 0,
      take: 50,

    }),
    {
      enabled: isAuth,
    },
  );
  const cartItems = cart?.items?.items;
  const priceWithDiscount = cart?.priceWithDiscount || 0;
  const price = cart?.totalPrice || 0;
  const totalPriceInUsd = cart?.totalPriceInUsd || 0;
  const totalTickets = cartItems?.reduce((acc, c) => acc + c.amount * c.productTicketsAmount, 0) || 0;

  const [openBonus, setOpenBonus] = useState(true);
  const handleBonus = () => setOpenBonus((s) => !s);

  const [openCartClean, setOpenCartClean] = useState(false);
  const handleCartClean = () => setOpenCartClean((s) => !s);

  useEffect(() => {
    if (cartItems?.some((c) => c.productTicketsAmount > 0 && !c?.withActiveEvent)) {
      setOpenCartClean(true);
    }
  }, [cartItems]);

  const { data: address, isLoading, isSuccess } = useQuery(
    ['user_address'],
    () => UserApi.getAddress(),
    {
      enabled: isAuth,
    },
  );
  const [charityModal, setCharityModal] = useState(false);

  return (
    <>
      {charityModal && <CharityModal setIsOpen={setCharityModal} ticketsAmount={totalTickets} />}
      {/* <BonusModal openMenu={openBonus} handleMenu={handleBonus} /> */}
      <CartCleanModal openMenu={openCartClean} handleMenu={handleCartClean} cartItems={cartItems} />
      <AppStyle>
        <Page>
          {(cartStage === 'BASE' || cartStage === 'CHECKOUT')
                    && (
                    <CartPageWrapper>
                      {cartStage === 'BASE' && <CartColumn cartItems={cartItems} />}
                      {isSuccess && (
                      <PaymentColumn
                        price={price}
                        totalPriceInUsd={totalPriceInUsd}
                        setCartStage={setCartStage}
                        totalTickets={totalTickets}
                        address={address}
                        setCharityModal={setCharityModal}
                        isCheckout={cartStage === 'CHECKOUT'}
                      />
                      )}
                      {cartStage === 'BASE' && isAuth && <AmountToPayMobile price={price} setCartStage={setCartStage} />}
                    </CartPageWrapper>
                    )}
          {cartStage === 'FINAL' && <CartFinalStage />}
        </Page>
      </AppStyle>
    </>
  );
};

import { Controller, useForm } from 'react-hook-form';
import React, { FC, useContext } from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useMutation } from 'react-query';
import { isPasswordGood } from '../Register/SecUtils';
import { ErrorText, FlexRow, StyledButton } from '../../../style/projectComponents';
import { ValidationInputComponent } from '../../global/ValidationInputComponent';
import AuthApi from '../../../service/auth-api/AuthApi';
import { IResetPasswordRequest } from '../../../service/auth-api/models';
import GlobalModalStore from '../../../storage/GlobalModalStore/GlobalModalStore';

const InputWrapper = styled(FlexRow)`
  position: relative;
  align-items: start;
  width: 300px;
  @media (max-width: 480px) {
    label {
      left: 20px;
    }
  }
`;

type IFormValues = {
  newPassword: string,
  badReset: any
};

type IInputs = {
  name: 'badReset' | 'newPassword',
  label: string,
  type: string,
  rules: any
};

const baseInputs: IInputs[] = [
  {
    name: 'newPassword',
    label: 'forgotPassword.newPass',
    type: 'password',
    rules: isPasswordGood,
  },
];

const Content: FC = (props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const {
    control, clearErrors, setError, handleSubmit, formState: { errors, isValid },
  } = useForm<IFormValues>();

  const resetPassQuery = useMutation((data: IResetPasswordRequest) => AuthApi.resetPassword(data), {
    onSuccess: (data) => {
      navigate('/login');
    },
    onError: (error) => {
      setError('badReset', {
        type: 'manual',
        message: 'auth.errors.password.serverError',
      });
    },
  });

  const [searchParams, setSearchParams] = useSearchParams();

  const GlobalModalStorage = useContext(GlobalModalStore);

  const onSubmit = (data: IFormValues) => {
    const req: IResetPasswordRequest = {
      newPassword: data.newPassword,
      resetToken: searchParams.get('token'),
      userId: searchParams.get('userId'),

    };
    resetPassQuery.mutate(req, {
      onSuccess: (data) => {
        GlobalModalStorage.makeVisible(true, t('common.success'));
      },
      onError: (error) => {
        GlobalModalStorage.makeVisible(false, t('common.error'));
      },
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {baseInputs.map((it, idx) => (
        <>
          <InputWrapper style={{ marginBottom: 5, marginTop: idx !== 0 && 10 }}>
            <Controller
              name={it.name}
              control={control}
              rules={it.rules}
              render={({ field: { ref, ...field } }) => (
                <ValidationInputComponent
                  {...field}
                  type={it.type}
                  isError={!!errors[it.name]}
                  label={t(it.label)}
                />
              )}
            />
          </InputWrapper>
          <ErrorText hasError={!!errors[it.name]} style={{ maxWidth: 300 }}>
            {t(errors[it.name]?.message?.toString())}
          </ErrorText>
        </>
      ))}
      <StyledButton
        style={{ width: '100%', height: 54, marginTop: 8 }}
        disabled={resetPassQuery.isLoading || !isValid}
        variant="darkBlue"
        noRadius
        onClick={() => clearErrors()}
        type="submit"
      >
        {t('forgotPassword.update')}
      </StyledButton>
      <ErrorText style={{ marginTop: 10 }} hasError={!!errors.badReset}>
        {t(errors?.badReset?.message.toString())}
      </ErrorText>
    </form>
  );
};

export default observer(Content);

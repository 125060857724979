import React, { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useSearchParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  FlexColumn, Page, StatsTitle,
} from '../../../style/projectComponents';
import { colors } from '../../../style/colors';

const UserPageWrapper = styled(FlexColumn)`
  margin-top: 40px;
  gap: 24px;
  width: 100%;
`;

const ContentBlock = styled(FlexColumn)`
  padding: 12px;
  height: 300px;
  width: 100%;
  background: ${colors.white};
  border-radius: 12px;
`;

interface AdminUserPageProps {
}

export const AdminUserPage: FC<AdminUserPageProps> = () => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const userId = searchParams.get('id');
  // TODO: добавить загрузку данных пользователя
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <Page>
      <UserPageWrapper>
        <StatsTitle style={{ marginBottom: 0 }}>{t('user.personalDetails')}</StatsTitle>
        <ContentBlock>
          <p>
            {t('adminAnalytics.userPage')}
          </p>
        </ContentBlock>
      </UserPageWrapper>
    </Page>
  );
};

import React, { useContext, useState } from 'react';
import { useQuery } from 'react-query';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  AppStyle, AppTextMedium, Page, FlexRow, FlexColumnStart, FlexColumn, StyledButton,
} from '../style/projectComponents';
import LotteryApi from '../service/lottery-api/LotteryApi';
import { DrawPreview } from '../components/Draws/DrawPreview';
import { colors } from '../style/colors';
import { DrawBlock } from '../components/Draws/DrawBlock';
import { WinnersCards } from '../components/Home/sections/WinnersCards';
import LotteryTicketsApi from '../service/lottery-tickets-api/LotteryTicketsApi';
import { VideoModal } from '../components/global/modal/VideoModal';
import { DrawUpcomingBlock } from '../components/Draws/DrawUpcomingBlock';
import { ZERO_EVENT_CODE_ID } from '../config/lockedEvents';
import UserStorage from '../storage/UserStorage/UserStorage';

const DrawsWrapper = styled(FlexRow)`
  gap: 6px;
  align-items: start;
  justify-content: start;
  overflow-x: scroll;
  -webkit-overflow-scrolling: touch; // Optional: for smooth scrolling on iOS
  width: 100%;
  margin-top: 16px;
  margin-bottom: 16px;
`;
const ActionsWrapper = styled(FlexRow)`
  background: #fff;
  height: 60px;
  width: 100%;
  border-radius: 12px;
  margin-bottom: 16px;
`;
const ActionButton = styled.button`
  background: none;
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  height: 100%;
  cursor: pointer;
  border-radius: 12px;
  border: none;
`;
const Draws = styled(FlexColumn)`
  width: 100%;
  gap: 16px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: start;
  @media (max-width: 768px) {
    gap: 8px;
    flex-direction: column;
  }
`;

export const DrawPage = () => {
  const { t } = useTranslation();
  const { data: events } = useQuery(
    'all_events_data',
    () => LotteryApi.getAllLotteryEvents(
      {
        skip: 0,
        take: 50,
      },
    ),
  );
  const eventsItems = events?.items;
  const eventsItemsFiltered = eventsItems?.filter((e) => e.code !== ZERO_EVENT_CODE_ID);
  const eventItemsSortedByUrl = eventsItemsFiltered?.sort((a, b) => {
    if (a.drawTranslationUrl === b.drawTranslationUrl) {
      return 0;
    }
    return a.drawTranslationUrl ? -1 : 1;
  });
  const [past, setPast] = useState(true);

  const pastEvents = eventsItemsFiltered?.filter((e) => e.status === 'finished');
  const upcomingEvents = eventsItemsFiltered?.filter((e) => (e.status === 'created' || e.status === 'inProgress'));

  const { data: winners, isLoading } = useQuery(
    'all_tickets_winners',
    () => LotteryTicketsApi.getLotteryTicketsWinners(
      {
        skip: 0,
        take: 20,
      },
    ),
  );
  const winnersItems = winners?.items;
  const mockWinners = [
    {
      announcementDate: 'soon',
      code: 'soon',
      prizeImageDownloadUrl: '/images/mock/coming-soon-grey.png',
      winnerFirstName: 'Soon',
      winnerLastName: '',
      prizeName: '',
    },
    {
      announcementDate: 'soon',
      code: 'soon',
      prizeImageDownloadUrl: '/images/mock/coming-soon-grey.png',
      winnerFirstName: 'Soon',
      winnerLastName: '',
      prizeName: '',
    },
    {
      announcementDate: 'soon',
      code: 'soon',
      prizeImageDownloadUrl: '/images/mock/coming-soon-grey.png',
      winnerFirstName: 'Soon',
      winnerLastName: '',
      prizeName: '',
    },
    {
      announcementDate: 'soon',
      code: 'soon',
      prizeImageDownloadUrl: '/images/mock/coming-soon-grey.png',
      winnerFirstName: 'Soon',
      winnerLastName: '',
      prizeName: '',
    },
  ];
  const [videoUrl, setVideoUrl] = useState('');
  const [videoModal, setVideoModal] = useState(false);
  const openVideo = (translationUrl: string) => {
    setVideoUrl(translationUrl);
    setVideoModal(true);
  };

  const UserStore = useContext(UserStorage);
  const isAuth = !!UserStore.accessToken;
  const navigate = useNavigate();

  return (
    <AppStyle>
      <Page>
        {videoModal && <VideoModal videoUrl={videoUrl} setIsOpen={setVideoModal} />}
        <FlexColumnStart style={{ width: '100%' }}>
          <AppTextMedium>
            {t('common.draws')}
          </AppTextMedium>
          <DrawsWrapper>
            {eventItemsSortedByUrl?.map((e) => (
              <DrawPreview
                image={e?.imageDownloadLink}
                openVideo={openVideo}
                drawDate={e?.drawDate}
                translationUrl={e?.drawTranslationUrl}
                key={e.id}
              />
            ))}
          </DrawsWrapper>
          {isAuth && (
            <ActionsWrapper>
              <ActionButton style={{ background: past && colors.accent }} onClick={() => setPast(true)}>
                {t('common.past')}
              </ActionButton>
              <ActionButton style={{ background: !past && colors.accent }} onClick={() => setPast(false)}>
                {t('common.upcoming')}
              </ActionButton>
            </ActionsWrapper>
          )}
          {!isAuth && (
            <FlexColumn style={{ width: '100%', height: 300 }}>
              <StyledButton
                variant="darkBlue"
                noRadius
                style={{
                  height: 50,
                  background: colors.primary,
                  border: `1px solid ${colors.primary}`,
                  marginTop: 24,
                }}
                onClick={() => navigate('/register')}
              >
                {t('common.registerToJoin')}
              </StyledButton>
            </FlexColumn>
          )}
          {isAuth && past && (
            <Draws>
              {pastEvents?.map((e) => e?.drawTranslationUrl
                                && <DrawBlock event={e} key={e?.id} openVideo={openVideo} />)}
            </Draws>
          )}
          {isAuth && !past && (
            <Draws>
              {upcomingEvents?.map((e) => e?.drawTranslationUrl
                                && <DrawUpcomingBlock event={e} key={e?.id} openVideo={openVideo} />)}
            </Draws>
          )}
          {isAuth && (
          <AppTextMedium style={{ marginTop: 32, marginBottom: 16 }}>
            {t('common.winners')}
          </AppTextMedium>
          )}
          {isAuth && <WinnersCards isLoading={isLoading} winners={mockWinners} isSmall />}
        </FlexColumnStart>
      </Page>
    </AppStyle>
  );
};

import React, { FC, useContext, useState } from 'react';
import styled from 'styled-components';
import { format, intervalToDuration } from 'date-fns';
import { enUS } from 'date-fns/locale';
import { useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import { observer } from 'mobx-react-lite';
import { colors } from '../../../style/colors';
import {
  FlexColumn,
  FlexRow,
  FlexRowBetween,
} from '../../../style/projectComponents';
import { CampaignProgressBar } from './CampaignProgressBar';
import UserStorage from '../../../storage/UserStorage/UserStorage';
import { LoginModal } from '../../global/modal/LoginModal';
import LotteryApi from '../../../service/lottery-api/LotteryApi';
import SelectQuantityModal from '../../global/modal/SelectQuantityModal';
import { ICartItem } from '../../../service/cart-api/models';
import { AddToCartButton } from '../../Product/AddToCartButton';
import CurrencyStorage from '../../../storage/CurrencyStorage/CurrencyStorage';

const Block = styled(FlexRowBetween)`
  width: 100%;
  max-width: calc(33% - 8px);
  height: 550px;
  border-radius: 24px;
  background: #fff;
  align-items: start;
  position: relative;
  overflow: hidden;
  @media (max-width: 920px) {
    height: auto;
    max-width: 328px;
  }
  @media (max-width: 480px) {
    height: auto;
    max-width: 100%;
  }
`;

const PrizeAmount = styled.h1`
  font-weight: 900;
  font-size: 22px;
  color: ${colors.black};
  margin: 40px 20px 0 20px;
`;
const Description = styled.h1`
  font-weight: 400;
  font-size: 10px;
  color: ${colors.gray400};
  margin: 0;
  text-align: center;
`;

const Content = styled(FlexColumn)`
  width: 100%;
  background: ${colors.blueDark};
  @media (max-width: 920px) {
    width: 328px;
    max-width: 328px;
  }
  @media (max-width: 480px) {
    height: auto;
    width: 100%;
    max-width: 100%;
  }
`;
const PrizeInfo = styled(FlexColumn)`
  align-items: start;
  width: 100%;
`;

interface ExploreCampaignBlockProps {
  id: string;
  drawDate: string;
  prize: string;
  image: string;
  productImage: string;
  price: number;
  ticketsLimit: number;
  ticketsPurchased: number;
  inFavorite: boolean;
  ticketsAmount: number;
  eventId: string;
  cartItems: ICartItem[];
  priceInUsd: number;
}

const ActionsPanel = styled(FlexRow)`
  width: 100%;
`;
const ButtonsWrapper = styled(FlexRow)`
  width: calc(100% - 40px);
  margin-top: 16px;
  margin-bottom: 30px;
  @media (max-width: 920px) {
    width: calc(100% - 40px);
  }
`;
const CampaignImage = styled.img`
  height: 227px;
  width: 388px;
  border-radius: 24px;
  @media (max-width: 920px) {
    height: 192px;
    width: 100%;
  }
`;
const WinImage = styled.img`
  width: 100px;
  position: absolute;
  left: 20px;
  bottom: -20px;
`;
const ImagesWrapper = styled(FlexColumn)`
  width: 100%;
  position: relative;
`;
const SoldOutImage = styled.img`
  width: 200px;
`;
const BlockHeader = styled(FlexRow)`
  background: ${colors.blueDark};
  width: 100%;
  height: 36px;

  p {
    font-size: 20px;
    font-weight: 700;
    color: transparent;
    -webkit-text-stroke: 1px #fff;
    text-stroke: 1px #fff;
    -webkit-background-clip: text;
    background-clip: text;
    text-transform: uppercase;
  }
`;
const CampaignInfo = styled(FlexColumn)`
  padding-bottom: 20px;
  gap: 16px;
  background: #fff;
  border-radius: 32px 32px 0 0;
  padding-top: 24px;
  @media (max-width: 480px) {
    width: 100%;
  }
`;

const ExploreCampaignBlock: FC<ExploreCampaignBlockProps> = ({
  id,
  drawDate,
  price,
  image,
  productImage,
  prize,
  ticketsLimit,
  ticketsPurchased,
  inFavorite,
  ticketsAmount,
  eventId,
  cartItems, priceInUsd,
}) => {
  const { t } = useTranslation();
  const formattedDate = drawDate && format(new Date(drawDate), 'dd MMMM yyyy', { locale: enUS }) || 0;
  const UserStore = useContext(UserStorage);
  const isAuth = !!UserStore.accessToken;
  const [loginModal, setLoginModal] = useState(false);
  const isSoldOut = ticketsPurchased >= ticketsLimit;

  const { data: event } = useQuery(
    ['event_data', eventId],
    () => LotteryApi.getLotteryEvent(eventId),
  );
  const hideEventBlock = event?.status === 'finished' || event?.status === 'inProgress';

  const [openMenu, setOpenMenu] = useState(false);
  const handleMenu = () => setOpenMenu((s) => !s);
  const addToCart = () => {
    if (isAuth) {
      setOpenMenu(true);
    } else {
      setLoginModal(true);
    }
  };

  const startDate = new Date(Date.now());
  const endDate = new Date(drawDate);
  const duration = drawDate && intervalToDuration({ start: startDate, end: endDate });
  const { days } = duration;
  const hours = duration?.hours + duration?.days * 24; // Добавляем дни к часам
  const { minutes } = duration;
  const { seconds } = duration;

  const formattedDuration = days && days <= 1 && `${hours}:${String(minutes).padStart(2, '0')}:${String(seconds).padStart(2, '0')}` || days && `${days} days`;
  const closingText = startDate && endDate && startDate?.getTime() > endDate?.getTime() && 'CLOSED' || `Closing in ${formattedDuration}`;

  const productAmountInCart = cartItems?.find((c) => c.productId === id)?.amount || 0;

  const CurrencyStore = useContext(CurrencyStorage);
  const currency = CurrencyStore?.currency;

  return (
    <>
      <LoginModal isOpen={loginModal} setIsOpen={setLoginModal} />
      <SelectQuantityModal handleMenu={handleMenu} openMenu={openMenu} productId={id} />
      <Block style={{ display: hideEventBlock && 'none' }}>
        <Content>
          <BlockHeader>
            <p>{closingText}</p>
          </BlockHeader>
          <CampaignInfo>
            {ticketsLimit > 0
                        && <CampaignProgressBar ticketsPurchased={ticketsPurchased} ticketsLimit={ticketsLimit} />}
            <PrizeInfo>
              <ImagesWrapper>
                <CampaignImage src={image} alt="" />
                <WinImage src="/images/interface/win.svg" alt="" />
              </ImagesWrapper>
              <PrizeAmount>{prize}</PrizeAmount>
              {isSoldOut && (
                <FlexRow style={{ width: '100%' }}>
                  <SoldOutImage src="/images/interface/sold-out.png" alt="" />
                </FlexRow>
              )}
              {!isSoldOut && (
                <ActionsPanel>
                  <ButtonsWrapper>
                    <AddToCartButton
                      action={addToCart}
                      image={productImage}
                      name=""
                      productId={id}
                      amount={productAmountInCart}
                      price={price}
                      priceInUsd={priceInUsd}
                      amountTickets={ticketsAmount}
                      currency={currency}
                    />
                  </ButtonsWrapper>
                </ActionsPanel>
              )}
            </PrizeInfo>
            <Description>
              {t('adminUserDetail.drawDate')}
              :
              {formattedDate}
              {' '}
              {t('common.orEarlierSoldOut')}
            </Description>
          </CampaignInfo>
        </Content>
      </Block>
    </>
  );
};

export default observer(ExploreCampaignBlock);
